var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "fs-14 my-2" },
    [
      _c(
        "b-card-title",
        { staticClass: "fs-14" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "my-1", attrs: { md: "8" } },
                [
                  _vm._v(" Поставщик:"),
                  _c("br"),
                  _c(
                    "router-link",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        to: {
                          name: "OrganizationShow",
                          params: { id: _vm.offer.organization.id },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _vm.offer.organization &&
                      _vm.offer.organization.region_code === "02"
                        ? _c("img", {
                            staticClass: "mr-2",
                            staticStyle: { "max-height": "20px" },
                            attrs: { src: "/images/icons/02.png", alt: "02" },
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.offer.organization.name) + " "),
                    ]
                  ),
                ],
                1
              ),
              _c("b-col", { staticClass: "my-1", attrs: { md: "4" } }, [
                _vm._v(" Цена предложения, руб.:"),
                _c("br"),
                _c("strong", [
                  _vm._v(_vm._s(_vm.$formatPrice(_vm.offer.price))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "my-1", attrs: { md: "4" } }, [
            _vm._v(" Время подачи предложения:"),
            _c("br"),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.getDateTimeFormatSeconds(_vm.offer.created_at))
              ),
            ]),
            _c("br"),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "my-1", attrs: { md: "4" } }, [
            _c("p", [
              _vm._v(" Рег.номер ТРУ: "),
              _c("strong", [_vm._v(_vm._s(_vm.offer.ktru_item.reg_number))]),
            ]),
            _c("p", [
              _vm._v(" Наименование ТРУ: "),
              _c("strong", [_vm._v(_vm._s(_vm.offer.ktru_item.name))]),
            ]),
            _c("p", [
              _vm._v(" Код ОКПД2: "),
              _c("strong", [_vm._v(_vm._s(_vm.offer.ktru_item.okpd_code))]),
            ]),
            _c("p", [
              _vm._v(" Единица измерения: "),
              _c("strong", [
                _vm._v(_vm._s(_vm.offer.ktru_item.okei_local_symbol)),
              ]),
            ]),
            _c("p", [
              _vm._v(" Страна происхождения: "),
              _c("strong", [
                _vm._v(_vm._s(_vm.offer.ktru_item.country_of_origin_name)),
              ]),
            ]),
          ]),
          _c("b-col", { attrs: { md: "8" } }, [
            _vm.offer.ktru_item.description
              ? _c("p", [
                  _c("strong", [_vm._v("Описание")]),
                  _vm._v(" - " + _vm._s(_vm.offer.ktru_item.description)),
                ])
              : _vm._e(),
            _vm.offer.ktru_item.specs.length > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.offer.ktru_item.specs, function (spec, index) {
                    return _c("li", { key: "spec_" + index }, [
                      _c("strong", [_vm._v(_vm._s(spec.name))]),
                      _vm._v(" - " + _vm._s(spec.value) + " "),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }