<template>
    <b-card class="fs-14 my-2">
        <proposal-card-title :index="index" :proposal="proposal" class="fs-16" />
        <b-row class="mb-0 pb-0">
            <b-col class="my-1" md="6">
                Время подачи предложения: <strong>{{ getDateTimeFormatSeconds(proposal.created_at) }}</strong
                ><br />
                <br />
                <b :class="'fs-16' + (proposal.status.id === 'winner' ? ' green-color' : proposal.status.id === 'rejected' ? ' text-danger' : '')">{{ proposal.status.title }}. </b>
                <template v-if="proposal.status.id === 'rejected'"
                    ><span class="fs-16"><b>Причина отклонения:</b> {{ proposal.rejection_reason }}</span></template
                >
            </b-col>
            <b-col class="my-1" md="3">
                <template v-if="proposal.supplier.rating !== null">
                    Рейтинг ИС: <strong>{{ proposal.supplier.rating }}</strong
                    ><br />
                </template>
                Рейтинг Контур.Фокус: <shield-red v-if="proposal.supplier.kontur_rating.red" /><shield-yellow v-if="proposal.supplier.kontur_rating.yellow" /><shield-green v-if="proposal.supplier.kontur_rating.green" />
            </b-col>
            <b-col class="my-1 text-right" md="3">
                <b-button :aria-expanded="detailsVisible ? 'true' : 'false'" :class="detailsVisible ? null : 'collapsed'" aria-controls="collapse-proposal-details" variant="custom-grey" class="fs-14 my-1 w-100" size="sm" @click="detailsVisible = !detailsVisible">
                    {{ detailsVisible ? 'Скрыть' : 'Показать' }} подробности
                </b-button>
            </b-col>
        </b-row>
        <b-collapse id="collapse-proposal-details" v-model="detailsVisible">
            <b-row class="my-1">
                <b-col md="9">
                    <div>
                        Карточка предприятия -
                        <template v-if="proposal.organization_card && proposal.organization_card.id">
                            <a :href="$getAttachmentDownloadLink(proposal.organization_card.id)">{{ proposal.organization_card.name }}</a>
                        </template>
                        <template v-else> Отсутствует</template>
                    </div>

                    <div v-if="proposal.without_nds">Организация освобождена от уплаты НДС</div>

                    <div v-if="supplierRequirements.only_smp || supplierRequirements.only_not_rnp || supplierRequirements.has_additional_requirements">
                        <strong>Требования к поставщикам:</strong><br />
                        <ul>
                            <template v-if="supplierRequirements.only_smp">
                                <li>
                                    <template v-if="proposal.supplier_requirements_confirmed.is_smp">
                                        <shield-green />
                                        Поставщик является субъектом малого предпринимательства (СМП)
                                    </template>
                                    <template v-else>
                                        <shield-red />
                                        Поставщик <strong>НЕ</strong> является субъектом малого предпринимательства (СМП)
                                    </template>
                                </li>
                            </template>
                            <template v-if="supplierRequirements.only_not_rnp">
                                <li>
                                    <template v-if="proposal.supplier_requirements_confirmed.is_not_rnp">
                                        <shield-green />
                                        Поставщик подтвердил, что <strong>НЕ</strong> присутствует в реестре недобросовестных поставщиков
                                    </template>
                                    <template v-else>
                                        <shield-red />
                                        Поставщик <strong>НЕ</strong> подтвердил, что не присутствует в реестре недобросовестных поставщиков
                                    </template>
                                </li>
                            </template>
                            <template v-if="supplierRequirements.has_additional_requirements">
                                <li>
                                    <template v-if="proposal.supplier_requirements_confirmed.additional_requirements && proposal.supplier_requirements_confirmed.additional_requirements.length > 0">
                                        <shield-green />
                                        Поставщик подтвердил что соответствует дополнительным требованиям
                                    </template>
                                    <template v-else>
                                        <shield-red />
                                        Поставщик <strong>НЕ</strong> подтвердил что соответствует дополнительным требованиям
                                    </template>
                                </li>
                                <span><strong>Документы, подтверждающие соответствие требованиям:</strong></span>
                                <ul>
                                    <li v-for="additionalRequirement in proposal.supplier_requirements_confirmed.additional_requirements" :key="additionalRequirement.id">
                                        {{ additionalRequirement.description }} (Причина или цель установки требования - {{ additionalRequirement.reason }}) -
                                        <a :href="$getAttachmentDownloadLink(additionalRequirement.confirmation_document.id)" class="fs-14">{{ additionalRequirement.confirmation_document.name }}</a>
                                    </li>
                                </ul>
                            </template>
                        </ul>
                    </div>
                    <div>
                        <strong>Дополнительные документы, приложенные поставщиком к предложению:</strong><br />
                        <ul v-if="proposal.attachments.length > 0">
                            <li v-for="(document, docIndex) of proposal.attachments" :key="'provider_' + index + '_document_' + docIndex">
                                <a :href="$getAttachmentDownloadLink(document.id)">{{ document.name }}</a>
                            </li>
                        </ul>
                        <span v-else>Отсутствуют</span>
                    </div>
                </b-col>
                <b-col v-if="!confirmed" class="my-1" md="3">
                    <download-organization-analytics-report-button :organization="proposal.supplier" />
                    <form-row-edit-text-compact :label-cols-lg="12" v-if="withRating && (proposal.status.id === 'actual' || proposal.status.id === 'winner')" v-model="rating" class="my-1 fs-14 w-100 px-0 py-1" label="Рейтинг:" type="number" />
                    <b-button v-if="proposal.status.id === 'actual'" class="fs-14 my-1 w-100" size="sm" variant="custom-green" @click="confirmWinner"> Выбрать победителем</b-button>
                    <reject-commercial-proposal-button :proposal="proposal" :purchase-id="purchaseId" @refresh="refresh" />
                </b-col>
                <b-col v-else class="my-1 text-right" md="3">
                    <p class="fs-14">Рейтинг: {{ proposal.rating ? proposal.rating : 'Не установлен' }}</p>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col md="12">
                    <strong>Спецификация:</strong><br />
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="tcw-50">№</th>
                                <th>Наименование товара (работ, услуг)</th>
                                <th class="tcw-100 text-right">Количество /<br />Ед.изм</th>
                                <th width="120" class="tcw-100 text-right">НДС, % /<br />НДС, руб.</th>
                                <th width="120" class="tcw-100 text-right">Цена за ед. с НДС, руб. / без НДС, руб.</th>
                                <th width="120" class="tcw-100 text-right">Стоимость с НДС, руб. / без НДС, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in proposal.deliverables" :key="item.id" v-bind="item">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.ktru_item.name }}</td>
                                <td class="text-right text-nowrap">
                                    {{ item.amount }}<br />
                                    {{ item.ktru_item.okei_local_symbol }}
                                </td>
                                <td class="text-right text-nowrap">
                                    {{ item.vat ? item.vat.title : '&mdash;' }}<br />
                                    {{ $formatPriceOrMdash(item.sum_vat) }}
                                </td>
                                <td class="text-right text-nowrap">
                                    {{ $formatPrice(item.price_per_unit_with_vat) }}<br />
                                    {{ $formatPriceOrMdash(item.price_per_unit_wo_vat) }}
                                </td>
                                <td class="text-right text-nowrap">
                                    {{ $formatPrice(item.sum_with_vat) }}<br />
                                    {{ $formatPriceOrMdash(item.sum_wo_vat) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-collapse>
    </b-card>
</template>

<script>
import ProposalCardTitle from './proposal-card-title.vue';
import ShieldGreen from '@/components/common/ShieldGreen.vue';
import ShieldRed from '@/components/common/ShieldRed.vue';
import RejectCommercialProposalButton from '@/components/products/commercial/partials/reject-commercial-proposal-button.vue';
import FormRowEditTextCompact from '@/components/common/form-rows/form-row-edit-text-compact.vue';
import ShieldYellow from '@/components/common/ShieldYellow.vue';
import DownloadOrganizationAnalyticsReportButton from '@/components/products/commercial/partials/download-organization-analytics-report-button.vue';

export default {
    name: 'private-commercial-winner-selection-proposal-card',
    props: ['supplierRequirements', 'proposal', 'index', 'purchaseId', 'value', 'v', 'withRating', 'confirmed', 'purchase'],
    components: {
        DownloadOrganizationAnalyticsReportButton,
        ShieldYellow,
        FormRowEditTextCompact,
        RejectCommercialProposalButton,
        ShieldRed,
        ShieldGreen,
        ProposalCardTitle
    },
    data() {
        return {
            detailsVisible: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        confirmWinner() {
            const message = 'Вы действительно хотите выбрать заявку ' + this.proposal.supplier.name + ' победителем?';
            const h = this.$createElement;
            const messageVNode = h('div', [h('p', [message]), h('p', { class: ['text-danger fw-600'] }, ['ВНИМАНИЕ! Данная операция необратима.'])]);
            this.$bvModal
                .msgBoxConfirm([messageVNode], {
                    title: 'Выбор победителя',
                    okTitle: 'Выбрать победителем',
                    okVariant: 'custom-danger',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'custom-outline-secondary',
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.products.commercial.purchases.setWinnerProposal(this.purchaseId, this.proposal.id);
                        this.refresh();
                    }
                });
        },
        confirmReject() {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите отклонить заявку ' + this.proposal.supplier.name + ' на сумму ' + this.$formatPrice(this.proposal.price) + ' сом?', {
                    title: 'Отклонение заявку',
                    okTitle: 'Отклонить',
                    okVariant: 'custom-danger',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'custom-outline-secondary',
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.purchases.competitive.rejectProposal(this.purchaseId, this.proposal.id);
                        this.refresh();
                    }
                });
        }
    },
    computed: {
        rating: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style scoped>
a {
    color: var(--green-color);
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
