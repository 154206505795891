<template>
    <div>
        <b-btn class="btn-card-action btn-card-action-green my-1" v-if="purchase.actions.customer.publish" @click="showModal = true"> Опубликовать </b-btn>
        <b-modal ref="publish-modal" v-model="showModal" size="lg" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Опубликовать закупку">
            <div>
                <p>
                    Вы действительно хотите опубликовать закупку <strong>{{ purchase.reg_number }}</strong
                    >?
                </p>
                <p>
                    <strong>Объект закупки</strong>: {{ purchase.purchase_object }}<br />
                    <strong>Предмет закупки</strong>: {{ purchase.deliverable_group_title }}<br />
                    <strong>Сумма закупки, руб.</strong>: {{ $formatPrice(purchase.start_price) }}<br />
                    <strong>Планируемая дата заключения договора</strong>: {{ getDateFormat(purchase.planned_contract_conclusion_date) }}
                </p>
            </div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showModal = false">Отмена</b-button>&nbsp;
                <b-button class="text-uppercase" variant="custom-green" @click="publishPurchase">Опубликовать</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'cabinet-fl223-notice-card-publish-button',
    props: {
        purchase: {
            type: Object
        }
    },
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        async publishPurchase() {
            if (await this.$api.products.fl223.purchases.publish(this.purchase.id)) {
                this.showModal = false;
                this.$emit('publish');
            }
        }
    }
};
</script>
