<template>
    <div class="mb-2">
        <p class="fs-12 m-0 grey-color">
            {{ label }}:
            <span class="fw-600"><slot></slot></span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'cabinet-notice-card-row-single',
    props: {
        label: {
            type: String,
            required: true
        }
    }
};
</script>
