var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols-sm": _vm.labelColsSm,
            "label-cols-lg": _vm.labelColsLg,
            description: _vm.description,
            label: _vm.label + ":",
            "label-for": _vm.id,
            state: _vm.state,
            "label-class":
              "fs-14 grey-color fw-700 d-flex align-items-baseline",
          },
        },
        [
          _c(
            "div",
            [
              _c("input", {
                ref: "fileload",
                staticStyle: { display: "none" },
                attrs: { type: "file", id: "fileload", accept: ".xml" },
                on: { change: _vm.onChangeFileUpload },
              }),
              _c(
                "b-button",
                {
                  attrs: { variant: _vm.buttonColor, disabled: _vm.disabled },
                  on: { click: _vm.fileClick },
                },
                [
                  _c("b-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "file-plus", size: "sm" },
                  }),
                  _vm._v(" Загрузить МЧД "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.poaDetails
        ? [
            _c("text-row-link", {
              attrs: {
                label: "Номер доверенности",
                "label-right": "",
                compact: "",
                href: _vm.poaDetails.link_check,
                value: _vm.poaDetails.poa_number,
              },
            }),
            _c("text-row", {
              attrs: {
                label: "ИНН доверителя",
                "label-right": "",
                compact: "",
                value: _vm.poaDetails.principal_inn,
              },
            }),
            _c("text-row", {
              attrs: {
                label: "ИНН представителя",
                "label-right": "",
                compact: "",
                value: _vm.poaDetails.representative_inn,
              },
            }),
            _c("text-row", {
              attrs: {
                label: "СНИЛС представителя",
                "label-right": "",
                compact: "",
                value: _vm.poaDetails.representative_snils,
              },
            }),
            _c("text-row-datetime", {
              attrs: {
                "label-right": "",
                compact: "",
                label: "Срок действия, с",
              },
              model: {
                value: _vm.poaDetails.valid_from,
                callback: function ($$v) {
                  _vm.$set(_vm.poaDetails, "valid_from", $$v)
                },
                expression: "poaDetails.valid_from",
              },
            }),
            _c("text-row-datetime", {
              attrs: {
                "label-right": "",
                compact: "",
                label: "Срок действия, по",
              },
              model: {
                value: _vm.poaDetails.valid_to,
                callback: function ($$v) {
                  _vm.$set(_vm.poaDetails, "valid_to", $$v)
                },
                expression: "poaDetails.valid_to",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }