var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("title", function () {
        return [_c("text-header", [_vm._v("Лимиты по КБК")])]
      }),
      _c("b-table", {
        staticClass: "table-atmo",
        attrs: {
          id: "my-table",
          fields: _vm.fields,
          items: _vm.items,
          "details-td-class": "py-1",
          small: "",
          responsive: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "cell(index)",
              fn: function (data) {
                return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
              },
            },
            {
              key: "row-details",
              fn: function (data) {
                return [_c("limit-info", { attrs: { limit: data.item } })]
              },
            },
            {
              key: "cell(kbk)",
              fn: function (data) {
                return [
                  _c("action-button-small", {
                    attrs: {
                      title:
                        (data.detailsShowing ? "Скрыть" : "Показать") +
                        " подробную информацию",
                      icon: data.detailsShowing ? "eye-slash" : "eye",
                    },
                    on: { click: data.toggleDetails },
                  }),
                  _vm._v(" " + _vm._s(data.item.kbk) + " "),
                ]
              },
            },
            {
              key: "cell(subkosgu_code)",
              fn: function (data) {
                return [
                  _vm._v(" " + _vm._s(data.item.subkosgu_code ?? "—") + " "),
                ]
              },
            },
            {
              key: "cell(amount_available)",
              fn: function (data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatPrice(data.item.amount_available)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "cell(amount_total)",
              fn: function (data) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$formatPrice(data.item.amount_total)) + " "
                  ),
                ]
              },
            },
            {
              key: "head(amount_assigned)",
              fn: function (data) {
                return [
                  _vm._v(" " + _vm._s(data.label) + " "),
                  _c("b-icon-question-circle", {
                    staticClass: "atmo-quick-helper",
                    attrs: {
                      title:
                        "Часть Стоимости спецификации, распределенная на данный Доступный лимит.",
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(amount_assigned)",
              fn: function (data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatPrice(data.item.amount_assigned)) +
                      " "
                  ),
                ]
              },
            },
            _vm.totalCost
              ? {
                  key: "bottom-row",
                  fn: function () {
                    return [
                      _c(
                        "b-td",
                        {
                          staticClass: "text-right py-1",
                          attrs: { colspan: "7" },
                        },
                        [
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              "Распределено итого, руб.: " +
                                _vm._s(_vm.$formatPrice(_vm.totalCost))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }