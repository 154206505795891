<template>
    <b-container class="main_layer py-2" fluid>
        <b-container>
            <b-row>
                <b-col md="9">
                    <p v-if="isUserCustomer" class="fs-36 fw-600 white-color mb-1">Личный кабинет заказчика</p>
                    <p v-if="isUserProvider" class="fs-36 fw-600 white-color mb-1">Личный кабинет поставщика</p>
                    <p class="fs-14 greyBg-color mb-2">
                        Наименование организации: <strong>{{ $store.state.organization.short_name }}</strong>
                    </p>
                    <p class="fs-14 greyBg-color mb-2">
                        Пользователь: <strong>{{ $store.state.user.name }}</strong>
                        <span class="pl-5">
                            Логин:
                            <strong>{{ $store.state.user.username }}</strong>
                        </span>
                    </p>
                    <p v-if="features.telegram_bot" class="fs-14 greyBg-color mb-2">
                        ID Пользователя для Telegram: <strong>{{ $store.state.user.id }}</strong>
                    </p>

                    <b-btn v-if="isUserCustomer" :to="{ name: 'purchases.grouped.item.create', params: { group: 'fl44' } }" class="my-1 mr-2" variant="custom-white">СОЗДАТЬ ЗАКУПКУ 44-ФЗ </b-btn>
                    <b-btn v-if="isUserCustomer" :to="{ name: 'ExternalPurchaseCreate', params: { mode: 'create' } }" class="my-1 mx-2" variant="custom-outline-light">ДОБАВИТЬ ВНЕСИСТЕМНУЮ ЗАКУПКУ 44-ФЗ </b-btn>

                    <template v-if="$store.getters.isLoggedIn && orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)">
                        <template v-if="$store.state.organization.settings.fl223.allowed">
                            <b-btn :to="{ name: 'Fl223PurchaseCreate' }" class="my-1 mr-2" variant="custom-white"> СОЗДАТЬ ЗАКУПКУ 223-ФЗ </b-btn>
                        </template>
                        <template v-else>
                            <b-button :id="'popover-create-fl223'" class="my-1 mr-2" variant="custom-white"> СОЗДАТЬ ЗАКУПКУ 223-ФЗ </b-button>

                            <b-popover :target="'popover-create-fl223'" triggers="hover">
                                <div class="fs-12">
                                    Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в
                                    <router-link style="color: var(--green-color); text-decoration: underline" :to="{ name: 'CabinetFl223Settings' }" active-class="" exact-active-class="">настройки</router-link>.
                                </div>
                            </b-popover>
                        </template>
                    </template>

                    <template v-if="$store.getters.isLoggedIn && orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL)">
                        <template>
                            <b-btn :to="{ name: 'CommercialPurchaseCreate' }" class="my-1 mr-2" variant="custom-white"> СОЗДАТЬ КОММЕРЧЕСКУЮ ЗАКУПКУ </b-btn>
                        </template>
                    </template>
                </b-col>
                <b-col md="3">
                    <div class="text-right mr-2">
                        <b-btn v-if="isUserProvider" active-class="" class="my-1 mx-2 w-100" exact-active-class="" tag="button" v-bind:to="{ name: 'OrganizationReliabilityReport' }" variant="custom-white">Проверка контрагентов </b-btn>
                        <b-btn v-if="isUserProvider && features.marketplace_integration" active-class="" class="text-uppercase my-1 mx-2 w-100" exact-active-class="" variant="custom-white" @click="goToBashmarket">ПЕРЕЙТИ НА БАШМАРКЕТ </b-btn>
                        <b-btn v-if="isUserProvider && getCertificateNeedToBeProlonged" :href="$links.prolong_certificate" active-class="" class="text-uppercase my-1 mx-2 w-100" exact-active-class="" target="_blank" variant="custom-danger">Продлить ЭЦП </b-btn>
                        <b-btn v-if="isUserProvider && !getOrganizationHasPayments" :to="{ name: 'ProviderAccountCabinet' }" active-class="" class="text-uppercase my-1 mx-2 w-100" exact-active-class="" variant="custom-danger"> Пополнить лицевой счет </b-btn>
                        <b-btn v-if="isUserProvider && !$store.state.user.is_chatme_notifiable && features.telegram_bot" :href="$links.telegram_events_bot" class="text-uppercase my-1 mx-2 w-100" target="_blank" variant="custom-white"
                            >Подключить уведомления о событиях в ИС и размещении новых извещений
                        </b-btn>
                        <b-btn v-if="isUserCustomer && !$store.state.user.is_chatme_notifiable && features.telegram_bot" :href="$links.telegram_events_bot" class="text-uppercase my-1 mx-2 w-100" target="_blank" variant="custom-white">Подключить уведомления о событиях в ИС </b-btn>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="$store.state.user.need_to_have_poa && $store.state.user.need_to_upload_poa" class="pt-1 text-center">
                <b-col md="12">
                    <b-button variant="danger" :to="{ name: 'UserProfile' }" class="fs-14 font-weight-bold w-100">Необходимо загрузить/заменить МЧД на странице: Данные учетной записи</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import Config from '@core/js/ddriven/application/config/Config';
import { mapGetters } from 'vuex';

export default {
    name: 'cabinet-header',
    methods: {
        async goToBashmarket() {
            await this.$api.integrations.gotoBashmarket();
        }
    },
    computed: {
        ...mapGetters(['getOrganizationHasPayments', 'getCertificateNeedToBeProlonged']),
        features() {
            return Config.get('theme.features');
        },
        isUserCustomer() {
            return this.$store.state.organization && !this.$store.state.organization.is_provider;
        },
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        }
    }
};
</script>
<style scoped>
.cabinet_menu li {
    position: relative;
    background: #fff;
    /*border-radius: 4px;*/
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

@media screen and (max-width: 769px) {
}
</style>
<style>
.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}
</style>
