<template>
    <div>
        <b-row v-if="title">
            <b-col class="d-flex justify-content-between align-items-center">
                <p class="fs-18 grey-color my-2">{{ title }}</p>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Наименование</th>
                            </tr>
                        </thead>
                        <tbody v-if="documents && documents.length > 0">
                            <tr v-for="document in documents" :key="document.id">
                                <td>
                                    <a style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }} {{ document.is_archived ? '(Архив)' : '' }}</a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center">Не добавлено документов</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'FormRowDocuments',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Array
        }
    },
    computed: {
        documents: {
            get() {
                return this.value;
            }
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
