<template>
    <div>
        <b-modal :visible="showModal" hide-footer no-close-on-backdrop scrollable no-close-on-esc size="xl" title="Выбрать позицию спецификации" @show="fillData" @close="onClose">
            <template #modal-header-close>
                <button-close-modal />
            </template>
            <b-row>
                <filter-text :md="3" v-model="filter.reg_number" label="Рег.номер" placeholder="Введите рег.номер" @input="onFilterChange" />
                <filter-text :md="3" v-model="filter.name" label="Название" placeholder="Введите название" @input="onFilterChange" />
                <filter-text :md="3" v-model="filter.specs" label="Характеристики" placeholder="Введите текст характеристик" @input="onFilterChange" />
                <filter-text :md="3" v-model="filter.okpd_code" label="Код ОКПД2" placeholder="Введите код ОКПД2 или его часть" @input="onFilterChange" />
            </b-row>
            <b-row>
                <b-col v-if="!items.length" class="text-center mt-5">
                    <img src="/images/empty_box.svg" alt="empty-data" />
                    <p class="fs-14 grey-color my-4">По вашему запросу ничего не найдено</p>
                </b-col>
                <b-col v-else class="mb-3">
                    <b-table id="ktru-table" :busy="isLoading" :fields="fields" :items="items" class="table-atmo" small hover responsive>
                        <template #row-details="data">
                            <p v-if="data.item.description"><b>Описание:</b> {{ data.item.description }}</p>
                            <p v-if="data.item.specs && data.item.specs.length > 0"><b>Характеристики:</b></p>
                            <ul v-if="data.item.specs && data.item.specs.length > 0">
                                <li v-for="(spec, key) in data.item.specs" :key="'spec' + key">
                                    <span style="font-weight: 900">{{ spec.name }}</span
                                    >: {{ spec.value }}
                                </li>
                            </ul>
                        </template>
                        <template #cell(reg_num)="data">
                            {{ data.item.reg_number }}
                        </template>
                        <template #cell(okpd)="data">
                            {{ data.item.okpd_code }}
                        </template>
                        <template #cell(specs)="data"> <action-button-small v-b-popover.hover.top="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" /> {{ data.item.name }} </template>
                        <template #cell(okei)="data">
                            {{ data.item.okei_local_symbol }}
                        </template>
                        <template #cell(country)="data">
                            {{ data.item.country_of_origin_name }}
                        </template>
                        <template #cell(actions)="data">
                            <action-button-small v-if="!data.item.is_favorite" icon="plus" @click="addItem(data.item.id).then(() => (data.item.is_favorite = true))" />
                        </template>
                    </b-table>
                    <b-pagination v-if="items && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="ktru-table" class="my-0 py-1" @input="fillData" />
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import FilterText from '@/components/common/filter-components/filter-text.vue';

export default {
    name: 'add-favorite-modal',
    components: { FilterText },
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        addItem: {
            type: Function,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            fields: [
                { key: 'reg_num', label: 'Рег.№', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'okpd', label: 'ОКПД2', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1' },
                { key: 'okei', label: 'Ед.изм.', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } },
                { key: 'country', label: 'Страна происхождения', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } },
                { key: 'actions', label: '', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } }
            ],
            filter: {
                name: '',
                reg_number: '',
                specs: '',
                okpd_code: '',
                country_of_origin_id: []
            },
            items: [],
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    methods: {
        async fillData(page = true) {
            try {
                this.isLoading = true;
                const response = await this.$api.ktru.getList(this.getFilters(page));
                this.items = response.data;
                this.meta = response.meta;
            } catch {
                this.items = [];
            } finally {
                this.isLoading = false;
            }
        },
        getFilters(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        onFilterChange() {
            this.fillData(false);
        }
    }
};
</script>
