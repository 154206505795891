var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase.is_hold
    ? _c(
        "div",
        [
          _c(
            "b-btn",
            {
              class: _vm.buttonClass,
              attrs: { disabled: _vm.purchase.hold_canceled },
              on: { click: _vm.cancelClick },
            },
            [_vm._v("Сформировать итоговый протокол")]
          ),
          _c("cancel-hold-time-modal", {
            attrs: { purchase: _vm.purchase, show: _vm.showModal },
            on: { cancel: _vm.cancelModal, ok: _vm.cancelHoldTime },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }