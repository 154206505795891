<template>
    <div v-if="isAvailable">
        <b-btn @click="showModal" class="btn-card-action btn-card-action-orange my-1">Отменить закупку</b-btn>
        <b-modal ref="cancel-modal" :title="title" v-model="isModalVisible" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div>
                <p>
                    Вы действительно хотите отменить закупку <strong>{{ purchase.reg_number }}</strong
                    >?
                </p>
            </div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelModal">Нет</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" @click="cancelPurchase">Отменить закупку</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'cabinet-commercial-notice-card-cancel-button',
    props: {
        purchase: {
            type: Object
        }
    },
    data() {
        return {
            isModalVisible: false,
            isDataSending: false
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
        },
        async cancelPurchase() {
            this.isDataSending = true;
            await this.$api.products.commercial.purchases.cancel(this.purchase.id);
            this.isDataSending = false;
            this.isModalVisible = false;
            this.$emit('cancel');
        }
    },
    computed: {
        title() {
            return `Отменить закупку № ${this.purchase.reg_number}?`;
        },
        isAvailable() {
            return this.purchase?.actions?.customer?.cancel === true;
        }
    }
};
</script>
