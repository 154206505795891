<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`purchase_part_header purchase_part-header_number ${cardCssClass} pr-2 py-2 position-relative`" body-class="purchase_part-body p-0">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">44-ФЗ</b-badge>
                        <b-badge class="my-auto fs-14 mr-2">Вне ИС</b-badge>
                        Запрос блокировки закупки №
                        <router-link class="white-system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: request.purchase.id } }" exact target="_blank">{{ request.purchase.reg_number }}</router-link>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase" md="auto">
                        {{ request.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <b-row class="purchase_part-body py-1">
                <b-col md="6" class="purchase_part-body_col">
                    <div>
                        <div class="my-1">
                            <p class="fs-12 m-0 fw-600 grey-color">Дата, время:
                                <span class="fw-400">
                                    {{ getDateTimeFormat(request.created_at) }}
                                </span>
                            </p>
                        </div>
                        <div class="my-1">
                            <p class="fs-12 m-0 fw-600 grey-color">Причина:</p>
                            <p class="fs-12 m-0 grey-color">
                                {{ request.request }}
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col v-if="request.status.id !== 'new'" md="6" class="purchase_part-body_col">
                    <div>
                        <div class="my-1">
                            <p class="fs-12 m-0 fw-600 grey-color">Исполнитель:
                                <span class="fw-400">
                                    {{ request.executant_name }}
                                </span>
                            </p>
                        </div>
                        <div class="my-1">
                            <p class="fs-12 m-0 fw-600 grey-color">Дата, время исполнения:
                                <span class="fw-400">
                                    {{ getDateTimeFormat(request.updated_at) }}
                                </span>
                            </p>
                        </div>
                        <div v-if="request.answer" class="my-1">
                            <p class="fs-12 m-0 fw-600 grey-color">Обоснование:</p>
                            <p class="fs-12 m-0 grey-color">
                                {{ request.answer }}
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>

<script>

export default {
    name: 'customer-fl44-external-purchase-block-request-card',
    props: {
        request: {
            type: Object,
            required: true
        }
    },
    computed: {
        cardCssClass() {
            switch (this.request.status.id) {
                case 'approved':
                    return 'card-header-green';
                case 'declined':
                    return 'card-header-yellow';
                default:
                    return 'card-header-grey';
            }
        },
        cardIcon() {
            switch (this.request.status.id) {
                case 'approved':
                    return 'shield-check';
                case 'declined':
                    return 'shield-x';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.purchase_part {
    background: #fff;
}
.card-header-grey {
    background-color: #959ba4 !important;
}
.card-header-green {
    background-color: var(--green-color);
}
.card-header-blue {
    background-color: #40acb4 !important;
}
.card-header-yellow {
    background-color: #f6b33a !important;
}
.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
