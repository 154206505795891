<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <template #label>
            {{ label }}:
            <div v-if="!!hint" class="atmo-help">
                &nbsp;<i class="atmo-icon-question-mark"></i><span class="atmo-help__text">{{ hint }}</span>
            </div>
        </template>
        <b-form-input :id="id" v-model="text" :state="state" :disabled="disabled" type="number" class="fs-14" step="1" :min="minValue" :max="maxValue" v-stop-number-mousewheel></b-form-input>
        <template v-if="!state">
            <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-edit-number',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            default: 0
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        minValue: {
            type: Number,
            default: 0
        },
        maxValue: {
            type: Number,
            default: null
        },
        hint: {
            type: String,
            default: ''
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        id() {
            return 'form_row_edit_number_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.numeric && !!this.v.$params.numeric) return 'Недопустимое значение';
                if (!this.v.minValue && !!this.v.$params.minValue) return `Минимальное значение - ${this.v.$params.minValue.min}`;
                if (!this.v.maxValue && !!this.v.$params.maxValue) return `Максимальное значение - ${this.v.$params.maxValue.max}`;
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
