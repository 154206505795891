var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "8" } }, [
            _c("h3", { staticClass: "grey-color main_text font-weight-bold" }, [
              _vm._v("Отчеты по закупкам"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "b-btn",
                {
                  attrs: {
                    href: _vm.$links.fl44_purchase_reports_manual,
                    size: "sm",
                    target: "_blank",
                    variant: "custom-danger",
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { "aria-hidden": "true", icon: "play-circle" },
                  }),
                  _vm._v(" Инструкция "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive py-3" },
        [
          _vm.reports && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "mb-3",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
          _c("b-table", {
            staticClass: "table-atmo",
            attrs: {
              busy: _vm.loading,
              fields: _vm.fields,
              items: _vm.reports,
              "per-page": _vm.meta.per_page,
              "empty-text": "Данные отсутствуют",
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle mr-1" }),
                        _c("strong", [_vm._v("Загрузка данных...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(period)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          `${row.item.report_period_title} ${row.item.year}`
                        ) +
                        " "
                    ),
                    row.item.status.id !== "new"
                      ? [
                          _c("action-button-small", {
                            attrs: {
                              icon: !row.detailsShowing ? "eye" : "eye-slash",
                              title:
                                (!row.detailsShowing ? "Показать" : "Скрыть") +
                                " подробности",
                            },
                            on: { click: row.toggleDetails },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(sent_at)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.item.sent_at
                            ? _vm.getDateTimeFormat(row.item.sent_at)
                            : "—"
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(sent_by_user)",
                fn: function (row) {
                  return [
                    row.item.sent_by_user
                      ? [_vm._v(_vm._s(row.item.sent_by_user))]
                      : [_vm._v("—")],
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    row.item.can_be_filled
                      ? [
                          _c("fl44-report-fill-button", {
                            attrs: { "fl44-purchase-report": row.item },
                            on: {
                              refresh: function ($event) {
                                return _vm.getReports()
                              },
                            },
                          }),
                        ]
                      : _c(
                          "b-badge",
                          {
                            staticClass: "fs-14",
                            attrs: {
                              variant: _vm.statusBadgeVariant(
                                row.item.status.id
                              ),
                              pill: "",
                            },
                          },
                          [_vm._v(_vm._s(row.item.status.title))]
                        ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-atmo" }, [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "text-nowrap",
                                attrs: { rowspan: "2" },
                              },
                              [_vm._v("Основание закупки")]
                            ),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Сводная информация"),
                            ]),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Закупки у поставщиков из РБ"),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Кол-во")]),
                            _c("th", [_vm._v("Сумма")]),
                            _c("th", [_vm._v("Кол-во")]),
                            _c("th", [_vm._v("Сумма")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(row.item.details, function (detailItem) {
                            return _c("tr", { key: detailItem.id }, [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(_vm._s(detailItem.order_type_name)),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: { width: "100" },
                                },
                                [_vm._v(_vm._s(detailItem.user_total_count))]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-right text-nowrap",
                                  attrs: { width: "150" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatPrice(
                                        detailItem.user_total_sum
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: { width: "100" },
                                },
                                [_vm._v(_vm._s(detailItem.user_local_count))]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-right text-nowrap",
                                  attrs: { width: "150" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatPrice(
                                        detailItem.user_local_sum
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.reports && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "mb-3",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                on: {
                  input: function ($event) {
                    return _vm.getReports()
                  },
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }