<template>
    <div class="purchase_part-header" :class="'status_color_' + purchase.status_id">
        <b-row class="w-100 m-0">
            <b-col md="8" lg="8" class="px-1">
                <p class="purchase_part-header_number" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                    <b-badge class="fs-14 mr-2">44-ФЗ</b-badge>
                    <template v-if="purchase.status_id === 11 || purchase.status_id === 13">{{ purchase.reg_number }}</template>
                    <router-link v-else class="white-system_link-style fw-600" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id } }" target="_blank">{{ purchase.reg_number }}</router-link>
                    <span v-if="purchase.purchase_object" style="text-overflow: ellipsis ellipsis">&nbsp;На закупку "{{ purchase.purchase_object }}"</span>
                </p>
            </b-col>
            <b-col md="4" lg="4" class="px-1">
                <p class="purchase_part-header_label fw-600 float-right">
                    <template v-if="purchase.status_id === 1">
                        <template v-if="purchase.is_hold"> Подведение итогов </template>
                        <template v-else>
                            {{ purchase.status_title }}
                            <img class="ml-2" src="/images/secundomer.svg" alt="check_label" v-if="purchase.status_id === 1" />
                        </template>
                    </template>
                    <template v-else>
                        {{ purchase.status_title }}
                        <img class="ml-2" src="/images/white_helmet.svg" alt="check_label" v-if="purchase.status_id === 2" />
                        <img class="ml-2" src="/images/white_contract.svg" alt="check_label" v-if="purchase.status_id === 3" />
                        <img class="ml-2" src="/images/white_times.svg" alt="check_label" v-if="purchase.status_id === 7 || purchase.status_id === 8" />
                        <img v-if="purchase.status_id === 6 || purchase.status_id === 5" class="ml-2" src="/images/white_times.svg" alt="" />
                        <img v-if="purchase.status_id === 4" class="ml-2" src="/images/file_check.svg" alt="" />
                    </template>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { __PURCHASE_STATUS as PURCHASE_STATUS } from '@/const';

export default {
    name: 'SubheaderCustomerCabinet',
    computed: {
        PURCHASE_STATUS() {
            return PURCHASE_STATUS;
        }
    },
    props: ['purchase']
};
</script>

<style scoped>
.purchase_part-header {
    background-color: #959ba4;
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px !important;
}
.status_color_2,
.status_color_3,
.status_color_4,
.status_color_11,
.status_color_13 {
    background-color: var(--green-color);
}
.status_color_7,
.status_color_8,
.status_color_5,
.status_color_6 {
    background-color: #f6b33a;
}
</style>
