var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "w-100",
      attrs: {
        label: _vm.label + ":",
        state: _vm.state,
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("b-form-radio-group", {
        staticClass: "fs-14 mt-2",
        class: _vm.stateClass,
        attrs: {
          id: _vm.id,
          options: _vm.options,
          stacked: _vm.stacked,
          disabled: _vm.disabled,
          "value-field": _vm.valueField,
          "text-field": _vm.textField,
        },
        model: {
          value: _vm.radio,
          callback: function ($$v) {
            _vm.radio = $$v
          },
          expression: "radio",
        },
      }),
      !_vm.state
        ? [
            _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }