<template>
    <b-container>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <p :class="headerClass + ' grey-color my-4'">{{ title }}</p>
                <b-button v-if="!ktruItem" style="color: var(--green-color) !important" variant="custom-unstyle-grey" @click="showSpec = true">
                    <!-- <img alt="clip_green" class="mr-2" src="/images/clip_green.svg" /> -->
                    <i class="atmo-icon-attachment"></i>&ensp; Выбрать позицию КТРУ
                </b-button>
            </b-col>
        </b-row>
        <b-row class="py-2" no-gutters>
            <b-col class="grey-color">
                <div :class="v.$invalid || !!specErrorMessage ? 'is-invalid' : ''" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="1"></th>
                                <th nowrap width="100px">Ед.изм</th>
                                <th nowrap width="100px">Страна происхождения</th>
                                <th style="min-width: 160px" width="160px">Цена за ед., руб.</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ktruItem">
                                <td class="align-text-top">
                                    {{ ktruItem.name }}
                                </td>
                                <td width="1">
                                    <button class="atmo-button-icon float-right" @click="visibleDetails = !visibleDetails">
                                        <span title="Показать описание/характеристики">
                                            <i v-if="!visibleDetails" class="atmo-icon-show" />
                                            <i v-else class="atmo-icon-hide" />
                                        </span>
                                    </button>
                                </td>
                                <td class="align-text-top">{{ ktruItem.okei_local_symbol }}</td>
                                <td class="align-text-top">{{ ktruItem.country_of_origin_name }}</td>
                                <td class="align-text-top">
                                    <currency-input v-model="ktruItem.price" :class="ktruItem.price > 0 ? '' : 'is-invalid'" class="form-control fs-14" currency="RUB" locale="ru" style="padding-top: 0; padding-bottom: 0; height: auto" />
                                </td>
                                <td class="align-text-top" nowrap>
                                    <b-button size="sm" style="background: transparent" variant="light" @click="removeSpec">
                                        <img alt="trash_icon" src="/images/trash_icon.svg" />
                                    </b-button>
                                </td>
                            </tr>
                            <tr v-if="ktruItem && visibleDetails">
                                <td class="align-text-top" colspan="6">
                                    <template v-if="ktruItem">
                                        <p v-if="ktruItem.description"><b>Описание:</b> {{ ktruItem.description }}</p>
                                        <p v-if="ktruItem.specs && ktruItem.specs.length > 0"><b>Характеристики:</b></p>
                                        <div class="ml-4">
                                            <ul v-if="ktruItem.specs && ktruItem.specs.length > 0">
                                                <li v-for="(spec, specIndex) of ktruItem.specs" :key="'spec_' + specIndex">
                                                    <b>{{ spec.name }}</b> - {{ spec.value }}
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                    <template v-else>&mdash;</template>
                                </td>
                            </tr>
                            <tr v-if="!ktruItem">
                                <td align="center" colspan="6">Выберите позицию КТРУ</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="specErrorMessage" class="text-danger">{{ specErrorMessage }}</p>
            </b-col>
        </b-row>
        <purchase-specification-modal :is-import-substitution-reply="this.isImportSubstitutionReply" :is-import-substitution-request="!this.isImportSubstitutionReply" :show="showSpec" @add="selectKtruItem" @cancel="cancelSpecModal" />
    </b-container>
</template>

<script>
import PurchaseSpecificationModal from '@/components/common/purchase-specification-modal';

export default {
    name: 'ImportSubstitutionRequestCreateKtruItemBlock',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Object
        },
        v: {
            type: Object,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        headerClass: {
            type: String,
            default: 'fs-32'
        },
        isImportSubstitutionReply: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PurchaseSpecificationModal
    },
    data() {
        return {
            showSpec: false,
            visibleDetails: false,
            tableFields: ['name', 'okpd_code', 'okei_local_symbol', 'price']
        };
    },
    methods: {
        selectKtruItem(spec) {
            this.ktruItem = { ...spec.ktru, price: 0 };
            this.showSpec = false;
        },
        removeSpec() {
            this.ktruItem = null;
        },
        cancelSpecModal() {
            this.showSpec = false;
        }
    },
    computed: {
        ktruItem: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        specErrorMessage() {
            return !this.ktruItem ? 'Выберите позицию КТРУ' : false;
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
