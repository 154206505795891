<template>
    <div class="text-right">
        <b-btn :href="href" class="fs-14 my-1 text-uppercase" size="lg" style="color: white !important" target="_blank" variant="danger">
            <img alt="documentation" class="mx-1" src="/images/youtube.svg" style="height: 16px" />
            {{ text }}
        </b-btn>
    </div>
</template>

<script>
export default {
    name: 'youtube-link-button',
    props: {
        href: {
            required: true
        },
        text: {
            required: true
        }
    }
};
</script>

<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

.end_list li {
    margin: 15px 0;
    font-size: 14px;
    color: var(--grey-color);
    position: relative;
}

.end_list li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: var(--green-color);
}

@media screen and (max-width: 769px) {
}
</style>
<style>
.purchase_session ul.nav-tabs li {
    width: 25%;
}

.purchase_session ul.nav-tabs li a {
    border: none;
}
</style>
<style lang="less">
@subtext-color: #777;
@preloader-bg-color: rgba(255, 255, 255, 0.75);
@suggestions-bg-color: #fff;
@subtext-label-color: #f5f5f5;

.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}

.rounded(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.cross-button-mixin() {
    @cross-padding: 20;
    @cross-ray-width: 10;
    @cross-ray-length: 50 - @cross-padding - @cross-ray-width;
    @svg: "<svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'><g><path d='m@{cross-padding},@{cross-padding}m@{cross-ray-width},0l@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},-@{cross-ray-width}l@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},-@{cross-ray-width}' fill='#999'/></g></svg>";
    @svg64: `new Buffer(@{svg}) .toString('base64') `;
    background: transparent url('data:image/svg+xml;base64,@{svg64}') 50% 50% no-repeat;
    background-size: contain;

    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

/**
   * Основной INPUT
   */

/**
   * Блок в правой части основного INPUT'а
   * В нем показывается иконка ЗАГРУЗКА
   */

/**
   * Выпадающий блок с найденными подсказками
   */

/**
   * Контейнер для одной подсказки
   */

/**
   * Выбранная (активная) подсказка
   */

/**
   * Информационный блок в верхней части выпадашки с подсказками
   */

/**
   * Ограничения поиска, показываются в левой части основного INPUT'а
   */

/**
   * Дополнительный текст в подсказке, который идет второй строкой
   */

/**
   * Размещает дополнительный текст в одну строку с основным текстом подсказки
   */

/**
   * Разделитель нескольких дополнительных текстов
   */

/**
   * Выделяет подсказку
   */

/**
   * Промо-блок
   */
</style>
