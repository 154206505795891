<template>
    <div v-if="!isLoading">
        <text-header-new>{{ notification.type_name }}</text-header-new>
        <b-row>
            <b-col>
                <text-row-datetime label="Отправлено" v-model="notification.created_at" compact />
                <text-row-raw label="Уведомление" compact>
                    <div class="my-auto col-form-label" v-html="message" />
                </text-row-raw>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onCancel">Назад</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import TextRowRaw from "@/components/common/form-rows/text-row-raw.vue";

export default {
    name: 'NotificationShow',
    components: {TextRowRaw},
    metaInfo: {
        title: 'Просмотр уведомления'
    },
    data() {
        return {
            notification: null,
            isLoading: true,
        };
    },
    async mounted() {
        await this.getNotification();
    },
    methods: {
        onCancel() {
            this.$router.push({ name: 'NotificationsList' });
        },
        async getNotification() {
            this.isLoading = true;
            await this.$api.notifications.showNotification(this.$route.params.id)
                .then((response) => {
                    this.notification = response;
                    this.$store.commit('set_unread_notifications_count', response.unread_notifications_count)
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 404) {
                        this.$router.push({ name: '404' }).catch(() => {});
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
    watch: {
        $route: 'getNotification'
    },
    computed: {
        message() {
            return this.notification.data.message.replace(/\n/g, '<br/>').replace(/(\b(https?|http):\/\/\S+)/g, "<a class='atmo-button-link' target='_blank' href='$1'>$1</a>");
        }
    }
};
</script>
