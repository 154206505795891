var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase
    ? _c(
        "div",
        { staticClass: "create" },
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-2", attrs: { fluid: "" } },
            [
              _c("b-container", [
                _c("p", { staticClass: "fs-40 fw-600 white-color my-2" }, [
                  _vm._v(
                    "Преобразование закупки " +
                      _vm._s(_vm.purchase.reg_number) +
                      " в несрочную"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Наименование",
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.purchase.organization.id },
                      },
                    },
                    model: {
                      value: _vm.purchase.organization.short_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "short_name", $$v)
                      },
                      expression: "purchase.organization.short_name",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ИНН",
                    },
                    model: {
                      value: _vm.purchase.organization.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "inn", $$v)
                      },
                      expression: "purchase.organization.inn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "КПП",
                    },
                    model: {
                      value: _vm.purchase.organization.kpp,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "kpp", $$v)
                      },
                      expression: "purchase.organization.kpp",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ОГРН",
                    },
                    model: {
                      value: _vm.purchase.organization.ogrn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "ogrn", $$v)
                      },
                      expression: "purchase.organization.ogrn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Юридический адрес",
                    },
                    model: {
                      value: _vm.purchase.organization.post_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "post_address", $$v)
                      },
                      expression: "purchase.organization.post_address",
                    },
                  }),
                  _vm.purchase.additional_contact_info
                    ? _c("text-row", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.additional_contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "additional_contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.additional_contact_info",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Предмет закупки",
                      value: _vm.purchase.purchase_name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Объект закупки",
                      value: _vm.purchase.purchase_object,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Тип закупки",
                      value: _vm.purchase.order_type.name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Продолжительность закупочной сессии",
                      value: "Несрочная",
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата заключения договора",
                      value: _vm.purchase.planned_contract_date,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата исполнения договора",
                      value: _vm.purchase.planned_end_date,
                    },
                  }),
                  _c("text-row-price", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Сумма закупки",
                      value: _vm.purchase.start_price,
                    },
                  }),
                  _c("text-row-single-document", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Проект договора",
                      value: _vm.contractDraftAttachment,
                    },
                  }),
                  _vm.additionalDocuments.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительные документы",
                        },
                        model: {
                          value: _vm.additionalDocuments,
                          callback: function ($$v) {
                            _vm.additionalDocuments = $$v
                          },
                          expression: "additionalDocuments",
                        },
                      })
                    : _vm._e(),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label:
                        "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации",
                      value: _vm.purchase.additional_info,
                    },
                  }),
                  _c("text-header", [_vm._v("Требования к поставщикам")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Отсутствие в реестре недобросовестных поставщиков",
                      value: _vm.purchase.disallow_unfair_suppliers
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                      value: _vm.purchase.only_smp ? "ДА" : "НЕТ",
                    },
                  }),
                  _vm.purchase.delivery_addresses.length > 0 ||
                  _vm.purchase.order_plan
                    ? [
                        _c("text-header", [_vm._v("Условия поставки")]),
                        _vm.purchase.delivery_addresses > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-sm": 4,
                                  "label-cols-lg": 4,
                                  label: "Адреса для доставки/самовывоза:",
                                  "label-class":
                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.purchase.delivery_addresses,
                                    function (address, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "fs-14 grey-color my-auto col-form-label",
                                        },
                                        [_vm._v(_vm._s(address))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("text-row", {
                          attrs: {
                            compact: true,
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label:
                              "График поставки товаров (выполнения работ, оказания услуг)",
                            value: _vm.purchase.order_plan,
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }, [_vm._v("№")]),
                          _c("th", [
                            _vm._v("Наименование товара (работ, услуг)"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "160px" },
                            },
                            [_vm._v("Количество")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "100px" },
                            },
                            [_vm._v("Ед.изм")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "160px" },
                            },
                            [_vm._v("Цена за ед., руб.")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "140px" },
                            },
                            [_vm._v("Стоимость, руб.")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.purchase.items, function (item, index) {
                          return _c(
                            "fragment",
                            _vm._b({ key: item.id }, "fragment", item, false),
                            [
                              _c(
                                "tr",
                                {
                                  class: _vm.visible[index]
                                    ? "border-bottom-0"
                                    : "",
                                },
                                [
                                  _c("td", [_vm._v(_vm._s(index + 1))]),
                                  _c(
                                    "td",
                                    [
                                      _c("action-button-small", {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "collapse-" + index,
                                            expression: "'collapse-' + index",
                                          },
                                        ],
                                        attrs: {
                                          title:
                                            (!_vm.visible[index]
                                              ? "Показать"
                                              : "Скрыть") + " характеристики",
                                          icon: !_vm.visible[index]
                                            ? "eye"
                                            : "eye-slash",
                                        },
                                      }),
                                      _c("b-collapse", {
                                        staticClass: "d-none",
                                        attrs: { id: "collapse-" + index },
                                        model: {
                                          value: _vm.visible[index],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.visible, index, $$v)
                                          },
                                          expression: "visible[index]",
                                        },
                                      }),
                                      _vm._v(
                                        " " + _vm._s(item.ktru.name) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [_vm._v(_vm._s(item.count))]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.ktru.okei_local_symbol ?? "—"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$formatPrice(item.price))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.roundToTwoDecimals(
                                              item.price * item.count
                                            )
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.visible[index],
                                      expression: "visible[index]",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "align-text-top",
                                      attrs: { colspan: "8" },
                                    },
                                    [
                                      item.ktru
                                        ? [
                                            _c("p", [
                                              _c("b", [
                                                _vm._v("Рег.номер ТРУ:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru.reg_number)
                                              ),
                                            ]),
                                            _c("p", [
                                              _c("b", [_vm._v("Группа ТРУ:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.ktru.ktru_group_name
                                                  )
                                              ),
                                            ]),
                                            _c("p", [
                                              _c("b", [
                                                _vm._v("Наименование:"),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(item.ktru.name)
                                              ),
                                            ]),
                                            _c("p", [
                                              _c("b", [_vm._v("Тип:")]),
                                              _vm._v(
                                                " " + _vm._s(item.ktru.type)
                                              ),
                                            ]),
                                            item.ktru.okpd_code
                                              ? _c("p", [
                                                  _c("b", [
                                                    _vm._v("Код ОКПД2:"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.ktru.okpd_code
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.ktru.description
                                              ? _c("p", [
                                                  _c("b", [
                                                    _vm._v("Описание:"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.ktru.description
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.ktru.specs &&
                                            item.ktru.specs.length > 0
                                              ? _c("p", [
                                                  _c("b", [
                                                    _vm._v("Характеристики:"),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                            item.ktru.specs &&
                                            item.ktru.specs.length > 0
                                              ? _c(
                                                  "ul",
                                                  _vm._l(
                                                    item.ktru.specs,
                                                    function (spec, specIndex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            index +
                                                            "_spec_" +
                                                            specIndex,
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(spec.name)
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " - " +
                                                              _vm._s(
                                                                spec.value
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        : [_vm._v("—")],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]),
                  ]),
                  _vm.isPurchaseCreator()
                    ? _c(
                        "b-row",
                        { staticClass: "my-3 mt-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-2 text-uppercase",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("Отменить")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase mx-2",
                                  attrs: {
                                    disabled: !_vm.$store.getters.getCanSign,
                                    variant: "custom-green",
                                  },
                                  on: { click: _vm.validateForm },
                                },
                                [_vm._v("Подписать и сохранить")]
                              ),
                              !_vm.$store.getters.getCanSign
                                ? [
                                    _vm.$store.getters.getLoginType === "cert"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                          },
                                          [
                                            _vm._v(
                                              "Для действия требуется загрузить/заменить МЧД на странице: "
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "green-link",
                                                attrs: {
                                                  to: { name: "UserProfile" },
                                                },
                                              },
                                              [_vm._v("Данные учетной записи")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                          },
                                          [
                                            _vm._v(
                                              "Подписание возможно только при авторизации при помощи ЭЦП"
                                            ),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-modal",
                    {
                      ref: "sign-form-modal",
                      attrs: {
                        centered: "",
                        "no-close-on-backdrop": "",
                        "no-close-on-esc": "",
                        scrollable: "",
                        size: "lg",
                        title:
                          "Подписать заявку на изменение продолжительности",
                      },
                      on: { close: _vm.cancelSignModal },
                      model: {
                        value: _vm.showSignModal,
                        callback: function ($$v) {
                          _vm.showSignModal = $$v
                        },
                        expression: "showSignModal",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block text-left" },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { "content-class": "mt-3 px-3" } },
                            [
                              _c(
                                "b-tab",
                                { attrs: { active: "" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("Форма")]
                                    ),
                                  ]),
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Информация о заказчике")]
                                  ),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label: "Наименование организации",
                                      value:
                                        _vm.purchase.organization.short_name,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label: "ИНН",
                                      value: _vm.purchase.organization.inn,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label: "КПП",
                                      value: _vm.purchase.organization.kpp,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label: "ОГРН",
                                      value: _vm.purchase.organization.ogrn,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label: "Адрес",
                                      value:
                                        _vm.purchase.organization.post_address,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      label:
                                        "Дополнительная контактная информация",
                                      value:
                                        _vm.purchase.additional_contact_info,
                                    },
                                  }),
                                  _c("hr"),
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Условия закупки")]
                                  ),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Предмет закупки",
                                      value: _vm.purchase.purchase_name,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Объект закупки",
                                      value: _vm.purchase.purchase_object,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Тип закупки",
                                      value: _vm.purchase.order_type.name,
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label:
                                        "Продолжительность закупочной сессии",
                                      value: "Несрочная",
                                    },
                                  }),
                                  _c("text-row-date", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label:
                                        "Планируемая дата заключения договора",
                                      value: _vm.purchase.planned_contract_date,
                                    },
                                  }),
                                  _c("text-row-date", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label:
                                        "Планируемая дата исполнения договора",
                                      value: _vm.purchase.planned_end_date,
                                    },
                                  }),
                                  _c("text-row-price", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Сумма закупки",
                                      value: _vm.purchase.start_price,
                                    },
                                  }),
                                  _c("text-row-single-document", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label: "Проект договора",
                                      value: _vm.contractDraftAttachment,
                                    },
                                  }),
                                  _vm.additionalDocuments.length > 0
                                    ? _c("text-row-documents", {
                                        attrs: {
                                          compact: true,
                                          "label-cols-lg": 4,
                                          "label-cols-sm": 4,
                                          label: "Дополнительные документы",
                                        },
                                        model: {
                                          value: _vm.additionalDocuments,
                                          callback: function ($$v) {
                                            _vm.additionalDocuments = $$v
                                          },
                                          expression: "additionalDocuments",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 4,
                                      "label-cols-sm": 4,
                                      label:
                                        "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации",
                                      value: _vm.purchase.additional_info,
                                    },
                                  }),
                                  _c("hr"),
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Требования к поставщикам")]
                                  ),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 6,
                                      "label-cols-sm": 6,
                                      label:
                                        "Отсутствие в реестре недобросовестных поставщиков",
                                      value: _vm.purchase
                                        .disallow_unfair_suppliers
                                        ? "ДА"
                                        : "НЕТ",
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 6,
                                      "label-cols-sm": 6,
                                      label:
                                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                                      value: _vm.purchase.only_smp
                                        ? "ДА"
                                        : "НЕТ",
                                    },
                                  }),
                                  _vm.purchase.delivery_addresses.length > 0 ||
                                  _vm.purchase.order_plan
                                    ? [
                                        _c("hr"),
                                        _c(
                                          "h4",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Условия поставки")]
                                        ),
                                        _vm.purchase.delivery_addresses.length >
                                        0
                                          ? _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "label-cols-sm": 4,
                                                  "label-cols-lg": 4,
                                                  label:
                                                    "Адреса для доставки/самовывоза:",
                                                  "label-class":
                                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    _vm.purchase
                                                      .delivery_addresses,
                                                    function (address, index) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "fs-14 grey-color my-auto col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              address.address
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("text-row", {
                                          attrs: {
                                            compact: true,
                                            "label-cols-lg": 4,
                                            "label-cols-sm": 4,
                                            label:
                                              "График поставки товаров (выполнения работ, оказания услуг)",
                                            value: _vm.purchase.order_plan,
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c("hr"),
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Спецификация")]
                                  ),
                                  _vm.purchase.items.length > 0
                                    ? _c("b-form-group", [
                                        _c(
                                          "ul",
                                          _vm._l(
                                            _vm.purchase.items,
                                            function (item, index) {
                                              return _c(
                                                "li",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "fs-14 grey-color my-auto col-form-label",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " - " +
                                                      _vm._s(item.count) +
                                                      ", " +
                                                      _vm._s(
                                                        item.okei.local_symbol
                                                      ) +
                                                      " "
                                                  ),
                                                  item.price
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "* " +
                                                            _vm._s(item.price) +
                                                            ", руб."
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "b-tab",
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("XML")]
                                    ),
                                  ]),
                                  !_vm.isSignedXml
                                    ? _c("b-form-textarea", {
                                        staticClass: "text-monospace fs-12",
                                        attrs: {
                                          disabled: "",
                                          rows: "20",
                                          "no-resize": "",
                                          "no-auto-shrink": "",
                                          wrap: "off",
                                        },
                                        model: {
                                          value: _vm.formXml,
                                          callback: function ($$v) {
                                            _vm.formXml = $$v
                                          },
                                          expression: "formXml",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.isSignedXml
                                    ? _c("b-form-textarea", {
                                        staticClass: "text-monospace fs-12",
                                        attrs: { disabled: "", rows: "20" },
                                        model: {
                                          value: _vm.signedFormXml,
                                          callback: function ($$v) {
                                            _vm.signedFormXml = $$v
                                          },
                                          expression: "signedFormXml",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-100 px-3",
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c("text-row", {
                            attrs: {
                              label: "Сертификат",
                              value: _vm.certificate?.subjectName,
                            },
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelSignModal },
                            },
                            [_vm._v("Отмена")]
                          ),
                          !_vm.isSignedXml
                            ? _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isSigningXml,
                                    rounded: "",
                                    opacity: "0.6",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  !_vm.isSignedXml
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            variant: "custom-green",
                                            disabled: !_vm.certificate,
                                          },
                                          on: { click: _vm.signForm },
                                        },
                                        [_vm._v("Подписать ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSignedXml
                            ? _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isDataSending,
                                    rounded: "",
                                    opacity: "0.6",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _vm.isSignedXml
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            variant: "custom-green",
                                            disabled: !_vm.certificate,
                                          },
                                          on: { click: _vm.sendData },
                                        },
                                        [_vm._v("Отправить ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }