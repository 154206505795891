<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`atmo-override-color atmo-entity-item-title ${cardCssClass} pr-2 position-relative`" body-class="purchase_part-body p-0" border-variant="light">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">44-ФЗ</b-badge>
                        <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id } }" exact target="_blank">{{ contract.reg_number }}</router-link>
                        <template v-if="contract.purchase_object"> На закупку "{{ contract.purchase_object }}"</template>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase status-title" md="auto">
                        {{ contract.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <div class="purchase_part-subheader">
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        <abbr title="Начальная цена контракта">НЦК</abbr>: <span class="fw-600">{{ $formatPrice(contract.start_price) }} руб.</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Предложений: <span class="fw-600">{{ contract.purchase_actual_offers_count }}</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color"
                        >Лучшее: <span class="fw-600"> {{ contract.purchase_min_offer_cost ? $formatPrice(contract.purchase_min_offer_cost) + ' руб.' : '-' }}</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Снижение: <span class="fw-600">{{ contract.economy_percentage }} %</span>
                    </span>
                </b-col>
            </div>
            <b-row class="purchase_part-body py-2">
                <b-col class="purchase_part-body_col" md="3">
                    <div>
                        <p v-if="contract.status.id === 'contract:active' || contract.status.id === 'contract:fulfilled' || contract.status.id === 'contract:unfulfilled' || contract.status.id === 'contract:terminated'" class="fs-14 m-0 grey-color my-1">
                            Контракт заключен: <span class="fw-600">{{ getDateFormat(contract.contract_date) }}</span>
                        </p>
                        <p v-if="contract.contract_price" class="fs-14 m-0 grey-color my-1">
                            Цена контракта: <span class="fw-600">{{ $formatPrice(contract.contract_price) }} руб.</span>
                        </p>
                        <p v-if="!!contract.planned_end_date" class="fs-14 m-0 grey-color my-1">
                            Планируемая дата исполнения: <span class="fw-600">{{ getDateFormat(contract.planned_end_date) }}</span>
                        </p>
                        <p class="fs-14 m-0 grey-color my-1">
                            Количество позиций: <span class="fw-600">{{ contract.items_count }}</span>
                        </p>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col" md="6">
                    <div>
                        <p class="fs-14 m-0 grey-color my-1">
                            Предмет закупки: <span class="fw-600">{{ contract.purchase_name }}</span>
                        </p>
                        <p class="fs-14 m-0 grey-color my-1">
                            Заказчик:
                            <span class="fw-600">
                                <router-link :to="'/organizations/' + contract.organization_id" class="system_link-style text-uppercase fs-14" target="_blank">
                                    {{ contract.customer_short_name }}
                                </router-link>
                            </span>
                        </p>
                        <p v-if="contract.organization_final_id" class="fs-14 m-0 grey-color my-1">
                            Поставщик:
                            <span class="fw-600">
                                <router-link :to="'/organizations/' + contract.organization_final_id" class="system_link-style text-uppercase fs-14" target="_blank">
                                    {{ contract.organization_final_name }}
                                </router-link>
                            </span>
                        </p>
                        <template v-if="contract.delivery_addresses && contract.delivery_addresses.length > 0">
                            <p class="fs-14 m-0 grey-color my-1">Адреса доставки или самовывоза:</p>
                            <ul style="padding-left: 20px; list-style-type: disc !important">
                                <li v-for="(address, index) in contract.delivery_addresses" :key="index" class="fs-14 m-0 grey-color fw-600" v-bind="address">
                                    {{ address.address }}
                                </li>
                            </ul>
                        </template>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col atmo-column atmo-special-style" md="3">
                    <div>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1">
                            <i class="atmo-icon-search link-icon"></i>
                            <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id } }" class="system_link-style" exact target="_blank">Информация о закупке</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1">
                            <i class="atmo-icon-dynamic link-icon"></i>
                            <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-proposals' }" class="system_link-style" exact target="_blank">Динамика предложений</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1">
                            <i class="atmo-icon-shield link-icon"></i>
                            <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-supplier-info' }" class="system_link-style" exact target="_blank">Итоговый протокол</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1" v-if="contract.status.id !== 'contract:failed'">
                            <i class="atmo-icon-contract link-icon"></i>
                            <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-contract' }" class="system_link-style" exact target="_blank">Контракт</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1" v-if="contract.status.id !== 'contract:failed'">
                            <i class="atmo-icon-fulfilled link-icon"></i>
                            <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-contract-fulfillment' }" class="system_link-style" exact target="_blank">Исполнение контракта</router-link>
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>

<script>
export default {
    name: 'fl44-contract-card',
    props: {
        contract: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            addressesVisible: false
        };
    },
    computed: {
        cardCssClass() {
            switch (this.contract.status.id) {
                case 'contract:fulfilled':
                    return 'card-header-blue';
                case 'contract:unfulfilled':
                case 'contract:terminated':
                    return 'card-header-grey';
                case 'contract:failed':
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        },
        cardIcon() {
            switch (this.contract.status.id) {
                case 'contract:active':
                    return 'file-earmark-ruled';
                case 'contract:fulfilled':
                    return 'file-earmark-check';
                case 'contract:unfulfilled':
                case 'contract:failed':
                case 'contract:terminated':
                    return 'x-circle';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.status-title {
    padding-top: 1px;
}

.link-icon {
    padding-right: 5px;
    color: #d5d5d5;
    font-size: medium;
}

.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.purchase_part {
    background: #fff;
}

.card-header-grey {
    background-color: #959ba4 !important;
}

.card-header-green {
    background-color: #26b89a !important;
}

.card-header-blue {
    background-color: #40acb4 !important;
}

.card-header-yellow {
    background-color: #f6b33a !important;
}

.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
