<template>
    <b-form-group :id="id" :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" :class="compact ? 'mb-0' : ''">
        <b-form-checkbox class="fs-14 grey-color mt-2 text-right" v-model="value" disabled />
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-checkbox',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Boolean
        },
        description: {
            type: String,
            default: ''
        },
        compact: {
            default: false
        },
        labelColsLg: {
            default: 11
        },
        labelColsSm: {
            default: 11
        }
    },
    computed: {
        id() {
            return 'text_row_checkbox_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
