var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canBeAdd
    ? _c(
        "div",
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-card-action btn-danger my-1",
              attrs: {
                to: {
                  name: "purchases.grouped.item.view",
                  params: { group: "fl44", id: _vm.purchase.id },
                  hash: "#explanation-requests",
                },
                tag: "button",
              },
            },
            [
              _vm._v("Ответить на запрос"),
              _c("br"),
              _vm._v(
                "(До " +
                  _vm._s(
                    _vm.getDateTimeFormat(
                      _vm.purchase
                        .can_be_add_explanation_request_answer_end_date
                    )
                  ) +
                  ") "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }