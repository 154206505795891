<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <h3 class="grey-color mb-3 main_text font-weight-bold">Запросы блокировок</h3>
            </b-col>
        </b-row>

        <b-row class="mb-2 cabinet_filter">
            <filter-new-multiple-select v-model="filter.status" :options="forFilter.statuses" label="Статус запроса" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" label-field="title" />
            <b-col class="mb-1 text-right fix-pos" lg="6" md="6">
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getRequests(true)">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
            </b-col>
        </b-row>

        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <b-pagination v-if="blockRequests && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" />

            <empty-request v-if="isLoading || !blockRequests.length" />
            <template v-else v-for="blockRequest in blockRequests">
                <customer-fl44-external-purchase-block-request-card :request="blockRequest" :key="blockRequest.id" />
            </template>
            <b-pagination v-if="blockRequests && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" @input="getRequests()" />
        </b-overlay>
    </div>
</template>

<script>

import CustomerFl44ExternalPurchaseBlockRequestCard from "@/components/cabinets/customer/blockRequests/partials/customer-fl44-external-purchase-block-request-card.vue";
import FilterNewMultipleSelect from "@/components/common/filter-components/filter-new-multiple-select.vue";

const emptyFilter = {
    status: []
};

export default {
    name: 'CustomerBlockRequestsList',
    components: { FilterNewMultipleSelect, CustomerFl44ExternalPurchaseBlockRequestCard },
    data() {
        return {
            blockRequests: [],
            isLoading: true,
            meta: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            forFilter: {
                statuses: []
            },
            filter: { ...emptyFilter }
        };
    },
    async mounted() {
        if (!this.orgRoleIsPermitted(this.$orgRoles.CUSTOMER_FL44)) {
            await this.$router.push({ name: '404' }).catch(() => {});
        }
        await this.fillFilters();
        await this.getRequests();
        this.$scrollToTop();
    },
    methods: {
        async fillFilters() {
            const data = await this.$api.dictionary.getList(['fl44_external_purchases_block_request_statuses']);
            this.forFilter.statuses = data.fl44_external_purchases_block_request_statuses;
        },
        getFilters(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        async getRequests(page = true) {
            this.isLoading = true;
            try {
                this.isLoading = true;
                const response = await this.$api.cabinet.customer.blockRequests.getList(this.getFilters(page));
                this.blockRequests = response.data;
                this.meta = response.meta;
            } finally {
                this.isLoading = false;
            }
        },
        async cleanFilters() {
            this.filter = { ...emptyFilter };
            await this.getRequests();
        }
    }
};
</script>
<style scoped>
.fix-pos {
    padding-top: 18px !important;
}
</style>
