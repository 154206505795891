<template>
    <div class="create" v-if="!loading">
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="main_layer">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Коммерческое предложение<br />№ {{ form.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14">
                            <b>К запросу КП № {{ form.quotation_request.reg_number }}</b>
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="greyBg pb-4">
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link compact label="Наименование" :value="form.quotation_request.organization.name" :router-link="{ name: 'OrganizationShow', params: { id: form.quotation_request.organization.id } }" />
                <text-row compact label="ИНН" :value="form.quotation_request.organization.inn" />
                <text-row compact label="КПП" :value="form.quotation_request.organization.kpp" />
                <text-row compact label="ОГРН" :value="form.quotation_request.organization.ogrn" />
                <text-row compact label="Адрес" :value="form.quotation_request.organization.address" />
                <text-row-raw compact label="Адрес электронной почты">
                    <a :href="'mailto:' + form.quotation_request.organization.email" class="fs-14 col-form-label d-inline-block">{{ form.quotation_request.organization.email }}</a>
                </text-row-raw>
                <text-row-raw compact label="Контактный номер телефона">
                    <a :href="'tel:+' + form.quotation_request.organization.phone" class="fs-14 col-form-label d-inline-block">{{ $parsePhone(form.quotation_request.organization.phone) }}</a>
                </text-row-raw>

                <text-header>Информация о запросе КП</text-header>
                <text-row compact label="Предмет закупки" :value="form.quotation_request.name" />
                <text-row-datetime compact label="Дата/время размещения" :value="form.quotation_request.created_at" />
                <text-row-datetime compact label="Срок сбора КП" :value="form.quotation_request.deadline" />

                <text-header>Информация о поставщике</text-header>
                <text-row-link compact label="Наименование" :value="form.organization.name" :router-link="{ name: 'OrganizationShow', params: { id: form.organization.id } }" />
                <text-row compact label="ИНН" :value="form.organization.inn" />
                <text-row compact label="КПП" :value="form.organization.kpp" />
                <text-row compact label="ОГРН" :value="form.organization.ogrn" />
                <text-row compact label="Адрес" :value="form.organization.address" />
                <text-row-raw compact label="Адрес электронной почты">
                    <a :href="'mailto:' + form.organization.email" class="fs-14 col-form-label d-inline-block">{{ form.organization.email }}</a>
                </text-row-raw>
                <text-row-raw compact label="Контактный номер телефона">
                    <a :href="'tel:+' + form.organization.phone" class="fs-14 col-form-label d-inline-block">{{ $parsePhone(form.organization.phone) }}</a>
                </text-row-raw>

                <text-header>Информация о коммерческом предложении</text-header>
                <text-row-datetime compact label="Дата/время размещения" :value="form.created_at" />
                <text-row-datetime compact label="Срок действия коммерческого предложения" :value="form.deadline" />
                <text-row-price compact label="Итоговая сумма коммерческого предложения, руб" :value="form.cost" />
                <text-row-single-document compact label="Документ" :value="form.document" />

                <text-header>Спецификации</text-header>
                <b-row class="py-3" no-gutters>
                    <b-col class="fs-14 grey-color">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="fw-600">№</th>
                                    <th class="fw-600">Наименование товара/работы/услуги</th>
                                    <th class="fw-600 text-right">Количество</th>
                                    <th class="fw-600">Ед.изм</th>
                                    <th class="fw-600 text-right">Цена за ед., руб.</th>
                                    <th class="fw-600 text-right">Стоимость, руб.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <fragment v-for="(item, index) in form.quotation_items" :key="index">
                                    <tr>
                                        <td class="align-text-top">{{ index + 1 }}</td>
                                        <td class="align-text-top">
                                            <button v-b-toggle="'collapse-' + index" style="font-size: 0.8em" class="atmo-button-icon">
                                                <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                                <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                            </button>
                                            <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                            {{ item.name }}
                                        </td>
                                        <td class="align-text-top text-right text-nowrap">{{ parseFloat(item.count).toPrecision() }}</td>
                                        <td class="align-text-top">{{ item.okei?.local_symbol }}</td>
                                        <td class="align-text-top text-right text-nowrap">{{ $formatPrice(item.price) }}</td>
                                        <td class="align-text-top text-right text-nowrap">{{ $formatPrice(item.total_sum) }}</td>
                                    </tr>
                                    <tr v-show="visible[index]">
                                        <td :colspan="6">
                                            <p v-if="item.ktru?.reg_number"><b>Рег.номер ТРУ:</b> {{ item.ktru?.reg_number }}</p>
                                            <p v-if="item.ktru?.okpd_code"><b>Код ОКПД2:</b> {{ item.ktru?.okpd_code }}</p>
                                            <p v-if="item.ktru?.description"><b>Описание:</b> {{ item.ktru?.description }}</p>
                                            <p v-if="item.ktru?.specs && item.ktru?.specs?.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru?.specs && item.ktru?.specs?.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru?.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.name }}</b> - {{ spec.value }}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </fragment>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>

                <b-overlay :show="pdfLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-btn variant="custom-outline-secondary" class="mr-2" @click="downloadPDF">СКАЧАТЬ ПЕЧАТНУЮ ФОРМУ</b-btn>
                </b-overlay>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import FileDownload from 'js-file-download';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';

export default {
    name: 'QuotationShow',
    components: {
        TextRowSingleDocument,
        TextRowRaw
    },
    metaInfo() {
        return {
            title: 'Коммерческое предложение'
        };
    },
    data() {
        return {
            loading: true,
            pdfLoading: false,
            form: null,
            visible: []
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.form = await this.$api.quotationRequests.showOffer(this.$route.params.id);
            this.loading = false;
        },
        downloadPDF() {
            this.pdfLoading = true;
            this.$api.quotationRequests
                .downloadOffer(this.$route.params.id)
                .then((response) => {
                    FileDownload(response.data, 'Коммерческое предложение ' + this.form.reg_number + '.pdf');
                })
                .catch((error) => {
                    this.showError(error);
                })
                .finally(() => {
                    this.pdfLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 70px;
}
a {
    color: var(--green-color) !important;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
