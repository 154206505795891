var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c("div", { staticClass: "list" }, [
          _c("p", [_vm._v("Заказчики и Поставщики")]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }