var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { "md-12": "" } },
            [
              _vm.isModeEdit
                ? _c("text-header-new", [
                    _vm._v("Редактирование запроса на добавление позиций КТРУ"),
                  ])
                : _c("text-header-new", [
                    _vm._v("Создание запроса на добавление позиций КТРУ"),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.isModeEdit && _vm.isSelectCreateMethod
            ? _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-block text-center w-75 m-auto pb-5" },
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "btn btn-block btn-custom-green mb-4",
                        on: { click: _vm.createKtruBasedOnExisting },
                      },
                      [
                        _vm._v(
                          "Выбрать существующую позицию КТРУ для создания новой позиции на ее основе"
                        ),
                      ]
                    ),
                    _c(
                      "b-btn",
                      {
                        staticClass: "btn btn-block btn-custom-green",
                        on: { click: _vm.createNewKtru },
                      },
                      [_vm._v("Добавить новую позицию КТРУ вручную")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "b-col",
                [
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.dictionaries.ktruGroups,
                      v: _vm.$v.form.ktru_group_id,
                      label: "Группа КТРУ",
                      placeholder: "Выберите группу КТРУ",
                    },
                    model: {
                      value: _vm.form.ktru_group_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ktru_group_id", $$v)
                      },
                      expression: "form.ktru_group_id",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.dictionaries.ktruTypes,
                      v: _vm.$v.form.ktru_type_id,
                      label: "Тип КТРУ",
                      placeholder: "Выберите тип КТРУ",
                    },
                    model: {
                      value: _vm.form.ktru_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ktru_type_id", $$v)
                      },
                      expression: "form.ktru_type_id",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.dictionaries.countries,
                      v: _vm.$v.form.country_of_origin_id,
                      label: "Страна происхождения",
                      placeholder: "Выберите страну происхождения",
                    },
                    model: {
                      value: _vm.form.country_of_origin_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "country_of_origin_id", $$v)
                      },
                      expression: "form.country_of_origin_id",
                    },
                  }),
                  _c("form-row-edit-text", {
                    attrs: { v: _vm.$v.form.name, label: "Название" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("form-row-edit-textarea", {
                    attrs: { rows: 5, label: "Описание" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("form-row-single-select-with-search", {
                    attrs: {
                      options: _vm.dictionaries.okeis,
                      v: _vm.$v.form.okei_id,
                      search: _vm.searchOkeis,
                      "is-loading": _vm.isOkeiLoading,
                      label: "Единица измерения (ОКЕИ)",
                      "label-field": "breadcrumb",
                    },
                    model: {
                      value: _vm.form.okei_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "okei_id", $$v)
                      },
                      expression: "form.okei_id",
                    },
                  }),
                  _c("form-row-single-select-with-search", {
                    attrs: {
                      options: _vm.dictionaries.okpds,
                      v: _vm.$v.form.okpd_id,
                      search: _vm.searchOkpds,
                      "is-loading": _vm.isOkpdLoading,
                      label: "ОКПД2",
                      "label-field": "breadcrumb",
                    },
                    model: {
                      value: _vm.form.okpd_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "okpd_id", $$v)
                      },
                      expression: "form.okpd_id",
                    },
                  }),
                  _c("b-table", {
                    staticClass: "table-atmo",
                    attrs: {
                      fields: _vm.specFields,
                      items: _vm.form.specs,
                      small: "",
                      hover: "",
                      responsive: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "head(actions)",
                        fn: function () {
                          return [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top",
                                    value: "Добавить",
                                    expression: "'Добавить'",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                staticClass: "atmo-button-icon",
                                on: { click: _vm.addSpec },
                              },
                              [
                                _c("i", {
                                  staticClass: "atmo-icon-add-to-list",
                                }),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(name)",
                        fn: function (data) {
                          return [
                            _c("b-form-input", {
                              staticClass: "fs-14",
                              attrs: {
                                disabled: data.index === 0,
                                state:
                                  !_vm.$v.form.specs.$each.$iter[data.index]
                                    .name.$invalid,
                              },
                              model: {
                                value: data.item.name,
                                callback: function ($$v) {
                                  _vm.$set(data.item, "name", $$v)
                                },
                                expression: "data.item.name",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(value)",
                        fn: function (data) {
                          return [
                            _c("b-form-textarea", {
                              staticClass: "fs-14",
                              attrs: {
                                state:
                                  !_vm.$v.form.specs.$each.$iter[data.index]
                                    .value.$invalid,
                              },
                              model: {
                                value: data.item.value,
                                callback: function ($$v) {
                                  _vm.$set(data.item, "value", $$v)
                                },
                                expression: "data.item.value",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (data) {
                          return [
                            data.index !== 0
                              ? _c("action-button-small", {
                                  attrs: { icon: "trash" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSpec(data.index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
      !_vm.isSelectCreateMethod || _vm.isModeEdit
        ? _c(
            "b-row",
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Отмена")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: { variant: "custom-danger" },
                          on: { click: _vm.onSaveDraft },
                        },
                        [_vm._v("Сохранить черновик")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: {
                            variant: "custom-green",
                            disabled: _vm.$v.form.$invalid,
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("Разместить запрос")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("purchase-specification-modal", {
        attrs: { show: _vm.showKtruSelectModal, "allow-create-request": false },
        on: { cancel: _vm.cancelKtruSelectModal, add: _vm.processKtruSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }