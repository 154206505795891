<template>
    <ul>
        <li v-if="limit.budget_name"><b>Наименование бюджета:</b> {{ limit.budget_name }}</li>
        <li v-if="limit.funds_type"><b>Код типа средств:</b> {{ limit.funds_type }}</li>
        <li v-if="limit.action_code"><b>Код мероприятия:</b> {{ limit.action_code }}</li>
        <li v-if="limit.kosgu_code"><b>Код КОСГУ:</b> {{ limit.kosgu_code }}</li>
        <li v-if="limit.source_funds"><b>Бюджет - источник трансферта:</b> {{ limit.source_funds }}</li>
        <li v-if="limit.subsidy_code"><b>Код субсидии:</b> {{ limit.subsidy_code }}</li>
        <li v-if="limit.invest_object"><b>Объект:</b> {{ limit.invest_object }}</li>
        <li v-if="limit.direction_code"><b>Код направления:</b> {{ limit.direction_code }}</li>
        <li v-if="limit.regional_project_result"><b>Результат проекта:</b> {{ limit.regional_project_result }}</li>
        <li v-if="limit.additional_information_code"><b>Код доп. информации:</b> {{ limit.additional_information_code }}</li>
        <li v-if="limit.eis_ident_no"><b>ИКЗ:</b> {{ limit.eis_ident_no }}</li>
    </ul>
</template>

<script>
export default {
    name: 'limit-info',
    props: {
        limit: {
            type: Object,
            required: true
        }
    }
};
</script>
