var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        {
          staticClass: "main_layer py-4",
          staticStyle: { "min-height": "200px !important" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-container",
            { staticClass: "d-flex flex-column justify-content-between h-100" },
            [
              _c(
                "b-row",
                { staticStyle: { "margin-top": "40px" } },
                [
                  _c("b-col", [
                    !_vm.loading && _vm.article
                      ? _c(
                          "p",
                          { staticClass: "fs-14 fw-600 white-color mb-4" },
                          [
                            _vm._v(
                              "Опубликовано: " +
                                _vm._s(
                                  _vm.getDateFormat(_vm.article.published_at)
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    !_vm.loading && _vm.article
                      ? _c(
                          "p",
                          { staticClass: "fs-52 fw-600 white-color mb-4" },
                          [_vm._v(" " + _vm._s(_vm.article.title) + " ")]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg py-5", attrs: { fluid: "" } },
        [
          !_vm.loading
            ? _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.article.text) },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "green-color fs-52 mb-5" }, [
                      _vm._v("Другие новости"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "news_swiper" },
                    [
                      _c(
                        "swiper",
                        {
                          staticClass: "swiper-container mb-5",
                          attrs: { options: _vm.swiperMultipleSlides },
                        },
                        _vm._l(_vm.articles, function (message, mIndex) {
                          return _c(
                            "swiper-slide",
                            {
                              key: "message_" + message.type_id + "_" + mIndex,
                              staticClass: "slide p-0",
                              attrs: { width: "270" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "article_head d-flex",
                                    class: {
                                      news_head: message.type_id === 1,
                                      work_head: message.type_id === 2,
                                      update_head: message.type_id === 3,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/images/lifebuoy.svg",
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          tag: "p",
                                          replace: "",
                                          to: "/articles/" + message.id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(message.title) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "article_body" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-0",
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.lengthControl(
                                              message.description,
                                              120
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "article_footer" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDateFormat(
                                            message.published_at
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-light btn-sm fs-12 mt-1 px-4 fw-100",
                                        staticStyle: {
                                          "text-transform": "uppercase",
                                          color: "var(--grey-color)",
                                          "background-color": "#f5f5f7",
                                        },
                                        attrs: {
                                          to: "/articles/" + message.id,
                                          tag: "button",
                                          replace: "",
                                          variant: "light",
                                          size: "sm",
                                        },
                                      },
                                      [_vm._v(" Подробнее ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-prev custom-icon",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev",
                        },
                        [_c("i", { staticClass: "fas fa-chevron-left" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-next custom-icon",
                          attrs: { slot: "button-next" },
                          slot: "button-next",
                        },
                        [_c("i", { staticClass: "fas fa-chevron-right" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }