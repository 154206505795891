var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.proposal
    ? _c(
        "div",
        { staticClass: "purchases_create atmo-page" },
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-offer-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v(
                              "Предложение на закупку 44ФЗ № " +
                                _vm._s(_vm.proposal.purchase.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _c("b", [_vm._v("Статус")]),
                          _vm._v(": " + _vm._s(_vm.proposal.status.title)),
                        ]),
                        _vm.proposal.status.id === "rejected" &&
                        _vm.proposal.rejection_reason
                          ? _c(
                              "p",
                              { staticClass: "white-color fs-14" },
                              [
                                _c("b", [_vm._v("Причина отклонения")]),
                                _vm._v(
                                  ": " + _vm._s(_vm.proposal.rejection_reason)
                                ),
                                _c("br"),
                                _vm.proposal.rejection_comment
                                  ? [
                                      _c("b", [
                                        _vm._v("Комментарий заказчика"),
                                      ]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(_vm.proposal.rejection_comment)
                                      ),
                                      _c("br"),
                                    ]
                                  : _vm._e(),
                                _vm.proposal.rejection_attachment?.id
                                  ? [
                                      _c("b", [
                                        _vm._v(
                                          "Приложенный заказчиком документ"
                                        ),
                                      ]),
                                      _vm._v(": "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "white-system_link-style",
                                          attrs: {
                                            href: _vm.$getAttachmentDownloadLink(
                                              _vm.proposal.rejection_attachment
                                                .id
                                            ),
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.proposal.rejection_attachment
                                                .name
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  !(
                    _vm.proposal.purchase.status.id === "draft" ||
                    _vm.proposal.purchase.status.id === "signed" ||
                    _vm.proposal.purchase.status.id === "revoked"
                  )
                    ? _c(
                        "text-header",
                        [
                          _vm._v(" Закупка 44ФЗ № "),
                          _c(
                            "router-link",
                            {
                              staticClass: "system_link-style",
                              attrs: {
                                target: "_blank",
                                to: {
                                  name: "purchases.grouped.item.view",
                                  params: {
                                    group: "fl44",
                                    id: _vm.proposal.purchase.id,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.proposal.purchase.reg_number) +
                                  " (" +
                                  _vm._s(_vm.proposal.purchase.status.title) +
                                  ")"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("text-header", [
                        _vm._v(
                          " Закупка 44ФЗ № " +
                            _vm._s(_vm.proposal.purchase.reg_number) +
                            " (" +
                            _vm._s(_vm.proposal.purchase.status.title) +
                            ") "
                        ),
                      ]),
                  _c("text-row-single-document", {
                    attrs: { compact: true, label: "Карточка предприятия" },
                    model: {
                      value: _vm.proposal.organization_card,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "organization_card", $$v)
                      },
                      expression: "proposal.organization_card",
                    },
                  }),
                  _vm.proposal.purchase.supplier_requirements
                    .has_additional_requirements
                    ? [
                        _c("text-header", [
                          _vm._v("Подтверждение требований к поставщикам"),
                        ]),
                        _vm.proposal.purchase.supplier_requirements
                          .has_additional_requirements
                          ? [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "p",
                                    { staticClass: "fs-14 grey-color mt-2" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._l(
                                _vm.proposal.supplier_requirements_confirmed
                                  .additional_requirements,
                                function (document) {
                                  return _c("text-row-single-document", {
                                    key: document.id,
                                    attrs: {
                                      compact: true,
                                      "label-cols-lg": 6,
                                      "label-cols-sm": 6,
                                      label:
                                        document.description +
                                        " (Причина: " +
                                        document.reason +
                                        ")",
                                    },
                                    model: {
                                      value: document.attachment,
                                      callback: function ($$v) {
                                        _vm.$set(document, "attachment", $$v)
                                      },
                                      expression: "document.attachment",
                                    },
                                  })
                                }
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.proposal.attachments.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          compact: true,
                          label: "Дополнительные документы",
                        },
                        model: {
                          value: _vm.proposal.attachments,
                          callback: function ($$v) {
                            _vm.$set(_vm.proposal, "attachments", $$v)
                          },
                          expression: "proposal.attachments",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }),
                          _c("th", [
                            _vm._v("Наименование товара/работы/услуги"),
                          ]),
                          _c("th", { attrs: { width: "120px" } }, [
                            _vm._v("Количество /"),
                            _c("br"),
                            _vm._v("Ед.изм"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "160px" },
                            },
                            [
                              _vm._v("Цена за ед. с НДС /"),
                              _c("br"),
                              _vm._v("без НДС, руб."),
                            ]
                          ),
                          _c("th", { attrs: { width: "160px" } }, [
                            _vm._v("НДС, %"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "140px" },
                            },
                            [
                              _vm._v("Стоимость с НДС /"),
                              _c("br"),
                              _vm._v("без НДС, руб."),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.proposal.deliverables,
                          function (item, index) {
                            return _c("fragment", { key: index }, [
                              _c("tr", [
                                _c("td", { staticClass: "align-top" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "align-top" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "collapse-" + index,
                                            expression: "'collapse-' + index",
                                          },
                                        ],
                                        staticClass: "atmo-button-icon",
                                        staticStyle: { "font-size": "0.8em" },
                                      },
                                      [
                                        !_vm.visible[index]
                                          ? _c(
                                              "span",
                                              {
                                                attrs: {
                                                  title:
                                                    "Показать характеристики",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "atmo-icon-show",
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                attrs: {
                                                  title:
                                                    "Скрыть характеристики",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "atmo-icon-hide",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                    _c("b-collapse", {
                                      staticClass: "d-none",
                                      attrs: { id: "collapse-" + index },
                                      model: {
                                        value: _vm.visible[index],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.visible, index, $$v)
                                        },
                                        expression: "visible[index]",
                                      },
                                    }),
                                    _vm._v(
                                      " " + _vm._s(item.ktru_item?.name) + " "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-top text-nowrap" },
                                  [
                                    _vm._v(_vm._s(item.amount)),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(item.ktru_item.okei_local_symbol)
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "align-top text-right" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(
                                            item.price_per_unit_with_vat
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPriceOrMdash(
                                            item.price_per_unit_wo_vat
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "align-top" }, [
                                  _vm._v(
                                    _vm._s(item.vat ? item.vat.title : "—")
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "align-top text-right" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(item.sum_with_vat)
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPriceOrMdash(
                                            item.sum_wo_vat
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.visible[index],
                                      expression: "visible[index]",
                                    },
                                  ],
                                },
                                [
                                  _c("td", { attrs: { colspan: 5 } }, [
                                    item.ktru_item.reg_number
                                      ? _c("p", [
                                          _c("b", [_vm._v("Рег.номер ТРУ:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(item.ktru_item.reg_number)
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.ktru_item?.okpd_code
                                      ? _c("p", [
                                          _c("b", [_vm._v("Код ОКПД2:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(item.ktru_item?.okpd_code)
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.ktru_item.description
                                      ? _c("p", [
                                          _c("b", [_vm._v("Описание:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(item.ktru_item.description)
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.ktru_item.specs &&
                                    item.ktru_item.specs.length > 0
                                      ? _c("p", [
                                          _c("b", [_vm._v("Характеристики:")]),
                                        ])
                                      : _vm._e(),
                                    item.ktru_item.specs &&
                                    item.ktru_item.specs.length > 0
                                      ? _c(
                                          "ul",
                                          _vm._l(
                                            item.ktru_item.specs,
                                            function (spec, specIndex) {
                                              return _c(
                                                "li",
                                                {
                                                  key:
                                                    index +
                                                    "_spec_" +
                                                    specIndex,
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(_vm._s(spec.name)),
                                                  ]),
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(spec.value) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ]),
                  ]),
                  _c("text-row-price", {
                    attrs: {
                      compact: true,
                      label: "Стоимость предложения с НДС, руб.",
                    },
                    model: {
                      value: _vm.proposal.price_with_vat,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "price_with_vat", $$v)
                      },
                      expression: "proposal.price_with_vat",
                    },
                  }),
                  _c("text-row-price", {
                    attrs: {
                      compact: true,
                      label: "Стоимость предложения без НДС, руб.",
                    },
                    model: {
                      value: _vm.proposal.price_wo_vat,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "price_wo_vat", $$v)
                      },
                      expression: "proposal.price_wo_vat",
                    },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticClass: "my-2",
              attrs: { fluid: "", id: "action_buttons_row" },
            },
            [
              _c(
                "b-container",
                { staticClass: "mb-5" },
                [
                  _vm.$store.getters.isLoggedIn &&
                  _vm.proposal.actions.supplier.edit
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "text-nowrap text-uppercase",
                            attrs: {
                              variant: "custom-green",
                              to: {
                                name: "PurchasesNewOffer",
                                params: { id: _vm.proposal.purchase.id },
                              },
                            },
                          },
                          [_vm._v(" Изменить предложение ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }