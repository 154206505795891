var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preloader-backdrop" }, [
    _c(
      "svg",
      {
        attrs: {
          id: "Group_442",
          "data-name": "Group 442",
          xmlns: "http://www.w3.org/2000/svg",
          width: "139.405",
          height: "136.485",
          viewBox: "0 0 139.405 136.485",
        },
      },
      [
        _c("g", { attrs: { id: "Group_441", "data-name": "Group 441" } }, [
          _c("g", { attrs: { id: "Group_440", "data-name": "Group 440" } }, [
            _c(
              "g",
              {
                attrs: {
                  id: "Group_433",
                  "data-name": "Group 433",
                  transform: "translate(49.262 79.807)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_460",
                    "data-name": "Path 460",
                    d: "M1003.231,395.664a12.172,12.172,0,0,1-10.753-6.464h-9.6a20.348,20.348,0,0,0,40.7,0h-9.6A12.168,12.168,0,0,1,1003.231,395.664Z",
                    transform: "translate(-982.79 -352.871)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_461",
                    "data-name": "Path 461",
                    d: "M1014.462,423.453h0c.791-1.183,2.983-4.689-.468-11.618h0c-1.127-2.175-10.719-20.642-10.719-20.642h0l-10.721,20.642h0c-3.449,6.929-1.257,10.435-.467,11.618a20.371,20.371,0,0,1-8.782-13.067h0a17.483,17.483,0,0,1,.4-9.523h0v0l.008-.025,0,0,.008-.021.008-.027.01-.023v0l.01-.023.008-.025.01-.025h0l.008-.023.019-.049v0a43.033,43.033,0,0,1,2.08-4.481h0l17.4-26.009,17.4,26.009a42.264,42.264,0,0,1,2.082,4.481h0v0l.019.049.006.023h0l.01.025.006.025.01.023,0,0,.01.023.006.027.01.021v0l.01.025v0a17.483,17.483,0,0,1,.4,9.523h0a20.361,20.361,0,0,1-8.78,13.067Z",
                    transform: "translate(-982.834 -370.125)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_434",
                  "data-name": "Group 434",
                  transform: "translate(80.658 74.614)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_462",
                    "data-name": "Path 462",
                    d: "M1025.31,390.878a12.166,12.166,0,0,1-11.757,4.375l-5.982,7.5a20.347,20.347,0,1,0,25.372-31.816l-5.982,7.5A12.171,12.171,0,0,1,1025.31,390.878Z",
                    transform: "translate(-991.855 -364.195)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_463",
                    "data-name": "Path 463",
                    d: "M1047.986,391.9v0c-.43-1.354-1.807-5.255-9.374-6.877h0c-2.4-.474-22.822-4.489-22.822-4.489h0s8.458,19.013,9.454,21.251h0c3.266,7.016,7.374,7.489,8.791,7.609a20.367,20.367,0,0,1-15.692-1.282h0a17.5,17.5,0,0,1-7.2-6.251h0l-.015-.023,0,0-.011-.019-.015-.023-.013-.021v0l-.013-.021-.013-.023-.013-.021v0l-.013-.019-.027-.046v0a42.659,42.659,0,0,1-2.209-4.419h0L999.319,367.4l31.183,2.613a42.419,42.419,0,0,1,4.8,1.167h0l.051.017.021.008h0l.025.008.025.011.023.006h0l.025.008.023.011.023.006,0,0,.025.01h0a17.462,17.462,0,0,1,7.693,5.626h0a20.354,20.354,0,0,1,4.742,15.01Z",
                    transform: "translate(-999.319 -367.398)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_435",
                  "data-name": "Group 435",
                  transform: "translate(83.635 34.336)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_464",
                    "data-name": "Path 464",
                    d: "M1027.931,365.162a12.172,12.172,0,0,1-3.91,11.921l2.135,9.355a20.347,20.347,0,1,0-9.054-39.674l2.135,9.355A12.168,12.168,0,0,1,1027.931,365.162Z",
                    transform: "translate(-986.21 -346.25)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_465",
                    "data-name": "Path 465",
                    d: "M1050.383,351.912h0c-1.327-.507-5.236-1.865-11.222,3.042h0c-1.868,1.583-17.739,15.042-17.739,15.042h0l22.51,5.86h0c7.521,1.821,10.454-1.1,11.429-2.129a20.367,20.367,0,0,1-10.786,11.467h0a17.473,17.473,0,0,1-9.372,1.729h0l-.027,0h0l-.023,0-.027,0-.025,0h0l-.023,0-.027,0-.027,0h0l-.023,0-.051-.008h0a42.57,42.57,0,0,1-4.832-1.03h0l-29.229-11.178,21.485-22.752a42.672,42.672,0,0,1,3.906-3.026h0l.044-.03.021-.011h0l.021-.015.023-.011.021-.015v0l.023-.013.023-.013.019-.011,0,0,.023-.013h0a17.474,17.474,0,0,1,9.193-2.508h0a20.365,20.365,0,0,1,14.694,5.653Z",
                    transform: "translate(-1000.882 -346.25)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_436",
                  "data-name": "Group 436",
                  transform: "translate(71.557)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_466",
                    "data-name": "Path 466",
                    d: "M1016.659,342.748a12.173,12.173,0,0,1,6.881,10.49l8.645,4.163a20.348,20.348,0,0,0-36.665-17.659l8.647,4.165A12.172,12.172,0,0,1,1016.659,342.748Z",
                    transform: "translate(-993.655 -328.221)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_467",
                    "data-name": "Path 467",
                    d: "M1012.035,328.317l0,0c-1.226.72-4.723,2.929-4.62,10.669h0c.072,2.447.7,23.247.7,23.247h0s16.655-12.475,18.615-13.945h0c6.114-4.744,5.662-8.856,5.462-10.264a20.363,20.363,0,0,1,2.241,15.583h0a17.465,17.465,0,0,1-4.493,8.4h0v0l-.019.019,0,0-.015.015-.019.021-.017.017h0l-.017.017-.019.019-.019.019h0l-.015.017-.038.036,0,0a43.1,43.1,0,0,1-3.817,3.135h0l-26.965,15.882-4.392-30.983a42.5,42.5,0,0,1,.071-4.94h0v0l0-.051,0-.025h0l0-.027.006-.025v-.027l0-.027.006-.025,0-.023v0l0-.025v0a17.478,17.478,0,0,1,3.773-8.751h0a20.363,20.363,0,0,1,13.579-7.965Z",
                    transform: "translate(-994.541 -328.135)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_437",
                  "data-name": "Group 437",
                  transform: "translate(9.996 74.614)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_468",
                    "data-name": "Path 468",
                    d: "M977.512,390.878a12.168,12.168,0,0,0,11.759,4.375l5.982,7.5a20.348,20.348,0,0,1-25.374-31.816l5.984,7.5A12.17,12.17,0,0,0,977.512,390.878Z",
                    transform: "translate(-962.217 -364.195)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_469",
                    "data-name": "Path 469",
                    d: "M962.3,391.9l0,0c.43-1.354,1.806-5.255,9.374-6.877h0c2.4-.474,22.82-4.489,22.82-4.489h0s-8.458,19.013-9.454,21.251h0c-3.264,7.016-7.374,7.489-8.79,7.609a20.362,20.362,0,0,0,15.69-1.282h0a17.49,17.49,0,0,0,7.2-6.251h0l.015-.023v0l.011-.019.015-.023.013-.021v0l.013-.021.013-.023.015-.021v0l.013-.019.027-.046v0a43.068,43.068,0,0,0,2.207-4.419h0l9.485-29.823-31.183,2.613a42.309,42.309,0,0,0-4.8,1.167h0l-.049.017-.023.008h0l-.025.008-.023.011-.023.006h0l-.025.008-.025.011-.021.006,0,0-.025.01h0a17.477,17.477,0,0,0-7.694,5.626h0A20.364,20.364,0,0,0,962.3,391.9Z",
                    transform: "translate(-962.218 -367.398)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_438",
                  "data-name": "Group 438",
                  transform: "translate(0 34.336)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_470",
                    "data-name": "Path 470",
                    d: "M971.019,365.162a12.164,12.164,0,0,0,3.91,11.921l-2.135,9.355a20.347,20.347,0,1,1,9.054-39.674l-2.135,9.355A12.165,12.165,0,0,0,971.019,365.162Z",
                    transform: "translate(-956.969 -346.25)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_471",
                    "data-name": "Path 471",
                    d: "M962.625,351.912h0c1.328-.507,5.234-1.865,11.222,3.042h0C975.715,356.537,991.586,370,991.586,370h0l-22.51,5.86h0c-7.523,1.821-10.454-1.1-11.429-2.129a20.354,20.354,0,0,0,10.786,11.467h0a17.469,17.469,0,0,0,9.372,1.729h0l.027,0h0l.023,0,.025,0,.027,0h0l.025,0,.027,0,.025,0h0l.023,0,.053-.008h0a42.645,42.645,0,0,0,4.832-1.03h0l29.229-11.178L990.64,351.933a42.369,42.369,0,0,0-3.9-3.026h0l-.044-.03-.021-.011h0l-.023-.015-.023-.011-.019-.015,0,0-.021-.013-.023-.013-.019-.011,0,0-.023-.013h0a17.481,17.481,0,0,0-9.2-2.508h0a20.363,20.363,0,0,0-14.692,5.653Z",
                    transform: "translate(-956.356 -346.25)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Group_439",
                  "data-name": "Group 439",
                  transform: "translate(27.297)",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_472",
                    "data-name": "Path 472",
                    d: "M988.849,342.748a12.175,12.175,0,0,0-6.883,10.49l-8.645,4.163a20.348,20.348,0,0,1,36.665-17.659l-8.645,4.165A12.172,12.172,0,0,0,988.849,342.748Z",
                    transform: "translate(-971.302 -328.221)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                    opacity: "0.5",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "Path_473",
                    "data-name": "Path 473",
                    d: "M994.359,328.317l0,0c1.225.72,4.721,2.929,4.619,10.669h0c-.072,2.447-.7,23.247-.7,23.247h0L979.659,348.29h0c-6.114-4.744-5.66-8.856-5.46-10.264a20.363,20.363,0,0,0-2.242,15.583h0a17.477,17.477,0,0,0,4.491,8.4h0l0,0,.017.019,0,0,.015.015.019.021.017.017h0l.017.017.019.019.017.019h0l.015.017.04.036v0a42.825,42.825,0,0,0,3.819,3.135h0l26.963,15.882,4.392-30.983a42.476,42.476,0,0,0-.069-4.94h0v0l-.006-.051,0-.025h0l0-.027-.006-.025,0-.025,0,0,0-.027,0-.025,0-.023v0l0-.025,0,0a17.444,17.444,0,0,0-3.773-8.751h0a20.358,20.358,0,0,0-13.578-7.965Z",
                    transform: "translate(-971.301 -328.135)",
                    fill: "#fff",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "text-center" }, [
      _c(
        "p",
        { staticClass: "text-center mt-4 fs-28 white-color" },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }