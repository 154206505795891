var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between" },
            [
              _c("text-header-new", [_vm._v("Пользователи организации")]),
              _c(
                "div",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: {
                        to: { name: "UserCreate" },
                        size: "sm",
                        variant: "custom-green",
                      },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "my-auto mr-2",
                        attrs: { "aria-hidden": "true", icon: "plus-circle" },
                      }),
                      _vm._v(" Добавить пользователя "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _vm.users && _vm.pagination.total > 0 && _vm.pagination.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1",
                attrs: {
                  "per-page": _vm.pagination.per_page,
                  "total-rows": _vm.pagination.total,
                  align: "center",
                },
                model: {
                  value: _vm.pagination.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "current_page", $$v)
                  },
                  expression: "pagination.current_page",
                },
              })
            : _vm._e(),
          _c("b-table", {
            attrs: {
              busy: _vm.loading,
              fields: _vm.fields,
              items: _vm.users,
              "per-page": _vm.pagination.per_page,
              "empty-text": "Данные отсутствуют",
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle mr-1" }),
                        _c("strong", [_vm._v("Загрузка данных...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(name)",
                fn: function (row) {
                  return [
                    row.item.is_blocked
                      ? _c("b-icon", {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.v-danger",
                              value: "Заблокирован",
                              expression: "'Заблокирован'",
                              modifiers: { hover: true, "v-danger": true },
                            },
                          ],
                          staticClass: "mr-1",
                          attrs: { icon: "lock-fill", variant: "danger" },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(row.item.name) + " "),
                  ]
                },
              },
              {
                key: "cell(username)",
                fn: function (row) {
                  return [
                    _vm._v(" " + _vm._s(row.item.username)),
                    _c("br"),
                    _vm._v(" " + _vm._s(row.item.email) + " "),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatDateTime(row.item.created_at)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "no-caret": "",
                              right: "",
                              size: "sm",
                              "toggle-class": "text-decoration-none",
                              variant: "link",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "button-content" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "gear-fill",
                                    variant: "secondary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "fs-14",
                                attrs: {
                                  to: {
                                    name: "UserEdit",
                                    params: { id: row.item.id },
                                  },
                                },
                              },
                              [_vm._v("Редактировать")]
                            ),
                            !row.item.is_blocked
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "fs-14",
                                    on: {
                                      click: function ($event) {
                                        return _vm.blockUser(row.item)
                                      },
                                    },
                                  },
                                  [_vm._v("Заблокировать")]
                                )
                              : _vm._e(),
                            row.item.is_blocked
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "fs-14",
                                    on: {
                                      click: function ($event) {
                                        return _vm.unblockUser(row.item)
                                      },
                                    },
                                  },
                                  [_vm._v("Разблокировать")]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.users && _vm.pagination.total > 0 && _vm.pagination.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1",
                attrs: {
                  "per-page": _vm.pagination.per_page,
                  "total-rows": _vm.pagination.total,
                  align: "center",
                },
                on: { input: _vm.fillData },
                model: {
                  value: _vm.pagination.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "current_page", $$v)
                  },
                  expression: "pagination.current_page",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }