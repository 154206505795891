var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Уважаемый пользователь!",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        visible: _vm.show,
        "hide-header-close": "",
      },
      on: { close: _vm.cancelModal, cancel: _vm.cancelModal },
    },
    [
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c("b-col", [
            _c("p", { staticClass: "fs-14 grey-color" }, [
              _vm._v(
                'Обращаем Ваше внимание, что данное действие необратимо. Нажимая кнопку "Сформировать итоговый протокол" Вы подтверждаете выбор поставщика для данной закупки.'
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: {
                    type: "reset",
                    variant: "custom-outline-secondary",
                    disabled: _vm.buttonsDisabled,
                  },
                  on: { click: _vm.cancelModal },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    type: "submit",
                    variant: "custom-green",
                    disabled: _vm.buttonsDisabled,
                  },
                  on: { click: _vm.okModal },
                },
                [_vm._v("Сформировать итоговый протокол")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }