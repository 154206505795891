<template>
    <div>
        <b-btn class="btn-card-action btn-card-action-danger my-1" v-if="purchase.actions.customer.unpublish" @click="showModal = true">
            Снять с публикации<template v-if="purchase.can_be_canceled_till"><br />(До {{ getDateTimeFormat(purchase.can_be_canceled_till) }})</template></b-btn
        >
        <b-modal ref="publish-modal" v-model="showModal" size="lg" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Снять с публикации для редактирования">
            <div>
                <p>
                    Вы действительно хотите снять с публикации для редактирования закупку <strong>{{ purchase.reg_number }}</strong
                    >?
                </p>
                <ul>
                    <li>- Снятие извещения с публикации возможно только 1 раз.</li>
                    <li>- Снятие извещения с публикации приведет к отмене всех поданных на данное извещение предложений от поставщиков.</li>
                    <li>- Снятие извещения с публикации позволит Вам внести изменения в извещение, за исключением поля "Наименование закупочной сессии".</li>
                    <li>
                        - В момент снятия извещения с публикации, будет зафиксировано оставшееся рабочее время до окончания подачи предложений. В момент возобновления публикации, время окончания подачи предложений будет рассчитано на основании зафиксированного в момент снятия извещения с публикации,
                        оставшегося рабочего времени.
                    </li>
                </ul>
                <p>
                    <strong>Объект закупки</strong>: {{ purchase.purchase_object }}<br />
                    <strong>Предмет закупки</strong>: {{ purchase.deliverable_group_title }}<br />
                    <template v-if="purchase.duration_type.id !== 'quotation_request'">
                        <strong>Сумма закупки, руб.</strong>: {{ $formatPrice(purchase.start_price) }}<br />
                    </template>
                    <strong>Планируемая дата заключения договора</strong>: {{ getDateFormat(purchase.planned_contract_conclusion_date) }}
                </p>
            </div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showModal = false">Отмена</b-button>&nbsp;
                <b-button class="text-uppercase" variant="custom-danger" @click="unpublishPurchase">Снять с публикации</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'cabinet-fl223-notice-card-unpublish-button',
    props: {
        purchase: {
            type: Object
        }
    },
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        async unpublishPurchase() {
            if (await this.$api.products.fl223.purchases.unpublish(this.purchase.id)) {
                this.showModal = false;
                this.$emit('unpublish');
            }
        }
    }
};
</script>
