<template>
    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
        <b-button size="sm" variant="custom-green" class="text-uppercase" @click="sendRecalculateRequest">Пересчитать</b-button>
    </b-overlay>
</template>

<script>
import { $api } from '@/services/backend/api';

export default {
    name: 'fl44-report-recalculate-button',
    props: {
        fl44PurchaseReport: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDataSending: false
        };
    },
    methods: {
        async sendRecalculateRequest() {
            this.isDataSending = true;
            if (await $api.personal.fl44PurchaseReports.recalculateReport(this.fl44PurchaseReport.id)) {
                this.refresh();
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        }
    }
};
</script>
