<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <slot name="header">
                    <text-header>Спецификация</text-header>
                </slot>
            </b-col>
        </b-row>
        <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" :class="{ 'is-table-invalid': v.$invalid || specErrors }" :busy="isLoading" details-td-class="py-1" show-empty small responsive>
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #row-details="data">
                <template v-if="data.item.ktru">
                    <p><b>Рег.номер ТРУ:</b> {{ data.item.ktru.reg_number }}</p>
                    <p><b>Группа ТРУ:</b> {{ data.item.ktru.ktru_group_name }}</p>
                    <p><b>Наименование:</b> {{ data.item.ktru.name }}</p>
                    <p><b>Тип:</b> {{ data.item.ktru.type }}</p>
                    <p v-if="data.item.okpd"><b>Код ОКПД2:</b> {{ data.item.okpd ? data.item.okpd.code : '&mdash;' }}</p>
                    <p v-if="data.item.ktru.country_of_origin_name"><b>Страна происхождения:</b> {{ data.item.ktru.country_of_origin_name }}</p>
                    <p v-if="data.item.ktru.description"><b>Описание:</b> {{ data.item.ktru.description }}</p>
                    <p v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                    <ul v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0">
                        <li v-for="(spec, key) in data.item.ktru.specs" :key="'spec' + key">
                            <span class="fw-600">{{ spec.name }}</span>: {{ spec.value }}
                        </li>
                    </ul>
                </template>
                <template v-else>&mdash;</template>
            </template>
            <template #cell(specs)="data">
                <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                {{ data.item.ktru.name }}
            </template>
            <template #cell(count)="data">
                <b-form-input
                    v-if="withCountEdit"
                    v-model.number="data.item.count"
                    class="mb-0 form-control fs-14 text-right amount-input"
                    :class="!v.$each[data.index].count.$invalid ? 'is-valid' : 'is-invalid'"
                    min="0.000000001"
                    step="0.000000001"
                    type="number"
                    @focus="$event.target?.select()" />
                <template v-else>
                    {{ data.item.count }}
                </template>
            </template>
            <template #cell(okei)="data">
                <template v-if="data.item.okei">{{ data.item.okei.local_symbol }}</template>
            </template>
            <template #cell(price)="data">
                <currency-input v-model="data.item.price" :class="v.$each[data.index]?.price && !v.$each[data.index].price.$invalid ? 'is-valid' : 'is-invalid'" class="form-control fs-14 text-right currency-input mb-0" currency="RUB" locale="ru" @focus="$event.target?.select()" />
            </template>
            <template #cell(cost)="data">
                {{ $formatPrice(roundToTwoDecimals(data.item.count * data.item.price)) }}
            </template>
            <template #cell(actions)="data">
                <action-button-small icon="trash" @click="deleteItem(data.index)" title="Удалить" />
            </template>
            <template #bottom-row>
                <b-td colspan="2" class="align-text-top py-1 pl-1">
                    <b-button v-if="!noAddSpec" variant="custom-green" @click="showSpec = true">Добавить позицию спецификации</b-button>
                </b-td>
                <b-td v-if="withPrice" colspan="4" class="text-right py-1">
                    <span v-if="items.length > 0" class="fw-600">Общая стоимость, руб.: {{ $formatPrice(totalCost) }}</span>
                </b-td>
            </template>
            <template #empty>
                <div class="w-100 text-center">Не добавлено позиций спецификации</div>
            </template>
        </b-table>
        <b-form-invalid-feedback :state="!v.$invalid && !specErrors" class="mt-3">{{ specErrorMessage }}</b-form-invalid-feedback>
        <purchase-specification-modal :show="showSpec" @add="addSpec" @cancel="showSpec = false" />
    </div>
</template>

<script>

import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";
import { CurrencyInput } from 'vue-currency-input';
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'form-row-specification-table-new',
    components: {
        CurrencyInput,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        v: { // TODO: проработать вариант с отсутствием в валидации $each и $each.count $each.price (нужно для подсветки конкретного поля в таблице)
            type: Object,
            required: true
        },
        withPrice: {
            type: Boolean,
            default: false
        },
        withCountEdit: {
            type: Boolean,
            default: false
        },
        specSum: {
            type: Number
        },
        specErrors: {
            type: Boolean
        },
        specErrorMessage: {
            type: String,
            default: ''
        },
        noAddSpec: {
            type: Boolean,
            default: false
        },
        noDeleteSpec: {
            type: Boolean,
            default: false
        },
        noDoubles: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseFields: [
                { key: 'index', label: '№', tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { minWidth: '400px' } },
                { key: 'count', label: 'Количество', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } },
                { key: 'okei', label: 'Ед.изм.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '100px' } },
            ],
            showSpec: false
        };
    },
    methods: {
        roundToTwoDecimals,
        deleteItem(index) {
            this.items.splice(index, 1);
        },
        addSpec(spec) {
            const newSpec = cloneDeep(spec);
            if (!newSpec.ktru_item_id) {
                this.pushToast({
                    text: 'Неверный формат спецификации',
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return;
            }
            const alreadyExists = this.items.find((i) => i.ktru_item_id === spec.ktru_item_id);
            if (this.noDoubles && alreadyExists) {
                return;
            }
            if (this.withPrice) {
                newSpec['price'] = 0;
            }
            this.items.push(newSpec);
            this.showSpec = false;
        }
    },
    computed: {
        fields() {
            const fields = [...this.baseFields];
            if (this.withPrice) {
                fields.push({ key: 'price', label: 'Цена за ед., руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } });
                fields.push({ key: 'cost', label: 'Стоимость, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '130px' } });
            }
            if (!this.noDeleteSpec) {
                fields.push(
                    { key: 'actions', label: '', tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                );
            }

            return fields;
        },
        items: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        totalCost() {
            return roundToTwoDecimals(this.items.reduce((sum, item) => {
                return sum += item.count * item.price;
            }, 0));
        }
    }
};
</script>
<style scoped>
.amount-input {
    height: 30px;
}
.is-table-invalid {
    border: 1px solid red;
    border-radius: 4px;
}
.form-control.is-invalid, .form-control.is-valid {
    background-image: none;
    padding-right: 0.3rem !important;
}
</style>
