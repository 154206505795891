var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAvailable
    ? _c(
        "div",
        [
          _c(
            "b-btn",
            {
              staticClass: "fs-14 my-1 w-100",
              attrs: { variant: "custom-danger", size: "sm" },
              on: { click: _vm.showModal },
            },
            [_vm._v("Отклонить заявку")]
          ),
          _c(
            "b-modal",
            {
              ref: "cancel-modal",
              attrs: {
                title: _vm.title,
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "hide-header-close": "",
                centered: "",
                size: "lg",
              },
              model: {
                value: _vm.isModalVisible,
                callback: function ($$v) {
                  _vm.isModalVisible = $$v
                },
                expression: "isModalVisible",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "fs-16 font-weight-bold grey-color" },
                      [
                        _vm._v(
                          "Укажите пункт конкурсной документации или Положения о закупках, в соответствии с которым отклоняется заявка участника."
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c("form-row-edit-textarea", {
                attrs: {
                  label: "Введите причину отклонения заявки",
                  v: _vm.$v.form.reason,
                  rows: 5,
                },
                model: {
                  value: _vm.form.reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "reason", $$v)
                  },
                  expression: "form.reason",
                },
              }),
              _c("p", { staticClass: "text-danger fw-600" }, [
                _vm._v("ВНИМАНИЕ! Данная операция необратима."),
              ]),
              _c(
                "div",
                {
                  staticClass: "w-100 text-right",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mr-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancelModal },
                        },
                        [_vm._v("Отмена")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            variant: "custom-danger",
                            disabled: _vm.$v.$invalid,
                          },
                          on: { click: _vm.rejectProposal },
                        },
                        [_vm._v("Отклонить заявку")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }