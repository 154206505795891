var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Запрос блокировки внесистемной закупки",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hide: _vm.onHide },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [_c("button-close-modal")]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isLoading,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onHide },
                    },
                    [_vm._v("Отмена ")]
                  ),
                  _vm._v("  "),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isLoading,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.$v.$invalid,
                        variant: "custom-danger",
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Подтвердить")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-block text-left" },
        [
          _c("p", [
            _vm._v(
              "Вы уверены, что хотите отправить запрос на блокировку закупки №" +
                _vm._s(_vm.purchaseRegNumber) +
                "?"
            ),
          ]),
          _c("form-row-edit-textarea", {
            attrs: {
              label: "Введите причину запроса блокировки",
              v: _vm.$v.blockMessage,
              rows: 4,
              "label-on-top": "",
            },
            model: {
              value: _vm.blockMessage,
              callback: function ($$v) {
                _vm.blockMessage = $$v
              },
              expression: "blockMessage",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }