var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-col", { staticClass: "my-2", attrs: { md: "6" } }, [
    _c("div", { staticClass: "purchase_part" }, [
      _c(
        "div",
        { staticClass: "organization_part-header" },
        [
          _c(
            "b-row",
            { staticClass: "w-100 m-0" },
            [
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "8", md: "8" } },
                [
                  _c(
                    "p",
                    { staticClass: "organization_part-header_number" },
                    [
                      _vm.organization.is_smp
                        ? _c(
                            "b-badge",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  value: { variant: "custom-green" },
                                  expression: "{ variant: 'custom-green' }",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "fs-12 mr-2",
                              attrs: {
                                variant: "inverse-green",
                                title:
                                  "Является субъектом малого предпринимательства",
                              },
                            },
                            [_vm._v("СМП")]
                          )
                        : _vm._e(),
                      _vm.organization.is_isp
                        ? _c(
                            "b-badge",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  value: { variant: "custom-green" },
                                  expression: "{ variant: 'custom-green' }",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "fs-12",
                              attrs: {
                                variant: "inverse-green",
                                title:
                                  "Является прямым производителем товаров, исполнителем услуг или подрядчиком работ",
                              },
                            },
                            [_vm._v("ИСП")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "4", md: "4" } },
                [
                  _vm.organization.is_registered
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "organization_part-header_label float-right text-right my-auto",
                        },
                        [
                          _c("img", {
                            staticClass: "mr-1",
                            attrs: {
                              src: "/images/check.svg",
                              alt: "check_label",
                            },
                          }),
                          _vm._v(" Зарегистрирован "),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "organization_part-body row" },
        [
          _c("div", { staticClass: "px-3 mt-2 w-100" }, [
            _c(
              "p",
              { staticClass: "green-color fw-600 fs-14 text-uppercase" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "system_link-style",
                    attrs: {
                      to: {
                        name: "OrganizationShow",
                        params: { id: _vm.organization.id },
                      },
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.organization.name))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "px-3 mb-1 w-100" }, [
            _c("p", { staticClass: "fs-14 fw-400 grey-color" }, [
              _vm._v(" Адрес: "),
              _c("span", { staticClass: "fw-600 grey-color" }, [
                _vm._v(" " + _vm._s(_vm.organization.address) + " "),
              ]),
            ]),
          ]),
          _c("b-col", { staticClass: "organization_part-body_col" }, [
            _c("div", { staticClass: "my-2" }, [
              _vm.organization.contact_name
                ? _c("div", { staticClass: "w-100 my-2" }, [
                    _c("p", { staticClass: "m-0 fs-14 grey-color" }, [
                      _vm._v("Контактное лицо:"),
                    ]),
                    _c("p", { staticClass: "m-0 fs-14 grey-color fw-600" }, [
                      _vm._v(_vm._s(_vm.organization.contact_name)),
                    ]),
                  ])
                : _vm._e(),
              _vm.organization.rating
                ? _c("div", { staticClass: "w-100 my-2" }, [
                    _c("p", { staticClass: "fs-14 fw-400 m-0 grey-color" }, [
                      _vm._v(" Рейтинг: "),
                      _c("span", { staticClass: "fw-600 grey-color" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$formatPrice(_vm.organization.rating)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "rating_scale" },
                      [
                        _vm._l(
                          _vm.organizationRatingWhole,
                          function (el, index) {
                            return _c(
                              "div",
                              {
                                key: "index_" + index,
                                staticClass: "rating_scale-part_wrapper",
                                style: `border-color: ${_vm.ratingColor}`,
                              },
                              [
                                _c("div", {
                                  staticClass: "rating_scale-part",
                                  style: `background-color: ${_vm.ratingColor}`,
                                }),
                              ]
                            )
                          }
                        ),
                        _vm._l(
                          Math.ceil(_vm.organizationRatingRest),
                          function (el, index) {
                            return _c(
                              "div",
                              {
                                key: "indexx_" + index,
                                staticClass: "rating_scale-part_wrapper",
                              },
                              [
                                _c("div", {
                                  staticClass: "rating_scale-part",
                                  style: `background: linear-gradient(to right, ${_vm.ratingColor} 0%, ${_vm.ratingColor} ${_vm.transitionPercent}%, #fff ${_vm.transitionPercent}%, #fff 100%)`,
                                }),
                              ]
                            )
                          }
                        ),
                        _vm._l(_vm.rest, function (el, index) {
                          return _c(
                            "div",
                            {
                              key: "indexxx_" + index,
                              staticClass:
                                "rating_scale-part_wrapper rest_part",
                            },
                            [_c("div", { staticClass: "rating_scale-part" })]
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("b-col", { staticClass: "organization_part-body_col" }, [
            _c("div", { staticClass: "my-2" }, [
              _c("div", { staticClass: "my-2" }, [
                _c(
                  "p",
                  { staticClass: "fs-14 fw-400 m-0 text-uppercase grey-color" },
                  [
                    _vm._v(" ОГРН / ОГРНИП: "),
                    _c("span", { staticClass: "fw-600 grey-color" }, [
                      _vm._v(" " + _vm._s(_vm.organization.ogrn) + " "),
                    ]),
                  ]
                ),
              ]),
              _vm.organization.inn
                ? _c("div", { staticClass: "my-2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-14 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ИНН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.organization.inn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.organization.kpp
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-14 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" КПП: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.organization.kpp) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }