var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `atmo-override-color atmo-entity-item-title ${_vm.cardCssClass} pr-2 position-relative`,
            "body-class": "purchase_part-body p-0",
            "border-variant": "light",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v("44-ФЗ")]
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "purchases.grouped.item.view",
                                  params: {
                                    group: "fl44",
                                    id: _vm.contract.id,
                                  },
                                },
                                exact: "",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.contract.reg_number))]
                          ),
                          _vm.contract.purchase_object
                            ? [
                                _vm._v(
                                  ' На закупку "' +
                                    _vm._s(_vm.contract.purchase_object) +
                                    '"'
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "text-nowrap text-uppercase status-title",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.contract.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "purchase_part-subheader" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _c(
                      "abbr",
                      { attrs: { title: "Начальная цена контракта" } },
                      [_vm._v("НЦК")]
                    ),
                    _vm._v(": "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        _vm._s(_vm.$formatPrice(_vm.contract.start_price)) +
                          " руб."
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Предложений: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(_vm._s(_vm.contract.purchase_actual_offers_count)),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v("Лучшее: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.contract.purchase_min_offer_cost
                              ? _vm.$formatPrice(
                                  _vm.contract.purchase_min_offer_cost
                                ) + " руб."
                              : "-"
                          )
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Снижение: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(_vm._s(_vm.contract.economy_percentage) + " %"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-2" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "3" } },
                [
                  _c("div", [
                    _vm.contract.status.id === "contract:active" ||
                    _vm.contract.status.id === "contract:fulfilled" ||
                    _vm.contract.status.id === "contract:unfulfilled" ||
                    _vm.contract.status.id === "contract:terminated"
                      ? _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                          _vm._v(" Контракт заключен: "),
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getDateFormat(_vm.contract.contract_date)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.contract.contract_price
                      ? _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                          _vm._v(" Цена контракта: "),
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$formatPrice(_vm.contract.contract_price)
                              ) + " руб."
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !!_vm.contract.planned_end_date
                      ? _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                          _vm._v(" Планируемая дата исполнения: "),
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getDateFormat(_vm.contract.planned_end_date)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                      _vm._v(" Количество позиций: "),
                      _c("span", { staticClass: "fw-600" }, [
                        _vm._v(_vm._s(_vm.contract.items_count)),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "6" } },
                [
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                        _vm._v(" Предмет закупки: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.contract.purchase_name)),
                        ]),
                      ]),
                      _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                        _vm._v(" Заказчик: "),
                        _c(
                          "span",
                          { staticClass: "fw-600" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style text-uppercase fs-14",
                                attrs: {
                                  to:
                                    "/organizations/" +
                                    _vm.contract.organization_id,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.contract.customer_short_name) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.contract.organization_final_id
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [
                              _vm._v(" Поставщик: "),
                              _c(
                                "span",
                                { staticClass: "fw-600" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "system_link-style text-uppercase fs-14",
                                      attrs: {
                                        to:
                                          "/organizations/" +
                                          _vm.contract.organization_final_id,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.contract.organization_final_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.contract.delivery_addresses &&
                      _vm.contract.delivery_addresses.length > 0
                        ? [
                            _c(
                              "p",
                              { staticClass: "fs-14 m-0 grey-color my-1" },
                              [_vm._v("Адреса доставки или самовывоза:")]
                            ),
                            _c(
                              "ul",
                              {
                                staticStyle: {
                                  "padding-left": "20px",
                                  "list-style-type": "disc !important",
                                },
                              },
                              _vm._l(
                                _vm.contract.delivery_addresses,
                                function (address, index) {
                                  return _c(
                                    "li",
                                    _vm._b(
                                      {
                                        key: index,
                                        staticClass:
                                          "fs-14 m-0 grey-color fw-600",
                                      },
                                      "li",
                                      address,
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(address.address) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "purchase_part-body_col atmo-column atmo-special-style",
                  attrs: { md: "3" },
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-search link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "purchases.grouped.item.view",
                                params: { group: "fl44", id: _vm.contract.id },
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Информация о закупке")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-dynamic link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "purchases.grouped.item.view",
                                params: { group: "fl44", id: _vm.contract.id },
                                hash: "#atmo-proposals",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Динамика предложений")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-shield link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "purchases.grouped.item.view",
                                params: { group: "fl44", id: _vm.contract.id },
                                hash: "#atmo-supplier-info",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Итоговый протокол")]
                        ),
                      ],
                      1
                    ),
                    _vm.contract.status.id !== "contract:failed"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "d-flex align-items-center green-color fs-14 fw-600 my-1",
                          },
                          [
                            _c("i", {
                              staticClass: "atmo-icon-contract link-icon",
                            }),
                            _c(
                              "router-link",
                              {
                                staticClass: "system_link-style",
                                attrs: {
                                  to: {
                                    name: "purchases.grouped.item.view",
                                    params: {
                                      group: "fl44",
                                      id: _vm.contract.id,
                                    },
                                    hash: "#atmo-contract",
                                  },
                                  exact: "",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Контракт")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.contract.status.id !== "contract:failed"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "d-flex align-items-center green-color fs-14 fw-600 my-1",
                          },
                          [
                            _c("i", {
                              staticClass: "atmo-icon-fulfilled link-icon",
                            }),
                            _c(
                              "router-link",
                              {
                                staticClass: "system_link-style",
                                attrs: {
                                  to: {
                                    name: "purchases.grouped.item.view",
                                    params: {
                                      group: "fl44",
                                      id: _vm.contract.id,
                                    },
                                    hash: "#atmo-contract-fulfillment",
                                  },
                                  exact: "",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Исполнение контракта")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }