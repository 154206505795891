var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                _vm._v("ТРУ для импортозамещения"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-2", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "grey-color" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Наименование товара/работы/услуги")]),
                    _c("th", { attrs: { width: "1" } }),
                    _c("th", { attrs: { nowrap: "", width: "100px" } }, [
                      _vm._v("Ед.изм"),
                    ]),
                    _c("th", { attrs: { nowrap: "", width: "100px" } }, [
                      _vm._v("Страна происхождения"),
                    ]),
                    _c(
                      "th",
                      {
                        staticStyle: { "min-width": "160px" },
                        attrs: { width: "160px" },
                      },
                      [_vm._v("Цена за ед., руб.")]
                    ),
                  ]),
                ]),
                _c("tbody", [
                  _vm.ktruItem
                    ? _c("tr", [
                        _c("td", { staticClass: "align-text-top" }, [
                          _vm._v(" " + _vm._s(_vm.ktruItem.name) + " "),
                        ]),
                        _c("td", { attrs: { width: "1" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button-icon float-right",
                              on: {
                                click: function ($event) {
                                  _vm.visibleDetails = !_vm.visibleDetails
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    title: "Показать описание/характеристики",
                                  },
                                },
                                [
                                  !_vm.visibleDetails
                                    ? _c("i", { staticClass: "atmo-icon-show" })
                                    : _c("i", {
                                        staticClass: "atmo-icon-hide",
                                      }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "align-text-top" }, [
                          _vm._v(_vm._s(_vm.ktruItem.okei_local_symbol)),
                        ]),
                        _c("td", { staticClass: "align-text-top" }, [
                          _vm._v(_vm._s(_vm.ktruItem.country_of_origin_name)),
                        ]),
                        _c("td", { staticClass: "align-text-top" }, [
                          _vm._v(_vm._s(_vm.price)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.ktruItem && _vm.visibleDetails
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "align-text-top",
                            attrs: { colspan: "5" },
                          },
                          [
                            _vm.ktruItem.description
                              ? _c("p", [
                                  _c("b", [_vm._v("Описание:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.ktruItem.description)
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ktruItem.specs && _vm.ktruItem.specs.length > 0
                              ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                              : _vm._e(),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm.ktruItem.specs &&
                              _vm.ktruItem.specs.length > 0
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.ktruItem.specs,
                                      function (spec, specIndex) {
                                        return _c(
                                          "li",
                                          { key: "spec_" + specIndex },
                                          [
                                            _c("strong", [
                                              _vm._v(_vm._s(spec.name)),
                                            ]),
                                            _vm._v(
                                              " - " + _vm._s(spec.value) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }