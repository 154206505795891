<template>
    <b-modal title="Снятие извещения с публикации" centered hide-footer no-close-on-backdrop no-close-on-esc @close="cancelModal" @cancel="cancelModal" :visible="show">
        <b-row class="py-2" no-gutters>
            <b-col class="fs-18 text-center">Вы действительно хотите снять извещение<br />№ {{ purchase.reg_number }} с публикации? </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col>
                <ul>
                    <li class="fs-14 grey-color">Снятие извещения с публикации возможно только 1 раз.</li>
                    <li class="fs-14 grey-color">Снятие извещения с публикации приведет к отмене всех поданных на данное извещение предложений от поставщиков.</li>
                    <li class="fs-14 grey-color">Снятие извещения с публикации позволит Вам внести изменения в извещение, за исключением поля "Наименование закупочной сессии".</li>
                    <li class="fs-14 grey-color">
                        В момент снятия извещения с публикации, будет зафиксировано оставшееся рабочее время до окончания подачи предложений. В момент возобновления публикации, время окончания подачи предложений будет рассчитано на основании зафиксированного в момент снятия извещения с публикации,
                        оставшегося рабочего времени.
                    </li>
                </ul>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col class="text-right">
                <b-button type="reset" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelModal">Отмена</b-button>
                <b-button type="submit" class="text-uppercase" variant="custom-green" @click="okModal">Отправить</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    name: 'CancelPurchasePublishingModal',
    props: ['purchase', 'show'],
    data() {
        return {};
    },
    methods: {
        cancelModal() {
            this.$emit('cancel');
        },
        okModal() {
            this.$emit('ok');
        }
    }
};
</script>

<style></style>
