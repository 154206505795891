<template>
    <div v-if="isAvailable">
        <b-btn @click="showModal" class="btn-card-action btn-card-action-orange my-1">Отменить закупку</b-btn>
        <b-modal ref="cancel-modal" :title="title" v-model="isModalVisible" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg">
            <b-row>
                <b-col md="12">
                    <p class="fs-16 font-weight-bold grey-color">Укажите причину отмены закупки и приложите документ обоснования причины отмены закупки с подписью руководителя и печатью организации. Данная информация будет отправлена поставщикам, подавшим заявки.</p>
                </b-col>
            </b-row>
            <form-row-edit-textarea label="Введите причину отмены закупки" v-model="form.reason" :v="$v.form.reason" :rows="8" />
            <form-row-upload-single-document title="Обоснование причины отмены закупки" v-model="form.attachment" :v="$v.form.attachment" header-class="fs-14 fw-700" />
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelModal">Нет</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" :disabled="$v.$invalid" @click="cancelPurchase">Отменить закупку</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import FormRowUploadSingleDocument from '@/components/common/form-rows/form-row-upload-single-document.vue';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'cabinet-fl223-notice-card-cancel-button',
    components: { FormRowUploadSingleDocument },
    props: {
        purchase: {
            type: Object
        }
    },
    data() {
        return {
            isModalVisible: false,
            form: {
                reason: '',
                attachment: null
            },
            isDataSending: false
        };
    },
    validations: {
        form: {
            reason: { required },
            attachment: { required }
        }
    },
    methods: {
        showModal() {
            this.form.reason = '';
            this.form.attachment = null;
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
            this.form.reason = '';
            this.form.attachment = null;
        },
        async cancelPurchase() {
            this.isDataSending = true;
            await this.$api.products.fl223.purchases.cancel(this.purchase.id, this.form);
            this.isDataSending = false;
            this.isModalVisible = false;
            this.$emit('cancel');
        }
    },
    computed: {
        title() {
            return `Отменить закупку № ${this.purchase.reg_number}?`;
        },
        isAvailable() {
            return this.purchase?.actions?.customer?.cancel === true;
        }
    }
};
</script>
