<template>
    <div>
        <text-header-new>Настройка уведомлений</text-header-new>
        <b-row>
            <b-col>
                <h6 class="fw-600 pl-1">Получать уведомления:</h6>
                <form-row-edit-checkbox compact label="на сайте" v-model="form.internal" />
                <form-row-edit-checkbox compact label="на E-mail" v-model="form.email" />
                <form-row-edit-checkbox compact label="в Telegram" v-model="form.telegram" :disabled="!$store.state.user.is_chatme_notifiable && features.telegram_bot">
                    <b-btn v-if="!$store.state.user.is_chatme_notifiable && features.telegram_bot" :href="$links.telegram_events_bot" class="text-uppercase my-1 mx-2 fixed-height" target="_blank" variant="custom-green">Подключить уведомления о событиях в ИС </b-btn>
                </form-row-edit-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-overlay :show="isSavingSettings" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
                    <b-button class="text-uppercase" variant="custom-green" @click="onSubmit">Сохранить</b-button>
                </b-overlay>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Config from "@core/js/ddriven/application/config/Config";
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'NotificationSettings',
    metaInfo: {
        title: 'Настройка уведомлений'
    },
    data() {
        return {
            isSavingSettings: false,
            form: {
                internal: false,
                email: false,
                telegram: false,
            },
        };
    },
    mounted() {
        this.form = { ...this.$store.state.user.notification_settings };
    },
    methods: {
        onCancel() {
            this.$router.push({ name: 'NotificationsList' });
        },
        async onSubmit() {
            this.isSavingSettings = true;
            await this.$api.notifications.saveNotificationSettings({ form: this.form })
                .then(async (response) => {
                    const user = cloneDeep(this.$store.state.user);
                    user.notification_settings = { ...this.form };
                    this.$store.commit('set_user', user);
                    this.$store.commit('set_unread_notifications_count', response.count)
                    this.onCancel();
                })
                .catch(() => {})
                .finally(() => {
                    this.isSavingSettings = false;
                });
        }
    },
    computed: {
        features() {
            return Config.get('theme.features');
        },
    }
};
</script>
<style scoped>
.fixed-height {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
}
</style>
