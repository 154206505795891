var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "mb-2",
      attrs: { "no-body": "", "border-variant": "light" },
    },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-0 border-light custom-header",
          attrs: { "header-tag": "header", role: "tab" },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c(
                "b-col",
                { staticClass: "col-auto" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase fs-14 fw-600",
                      attrs: {
                        variant: _vm.visible
                          ? "custom-green"
                          : "outline-secondary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.displayed = !_vm.displayed
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _c(
                        "span",
                        { staticClass: "float-right ml-2" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: _vm.displayed
                                ? "chevron-up"
                                : "chevron-down",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("b-col", { staticClass: "my-auto" }, [
                !_vm.displayed
                  ? _c("span", { staticClass: "fs-14 fw-600" }, [
                      _vm._v(
                        " от " +
                          _vm._s(
                            _vm.$formatDate(
                              _vm.supplementaryAgreement
                                .supplementary_agreement_date
                            )
                          ) +
                          ", на сумму " +
                          _vm._s(
                            _vm.$formatPrice(_vm.supplementaryAgreement.price)
                          ) +
                          " руб. "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            id: _vm.id,
            accordion: "supplementary-agreements-accordion",
            role: "tabpanel",
          },
          model: {
            value: _vm.displayed,
            callback: function ($$v) {
              _vm.displayed = $$v
            },
            expression: "displayed",
          },
        },
        [
          _c(
            "b-card-body",
            { staticClass: "py-0 px-2" },
            [
              _c("text-row-price", {
                attrs: {
                  value: _vm.supplementaryAgreement.price,
                  compact: "",
                  label: "Общая стоимость",
                },
              }),
              _c("text-row-date", {
                attrs: {
                  value:
                    _vm.supplementaryAgreement.supplementary_agreement_date,
                  compact: "",
                  label: "Дата заключения дополнительного соглашения",
                },
              }),
              !_vm.purchase.is_private_provider ||
              (_vm.purchase.is_private_provider && _vm.isPurchaseCreator())
                ? _c("text-row-single-document", {
                    attrs: { label: "Документы", compact: "" },
                    model: {
                      value:
                        _vm.supplementaryAgreement.supplementary_agreement_file,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.supplementaryAgreement,
                          "supplementary_agreement_file",
                          $$v
                        )
                      },
                      expression:
                        "supplementaryAgreement.supplementary_agreement_file",
                    },
                  })
                : _vm._e(),
              _c("specifications-table", {
                attrs: {
                  items: _vm.supplementaryAgreement.deliverables,
                  "total-cost": _vm.supplementaryAgreement.price,
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("h5", { staticClass: "grey-color fw-600" }, [
                          _vm._v("Спецификация дополнительного соглашения"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              !_vm.supplementaryAgreement.without_limits &&
              _vm.supplementaryAgreement.limits.length > 0 &&
              _vm.isPurchaseCreator()
                ? [
                    _vm.purchase.status.id === "contract:active" &&
                    _vm.isLimitsEdit
                      ? _c("limits-editable-table", {
                          attrs: {
                            "total-cost": _vm.supplementaryAgreement.price,
                            "limit-year": _vm.purchase.purchase_limit_year,
                            "order-type": {
                              id: _vm.purchase.order_type_id,
                              name: _vm.purchase.order_type_name,
                            },
                            "purchase-type": "fl44_external",
                            "purchase-id": _vm.purchase.id,
                          },
                          on: { validate: _vm.limitsValidate },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "h5",
                                      { staticClass: "grey-color fw-600" },
                                      [_vm._v("Измените лимиты")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3796081880
                          ),
                          model: {
                            value: _vm.form.limits,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "limits", $$v)
                            },
                            expression: "form.limits",
                          },
                        })
                      : _c("limits-table", {
                          attrs: { items: _vm.supplementaryAgreement.limits },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "h5",
                                      { staticClass: "grey-color fw-600" },
                                      [_vm._v("Лимиты по КБК")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3321181564
                          ),
                        }),
                    _vm.purchase.status.id === "contract:active" &&
                    _vm.purchase.last_supplementary_agreement_id ===
                      _vm.supplementaryAgreement.id
                      ? [
                          _vm.isLimitsEdit
                            ? [
                                _c(
                                  "b-overlay",
                                  {
                                    staticClass: "d-inline-block",
                                    attrs: {
                                      show: _vm.isLoading,
                                      opacity: "0.6",
                                      rounded: "",
                                      "spinner-small": "",
                                      "spinner-variant": "primary",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "text-uppercase mt-1 mb-3 mr-3",
                                        attrs: {
                                          variant: "custom-outline-secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isLimitsEdit = false
                                          },
                                        },
                                      },
                                      [_vm._v("Отмена")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "text-uppercase mt-1 mb-3",
                                        attrs: {
                                          variant: "custom-green",
                                          disabled: !_vm.isLimitsValid,
                                        },
                                        on: { click: _vm.updateLimits },
                                      },
                                      [_vm._v("Сохранить")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "text-uppercase mt-1 mb-3",
                                    attrs: { variant: "custom-green" },
                                    on: { click: _vm.editLimits },
                                  },
                                  [_vm._v("Изменить лимиты")]
                                ),
                              ],
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.supplementaryAgreement.supplier_accounting &&
              _vm.isPurchaseCreator()
                ? [
                    _c("h5", { staticClass: "grey-color fw-600" }, [
                      _vm._v("Банковские реквизиты поставщика"),
                    ]),
                    _c("text-row", {
                      attrs: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting
                            .bank_type.title,
                        compact: "",
                        label: "Вид обслуживающей организации",
                      },
                    }),
                    _c("text-row", {
                      attrs: { compact: "", label: "БИК банка" },
                      model: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting.bik,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplementaryAgreement.supplier_accounting,
                            "bik",
                            $$v
                          )
                        },
                        expression:
                          "supplementaryAgreement.supplier_accounting.bik",
                      },
                    }),
                    _c("text-row", {
                      attrs: { compact: "", label: "Название банка" },
                      model: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting
                            .bank_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplementaryAgreement.supplier_accounting,
                            "bank_name",
                            $$v
                          )
                        },
                        expression:
                          "supplementaryAgreement.supplier_accounting.bank_name",
                      },
                    }),
                    _c("text-row", {
                      attrs: { compact: "", label: "Город банка" },
                      model: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting
                            .bank_city,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplementaryAgreement.supplier_accounting,
                            "bank_city",
                            $$v
                          )
                        },
                        expression:
                          "supplementaryAgreement.supplier_accounting.bank_city",
                      },
                    }),
                    _c("text-row", {
                      attrs: { compact: "", label: "Кор. счет" },
                      model: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting.ks,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplementaryAgreement.supplier_accounting,
                            "ks",
                            $$v
                          )
                        },
                        expression:
                          "supplementaryAgreement.supplier_accounting.ks",
                      },
                    }),
                    _c("text-row", {
                      attrs: { compact: "", label: "Расчетный счет" },
                      model: {
                        value:
                          _vm.supplementaryAgreement.supplier_accounting.rs,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.supplementaryAgreement.supplier_accounting,
                            "rs",
                            $$v
                          )
                        },
                        expression:
                          "supplementaryAgreement.supplier_accounting.rs",
                      },
                    }),
                    _vm.supplementaryAgreement.supplier_accounting.bank_type
                      .id !== "bank"
                      ? _c("text-row", {
                          attrs: { compact: "", label: "Лицевой счет" },
                          model: {
                            value:
                              _vm.supplementaryAgreement.supplier_accounting.ls,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.supplementaryAgreement.supplier_accounting,
                                "ls",
                                $$v
                              )
                            },
                            expression:
                              "supplementaryAgreement.supplier_accounting.ls",
                          },
                        })
                      : _vm._e(),
                    _vm.supplementaryAgreement.supplier_accounting.bank_type
                      .id !== "bank"
                      ? _c("text-row", {
                          attrs: {
                            compact: "",
                            label: "ИНН финансового органа",
                          },
                          model: {
                            value:
                              _vm.supplementaryAgreement.supplier_accounting
                                .fo_inn,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.supplementaryAgreement.supplier_accounting,
                                "fo_inn",
                                $$v
                              )
                            },
                            expression:
                              "supplementaryAgreement.supplier_accounting.fo_inn",
                          },
                        })
                      : _vm._e(),
                    _vm.supplementaryAgreement.supplier_accounting.bank_type
                      .id !== "bank"
                      ? _c("text-row", {
                          attrs: {
                            compact: "",
                            label: "Полное наименование финансового органа",
                          },
                          model: {
                            value:
                              _vm.supplementaryAgreement.supplier_accounting
                                .fo_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.supplementaryAgreement.supplier_accounting,
                                "fo_name",
                                $$v
                              )
                            },
                            expression:
                              "supplementaryAgreement.supplier_accounting.fo_name",
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }