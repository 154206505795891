<template>
    <div>
        <text-header id="supplementary-agreements">Дополнительные соглашения</text-header>
        <div v-if="purchase.supplementary_agreements.length > 0" class="accordion" role="tablist">
            <supplementary-agreement-item v-for="(agreement, key) in purchase.supplementary_agreements" :key="`agreement-${key}`" :title="`Дополнительное соглашение №${agreement.number}`" :purchase="purchase" :supplementary-agreement="agreement" :visible="purchase.last_supplementary_agreement_id === agreement.id" @refresh="done" />
        </div>
        <template v-if="isPurchaseCreator() && purchase.status.id === 'contract:active'">
            <b-button v-if="!isCreate" variant="custom-green" class="text-uppercase mt-1" @click="isCreate = true">Добавить дополнительное соглашение</b-button>
            <create-supplementary-agreements v-else :purchase-id="purchase.id" @cancel="isCreate = false" @done="done" />
        </template>
    </div>
</template>

<script>

import SupplementaryAgreementItem from "@/components/externalPurchases/partials/supplementary-agreement-item.vue";
import CreateSupplementaryAgreements from "@/components/externalPurchases/partials/create-supplementary-agreement.vue";

export default {
    name: 'supplementary-agreements',
    components: {CreateSupplementaryAgreements, SupplementaryAgreementItem},
    props: {
        purchase: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isCreate: false,
        }
    },
    methods: {
        done() {
            this.$emit('refresh');
            this.isCreate = false;
        }
    },
};
</script>
