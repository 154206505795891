<template>
    <div>
        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
        <b-button v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract' }" exact target="_blank" class="btn btn-card-action btn-card-action-danger my-1" v-if="purchase.conditions.customer_need_send_contract_to_provider"
            >Отправить договор</b-button
        >
        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
        <b-button
            v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-disputes-resolution' }"
            exact
            target="_blank"
            class="btn btn-card-action btn-card-action-danger my-1"
            v-if="purchase.conditions.customer_need_answer_contract_dispute || purchase.conditions.customer_need_answer_supplementary_agreement_dispute"
            >Ответить на протокол разногласий</b-button
        >
        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
        <b-button
            v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract' }"
            exact
            target="_blank"
            class="btn btn-card-action btn-card-action-danger my-1"
            v-if="purchase.conditions.customer_need_sign_contract || purchase.conditions.provider_need_sign_contract"
            >Подписать договор</b-button
        >
        <b-button
            v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract-annexes' }"
            exact
            target="_blank"
            class="btn btn-card-action btn-card-action-danger my-1"
            v-if="purchase.conditions.customer_need_sign_supplementary_agreement || purchase.conditions.provider_need_sign_supplementary_agreement"
            >Подписать дополнительное соглашение</b-button
        >
    </div>
</template>

<script>
export default {
    name: 'PurchaseSupportingButtons',
    props: {
        purchase: {
            type: Object,
            required: true
        }
    }
};
</script>
