var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c("b-container", [
            _c("p", { staticClass: "fs-36 fw-600 white-color mb-2" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c(
              "ul",
              {
                staticClass:
                  "atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner",
              },
              [
                _c("li", [
                  _c("dt", [_vm._v("Организация:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(this.$props.organization.name),
                    },
                  }),
                ]),
                _c("li", [
                  _c("dt", [_vm._v("ИНН:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(this.$props.organization.inn),
                    },
                  }),
                ]),
                this.$props.organization.kpp
                  ? _c("li", [
                      _c("dt", [_vm._v("КПП:")]),
                      _c("dd", {
                        domProps: {
                          textContent: _vm._s(this.$props.organization.kpp),
                        },
                      }),
                    ])
                  : _vm._e(),
                this.$props.organization.ogrn
                  ? _c("li", [
                      _c("dt", [_vm._v("ОГРН:")]),
                      _c("dd", {
                        domProps: {
                          textContent: _vm._s(this.$props.organization.ogrn),
                        },
                      }),
                    ])
                  : _vm._e(),
                _c("li", [
                  _c("dt", [_vm._v("Адрес:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.organization.legal_address
                      ),
                    },
                  }),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }