var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-green my-1",
              on: { click: _vm.copyPurchase },
            },
            [_vm._v("Копировать")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }