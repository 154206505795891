var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.days + _vm.hours + _vm.minutes + _vm.seconds > 0 || _vm.endText
    ? _c("div", [
        _vm.endText
          ? _c(
              "p",
              { staticClass: "fs-12 m-0 grey-color fw-600 time_to_end" },
              [_vm._v(" " + _vm._s(_vm.endText) + " ")]
            )
          : _c(
              "p",
              { staticClass: "fs-12 m-0 grey-color fw-600 time_to_end" },
              [
                _vm.days > 0
                  ? _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        _vm._s(_vm.days) +
                          " " +
                          _vm._s(
                            _vm.num2str(_vm.days, ["день", "дня", "дней"])
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(
                  _vm._s(_vm.hours) +
                    ":" +
                    _vm._s(_vm.minutes) +
                    ":" +
                    _vm._s(_vm.seconds) +
                    " "
                ),
              ]
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }