<template>
    <div class="pb-2">
        <p class="fs-12 m-0 grey-color">{{ label }}:</p>
        <p class="fs-12 m-0 green-color fw-600"><slot></slot></p>
    </div>
</template>

<script>
export default {
    name: 'cabinet-notice-card-row-dual',
    props: {
        label: {
            type: String,
            required: true
        }
    }
};
</script>
