var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "h3",
              { staticClass: "grey-color mb-3 main_text font-weight-bold" },
              [_vm._v("Запросы блокировок")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2 cabinet_filter" },
        [
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.forFilter.statuses,
              label: "Статус запроса",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус",
              "label-field": "title",
            },
            model: {
              value: _vm.filter.status,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status", $$v)
              },
              expression: "filter.status",
            },
          }),
          _c(
            "b-col",
            {
              staticClass: "mb-1 text-right fix-pos",
              attrs: { lg: "6", md: "6" },
            },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getRequests(true)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cleanFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            rounded: "",
            opacity: "0.6",
            "spinner-variant": "primary",
          },
        },
        [
          _vm.blockRequests && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1 py-0",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
          _vm.isLoading || !_vm.blockRequests.length
            ? _c("empty-request")
            : _vm._l(_vm.blockRequests, function (blockRequest) {
                return [
                  _c("customer-fl44-external-purchase-block-request-card", {
                    key: blockRequest.id,
                    attrs: { request: blockRequest },
                  }),
                ]
              }),
          _vm.blockRequests && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1 py-0",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                on: {
                  input: function ($event) {
                    return _vm.getRequests()
                  },
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }