import { render, staticRenderFns } from "./cabinet-fl223-notice-card-delete-button.vue?vue&type=template&id=e0ad6108&"
import script from "./cabinet-fl223-notice-card-delete-button.vue?vue&type=script&lang=js&"
export * from "./cabinet-fl223-notice-card-delete-button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-dev/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e0ad6108')) {
      api.createRecord('e0ad6108', component.options)
    } else {
      api.reload('e0ad6108', component.options)
    }
    module.hot.accept("./cabinet-fl223-notice-card-delete-button.vue?vue&type=template&id=e0ad6108&", function () {
      api.rerender('e0ad6108', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cabinets/fl223/cards/partials/cabinet-fl223-notice-card-delete-button.vue"
export default component.exports