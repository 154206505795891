var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    { staticClass: "py-5" },
    [
      _c(
        "b-col",
        { staticClass: "text-center" },
        [
          _c("img", {
            attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
          }),
          _vm._t("info", function () {
            return [
              _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                _vm._v("По вашему запросу ничего не найдено"),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }