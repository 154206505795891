var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.user && _vm.$store.state.organization
    ? _c(
        "div",
        { staticStyle: { "background-color": "var(--greyBg-color)" } },
        [
          _c("cabinet-header"),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "py-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "3", sm: "12" } },
                        [_c("cabinet-sidenav")],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "9", sm: "12" } },
                        [_c("router-view")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }