var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c(
          "h2",
          {
            staticClass: "grey-color font-weight-bold my-3",
            class: _vm.headerClass,
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c(
          "file-uploader",
          {
            staticClass: "fix-pos",
            attrs: { "is-disabled": _vm.isLoading, "is-main": false },
            on: { set_file: _vm.addFile },
          },
          [_vm._v("Загрузить")]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "table-responsive py-2",
        class: _vm.v.$invalid ? "is-invalid" : "",
      },
      [
        _c("table", { staticClass: "table" }, [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(_vm.documents, function (document, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "fs-14 col-form-label d-inline-block",
                        attrs: {
                          href: _vm.$getAttachmentDownloadLink(document.id),
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(document.name))]
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("action-button-small", {
                        attrs: { disabled: _vm.isLoading, icon: "trash" },
                        on: {
                          click: function ($event) {
                            return _vm.removeFile(index, true)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              _vm.documents.length === 0
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "2", align: "center" } }, [
                      _vm._v("Не добавлено документов"),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]
    ),
    _vm.v.$invalid
      ? _c("p", { staticClass: "invalid-feedback" }, [
          _vm._v("Прикрепите документ"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Наименование")]),
        _c("th", { attrs: { width: "1" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }