var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create" },
    [
      _c("form-header", {
        attrs: {
          title: _vm.title,
          "title-class": "fs-36",
          "without-current-organization": "",
        },
      }),
      _c(
        "form-content-group",
        { attrs: { title: "Информация о запросе" } },
        [
          _c("text-row", {
            attrs: {
              value: _vm.importSubstitutionRequest.purchase_category_name,
              label: "Предмет запроса",
            },
          }),
          _c("text-row-datetime", {
            attrs: {
              value: _vm.importSubstitutionRequest.deadline,
              label: "Срок сбора предложений",
            },
          }),
        ],
        1
      ),
      _c("import-substitution-request-show-ktru-item-block", {
        attrs: {
          ktruItem: _vm.importSubstitutionRequest.ktru_item,
          price: _vm.importSubstitutionRequest.price,
        },
      }),
      _c(
        "form-content-group",
        { attrs: { title: "Техническое описание" } },
        [
          _c("text-row-documents", {
            attrs: { value: _vm.documents, label: "Документ" },
          }),
        ],
        1
      ),
      _c("import-substitution-request-create-ktru-item-block", {
        attrs: {
          "is-import-substitution-reply": "",
          v: _vm.$v.form.ktru_item,
          title: "Предложенное ТРУ для импортозамещения",
        },
        model: {
          value: _vm.form.ktru_item,
          callback: function ($$v) {
            _vm.$set(_vm.form, "ktru_item", $$v)
          },
          expression: "form.ktru_item",
        },
      }),
      _c(
        "b-container",
        [
          _c(
            "form-action-buttons-group",
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("Отменить")]
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mx-2 text-uppercase",
                      attrs: {
                        disabled: _vm.$v.form.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.sendData },
                    },
                    [_vm._v("Разместить предложение")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }