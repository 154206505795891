import axios from 'axios';

export default {
    getPurchases(filters) {
        return axios
            .get('/purchases', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    },
    get(id) {
        return axios.get(`/purchases/${id}`);
    },
    checkProvider(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/check_provider`);
    },
    getOffers(filters) {
        return axios.post('/cabinet/provider/offers', { filters: filters });
    },
    cancel(id, payload) {
        return axios.post(`/purchases/${id}/cancel`, payload);
    },
    getFailedPurchases() {
        return axios.get('/failed_purchases');
    },
    setRating(purchaseId, rating) {
        return axios.post(`/purchases/${purchaseId}/set_rating`, { rating });
    },
    updateComment(purchase_id, comment) {
        return axios.put(`/purchases/${purchase_id}/comment`, { purchase_comment: comment });
    },
    copyPurchase(purchaseId) {
        return axios.post(`/purchases/${purchaseId}/copy`);
    }
};
