<template>
    <b-modal :visible="show" :hide-footer="! (limits.length && meta.total > 0 && meta.last_page > 1)" body-class="pt-0" no-close-on-backdrop scrollable no-close-on-esc size="xl" title="Выберите лимит КБК" @show="onShow" @hide="onHide">
        <template #modal-header-close>
            <button-close-modal />
        </template>
        <b-row class="sticky-top filter-bar">
            <b-col :md="12">
                <span>Год плана-графика лимитов: {{ year }}; </span>
                <span>Тип закупки: {{ orderType.name ?? orderType.title }}</span>
            </b-col>
            <template v-if="!isEdit">
                <filter-text :disabled="isLoading" v-model="filter.kbk" label="КБК" placeholder="Введите КБК" @input="getLimits(false)" />
                <filter-text :disabled="isLoading" v-model="filter.funds_type" label="Код типа средств" placeholder="Введите код типа средств" @input="getLimits(false)" />
                <filter-text :disabled="isLoading" v-model="filter.subkosgu_code" label="Код CубКОСГУ" placeholder="Введите код CубКОСГУ" @input="getLimits(false)" />
            </template>
        </b-row>
        <b-row>
            <b-col v-if="!limits.length" class="text-center mt-5">
                <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
                    <empty-request>
                        <template #info>
                            <p class="fs-14 text-danger my-4">Лимиты по КБК по вашему запросу не найдены.</p>
                        </template>
                    </empty-request>
                </b-overlay>
            </b-col>
            <b-col v-else>
                <b-table id="limits-table" :busy="isLoading" :fields="fields" :items="limits" class="table-atmo" small responsive>
                    <template #row-details="data">
                        <b-row class="w-100">
                            <b-col md="6">
                                <limit-info :limit="data.item" />
                            </b-col>
                            <b-col md="6">
                                <b-table :fields="detailFields" :items="filterOutZeroAmount(data.item.limits)" class="table-atmo" small responsive>
                                    <template #cell(amount_available)="data">
                                        {{ $formatPrice(data.item.amount_available) }}
                                    </template>
                                    <template #cell(amount_total)="data">
                                        {{ $formatPrice(data.item.amount_total) }}
                                    </template>
                                    <template #cell(actions)="detailData">
                                        <action-button-small :disabled="detailData.item.amount_available === 0" icon="save2" @click="submitLimit(data.item, detailData.item)" title="Выбрать лимит" />
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </template>
                    <template #cell(kbk)="data">
                        <action-button-small :title="(data.detailsShowing ? 'Скрыть список' : 'Показать список лимитов по годам финансирования')" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                        {{ data.item.kbk }}
                    </template>
                    <template #cell(subkosgu_code)="data">
                        {{ data.item.subkosgu_code ?? '&mdash;' }}
                    </template>
                    <template #cell(target_code)="data">
                        {{ data.item.target_code ?? '&mdash;' }}
                    </template>
                    <template #cell(targeted_funds_code)="data">
                        {{ data.item.targeted_funds_code ?? '&mdash;' }}
                    </template>
                    <template #cell(rks_code)="data">
                        {{ data.item.rks_code ?? '&mdash;' }}
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-row class="w-100 m-0">
                <b-col class="text-center">
                    <b-overlay :show="isLoading && limits.length && meta.total > 0 && meta.last_page > 1" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-pagination v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-0 py-0" @input="getLimits" />
                    </b-overlay>
                </b-col>
            </b-row>
        </template>
    </b-modal>
</template>

<script>
import FilterText from '@/components/common/filter-components/filter-text';
import LimitInfo from "@/components/externalPurchases/partials/limit-info.vue";

const emptyFilter = {
    kbk: '',
    funds_type: '',
    subkosgu_code: '',
    type: '',
    purchase_id: 0,
    id: 0
}

export default {
    name: 'limit-select-modal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        year: {
            type: Number,
            required: true
        },
        orderType: {
            type: Object,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        purchaseType: {
            type: String,
            default: 'fl44'
        },
        purchaseId: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Number | null
        },
        limitId: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Number | null,
        },
        kbk: {
            type: String,
            default: ''
        }
    },
    components: {
        LimitInfo,
        FilterText
    },
    data() {
        return {
            isLoading: true,
            limits: [],
            filter: { ...emptyFilter },
            fields: [
                { key: 'kbk', label: 'КБК', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'text-nowrap py-1' },
                { key: 'subkosgu_code', label: 'СубКОСГУ', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'account', label: 'Лицевой счет', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'budget_name', label: 'Наименование бюджета', tdClass: 'align-text-top py-1', thClass: 'py-1' },
                { key: 'target_code', label: 'Код цели', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'targeted_funds_code', label: 'Код целевых средств', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'pay_promise_code', label: 'Код расходного обязательства', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'budget_allocation_code', label: 'Код бюджетного ассигнования', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
                { key: 'rks_code', label: 'Код РКС', tdClass: 'align-text-top py-1 text-nowrap', thClass: 'py-1' },
            ],
            detailFields: [
                { key: 'year', label: 'Год финансирования', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_available', label: 'Доступный лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_total', label: 'Общий лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'actions', label: '', tdClass: 'align-text-top text-nowrap py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } }
            ],
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    methods: {
        async onShow() {
            this.$_debounceTimer = null;
            await this.getLimits();
        },
        getFilters(page = true) {
            const filterValues = [];
            if(this.isEdit) {
                this.filter.id = this.limitId;
                this.filter.type = this.purchaseId ? this.purchaseType : '';
                this.filter.purchase_id = this.purchaseId;
            }
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1,
                year: this.year,
                order_type_id: this.orderType.id
            };
        },
        async getLimits(page = true) {
            try {
                this.isLoading = true;
                const response = await this.$api.dictionary.getLimits(this.getFilters(page));
                this.limits = response.data;
                this.meta = response.meta;
            } catch {
                this.limits = [];
            } finally {
                this.isLoading = false;
            }
        },
        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },
        filterOutZeroAmount(amountRecords) {
            return amountRecords.filter((elem) => {
                return elem.amount_total > 0;
            });
        },
        onHide() {
            this.filter = { ...emptyFilter };
            this.limits = [];
            this.$emit('hide');
        },
        submitLimit(item, detailItem) {
            // eslint-disable-next-line no-unused-vars
            this.$emit('submit', Object.assign({}, (({ account, budget_allocation_code, fin_source_code, fin_year, limit_total, pay_promise_code, rks_code, target_code, targeted_funds_code, year, limits, _showDetails, ...o }) => o)(item), detailItem));
        }
    }
};
</script>

<style scoped>
.filter-bar {
    background-color: var(--white-color);
}
</style>
