<template>
    <div>
        <h3 class="grey-color mb-3 main_text font-weight-bold">Коммерческие предложения</h3>

        <b-row class="mb-2 cabinet_filter">
            <filter-new-text v-model="filter.customer" label="Заказчик (ИНН/Название)" md="6" placeholder="Введите название или ИНН организации заказчика" />
            <filter-new-multiple-select v-model="filter.status" :options="filterStatuses" label="Статус предложения" label-class="grey-color" label-field="title" lg="6" md="6" placeholder="Выберите статус предложения" />
            <filter-new-multiple-select v-model="filter.purchase_status" :options="filterPurchaseStatuses" label="Статус закупки" label-class="grey-color" label-field="title" lg="6" md="6" placeholder="Выберите статус закупки" />

            <b-col class="mb-1 mt-auto" lg="3" md="3">
                <b-btn class="text-uppercase w-100" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getProposals(true)">НАЙТИ</b-btn>
            </b-col>
            <b-col class="mb-1 mt-auto" lg="3" md="3">
                <b-btn class="text-uppercase w-100" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="clearFilter">СБРОС</b-btn>
            </b-col>
        </b-row>

        <b-pagination v-if="proposals && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" />

        <div v-for="proposal in proposals" :key="proposal.id">
            <cabinet-commercial-proposal-card :proposal="proposal" @refresh="refresh" />
        </div>
        <empty-request v-if="!proposals.length && !loading"></empty-request>

        <b-pagination v-if="proposals && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getProposals()" />
    </div>
</template>

<script>
import CabinetCommercialProposalCard from '@/components/cabinets/commercial/cards/cabinet-commercial-proposal-card.vue';
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';

export default {
    name: 'cabinet-commercial-proposals-list',
    metaInfo: {
        title: 'Коммерческие закупки. Предложения'
    },
    components: {
        FilterNewText,
        FilterNewMultipleSelect,
        CabinetCommercialProposalCard
    },
    data() {
        return {
            proposals: [],
            loading: false,
            filter: {
                status: [],
                purchase_status: [],
                customer: null
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            },
            filterStatuses: [
                { id: 'actual', title: 'Предложение актуально' },
                // { id: 'withdrawn', title: 'Предложение отозвано поставщиком',},
                { id: 'rejected', title: 'Предложение отклонено заказчиком' },
                { id: 'purchase_canceled', title: 'Предложение отклонено в результате снятия извещения с публикации' },
                { id: 'winner', title: 'Предложение победитель' }
            ]
        };
    },
    async mounted() {
        await this.getProposals();
    },
    watch: {
        $route: 'applyFilters'
    },
    methods: {
        getFilters(page = true) {
            const filterValues = [];
            for (let obj of Object.entries(this.filter)) {
                if (obj[1]) {
                    filterValues[obj[0]] = obj[1];
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        async getProposals(page = true, loading = true) {
            this.loading = loading;
            const response = await this.$api.personal.commercial.getProposalsList(this.getFilters(page));
            this.proposals = response.data;
            this.meta = response.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        refresh() {
            this.getProposals();
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getProposals();
        },
        clearFilter() {
            this.filter.status = [].splice(0);
            this.filter.purchase_status = [].splice(0);
            this.filter.customer = null;
            this.getProposals(false);
        }
    },
    computed: {
        filterPurchaseStatuses() {
            return this.$globalDictionaries.purchaseStatuses.filter((status) => status.stage.includes('notice'));
        }
    }
};
</script>
