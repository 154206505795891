<template>
    <div>
        <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
            <div>
                <input type="file" id="fileload" ref="fileload" v-on:change="onChangeFileUpload" style="display: none" accept=".xml" />
                <b-button @click="fileClick" :variant="buttonColor" :disabled="disabled">
                    <b-icon icon="file-plus" size="sm" class="mr-2" />
                    Загрузить МЧД
                </b-button>
            </div>
        </b-form-group>
        <template v-if="poaDetails">
            <text-row-link label="Номер доверенности" label-right compact :href="poaDetails.link_check" :value="poaDetails.poa_number" />
            <text-row label="ИНН доверителя" label-right compact :value="poaDetails.principal_inn" />
            <text-row label="ИНН представителя" label-right compact :value="poaDetails.representative_inn" />
            <text-row label="СНИЛС представителя" label-right compact :value="poaDetails.representative_snils" />
            <text-row-datetime v-model="poaDetails.valid_from" label-right compact label="Срок действия, с" />
            <text-row-datetime v-model="poaDetails.valid_to" label-right compact label="Срок действия, по" />
        </template>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';

export default {
    name: 'FormRowUploadPoa',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: File
        },
        v: {
            type: Object
        },
        required: {
            type: Boolean,
            default: true
        },
        description: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        userInn: {
            type: String,
            required: true
        },
        userSnils: {
            type: String,
            required: true
        },
        organizationInn: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            poaDetails: null
        };
    },
    methods: {
        fileClick() {
            this.$refs.fileload.value = '';
            this.file = null;
            this.returnFile = null;
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            this.$refs.fileload.dispatchEvent(evt);
        },
        async getPoaData(file) {
            const response = await $api.authentication.checkPoa(file, this.organizationInn, this.userInn, this.userSnils);
            if (response) {
                this.poaDetails = response;
            } else {
                this.file = null;
                this.poaDetails = null;
            }
        },
        async onChangeFileUpload() {
            if (this.$refs.fileload.files.length > 0) {
                this.file = this.$refs.fileload.files[0];
                await this.getPoaData(this.$refs.fileload.files[0]);
            }
        }
    },
    computed: {
        file: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        state() {
            return typeof this.v !== 'undefined' && this.v !== null ? !this.v.$invalid : null;
        },
        id() {
            return 'form_row_upload_poa_' + this._uid;
        },
        buttonColor() {
            return this.state === null || this.state === true ? 'custom-green-small' : 'custom-danger-small';
        }
    }
};
</script>
