<template>
    <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" :class="{ 'is-table-invalid': v.$invalid }" :busy="isLoading" details-td-class="py-1" small responsive>
        <template #cell(index)="data">
            {{ data.index + 1 }}
        </template>
        <template #row-details="data">
            <template v-if="data.item.ktru">
                <p><b>Рег.номер ТРУ:</b> {{ data.item.ktru.reg_number }}</p>
                <p><b>Группа ТРУ:</b> {{ data.item.ktru.ktru_group_name }}</p>
                <p><b>Наименование:</b> {{ data.item.ktru.name }}</p>
                <p><b>Тип:</b> {{ data.item.ktru.type }}</p>
                <p v-if="data.item.okpd"><b>Код ОКПД2:</b> {{ data.item.okpd ? data.item.okpd.code : '&mdash;' }}</p>
                <p v-if="data.item.ktru.country_of_origin_name"><b>Страна происхождения:</b> {{ data.item.ktru.country_of_origin_name }}</p>
                <p v-if="data.item.ktru.description"><b>Описание:</b> {{ data.item.ktru.description }}</p>
                <p v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                <ul v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0">
                    <li v-for="(spec, key) in data.item.ktru.specs" :key="'spec' + key">
                        <span class="fw-600">{{ spec.name }}</span>: {{ spec.value }}
                    </li>
                </ul>
            </template>
            <template v-else>&mdash;</template>
        </template>
        <template #cell(specs)="data">
            <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
            {{ data.item.ktru.name }}
        </template>
        <template #cell(amount)="data">
            <b-form-input
                v-model.number="data.item.amount"
                class="mb-0 form-control fs-14 text-right amount-input"
                :class="!v.$each[data.index].amount.$invalid && v.$each[data.index].maxAmount && v.$each[data.index].minAmount ? 'is-valid' : 'is-invalid'"
                min="0.000000001"
                step="0.000000001"
                type="number"
                @focus="$event.target?.select()" />
        </template>
        <template #cell(okei)="data">
            <template v-if="data.item.okei">{{ data.item.okei.local_symbol }}</template>
            <template v-else>&mdash;</template>
        </template>
        <template #cell(price)="data">
            <currency-input v-model="data.item.price" :class="!v.$each[data.index].price.$invalid && v.$each[data.index].maxPrice ? 'is-valid' : 'is-invalid'" class="form-control fs-14 text-right currency-input mb-0" currency="RUB" locale="ru" @focus="$event.target?.select()" />
        </template>
        <template #cell(cost)="data">
            {{ $formatPrice(data.item.amount * data.item.price) }}
        </template>
        <template #cell(actions)="data">
            <action-button-small v-if="data.item.is_manually_added" variant="outline" icon="trash" @click="deleteItem(data.index)" title="Удалить"/>
            <action-button-small v-else icon="diagram2-fill" @click="duplicateItem(data.index)" title="Дублировать спецификацию" />
        </template>
        <template v-if="totalCost" #bottom-row>
            <b-td colspan="6" class="text-right py-1">
                <span class="fw-600">Итого, руб.: {{ $formatPrice(totalCost) }}</span>
            </b-td>
        </template>
    </b-table>
</template>

<script>

import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";
import { CurrencyInput } from 'vue-currency-input';

export default {
    name: 'supplementary-agreement-specifications-edit-table',
    components: {
        CurrencyInput,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fields: [
                { key: 'index', label: '№', tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '60px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { minWidth: '400px' } },
                { key: 'amount', label: 'Количество', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } },
                { key: 'okei', label: 'Ед.изм.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '100px' } },
                { key: 'price', label: 'Цена за ед., руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } },
                { key: 'cost', label: 'Стоимость, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '130px' } },
                { key: 'actions', label: '', tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '1px' } },
            ]
        };
    },
    methods: {
        duplicateItem(index) {
            const duplicateItem = { ...this.items[index] };
            duplicateItem.is_manually_added = true;
            duplicateItem.is_delivery_item = false;
            this.items.splice(index + 1, 0, duplicateItem);
            this.items.map((item) => {
                const amount = item.amount;
                item.amount = null;
                item.amount = amount;
            });
        },
        deleteItem(index) {
            this.items.splice(index, 1);
            this.items.map((item) => {
                const amount = item.amount;
                item.amount = null;
                item.amount = amount;
            });
        }
    },
    computed: {
        items: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        totalCost() {
            return roundToTwoDecimals(this.items.reduce((sum, item) => {
                return sum += item.amount * item.price;
            }, 0));
        }
    }
};
</script>
<style scoped>
.amount-input {
    height: 30px;
}
.is-table-invalid {
    border: 1px solid red;
    border-radius: 4px;
}
.form-control.is-invalid, .form-control.is-valid {
    background-image: none;
    padding-right: 0.3rem !important;
}
</style>
