var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-2" }, [
    _c("div", { staticClass: "purchase_part" }, [
      _c(
        "div",
        { staticClass: "purchase_part-header" },
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "purchase_part-header_number" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "white-system_link-style fw-600",
                    attrs: {
                      to: {
                        name: "QuotationRequestShow",
                        params: { id: _vm.quotationRequest.id },
                      },
                      exact: "",
                    },
                  },
                  [
                    _vm._v(
                      " № " + _vm._s(_vm.quotationRequest.reg_number) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("p", { staticClass: "purchase_part-header_label fw-600" }, [
              _vm._v(" " + _vm._s(_vm.quotationRequest.status.title) + " "),
              _c("img", {
                staticClass: "ml-2",
                attrs: { src: "/images/secundomer.svg", alt: "secundomer" },
              }),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "purchase_part-subheader" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { xs: "12", sm: "6", lg: "3" },
            },
            [
              _vm.quotationRequest.quotations_count
                ? _c("span", { staticClass: "fs-11 green-color" }, [
                    _vm._v(" Предложений: "),
                    _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                      _vm._v(_vm._s(_vm.quotationRequest.quotations_count)),
                    ]),
                  ])
                : _c("span", { staticClass: "fs-11 green-color" }, [
                    _vm._v("Предложений нет"),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "purchase_part-body row" },
        [
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
            [
              _c("div", [
                _c("div", { staticClass: "my-2" }, [
                  _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                    _vm._v("До окончания подачи КП:"),
                  ]),
                  _c(
                    "p",
                    { staticClass: "fs-18 m-0 grey-color fw-600 time_to_end" },
                    [
                      !!_vm.quotationRequest.deadline
                        ? _c("timer-new", {
                            attrs: { data: _vm.quotationRequest.deadline },
                            on: {
                              timeout: function ($event) {
                                _vm.overTime = true
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.quotationRequest.status.id === "started"
                  ? _c(
                      "div",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "b-btn",
                          {
                            staticClass: "btn-card-action btn-danger",
                            on: { click: _vm.showStopQuotationRequestDialog },
                          },
                          [_vm._v("Завершить сбор КП")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
            [
              _c("div", [
                _c("div", { staticClass: "my-2" }, [
                  _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                    _vm._v("Предмет закупки:"),
                  ]),
                  _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                    _vm._v(" " + _vm._s(_vm.quotationRequest.name) + " "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "my-2 d-flex flex-column" },
                  [
                    _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                      _vm._v("Заказчик:"),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "system_link-style fw-600 fs-12",
                        attrs: {
                          to:
                            "/organizations/" +
                            _vm.quotationRequest.organization_id,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.quotationRequest.organization.name) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "purchase_part-body_col hidden-mobile",
              attrs: { md: "4" },
            },
            [
              _c("div", [
                _c("div", { staticClass: "my-2" }, [
                  _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                    _vm._v(" Количество позиций: "),
                    _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                      _vm._v(_vm._s(_vm.quotationRequest.items_count)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }