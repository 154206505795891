import axios from 'axios';

export default {
    index() {
        return axios.get('/notifications');
    },
    markAsRead(id) {
        return axios.get('/notifications/' + id + '/mark_as_read');
    }
};
