var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "text-uppercase",
          attrs: { size: "sm", variant: "custom-danger" },
          on: { click: _vm.showModal },
        },
        [_vm._v("Запрос аннулирования")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c("form-row-single-select", {
            attrs: {
              label: "Причина запроса аннулирования",
              "label-cols-sm": 0,
              "label-cols-lg": 0,
              options: _vm.rejectRequestReasons,
              v: _vm.$v.form.reject_request_reason,
            },
            model: {
              value: _vm.form.reject_request_reason,
              callback: function ($$v) {
                _vm.$set(_vm.form, "reject_request_reason", $$v)
              },
              expression: "form.reject_request_reason",
            },
          }),
          _c("p", { staticClass: "fs-14 text-danger fw-900" }, [
            _vm._v("Внимание! Операция необратима!"),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.$v.$invalid,
                        variant: "custom-danger",
                      },
                      on: { click: _vm.sendRejectRequest },
                    },
                    [_vm._v("Запросить аннулирование")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }