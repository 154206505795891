import axios from 'axios';
import store from '@/store';

export default {
    async cancelPurchasePublishing(purchase) {
        try {
            await axios.post('/cabinet/customer/purchases/' + purchase.id + '/cancelPublishing');
            return true;
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    },
    cancelHoldTime(purchase) {
        return axios.post('/cabinet/customer/purchases/' + purchase.id + '/cancelHoldTime');
    },
    prolong(purchase) {
        return axios.post('/cabinet/customer/purchases/' + purchase.id + '/prolong');
    },
    // TODO: delete
    getContractsXlsExport(exportRequest) {
        return axios.post('/cabinet/customer/purchases/download/xls', exportRequest, { responseType: 'blob' });
    },
    async getPurchasesList(filters) {
        try {
            return await axios.get('/cabinet/customer/purchases', { params: filters });
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    },
    // TODO: delete
    async getContractsList(filters) {
        try {
            return await axios.get('/cabinet/customer/contracts', { params: filters });
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    }
};
