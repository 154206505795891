<template>
    <div class="create" v-if="!loading">
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="main_layer">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Запрос коммерческих предложений<br />№ {{ form.reg_number }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="greyBg pb-4">
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link compact label="Наименование" :value="form.organization.name" :router-link="{ name: 'OrganizationShow', params: { id: form.organization.id } }" />
                <text-row compact label="ИНН" :value="form.organization.inn" />
                <text-row compact label="КПП" :value="form.organization.kpp" />
                <text-row compact label="ОГРН" :value="form.organization.ogrn" />
                <text-row compact label="Адрес" :value="form.organization.address" />
                <text-row-raw compact label="Адрес электронной почты">
                    <a :href="'mailto:' + form.organization.email" class="fs-14 col-form-label d-inline-block">{{ form.organization.email }}</a>
                </text-row-raw>
                <text-row-raw compact label="Контактный номер телефона">
                    <a :href="'tel:+' + form.organization.phone" class="fs-14 col-form-label d-inline-block">{{ $parsePhone(form.organization.phone) }}</a>
                </text-row-raw>

                <text-header>Информация о запросе КП</text-header>
                <text-row compact label="Предмет закупки" :value="form.name" />
                <text-row-datetime compact label="Дата/время размещения" :value="form.created_at" />
                <text-row-datetime compact label="Срок сбора КП" :value="form.deadline" />
                <text-row-single-document v-if="!isGuest()" compact label="Техническое задание" :value="form.document" />

                <template v-if="!isGuest()">
                    <text-header>Спецификации</text-header>
                    <b-row class="py-3" no-gutters>
                        <b-col class="fs-14 grey-color">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th width="1"></th>
                                        <th>Наименование товара/работы/услуги</th>
                                        <th width="100px">Количество</th>
                                        <th width="100px" nowrap>Ед.изм</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <fragment v-for="(item, index) in form.items" :key="index">
                                        <tr>
                                            <td class="align-text-top">{{ index + 1 }}</td>
                                            <td class="align-text-top">
                                                <button v-b-toggle="'collapse-' + index" style="font-size: 0.8em" class="atmo-button-icon">
                                                    <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                                    <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                                </button>
                                                <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                                {{ item.name }}
                                            </td>
                                            <td style="text-align: right" class="align-text-top">{{ parseFloat(item.count).toPrecision() }}</td>
                                            <td class="align-text-top">{{ item.okei?.local_symbol }}</td>
                                        </tr>
                                        <tr v-show="visible[index]">
                                            <td :colspan="4">
                                                <p v-if="item.ktru?.reg_number"><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</p>
                                                <p v-if="item.ktru?.okpd_code"><b>Код ОКПД2:</b> {{ item.ktru.okpd_code }}</p>
                                                <p v-if="item.ktru?.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                                <p v-if="item.ktru?.specs && item.ktru?.specs?.length > 0"><b>Характеристики:</b></p>
                                                <ul v-if="item.ktru?.specs && item.ktru?.specs?.length > 0">
                                                    <li v-for="(spec, specIndex) of item.ktru?.specs" :key="index + '_spec_' + specIndex">
                                                        <b>{{ spec.name }}</b> - {{ spec.value }}
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </fragment>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </template>
                <template v-if="isGuest()">
                    <text-header>Коммерческие предложения</text-header>
                    <b-row>
                        <b-col>
                            <p class="fs-14 grey-color">На данный запрос подано {{ form.quotations_count }} предложений.</p>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="!isGuest() && !form.is_my_request">
                    <text-header>Коммерческие предложения</text-header>
                    <b-row>
                        <b-col>
                            <p class="fs-14 grey-color">
                                На данный запрос подано {{ form.quotations_count }} предложений.&nbsp;
                                <template v-if="form.my_quotation">В том числе одно Ваше предложение</template>
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="py-3" no-gutters>
                        <b-col class="fs-14 grey-color">
                            <table class="table" v-if="form.my_quotation">
                                <thead>
                                    <tr>
                                        <th width="150px">Рег.номер</th>
                                        <th width="100px" nowrap>Срок действия предложения</th>
                                        <th width="100px" nowrap>Сумма</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <router-link :to="{ name: 'QuotationShow', params: { id: form.my_quotation.id } }">
                                                {{ form.my_quotation.reg_number }}
                                            </router-link>
                                        </td>
                                        <td>{{ getDateTimeFormat(form.my_quotation.deadline) }}</td>
                                        <td style="text-align: right">{{ $formatPrice(form.my_quotation.cost) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="!isGuest() && form.is_my_request">
                    <text-header>Коммерческие предложения</text-header>
                    <b-row class="py-3" no-gutters>
                        <b-col class="fs-14 grey-color">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th width="150px">Рег.номер</th>
                                        <th>Поставщик</th>
                                        <th width="100px" class="text-nowrap">Срок действия предложения</th>
                                        <th width="160px" class="text-right text-nowrap">Сумма</th>
                                    </tr>
                                </thead>
                                <tbody v-if="form.quotations.length > 0">
                                    <tr v-for="(item, index) in form.quotations" :key="index">
                                        <td>
                                            <router-link :to="{ name: 'QuotationShow', params: { id: item.id } }">
                                                {{ item.reg_number }}
                                            </router-link>
                                        </td>
                                        <td>
                                            <router-link :to="{ name: 'OrganizationShow', params: { id: item.organization_id } }" target="_blank">{{ item.organization_name }}</router-link>
                                        </td>
                                        <td>{{ getDateTimeFormat(item.deadline) }}</td>
                                        <td class="text-right text-nowrap">{{ $formatPrice(item.cost) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="4" style="text-align: center">Коммерческие предложения отсутствуют</td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="!isGuest()">
                    <b-overlay :show="pdfLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-btn variant="custom-outline-secondary" class="mr-2 text-uppercase" @click="downloadPDF">Скачать печатную форму</b-btn>
                    </b-overlay>
                    <b-btn v-if="form.allow_to_add_offer" variant="custom-green text-uppercase" @click="$router.push({ name: 'QuotationCreate', params: { id: form.id } })" class="mr-2">Подать коммерческое предложение</b-btn>
                </template>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import FileDownload from 'js-file-download';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';

export default {
    name: 'QuotationRequestShow',
    components: { TextRowSingleDocument, TextRowRaw },
    metaInfo() {
        return {
            title: 'Запрос коммерческих предложений'
        };
    },
    data() {
        return {
            visible: [],
            loading: true,
            pdfLoading: false,
            form: {}
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.form = await this.$api.quotationRequests.showRequest(this.$route.params.id);
            this.loading = false;
        },
        downloadPDF() {
            this.pdfLoading = true;
            this.$api.quotationRequests
                .downloadRequest(this.$route.params.id)
                .then((response) => {
                    FileDownload(response.data, 'Запрос КП ' + this.form.reg_number + '.pdf');
                })
                .catch((error) => {
                    this.showError(error);
                })
                .finally(() => {
                    this.pdfLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 70px;
}
a {
    color: var(--green-color) !important;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
