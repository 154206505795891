<template>
    <div v-if="$store.getters.isLoggedIn && orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL)" id="atmo-top" class="purchases_create atmo-page atmo-hash-jump-point">
        <section class="atmo-fast-access-menu">
            <ul class="atmo-container-fixed">
                <li><a href="#purchase-terms">Условия закупки</a></li>
                <li><a href="#supplier-requirements">Требования к поставщикам</a></li>
                <li><a href="#supply-terms">Условия поставки</a></li>
                <li><a href="#specification">Спецификация</a></li>
                <li><a href="#additional-documents">Дополнительные документы</a></li>

                <section class="atmo-group">
                    <li title="К верху страницы">
                        <a class="atmo-button-icon" href="#atmo-top"><i class="atmo-icon-angle-up"></i></a>
                    </li>
                    <li title="К низу страницы">
                        <a class="atmo-button-icon" href="#atmo-bottom"><i class="atmo-icon-angle-down"></i></a>
                    </li>
                </section>
            </ul>
        </section>

        <purchase-header-block :organization="customer" :title="isModeEdit ? `Редактирование коммерческого извещения № ${form.reg_number}` : 'Объявление о коммерческой закупочной сессии'" />
        <b-container class="my-4 position-relative purchase_session" style="background-color: #f5f5f7">
            <div class="mx-1 mb-2">
                <b-btn variant="danger" class="fs-14 my-2 text-uppercase" style="color: white !important" size="lg" :href="$links.commercial_purchase_create_manual" target="_blank">
                    <i class="atmo-icon-play" />
                    Инструкция по созданию извещения</b-btn
                >
            </div>

            <form-row-edit-text v-model="form.purchase_terms.contact_info" label="Дополнительная контактная информация" />
            <form-row-edit-textarea v-model="form.purchase_terms.comment" :rows="5" label="Примечание к закупке (отображается только в ЛК Заказчика)" />

            <h2 id="purchase-terms" class="atmo-hash-jump-point font-weight-bold">Условия закупки</h2>
            <form-row-edit-text v-model="form.purchase_terms.purchase_object" label="Объект закупки" description="Укажите объект закупки по договору (например: строительные материалы, канцелярские товары и т.п.)" :v="$v.form.purchase_terms.purchase_object" />
            <form-row-single-select
                v-model="form.purchase_terms.deliverable_group_id"
                :disabled="restrictions.was_canceled && form.purchase_terms.deliverable_group_id && !$v.form.purchase_terms.deliverable_group_id.$anyDirty"
                :options="dictionaries.deliverable_groups"
                :v="$v.form.purchase_terms.deliverable_group_id"
                label="Предмет закупки"
                placeholder="Выберите предмет закупки" />
            <form-row-single-select v-model="form.purchase_terms.duration_type" :disabled="restrictions.was_canceled" :options="dictionaries.duration_types" :v="$v.form.purchase_terms.duration_type" label="Тип закупочной сессии" placeholder="Выберите тип закупочной сессии" />
            <form-row-edit-number
                v-model="form.purchase_terms.duration"
                :disabled="restrictions.was_canceled"
                :label="`Продолжительность закупочной сессии${currentDurationTypeSuffix}`"
                :max-value="currentDurationTypeMaxLength"
                :min-value="currentDurationTypeMinLength"
                :v="$v.form.purchase_terms.duration" />
            <form-row-single-select v-model="form.purchase_terms.payment_form" :options="dictionaries.payment_forms" :v="$v.form.purchase_terms.payment_form" label="Вид оплаты" placeholder="Выберите вид оплаты" />
            <form-row-single-select v-model="form.purchase_terms.payment_term" :options="dictionaries.payment_terms" :v="$v.form.purchase_terms.payment_term" label="Условия оплаты" placeholder="Выберите условия оплаты" />
            <form-row-edit-date v-model="form.purchase_terms.planned_contract_conclusion_datetime" :min-date="moment().format('YYYY-MM-DD')" :v="$v.form.purchase_terms.planned_contract_conclusion_datetime" label="Планируемая дата заключения договора" />
            <form-row-edit-date
                v-model="form.purchase_terms.planned_contract_execution_datetime"
                :disabled="!form.purchase_terms.planned_contract_conclusion_datetime"
                :min-date="form.purchase_terms.planned_contract_conclusion_datetime"
                :v="$v.form.purchase_terms.planned_contract_execution_datetime"
                label="Планируемая дата исполнения договора" />
            <form-row-single-line-document-upload :label-cols-lg="3" v-model="form.purchase_terms.contract_draft_attachment" :v="$v.form.purchase_terms.contract_draft_attachment" title="Проект договора" />

            <h2 id="supplier-requirements" class="atmo-hash-jump-point font-weight-bold">Требования к поставщикам</h2>
            <form-row-edit-checkbox :label-cols-sm="9" :label-cols-lg="6" v-model="form.supplier_requirements.only_not_rnp" label="Отсутствие в реестре недобросовестных поставщиков" />
            <form-row-edit-checkbox :label-cols-sm="9" :label-cols-lg="6" v-model="form.supplier_requirements.only_smp" label="Участником закупки могут быть только субъекты малого предпринимательства (СМП)" />
            <form-row-edit-checkbox :label-cols-sm="9" :label-cols-lg="6" v-model="form.supplier_requirements.has_additional_requirements" label="Дополнительные требования к участникам закупки" @input="changedHasAdditionalRequirements" />
            <div v-show="form.supplier_requirements.has_additional_requirements" class="atmo-nested-section">
                <section style="width: 100%">
                    <div class="table-responsive">
                        <table v-bind:class="{ 'atmo-empty': isEmpty(form.supplier_requirements.additional), 'is-invalid': $v.form.supplier_requirements.additional.$invalid }" class="atmo-content-table mb-1">
                            <thead>
                                <tr>
                                    <th class="atmo-ctc-place">№</th>
                                    <th class="atmo-ctc-width-45pct">Описание</th>
                                    <th class="atmo-ctc-width-45pct">Причина или цель установки требования</th>
                                    <th class="atmo-ctc-command-remove">&nbsp;</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(requirement, index) in form.supplier_requirements.additional" v-bind:key="index">
                                    <td v-text="index + 1" class="atmo-ctc-place"></td>
                                    <td class="atmo-ctc-width-45pct">
                                        <span class="atmo-form__field atmo-inverse">
                                            <!-- // NB: The ID is used by the viewmodel to set the input focus -->
                                            <input v-bind:id="`requirement-additional-item-${index + 1}`" v-model="requirement.description" type="text" required min="1" />
                                        </span>
                                    </td>
                                    <td class="atmo-ctc-width-45pct">
                                        <span class="atmo-form__field atmo-inverse">
                                            <input v-model="requirement.reason" type="text" required min="1" />
                                        </span>
                                    </td>
                                    <td class="atmo-ctc-command-remove">
                                        <button v-on:click.stop="removeAdditionalRequirement(index)" class="atmo-button-icon" title="Удалить описание"><i class="atmo-icon-trash"></i></button>
                                    </td>
                                </tr>

                                <tr v-if="isEmpty(form.supplier_requirements.additional)" class="atmo-ctc-center">
                                    <td colspan="3" class="atmo-centered">Не добавлено описаний документов</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <p v-if="isEmpty(form.supplier_requirements.additional)" class="atmo-error">Добавьте и опишите дополнительные требования.</p>

                        <button v-on:click.stop="addAdditionalRequirement" class="atmo-button">Добавить описание требования</button>
                    </div>
                </section>
            </div>

            <h2 id="supply-terms" class="atmo-hash-jump-point font-weight-bold">Условия поставки</h2>
            <form-row-edit-addresses-new :value.sync="form.delivery_terms.addresses" label="Адрес для доставки/самовывоза" />
            <form-row-edit-textarea :rows="4" v-model="form.delivery_terms.schedule" label="График поставки товаров (выполнения работ, оказания услуг)" />

            <form-row-specification-table id="specification" class="atmo-hash-jump-point" v-model="form.deliverables" :spec-error-message="specErrorMessage" :spec-errors="specErrors" :spec-sum="specSum" :v="$v.form.deliverables" with-count-edit with-price />
            <form-row-upload-multiple-documents id="additional-documents" class="atmo-hash-jump-point" title="Дополнительные документы" v-model="form.additional_documents" :v="$v.form.additional_documents" />
            <b-row class="my-3 mt-5">
                <b-col>
                    <b-button class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="onCancel">Отменить</b-button>
                    <b-button class="text-uppercase mx-2" variant="custom-danger" @click="saveDraft">Сохранить черновик</b-button>
                    <b-button :disabled="!isValid || !$store.getters.getCanSign" class="text-uppercase mx-2" variant="custom-green" @click="validateForm">Подписать и сохранить</b-button>
                    <template v-if="!$store.getters.getCanSign">
                        <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                        <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </template>
                </b-col>
            </b-row>
            <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc scrollable size="lg" title="Подписать извещение" @close="cancelSignModal">
                <div class="d-block text-left">
                    <b-tabs content-class="mt-3 px-3">
                        <b-tab active>
                            <template slot="title">
                                <span class="system_link-style fs-16">Форма</span>
                            </template>
                            <h4 class="font-weight-bold">Информация о заказчике</h4>
                            <text-row :compact="true" label="Наименование организации" :value="customer.name" />
                            <text-row :compact="true" label="ИНН" :value="customer.inn" />
                            <text-row :compact="true" label="КПП" :value="customer.kpp" />
                            <text-row :compact="true" label="ОГРН" :value="customer.ogrn" />
                            <text-row :compact="true" label="Адрес" :value="customer.legal_address" />
                            <text-row :compact="true" label="Дополнительная контактная информация" :value="form.purchase_terms.contact_info" />

                            <hr />
                            <h4 class="font-weight-bold">Условия закупки</h4>
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Объект закупки" :value="form.purchase_terms.purchase_object" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Предмет закупки" :value="dictionaries.deliverable_groups?.find((item) => item.id === form.purchase_terms.deliverable_group_id)?.name" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Тип закупочной сессии" :value="dictionaries.duration_types?.find((item) => item.id === form.purchase_terms.duration_type)?.name" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :label="`Продолжительность закупочной сессии${currentDurationTypeSuffix}`" :value="form.purchase_terms.duration" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Вид оплаты" :value="dictionaries.payment_forms?.find((item) => item.id === form.purchase_terms.payment_form)?.name" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Условия оплаты" :value="dictionaries.payment_terms?.find((item) => item.id === form.purchase_terms.payment_term)?.name" />
                            <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Планируемая дата заключения договора" :value="form.purchase_terms.planned_contract_conclusion_datetime" />
                            <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Планируемая дата исполнения договора" :value="form.purchase_terms.planned_contract_execution_datetime" />
                            <text-row-price :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Сумма закупки" :value="specSum" />
                            <text-row-single-document :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Проект договора" :value="form.purchase_terms.contract_draft_attachment" />

                            <hr />
                            <h4 class="font-weight-bold">Требования к поставщикам</h4>
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Отсутствие в реестре недобросовестных поставщиков" :value="form.supplier_requirements.only_not_rnp ? 'ДА' : 'НЕТ'" />
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Участником закупки могут быть только субъекты малого предпринимательства (СМП)" :value="form.supplier_requirements.only_smp ? 'ДА' : 'НЕТ'" />
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Дополнительные требования к участникам закупки" :value="form.supplier_requirements.has_additional_requirements ? 'ДА' : 'НЕТ'" />
                            <div v-show="form.supplier_requirements.has_additional_requirements">
                                <section style="width: 100%">
                                    <div class="table-responsive">
                                        <table class="atmo-content-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="atmo-ctc-width-45pct">Описание</th>
                                                    <th class="atmo-ctc-width-45pct">Причина или цель установки требования</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(requirement, index) in form.supplier_requirements.additional" v-bind:key="index">
                                                    <td class="atmo-ctc-width-45pct">{{ requirement.description }}</td>
                                                    <td class="atmo-ctc-width-45pct">{{ requirement.reason }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </div>

                            <template v-if="form.delivery_terms.addresses.length > 0 || form.delivery_terms.schedule">
                                <hr />
                                <h4 class="font-weight-bold">Условия поставки</h4>
                                <b-form-group v-if="form.delivery_terms.addresses.length > 0" :label-cols-sm="4" :label-cols-lg="4" label="Адреса для доставки/самовывоза:" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                                    <ul>
                                        <li v-for="(address, index) in form.delivery_terms.addresses" :key="index" class="fs-14 grey-color my-auto col-form-label">{{ address.address }}</li>
                                    </ul>
                                </b-form-group>
                                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="График поставки товаров (выполнения работ, оказания услуг)" :value="form.delivery_terms.schedule" />
                            </template>

                            <hr />
                            <h4 class="font-weight-bold">Спецификация</h4>
                            <b-form-group v-if="form.deliverables.length > 0">
                                <ul>
                                    <li v-for="(item, index) in form.deliverables" :key="index" class="fs-14 grey-color my-auto col-form-label">
                                        {{ item.name }} - {{ item.count }}, {{ item.okei.local_symbol }} <span v-if="item.price">* {{ item.price }}, руб.</span>
                                    </li>
                                </ul>
                            </b-form-group>

                            <hr />
                            <h4 class="font-weight-bold">Дополнительные документы</h4>
                            <text-row-documents label="Дополнительные документы" v-model="form.additional_documents" v-if="form.additional_documents.length > 0" />
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                <span class="system_link-style fs-16">XML</span>
                            </template>
                            <b-form-textarea v-if="!isSignedXml" v-model="formXml" disabled rows="20" no-resize no-auto-shrink wrap="off" class="text-monospace fs-12"></b-form-textarea>
                            <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" disabled rows="20" class="text-monospace fs-12"></b-form-textarea>
                        </b-tab>
                    </b-tabs>
                </div>
                <div slot="modal-footer" class="w-100 px-3">
                    <text-row label="Сертификат" :value="certificate?.subjectName" />
                    <b-button :disabled="isDataSending" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                    <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                    </b-overlay>
                    <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="sendData">Отправить</b-button>
                    </b-overlay>
                </div>
            </b-modal>
            <span id="atmo-bottom" class="atmo-hash-jump-point"></span>
        </b-container>
    </div>
</template>

<script>
import { maxValue, minLength, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import PurchaseHeaderBlock from '@/components/products/commercial/purchase-header-block.vue';
import FormRowEditNumber from '@/components/common/form-rows/form-row-edit-number.vue';
import setFastAccessMenuTop from '@lib/js/src/misc/setFastAccessMenuTop';
import isEmpty from 'lodash.isempty';
import FormRowUploadMultipleDocuments from '@/components/common/form-rows/form-row-upload-multiple-documents.vue';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import { createMultipleSignatures, getCertificate } from 'crypto-pro';
import moment from 'moment-timezone';

export default {
    name: 'commercial-purchase-create',
    metaInfo() {
        return {
            title: this.isModeEdit ? 'Редактирование коммерческой закупки ' + this.getRegNumber : 'Создание коммерческой закупки'
        };
    },
    components: {
        TextRowSingleDocument,
        FormRowUploadMultipleDocuments,
        FormRowEditNumber,
        PurchaseHeaderBlock
    },
    validations() {
        return {
            form: {
                purchase_terms: {
                    contact_info: {},
                    comment: {},
                    purchase_object: { required },
                    deliverable_group_id: { required },
                    duration_type: { required },
                    duration: {
                        required,
                        minValue: minValue(this.currentDurationTypeMinLength),
                        maxValue: maxValue(this.currentDurationTypeMaxLength ?? 999999)
                    },
                    payment_form: { required },
                    payment_term: { required },
                    planned_contract_conclusion_datetime: { required },
                    planned_contract_execution_datetime: { required },
                    contract_draft_attachment: { required }
                },
                supplier_requirements: {
                    only_smp: {},
                    only_not_rnp: {},
                    has_additional_requirements: {},
                    additional: {
                        required: requiredIf(() => this.form.supplier_requirements.has_additional_requirements),
                        minLength: minLength(1),
                        $each: {
                            description: {
                                required: requiredIf(() => this.form.supplier_requirements.has_additional_requirements)
                            },
                            reason: {
                                required: requiredIf(() => this.form.supplier_requirements.has_additional_requirements)
                            }
                        }
                    }
                },
                delivery_terms: {
                    addresses: {},
                    schedule: {}
                },
                deliverables: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        ktru_item_id: { required },
                        count: { required, minValue: minValue(0.00001) },
                        price: { required, minValue: minValue(0.01) }
                    }
                },
                additional_documents: {
                    $each: {
                        id: { required }
                    }
                }
            }
        };
    },
    data() {
        return {
            isSigningXml: false,
            isDataSending: false,
            visible: [],
            id: null,
            formXml: '',
            signedFormXml: null,
            isSignedXml: false,
            showSignModal: false,
            showSpec: false,
            customer: {},
            dictionaries: [],
            validations: {},
            form: {
                reg_number: null,
                purchase_terms: {
                    contact_info: '',
                    comment: '',
                    purchase_object: '',
                    deliverable_group_id: null,
                    duration_type: 'normal',
                    duration: 2,
                    payment_form: '',
                    payment_term: '',
                    planned_contract_conclusion_datetime: null,
                    planned_contract_execution_datetime: null,
                    contract_draft_attachment: null
                },
                supplier_requirements: {
                    only_msp: false,
                    only_not_rnp: false,
                    has_additional_requirements: false,
                    additional: []
                },
                delivery_terms: {
                    addresses: [],
                    schedule: null
                },
                additional_documents: [],
                deliverables: []
            },
            restrictions: {
                was_canceled: false
            },
            signedAttachments: [],
            commercialOrganizationInfo: null,
            certificate: null
        };
    },
    created() {
        setFastAccessMenuTop();
        this.fillData();
    },
    methods: {
        moment,
        isEmpty,
        async fillData() {
            if (this.isModeEdit) {
                this.id = this.$route.params.id;
                const response = await this.$api.products.commercial.purchases.getDraft(this.id);
                this.customer = response.customer;
                this.dictionaries = response.dictionaries;
                this.validations = response.validations;
                this.form = response.purchase;
                if (this.form.delivery_terms.addresses.length > 0 && !this.form.delivery_terms.addresses[0].address) this.form.delivery_terms.addresses = [];
                this.restrictions = response.restrictions;
            } else {
                const response = await this.$api.products.commercial.purchases.retrieveTemplate();
                this.customer = response.customer;
                this.dictionaries = response.dictionaries;
                this.validations = response.validations;
            }
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        async saveDraft() {
            if (this.isModeEdit) {
                await this.$api.products.commercial.purchases.updateDraft(this.id, this.form);
            } else {
                const response = await this.$api.products.commercial.purchases.createDraft(this.form);
                if (response) {
                    this.id = response.id;
                    this.form.reg_number = response.reg_number;
                    if (!(Object.prototype.hasOwnProperty.call(this.$route.params, 'id') && +this.$route.params.id === this.id)) {
                        await this.$router.replace({ name: 'CommercialPurchaseEdit', params: { id: this.id } });
                    }
                }
            }
        },
        async validateForm() {
            await this.saveDraft();
            if (!this.$store.getters.getCanSign) {
                return;
            }
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (!this.certificate) {
                return;
            }
            this.showSignForm();
        },
        showSignForm() {
            this.$api.products.commercial.purchases.getXml(this.id).then((resp) => {
                this.formXml = resp.xml;
                this.showSignModal = true;
            });
        },
        async signForm() {
            this.isSigningXml = true;
            const dataToSign = [
                {
                    method: 'xml',
                    value: this.formXml
                }
            ];
            for (const attachment of [this.form.purchase_terms.contract_draft_attachment, ...this.form.additional_documents]) {
                dataToSign.push({
                    method: 'detached',
                    value: attachment.hash
                });
            }
            try {
                const signedResult = await createMultipleSignatures(this.$store.getters.getCurrentThumbprint, dataToSign, true);
                this.signedFormXml = signedResult.find((item) => item.method === 'xml').result;
                this.signedAttachments.splice(0);
                for (const attachment of [this.form.purchase_terms.contract_draft_attachment, ...this.form.additional_documents]) {
                    const result = signedResult.find((item) => item.method === 'detached' && item.value === attachment.hash);
                    this.signedAttachments.push({
                        id: attachment.id,
                        hash: attachment.hash,
                        md5: result.result
                    });
                }
                this.isSignedXml = !!this.signedFormXml && this.signedAttachments.length === this.form.additional_documents.length + 1 && this.signedAttachments.every((item) => item.md5 !== null);
            } catch (e) {
                await this.$store.dispatch('showDangerToast', e.message);
            } finally {
                this.isSigningXml = false;
            }
        },
        async sendData() {
            this.isDataSending = true;
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (!this.certificate) {
                return;
            }
            if (await this.$api.products.commercial.purchases.storeSigned(this.id, this.form, this.signedFormXml, this.certificate, this.signedAttachments)) {
                this.onCancel();
            }
            this.isDataSending = false;
        },
        onCancel() {
            this.$router.push({ name: 'CabinetCommercialNotices' });
        },
        addAdditionalRequirement() {
            this.form.supplier_requirements.additional.push({ description: '', reason: '' });
        },
        removeAdditionalRequirement(index) {
            this.form.supplier_requirements.additional.splice(index, 1);
        },
        changedHasAdditionalRequirements(hasAdditionalRequirements) {
            if (!hasAdditionalRequirements) {
                this.form.supplier_requirements.additional.splice(0);
            }
        }
    },
    computed: {
        isModeEdit() {
            return !!this.$route.params.id && this.$route.name === 'CommercialPurchaseEdit';
        },
        getRegNumber() {
            return this.form?.reg_number ?? '';
        },
        specSum() {
            return Math.round(this.form.deliverables.reduce((acc, cv) => acc + Math.round(cv.count * cv.price * 100) / 100, 0) * 100) / 100;
        },
        currentDurationType() {
            return this.form.purchase_terms.duration_type ? this.dictionaries.duration_types?.find((i) => i.id === this.form.purchase_terms.duration_type) : null;
        },
        currentDurationTypeSuffix() {
            return this.currentDurationType?.suffix;
        },
        currentDurationTypeMinLength() {
            return this.currentDurationType?.minLength;
        },
        currentDurationTypeMaxLength() {
            return this.currentDurationType?.maxLength;
        },
        currentOrganization() {
            return this.$store.getters.getOrganization;
        },
        specErrors() {
            return !!this.specErrorMessage;
        },
        specErrorMessage() {
            if (this.form.deliverables.length === 0) return 'Заполните таблицу спецификаций';
            if (!this.form.deliverables.every((e) => e.count > 0)) return 'У одной или более позиций в таблице спецификации не заполнено поле "Количество"';
            if (this.specSum <= 0) return 'Сумма закупки должна быть больше 0 руб.';
            return '';
        },
        isValid() {
            return !this.specErrors && !this.$v.$invalid;
        }
    }
};
</script>

<style scoped>
.atmo-hash-jump-point {
    scroll-margin-top: 190px;
}
</style>
