<template>
    <b-row>
        <b-col md="12">
            <div class="list">
                <p>Заказчики и Поставщики</p>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'organizations-list-header'
};
</script>

<style scoped>
.list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
}
.list p {
    padding: 0px 3px;
    font-size: 32px;
    font-weight: 600;
    color: #fff !important;
}

@media screen and (max-width: 568px) {
    .list {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media screen and (max-width: 485px) {
    .list {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .list p {
        font-size: 20px;
    }
}
</style>
