<template>
    <b-col :md="md" class="mb-1">
        <b-form-group :label="label + ':'" :description="description" label-class="filter-header" class="mb-0">
            <b-input-group>
                <b-form-input v-model="text" @input="onFilterChange" :placeholder="placeholder" :disabled="disabled" class="fs-12 px-2 py-0 input-height" v-on:keyup.enter="onEnter" />
                <b-input-group-append>
                    <b-button :disabled="!text || disabled" @click="clearValue" size="xs" class="py-0 px-1">
                        <b-icon icon="x-circle" scale="0.6" aria-hidden="true" />
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </b-col>
</template>

<script>
import debounceMixin from '@/mixins/debounce';

export default {
    name: 'filter-new-text',
    props: {
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        md: {
            default: 4
        },
        debounced: {
            type: Boolean,
            default: false
        }
    },
    mixins: [debounceMixin],
    methods: {
        async onEnter() {
            await this.$emit('enter');
        },
        onFilterChange(value) {
            if (this.debounced) {
                this.debounce(() => {}, 750);
            } else {
                this.$emit('input', value);
            }
        },
        clearValue() {
            this.$emit('input', '');
            this.$emit('enter');
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set() {
                return true;
            }
        },
        id() {
            return 'filter-text_' + this._uid;
        }
    }
};
</script>

<style>
.filter-header {
    margin-left: calc(0.5rem + 2px);
    padding-bottom: 0;
    font-size: 12px;
    font-weight: bold;
}
</style>
