<template>
    <b-container class="form-header" fluid>
        <b-container>
            <b-row>
                <b-col>
                    <p class="fw-600 white-color mb-3 mt-3" :class="titleClass">{{ title }}</p>
                </b-col>
            </b-row>
            <b-row v-if="!withoutCurrentOrganization">
                <b-col>
                    <p class="white-color fs-14">&laquo;{{ $store.state.organization.short_name }}&raquo;</p>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'FormHeader',
    props: {
        title: {
            type: String,
            required: true
        },
        withoutCurrentOrganization: {
            type: Boolean,
            default: false
        },
        titleClass: {
            type: String,
            default: 'fs-40'
        }
    }
};
</script>

<style>
.form-header {
    background-image: url('/images/purchases_head.svg');
    min-height: 70px;
}
</style>
