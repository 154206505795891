var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "input-group-2", "label-for": "input-2" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-2",
                          type: "text",
                          placeholder: "Название организации",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                      _c("span", { staticClass: "fs-10 halfWhite-color" }, [
                        _vm._v("Укажите название организации"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "input-group-8", "label-for": "input-8" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-8",
                          placeholder: "Адрес",
                          type: "text",
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                      _c("span", { staticClass: "fs-10 halfWhite-color" }, [
                        _vm._v("Укажите адрес"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "input-group-3", "label-for": "input-3" } },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "############",
                            expression: "'############'",
                          },
                        ],
                        attrs: {
                          id: "input-3",
                          type: "text",
                          placeholder: "ИНН",
                        },
                        model: {
                          value: _vm.form.inn,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "inn", $$v)
                          },
                          expression: "form.inn",
                        },
                      }),
                      _c("span", { staticClass: "fs-10 halfWhite-color" }, [
                        _vm._v("Укажите ИНН"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "input-group-5", "label-for": "input-5" } },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#########",
                            expression: "'#########'",
                          },
                        ],
                        attrs: {
                          id: "input-5",
                          type: "text",
                          placeholder: "КПП",
                        },
                        model: {
                          value: _vm.form.kpp,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "kpp", $$v)
                          },
                          expression: "form.kpp",
                        },
                      }),
                      _c("span", { staticClass: "fs-10 halfWhite-color" }, [
                        _vm._v("Укажите КПП"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "flex_col-mobile",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2 text-uppercase",
                      attrs: { variant: "custom-white-orange" },
                      on: { click: _vm.applyFilters },
                    },
                    [_vm._v(" Найти ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-outline-light" },
                      on: {
                        click: function ($event) {
                          return _vm.clearFilter()
                        },
                      },
                    },
                    [_vm._v("Сбросить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }