var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { class: `${_vm.grey ? "greyBg" : ""} pb-4`, attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _vm.img
                    ? _c("img", { attrs: { src: _vm.img, alt: _vm.imgAlt } })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }