<template>
    <div v-if="!loading" id="atmo-top" class="purchases_create atmo-page atmo-hash-jump-point">
        <b-container class="purchase-offer-header-block" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Подача предложения на закупку 223ФЗ <br />№ {{ purchase.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14">Форма подачи предложения</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="my-2" fluid>
            <b-container>
                <b-card class="my-3" no-body>
                    <b-card-header class="p-1" header-tag="header" role="tab" @click="purchaseInfoDisplayed = !purchaseInfoDisplayed">
                        <h2 class="grey-color font-weight-bold my-2 ml-3 cursor-pointer">
                            Информация об извещении
                            <template v-if="purchaseInfoDisplayed">
                                <span class="float-right mr-3"><b-icon icon="chevron-up" class="icon alt" /> </span>
                            </template>
                            <template v-else>
                                <span class="float-right mr-3"><b-icon icon="chevron-down" class="icon alt" /> </span>
                            </template>
                        </h2>
                    </b-card-header>
                    <b-collapse id="accordion-purchase-info" v-model="purchaseInfoDisplayed" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <text-header>Информация о заказчике</text-header>
                            <text-row-link v-model="purchase.customer.full_name" :compact="true" :label-cols-lg="4" :label-cols-sm="4" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" label="Наименование" />
                            <text-row v-model="purchase.customer.inn" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="ИНН" />
                            <text-row v-model="purchase.customer.kpp" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="КПП" />
                            <text-row v-model="purchase.customer.ogrn" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="ОГРН" />
                            <text-row v-model="purchase.customer.address" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Юридический адрес" />
                            <text-row v-if="purchase.purchase_terms.contact_info" v-model="purchase.purchase_terms.contact_info" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дополнительная контактная информация" />

                            <text-header>Условия закупки</text-header>
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.purchase_object" label="Объект закупки" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.deliverable_group.name" label="Предмет закупки" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.duration_type.name" label="Тип закупочной сессии" />
                            <text-row :compact="true" :label="'Продолжительность закупочной сессии' + purchase.purchase_terms.duration_type.suffix" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.duration" />
                            <text-row-datetime v-model="purchase.purchase_terms.purchase_start_datetime" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дата и время размещения закупки" />
                            <text-row-datetime v-model="purchase.purchase_terms.purchase_end_datetime" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дата и время окончания приема предложений" />

                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.payment_form" label="Вид оплаты" />
                            <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.payment_term" label="Условия оплаты" />
                            <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.planned_contract_conclusion_datetime" label="Планируемая дата заключения договора" />
                            <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.planned_contract_execution_datetime" label="Планируемая дата исполнения договора" />
                            <text-row-price v-if="purchase.purchase_terms.duration_type.id !== 'quotation_request'" :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.start_price" label="Сумма закупки" />
                            <text-row-single-document :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.purchase_terms.contract_draft_attachment" label="Проект договора" />
                            <text-row-documents v-if="purchase.additional_documents.length > 0" v-model="purchase.additional_documents" :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дополнительные документы" />

                            <text-header>Требования к поставщикам</text-header>
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="purchase.supplier_requirements.only_not_rnp ? 'ДА' : 'НЕТ'" label="Отсутствие в реестре недобросовестных поставщиков" />
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="purchase.supplier_requirements.only_smp ? 'ДА' : 'НЕТ'" label="Участником закупки могут быть только субъекты малого предпринимательства (СМП)" />
                            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="purchase.supplier_requirements.has_additional_requirements ? 'ДА' : 'НЕТ'" label="Дополнительные требования к участникам закупки" />
                            <div v-show="purchase.supplier_requirements.has_additional_requirements">
                                <section style="width: 100%">
                                    <div class="table-responsive">
                                        <table class="atmo-content-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="atmo-ctc-width-45pct">Описание</th>
                                                    <th class="atmo-ctc-width-45pct">Причина или цель установки требования</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(requirement, index) in purchase.supplier_requirements.additional" v-bind:key="index">
                                                    <td class="atmo-ctc-width-45pct">{{ requirement.description }}</td>
                                                    <td class="atmo-ctc-width-45pct">{{ requirement.reason }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </div>

                            <template v-if="purchase.delivery_terms.addresses.length > 0 || purchase.delivery_terms.schedule">
                                <text-header>Условия поставки</text-header>
                                <b-form-group v-if="purchase.delivery_terms.addresses.length > 0" :label-cols-lg="4" :label-cols-sm="4" label="Адреса для доставки/самовывоза:" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                                    <ul>
                                        <li v-for="(address, index) in purchase.delivery_terms.addresses" :key="index" class="fs-14 grey-color my-auto col-form-label">{{ address }}</li>
                                    </ul>
                                </b-form-group>
                                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :value="purchase.delivery_terms.schedule" label="График поставки товаров (выполнения работ, оказания услуг)" />
                            </template>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <text-header>Информация об организации</text-header>
                <form-row-edit-text v-model="form.contact_name" :v="$v.form.contact_name" label="Контактное лицо поставщика" />
                <form-row-single-line-document-upload v-model="form.organization_card" :label-cols-lg="3" :label-cols-sm="3" :v="$v.form.organization_card" title="Карточка предприятия" />
                <form-row-edit-checkbox v-model="form.without_vat" label="Организация освобождена от уплаты НДС" />

                <text-header>Спецификация</text-header>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="110px">Количество /<br />Ед.изм</th>
                                <th v-if="purchase.purchase_terms.duration_type.id !== 'quotation_request'" width="110px">Извещение.<br />Цена за единицу /<br />Стоимость</th>
                                <th width="160px">Цена за ед. с НДС, руб.</th>
                                <th width="175px">НДС, %</th>
                                <th class="text-right" width="140px">Стоимость с НДС, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in form.deliverables" :key="index">
                                <tr>
                                    <td class="align-top">{{ index + 1 }}</td>
                                    <td class="align-top">
                                        <button v-b-toggle="'collapse-' + index" class="atmo-button-icon" style="font-size: 0.8em">
                                            <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                            <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                        </button>
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        {{ getPurchaseDeliverable(item.id)?.ktru_item?.name }}
                                    </td>
                                    <td class="align-top text-nowrap">
                                        {{ getPurchaseDeliverable(item.id)?.amount }}<br />
                                        {{ getPurchaseDeliverable(item.id).ktru_item.okei_local_symbol }}
                                    </td>
                                    <td v-if="purchase.purchase_terms.duration_type.id !== 'quotation_request'" class="align-top text-nowrap">
                                        {{ $formatPrice(getPurchaseDeliverable(item.id).price) }}<br />
                                        {{ $formatPrice(getPurchaseDeliverable(item.id).total_price) }}
                                    </td>
                                    <td class="align-text-top">
                                        <currency-input v-model="item.price" :class="checkItemPrice(item) ? 'is-valid' : 'is-invalid'" class="form-control fs-14 text-right" currency="RUB" locale="ru" />
                                    </td>
                                    <td class="align-top ws-nowrap">
                                        <template v-if="form.without_vat">
                                            <input class="form-control fs-14" disabled type="text" value="Без НДС" />
                                        </template>
                                        <template v-else>
                                            <v-select
                                                v-model="item.vat"
                                                :calculate-position="withPopper"
                                                :class="{ 'is-invalid': item.vat === null, 'd-inline-block': true, 'w-75': true }"
                                                :clearable="false"
                                                :options="dictionaries.vat"
                                                :reduce="(item) => item.id"
                                                :searchable="false"
                                                append-to-body
                                                label="title"
                                                placeholder="НДС">
                                                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                                                <template #no-options="{ search, searching, loading }"> Записей, соответствующих вашему запросу, не найдено.</template>
                                            </v-select>
                                            <span v-b-popover.hover.top="'Применить ко всем позициям спецификации'" class="d-inline-block pl-1">
                                                <action-button-small :disabled="item.vat === null" icon="chevron-double-down" @click="applyAll(item.vat)" />
                                            </span>
                                        </template>
                                    </td>
                                    <td class="align-top" style="text-align: right">
                                        {{ $formatPrice(itemSum(item)) }}
                                    </td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td :colspan="7">
                                        <p v-if="getPurchaseDeliverable(item.id).ktru_item.reg_number"><b>Рег.номер ТРУ:</b> {{ getPurchaseDeliverable(item.id).ktru_item.reg_number }}</p>
                                        <p v-if="getPurchaseDeliverable(item.id).ktru_item.okpd_code"><b>Код ОКПД2:</b> {{ getPurchaseDeliverable(item.id).ktru_item.okpd_code }}</p>
                                        <p v-if="getPurchaseDeliverable(item.id).ktru_item.description"><b>Описание:</b> {{ getPurchaseDeliverable(item.id).ktru_item.description }}</p>
                                        <p v-if="getPurchaseDeliverable(item.id).ktru_item.specs && getPurchaseDeliverable(item.id).ktru_item.specs.length > 0"><b>Характеристики:</b></p>
                                        <ul v-if="getPurchaseDeliverable(item.id).ktru_item.specs && getPurchaseDeliverable(item.id).ktru_item.specs.length > 0">
                                            <li v-for="(spec, specIndex) of getPurchaseDeliverable(item.id).ktru_item.specs" :key="index + '_spec_' + specIndex">
                                                <b>{{ spec.name }}</b> - {{ spec.value }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </fragment>
                            <tr>
                                <td align="right" class="py-2 border-0" colspan="7">
                                    <span class="fs-18 fw-600">Стоимость спецификации предложения с НДС, руб.: {{ $formatPrice(totalSum) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <template v-if="purchase.purchase_terms.duration_type.id === 'quotation_request'">
                    <form-row-edit-number v-model="form.delivery_time_days" label="Срок доставки, рабочих дней" :min-value="1" :v="$v.form.delivery_time_days" hint="Укажите срок доставки товаров / выполнения работ / оказания услуг в рабочих днях от даты заключения договора." />
                    <form-row-edit-price v-model="form.delivery_cost" label="Стоимость доставки, руб" :v="$v.form.delivery_cost" hint="Укажите стоимость доставки товаров / выполнения работ / оказания услуг." />

                </template>

                <template v-if="purchase.supplier_requirements.only_smp || purchase.supplier_requirements.only_not_rnp || purchase.supplier_requirements.has_additional_requirements">
                    <text-header>Подтверждение требований к поставщикам</text-header>
                    <b-form-group
                        v-if="purchase.supplier_requirements.only_smp"
                        :label-cols-lg="11"
                        :label-cols-sm="11"
                        :state="!$v.form.supplier_requirements_confirmed.is_smp.$invalid"
                        label="Подтверждаю, что организация является субъектом малого предпринимательства (СМП)"
                        label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                        <b-form-checkbox v-model="form.supplier_requirements_confirmed.is_smp" :state="!$v.form.supplier_requirements_confirmed.is_smp.$invalid" class="fs-14 grey-color mt-2 text-right" disabled></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                        v-if="purchase.supplier_requirements.only_not_rnp"
                        :label-cols-lg="11"
                        :label-cols-sm="11"
                        :state="!$v.form.supplier_requirements_confirmed.is_not_rnp.$invalid"
                        label="Подтверждаю, что организация не присутствует в реестре недобросовестных поставщиков"
                        label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                        <b-form-checkbox v-model="form.supplier_requirements_confirmed.is_not_rnp" :state="!$v.form.supplier_requirements_confirmed.is_not_rnp.$invalid" class="fs-14 grey-color mt-2 text-right"></b-form-checkbox>
                    </b-form-group>
                    <div v-if="purchase.supplier_requirements.has_additional_requirements" class="form-row">
                        <div class="col">
                            <p class="fs-14 grey-color mt-2">
                                <strong>Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:</strong>
                            </p>
                        </div>
                    </div>
                    <template v-if="purchase.supplier_requirements.has_additional_requirements">
                        <form-row-single-line-document-upload
                            v-for="(item, index) in $v.form.supplier_requirements_confirmed.additional.$each.$iter"
                            :key="index"
                            v-model="item.$model.confirmation_document"
                            :label-cols-lg="4"
                            :title="getAdditionalRequirementTitle(item.$model.additional_requirement_id)"
                            :v="item" />
                    </template>
                </template>

                <form-row-upload-multiple-documents v-model="form.attachments" :v="$v.form.attachments" title="Документы" />

                <p v-if="purchase.fee_amount && !form.existing_proposal" class="font-weight-bold grey-color">При подаче заявки на лицевом счету Вашей организации, в соответствии с регламентом, будет заблокирована сумма {{ $formatPrice(purchase.fee_amount) }} руб.</p>
                <p v-else-if="purchase.purchase_terms.duration_type.id === 'quotation_request'" class="font-weight-bold grey-color">При подаче заявки на лицевом счету Вашей организации, в соответствии с регламентом, будет заблокирована сумма {{ $formatPrice(commission) }} руб.</p>

                <b-row class="my-5">
                    <b-col>
                        <b-button class="mr-2" variant="custom-outline-secondary" @click="cancelOffer">ОТМЕНИТЬ</b-button>
                        <b-button :disabled="$v.$invalid || !$store.getters.getCanSign" class="mx-2" variant="custom-green" @click="validateForm">ПОДАТЬ ПРЕДЛОЖЕНИЕ</b-button>
                        <template v-if="!$store.getters.getCanSign">
                            <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                            <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подача и изменение предложения возможны только при авторизации при помощи ЭЦП</span>
                        </template>
                        <template v-else-if="$v.$invalid && !$v.form.accountingAvailable">
                            <span class="text-danger px-3 pt-0 fs-14 w-50 my-auto">На лицевом счете недостаточно средств. Для подачи предложения не хватает <strong>{{ $formatPrice(Math.abs(accounting.sum_available - commission)) }}</strong> руб.</span>
                            <router-link :to="{ name: 'ProviderAccountCabinet' }" class="system_link-style fs-14">Пополнить лицевой счет</router-link>
                            .
                        </template>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <!--SIGN OFFER MODAL WINDOW -->
        <b-modal ref="sign-form-modal" v-model="showSignModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Подписать предложение">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3">
                    <b-tab active>
                        <template slot="title">
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <text-row v-model="purchase.reg_number" label="Закупочная сессия" />
                        <text-row-price v-model="totalSum" label="Итоговая стоимость заявки" />
                    </b-tab>
                    <b-tab>
                        <template slot="title">
                            <span class="system_link-style fs-16">XML</span>
                        </template>
                        <b-form-textarea v-if="!isSignedXml" v-model="formXml" class="text-monospace fs-12" disabled no-auto-shrink no-resize rows="20" wrap="off"></b-form-textarea>
                        <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" class="text-monospace fs-12" disabled rows="20"></b-form-textarea>
                    </b-tab>
                </b-tabs>
            </div>
            <div slot="modal-footer" class="w-100 px-3">
                <text-row :value="certificate?.subjectName" label="Сертификат" />
                <b-button :disabled="isDataSending" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="sendData">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
        <span id="atmo-bottom" class="atmo-hash-jump-point"></span>
        <system-preloader v-if="loading"></system-preloader>
    </div>
</template>

<script>
import TextHeader from '@/components/common/form-rows/text-header';
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPrice from '@/components/common/form-rows/text-row-price';
import TextRowDocuments from '@/components/common/form-rows/text-row-documents';
import {minValue, numeric, required, requiredIf, sameAs} from 'vuelidate/lib/validators';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import FormRowUploadMultipleDocuments from '@/components/common/form-rows/form-row-upload-multiple-documents.vue';
import { getCertificate } from 'crypto-pro';
import { $api } from '@/services/backend/api';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';
import { withPopper } from '@/helpers';
import FormRowEditNumber from "@/components/common/form-rows/form-row-edit-number.vue";

export default {
    name: 'fl223-purchase-proposal-create',
    metaInfo() {
        return {
            title: 'Подача предложения на закупку 223ФЗ'
        };
    },
    components: {
        FormRowEditNumber,
        FormRowUploadMultipleDocuments,
        TextRowSingleDocument,
        TextRowDocuments,
        TextRowPrice,
        TextRow,
        TextRowLink,
        TextHeader
    },
    data() {
        return {
            purchaseInfoDisplayed: true,
            isSigningXml: false,
            isDataSending: false,
            formXml: '',
            isSignedXml: false,
            loading: true,
            showSignModal: false,
            showAttentionModal: false,
            visible: [],
            signedFormXml: null,
            purchase: null,
            accounting: null,
            dictionaries: [],
            form: {
                existing_proposal: false,
                organization_card: null,
                without_vat: false,
                contact_name: '',
                deliverables: [],
                attachments: [],
                supplier_requirements_confirmed: {
                    is_smp: false,
                    is_rnp: false,
                    additional: []
                },
                delivery_time_days: 1,
                delivery_cost: null
            },
            proposalTotalCost: 0,
            isPurchaseLoading: true,
            isGettingXML: false,
            isCertificateChecking: false,
            selectCertificate: null,
            certificate: null
        };
    },
    validations() {
        return {
            totalSum: {
                required,
                minValue: minValue(0.01)
            },
            form: {
                contact_name: { required },
                organization_card: { required },
                without_vat: {},
                supplier_requirements_confirmed: {
                    is_smp: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.only_smp)
                    },
                    is_not_rnp: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.only_not_rnp)
                    },
                    additional: {
                        $each: {
                            confirmation_document: {
                                required
                            }
                        }
                    }
                },
                deliverables: {
                    required,
                    $each: {
                        required,
                        price: {
                            required,
                            minValue: minValue(0.01)
                        },
                        vat: {
                            required: requiredIf(() => !this.form.without_vat)
                        }
                    }
                },
                attachments: {},
                ...this.purchase.purchase_terms.duration_type.id === 'quotation_request' ? {
                        delivery_time_days: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        delivery_cost: {
                            minValue: minValue(0.0)
                        }
                    } : {},
                accountingAvailable: () => this.accounting.sum_available - this.commission > 0
            }
        };
    },
    async mounted() {
        await this.fillData();
        this.loading = false;
    },
    methods: {
        applyAll(vat) {
            this.form.deliverables.forEach((val) => (val.vat = vat));
        },
        async validateForm() {
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (!this.certificate) {
                return;
            }
            this.showSignForm();
        },
        showSignForm() {
            this.$api.products.fl223.purchases.getProposalXML(this.$route.params.id, this.form, this.totalSum).then((resp) => {
                this.formXml = resp.xml;
                this.showSignModal = true;
            });
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await $api.signXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        withPopper,
        checkItemPrice(item) {
            return item.price > 0;
        },
        itemSum(item) {
            return roundToTwoDecimals(item.price * this.getPurchaseDeliverable(item.id).amount);
        },
        recalculateTotalSum() {
            this.proposalTotalCost = this.totalSum;
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        async fillData() {
            const purchaseData = await this.$api.products.fl223.purchases.getProposalCreateData(this.$route.params.id);
            this.form = Object.assign({}, this.form, purchaseData.proposal);
            this.dictionaries = purchaseData.dictionaries;
            this.purchase = purchaseData.purchase;
            this.accounting = purchaseData.accounting;
            this.recalculateTotalSum();
        },
        getPurchaseDeliverable(id) {
            return this.purchase.deliverables.find((x) => x.id === id);
        },
        getAdditionalRequirementTitle(id) {
            const found = this.purchase.supplier_requirements.additional.find((x) => x.id === id);
            if (!found) {
                return '';
            }
            return found.description + ' (Причина: ' + found.reason + ')';
        },
        async sendData() {
            this.isDataSending = true;
            const responseData = await this.$api.products.fl223.purchases.storeSignedProposal(this.$route.params.id, this.form, this.signedFormXml, this.certificate);
            this.isDataSending = false;
            if (responseData) {
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'CabinetFl223ProposalsList' });
        },
        cancelOffer() {
            this.onCancel();
        }
    },
    computed: {
        totalSum() {
            let sum = 0;
            this.form.deliverables.forEach(function (item) {
                const purchaseDeliverable = this?.purchase?.deliverables.find((x) => x.id === item.id);
                sum += roundToTwoDecimals(item.price * purchaseDeliverable?.amount);
            }, this);
            if (sum) {
                return sum;
            }
            return 0;
        },
        commission() {
            return Math.min(roundToTwoDecimals(this.totalSum * this.accounting.commission_percentage / 100), this.accounting.commission_max_sum);
        }
    }
};
</script>

<style scoped>
.purchase-offer-header-block {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    min-height: 250px;
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>

<style>
.new_offer .is-valid {
    border-color: rgb(206, 212, 218) !important;
    background: #fff !important;
}
</style>
