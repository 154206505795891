<template>
    <div>
        <b-button size="sm" variant="custom-danger" class="text-uppercase" @click="showModal">Запрос аннулирования</b-button>

        <b-modal v-model="isModalVisible" :title="title" centered hide-header-close no-close-on-backdrop no-close-on-esc>
            <form-row-single-select label="Причина запроса аннулирования" :label-cols-sm="0" :label-cols-lg="0" :options="rejectRequestReasons" v-model="form.reject_request_reason" :v="$v.form.reject_request_reason" />
            <p class="fs-14 text-danger fw-900">Внимание! Операция необратима!</p>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelModal">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.$invalid" class="text-uppercase" variant="custom-danger" @click="sendRejectRequest">Запросить аннулирование</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'fl44-report-delete-button',
    props: {
        fl44PurchaseReport: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            rejectRequestReasons: [
                { id: 'Ошибка в отчете по п. 4 ч. 1 ст. 93', name: 'Ошибка в отчете по п. 4 ч. 1 ст. 93' },
                { id: 'Ошибка в отчете по п. 5 ч. 1 ст. 93', name: 'Ошибка в отчете по п. 5 ч. 1 ст. 93' },
                { id: 'Ошибка в отчете по п. 23 ч. 1 ст. 93', name: 'Ошибка в отчете по п. 23 ч. 1 ст. 93' },
                { id: 'Ошибка в отчете по п. 44 ч. 1 ст. 93', name: 'Ошибка в отчете по п. 44 ч. 1 ст. 93' },
                { id: 'Ошибка в двух и более строках отчета', name: 'Ошибка в двух и более строках отчета' }
            ],
            isModalVisible: false,
            isDataSending: false,
            form: {
                reject_request_reason: null
            }
        };
    },
    validations() {
        return {
            form: {
                reject_request_reason: {
                    required,
                    minLength: minLength(3)
                }
            }
        };
    },
    methods: {
        async showModal() {
            this.isLoading = false;
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
            this.form.reject_request_reason = null;
        },
        async sendRejectRequest() {
            this.isDataSending = true;
            if (await $api.personal.fl44PurchaseReports.delete(this.fl44PurchaseReport.id, this.form)) {
                this.isModalVisible = false;
                this.refresh();
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        title() {
            return `Запрос на аннулирование отчета за ${this.fl44PurchaseReport.quarter} квартал ${this.fl44PurchaseReport.year}`;
        }
    }
};
</script>
