<template>
    <div class="new_quotation">
        <system-preloader v-if="loading"></system-preloader>
        <b-container class="main_layer" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Подача коммерческого предложения<br />на запрос КП № {{ quotationRequest.reg_number }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="pb-4" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link :router-link="{ name: 'OrganizationShow', params: { id: quotationRequest.organization.id } }" :value="quotationRequest.organization.name" compact label="Наименование" />
                <text-row :value="quotationRequest.organization.inn" compact label="ИНН" />
                <text-row :value="quotationRequest.organization.kpp" compact label="КПП" />
                <text-row :value="quotationRequest.organization.ogrn" compact label="ОГРН" />
                <text-row :value="quotationRequest.organization.address" compact label="Адрес" />
                <text-row-raw compact label="Адрес электронной почты">
                    <a :href="'mailto:' + quotationRequest.organization.email" class="fs-14 col-form-label d-inline-block">{{ quotationRequest.organization.email }}</a>
                </text-row-raw>
                <text-row-raw compact label="Контактный номер телефона">
                    <a :href="'tel:+' + quotationRequest.organization.phone" class="fs-14 col-form-label d-inline-block">{{ $parsePhone(quotationRequest.organization.phone) }}</a>
                </text-row-raw>

                <text-header>Информация о поставщике</text-header>
                <text-row-link :router-link="{ name: 'OrganizationShow', params: { id: $store.state.organization.id } }" :value="$store.state.organization.name" compact label="Наименование" />
                <text-row :value="$store.state.organization.inn" compact label="ИНН" />
                <text-row :value="$store.state.organization.kpp" compact label="КПП" />
                <text-row :value="$store.state.organization.ogrn" compact label="ОГРН" />
                <text-row :value="$store.state.organization.post_address" compact label="Адрес" />

                <text-header>Информация о запросе КП</text-header>
                <text-row :value="quotationRequest.reg_number" compact label="Рег.номер запроса КП" />
                <text-row :value="quotationRequest.name" compact label="Предмет закупки" />
                <text-row-datetime :value="quotationRequest.deadline" compact label="Срок сбора КП" />
                <text-row-single-document :value="quotationRequest.document" compact label="Документ" />

                <text-header>Информация о коммерческом предложении</text-header>
                <form-row-edit-date v-model="form.deadline" :min-date="new Date().toJSON().slice(0, 10)" :v="$v.form.deadline" label="Срок действия коммерческого предложения" />

                <form-row-specification-table v-model="form.specs" :spec-error-message="specErrorMessage" :spec-errors="specErrors" :spec-sum="specSum" :v="$v.form.specs" no-add-spec no-delete-spec with-price />

                <form-row-single-line-document-upload v-model="form.document" :label-cols-lg="3" :v="$v.form.document" title="Документ" />

                <b-row class="mb-4">
                    <b-col>
                        <b-button class="mr-3" variant="custom-outline-secondary" @click="cancelQuotation">Отменить</b-button>
                        <b-button :disabled="!isValid || !$store.getters.getCanSign" variant="custom-green" @click="prepareForm">Подписать и подать предложение</b-button>
                        <template v-if="!$store.getters.getCanSign">
                            <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                            <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                        </template>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc size="lg" title="Подписать предложение" @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3">
                    <b-tab active>
                        <template #title>
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <h4 class="font-weight-bold">Информация о коммерческом предложении</h4>
                        <text-row :value="quotationRequest.reg_number" compact label="Рег. номер запроса КП" />
                        <text-row-price :value="specSum" compact label="Общая стоимость предложения" />
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <span class="system_link-style fs-16">Xml представление</span>
                        </template>
                        <b-form-textarea v-if="!isSignedXml" v-model="formXml" class="text-monospace fs-12" disabled no-auto-shrink no-resize rows="20" wrap="off"></b-form-textarea>
                        <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" class="text-monospace fs-12" disabled no-auto-shrink no-resize rows="20" wrap="off"></b-form-textarea>
                    </b-tab>
                </b-tabs>
            </div>
            <div slot="modal-footer" class="w-100">
                <text-row :value="certificate?.subjectName" label="Сертификат" />
                <b-button :disabled="isDataSending || isSigningXml" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!certificate" class="text-uppercase" variant="custom-green" @click="sendData">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FormRowSpecificationTable from '@/components/common/form-rows/form-row-specification-table.vue';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import { getCertificate } from 'crypto-pro';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';
import { $api } from '@/services/backend/api';

export default {
    name: 'QuotationCreate',
    components: {
        TextRowSingleDocument,
        TextRowRaw,
        FormRowSpecificationTable
    },
    metaInfo: {
        title: 'Подача коммерческого предложения'
    },
    data() {
        return {
            formXml: '',
            signedFormXml: null,
            currentCertSubject: '',
            form: {
                deadline: null,
                document: null,
                specs: []
            },
            isSignedXml: false,
            quotationRequest: null,
            loading: true,
            showSignModal: false,
            showAttentionModal: false,
            showDepositModal: false,
            email: this.$store.state.user.email,
            certificate: null,
            isDataSending: false,
            isSigningXml: false
        };
    },
    validations: {
        form: {
            deadline: { required },
            document: { required },
            specs: { required }
        }
    },
    async mounted() {
        if (!this.$store.getters.isLoggedIn) {
            await this.$router.replace({ name: '404' }).catch(() => {});
        } else {
            await this.fillData();
        }
    },
    methods: {
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        async fillData() {
            this.quotationRequest = await this.$api.quotationRequests.getItem(this.$route.params.id);
            this.quotationRequest.items.forEach((item) => {
                this.form.specs.push({ ...item, price: 0, total_sum: 0 });
            });
            this.loading = false;
        },
        async prepareForm() {
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (this.certificate) {
                await this.showSignForm();
            }
        },
        recalcItemsTotal() {
            this.form.specs.forEach((item, index) => {
                this.form.specs[index].total_sum = item.price * item.count;
            });
        },
        async showSignForm() {
            this.recalcItemsTotal();
            const formData = {
                ...this.form,
                total_cost: this.specSum
            };
            this.formXml = await this.$api.quotationRequests.getOfferXml(this.quotationRequest.id, formData);
            if (this.formXml) this.showSignModal = true;
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await $api.signXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        async sendData() {
            this.isDataSending = true;
            const formData = {
                form: {
                    ...this.form,
                    total_cost: this.specSum
                },
                xml: this.signedFormXml,
                certificate: this.certificate
            };
            if (await this.$api.quotationRequests.storeSignedOffer(this.quotationRequest.id, formData)) {
                this.cancelSignModal();
                this.cancelQuotation();
            }
            this.isDataSending = false;
        },
        cancelQuotation() {
            this.$router.push('/quotation-requests/new').catch(() => {});
        }
    },
    computed: {
        specErrors() {
            return !!this.specErrorMessage;
        },
        specErrorMessage() {
            if (!this.form.specs.every((e) => e.price > 0)) return 'У одной или нескольких позиций в таблице спецификации не заполнено поле "Цена за единицу"';
            return '';
        },
        specSum() {
            return roundToTwoDecimals(this.form.specs.reduce((acc, cv) => acc + roundToTwoDecimals(cv.count * cv.price), 0));
        },
        isValid() {
            return !this.$v.$invalid && !this.specErrors;
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    min-height: 70px;
}

a {
    color: var(--green-color) !important;
}
</style>
