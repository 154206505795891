import axios from 'axios';

export default {
    getQuotations(param, page) {
        return axios.get('/cabinet/provider/quotations/' + param, {
            params: {
                page: page
            }
        });
    }
};
