<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <text-header-new>Уведомления</text-header-new>
            </b-col>
            <b-col class="col-auto">
                <b-btn variant="custom-green" size="sm" :to="{ name: 'NotificationSettings' }">
                    <b-icon-gear-fill class="my-auto mr-2" aria-hidden="true" />
                    Настроить получение уведомлений
                </b-btn>
            </b-col>
        </b-row>
        <template v-if="notifications.length">
            <b-overlay :show="isDataSending && isNotificationsLoad" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-container>
                <b-row fluid class="mb-1">
                    <b-col class="col-auto px-1 fs-14 my-auto">
                        <b-button variant="btn btn-outline" class="p-0" style="width: 24px; height: 24px" @click="selectDeselectAllRows">
                            <b-icon class="checkbox-icon my-auto" aria-hidden="true" :icon="isAllSelected ? 'check2-square' : 'square'" />
                        </b-button>
                        <b-button v-if="isAnySelected" title="Прочитать" style="width: 24px; height: 24px" class="p-0 ml-1" variant="outline" @click="markNotificationsAsRead(null)"><b-icon icon="circle" scale="0.6" class="green-color read-icon my-auto" aria-hidden="true" /></b-button>
                        <b-button v-else title="Отметить все прочитанными" style="width: 24px; height: 24px" class="p-0 ml-1" variant="outline" @click="markAllNotificationsAsRead"><b-icon icon="circle" scale="0.6" class="green-color read-icon my-auto" aria-hidden="true" /></b-button>
                    </b-col>
                    <b-col class="col-auto px-1">
                    </b-col>
                    <b-col />
                    <b-col class="col-auto px-1">
                        <action-button-small v-if="isAnySelected" icon="trash" variant="outline" title="Удалить выбранные" @click="deleteNotifications" />
                        <action-button-small v-else icon="trash" variant="outline" title="Удалить все" @click="deleteAllNotifications" />
                    </b-col>
                </b-row>
                </b-container>
            </b-overlay>
        </template>
        <template v-if="!notifications.length">
            <b-overlay :show="isNotificationsLoad" opacity="0.6" rounded spinner-variant="primary">
                <empty-request />
            </b-overlay>
        </template>
        <template v-else>
            <b-overlay :show="isDataSending && isNotificationsLoad" opacity="0.6" rounded spinner-variant="primary">
                <b-container>
                <notification-row v-for="notification in notifications" :notification="notification" :key="notification.id" :row-selected="notification.row_selected" with-checkbox @select="select" @read="markNotificationsAsRead" @unread="markNotificationsAsUnread" @delete="deleteNotifications" />
                </b-container>
            </b-overlay>
        </template>
        <template v-if="notifications.length && meta.total > 0 && meta.last_page > 1" class="py-1">
            <b-row class="w-100 m-0">
                <b-col class="text-center">
                    <b-overlay :show="isNotificationsLoad && isDataSending && notifications.length && meta.total > 0 && meta.last_page > 1" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-pagination v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-0 py-0" @input="onPaginate" />
                    </b-overlay>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>

import NotificationRow from "@/layout/elements/notification-row.vue";

export default {
    name: 'Notifications',
    components: {NotificationRow},
    metaInfo: {
        title: 'Настройка уведомлений'
    },
    data() {
        return {
            isNotificationsLoad: false,
            isDataSending: false,
            notifications: [],
            meta: {
                current_page: 1,
                total: 0,
                last_page: 1,
                per_page: 50
            }
        };
    },
    async mounted() {
        await this.getNotifications();
    },
    methods: {
        async getNotifications(page = null) {
            this.isNotificationsLoad = true;
            await this.$api.notifications.getNotificationList({page: page ? page : this.meta.current_page})
                .then(response => {
                    const selected = this.notifications.filter((notification) => notification.row_selected);
                    response.data.forEach(notification => notification.row_selected = selected.find((value) => value.id === notification.id) !== undefined);
                    this.notifications = response.data;
                    this.meta = response.meta;
                    this.unreadCount = response.unread_notifications_count;
                    this.$store.commit('set_unread_notifications_count', response.unread_notifications_count);
                })
                .finally(() => {
                    this.isNotificationsLoad = false;
                });
        },
        async onPaginate() {
            this.isDataSending = true;
            await this.getNotifications().finally(() => this.isDataSending = false);
        },
        select(id) {
            const notification = this.notifications.find((notification) => notification.id === id);
            notification.row_selected = !notification.row_selected;
        },
        selectDeselectAllRows() {
            const isSelected = !this.isAllSelected;
            this.notifications.forEach(notification => notification.row_selected = isSelected);
        },
        async deleteNotifications(id = null) {
            const data = [];
            this.isDataSending = true;
            this.isNotificationsLoad = true;
            if (id) {
                data.push(id);
            } else {
                this.notifications.filter(notification => notification.row_selected).forEach(notification => { data.push(notification.id) });
            }
            const newCurrentPage = this.meta.current_page !== this.meta.last_page ? this.meta.current_page : Math.ceil((this.meta.total - data.length) / this.meta.per_page) ?? 1;
            await this.$api.notifications.delete(data)
                .then(async response => {
                    this.unreadCount = response.count;
                    this.$store.commit('set_unread_notifications_count', response.count);
                    await this.getNotifications(newCurrentPage);
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        },
        async markNotificationsAsRead(id = null) {
            const data = [];
            if (id) {
                data.push(id);
            } else {
                this.notifications.filter(notification => notification.row_selected && !notification.is_read).forEach(notification => { data.push(notification.id) });
            }
            if (data.length > 0) {
                this.isDataSending = !id;
                this.isNotificationsLoad = true;
                await this.$api.notifications.markAsRead({ notifications: data })
                    .then(async response => {
                        this.unreadCount = response.count;
                        this.$store.commit('set_unread_notifications_count', response.count);
                        await this.getNotifications();
                    })
                    .finally(() => {
                        this.isDataSending = false;
                    });
            }
        },
        async markNotificationsAsUnread(id) {
            const data = [];
            data.push(id);
            this.isNotificationsLoad = true;
            await this.$api.notifications.markAsUnread({ notifications: data })
                .then(async response => {
                    this.unreadCount = response.count;
                    this.$store.commit('set_unread_notifications_count', response.count);
                    await this.getNotifications();
                });
        },
        async markAllNotificationsAsRead() {
            if (this.unreadCount > 0) {
                this.isDataSending = true;
                this.isNotificationsLoad = true;
                await this.$api.notifications.markAllAsRead()
                    .then(async response => {
                        this.unreadCount = response.count;
                        this.$store.commit('set_unread_notifications_count', response.count);
                        await this.getNotifications();
                    })
                    .finally(() => {
                        this.isDataSending = false;
                    });
            }
        },
        async deleteAllNotifications() {
            if (this.notifications.length > 0) {
                this.$bvModal
                    .msgBoxConfirm('Вы действительно хотите удалить все уведомления?', {
                        title: 'Подтвердите действие',
                        okVariant: 'custom-danger',
                        okTitle: 'Удалить',
                        cancelTitle: 'Отмена',
                        cancelVariant: 'custom-outline-secondary',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(async (value) => {
                        if (value) {
                            this.isDataSending = true;
                            this.isNotificationsLoad = true;
                            await this.$api.notifications.deleteAll()
                                .then(async response => {
                                    this.unreadCount = response.count;
                                    this.$store.commit('set_unread_notifications_count', response.count);
                                    await this.getNotifications();
                                })
                                .finally(() => {
                                    this.isDataSending = false;
                                });
                        }
                    })
                    .catch((err) => {
                        // An error occurred
                        console.error(err);
                    });
            }
        }
    },
    computed: {
        isAllSelected() {
            return this.notifications.filter((notification) => notification.row_selected).length === this.notifications.length;
        },
        isAnySelected() {
            return this.notifications.filter((notification) => notification.row_selected).length > 0;
        }
    }
};
</script>
<style scoped>
.read-icon {
    vertical-align: initial !important;
    border: 1px solid transparent;
    border-color: var(--green-color);
    border-radius: 7px;
    width: 14px;
    height: 14px;
}
.checkbox-icon {
    vertical-align: initial !important;
    width: 14px;
    height: 14px;
}

.btn-outline {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    vertical-align: -0.15em;
}
.btn-outline:not(:disabled):not(.disabled):active,
.btn-outline:active {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    filter: brightness(85%);
}
.btn-outline:focus {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
}
.btn-outline:hover {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    filter: brightness(85%);
}
</style>
