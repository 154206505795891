var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between" },
            [
              _c("text-header-new", [_vm._v("Запросы КП")]),
              _c(
                "div",
                [
                  _c(
                    "b-btn",
                    {
                      staticStyle: { background: "transparent" },
                      attrs: {
                        variant: "custom-green",
                        to: { name: "QuotationRequestCreate" },
                      },
                    },
                    [_vm._v(" Сформировать запрос КП ")]
                  ),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "tabs" },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "li",
                    { key: "key_" + index },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "QuotationRequestsRequestsList",
                              params: { cat: tab.path },
                            },
                            exact: "",
                          },
                        },
                        [_vm._v(_vm._s(tab.title))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.quotationRequests && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _vm._l(_vm.quotationRequests, function (quotationRequest) {
        return [
          quotationRequest.status.id === "started"
            ? _c("quotation-request-card-open", {
                key: quotationRequest.id,
                attrs: { quotationRequest: quotationRequest },
                on: { reloadData: _vm.fillData },
              })
            : _vm._e(),
          quotationRequest.status.id === "completed"
            ? _c("quotation-request-card-closed", {
                key: quotationRequest.id,
                attrs: { quotationRequest: quotationRequest },
              })
            : _vm._e(),
        ]
      }),
      !_vm.quotationRequests.length && !_vm.loading
        ? _c("empty-request")
        : _vm._e(),
      _vm.quotationRequests && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: { input: _vm.fillData },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }