var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        [
          _c("form-header", {
            attrs: { title: _vm.title, "without-current-organization": "" },
          }),
          _c(
            "form-content-group",
            { attrs: { title: "Информация о запросе" } },
            [
              _c("text-row", {
                attrs: {
                  value: _vm.importSubstitutionRequest.purchase_category_name,
                  label: "Предмет запроса",
                },
              }),
              _c("text-row-datetime", {
                attrs: {
                  value: _vm.importSubstitutionRequest.deadline,
                  label: "Срок сбора предложений",
                },
              }),
            ],
            1
          ),
          _c("import-substitution-request-show-ktru-item-block", {
            attrs: {
              ktruItem: _vm.importSubstitutionRequest.ktru_item,
              price: _vm.importSubstitutionRequest.price,
            },
          }),
          _c(
            "form-content-group",
            { attrs: { title: "Техническое описание" } },
            [
              _c("text-row-documents", {
                attrs: { label: "Документ", value: _vm.documents },
              }),
            ],
            1
          ),
          _c(
            "form-content-group",
            { attrs: { title: "Предложения импортозамещения" } },
            [
              _vm.importSubstitutionRequest.replies.length > 0
                ? _vm._l(
                    _vm.importSubstitutionRequest.replies,
                    function (offer, index) {
                      return _c("import-substitution-offer-card", {
                        key: "offer_" + index,
                        attrs: { offer: offer, index: index },
                      })
                    }
                  )
                : [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-center fs-16 grey-color font-weight-bold",
                      },
                      [_vm._v("Предложения отсутствуют")]
                    ),
                  ],
            ],
            2
          ),
          _vm.importSubstitutionRequest.status_id === 1
            ? _c(
                "form-content-group",
                { staticClass: "mb-5", attrs: { title: "" } },
                [
                  _vm.$store.getters.isLoggedIn && _vm.$store.getters.isProvider
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-green" },
                          on: { click: _vm.createReply },
                        },
                        [_vm._v("Подать предложение")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }