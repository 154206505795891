<template>
    <div>
        <b-container fluid class="main_layer py-4" style="min-height: 200px !important">
            <b-container class="d-flex flex-column justify-content-between h-100">
                <b-row style="margin-top: 40px">
                    <b-col>
                        <p class="fs-14 fw-600 white-color mb-4" v-if="!loading && article">Опубликовано: {{ getDateFormat(article.published_at) }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mb-4" v-if="!loading && article">
                            {{ article.title }}
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="greyBg py-5">
            <b-container v-if="!loading">
                <b-row>
                    <b-col>
                        <span v-html="article.text"></span>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <p class="green-color fs-52 mb-5">Другие новости</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="news_swiper">
                        <swiper class="swiper-container mb-5" :options="swiperMultipleSlides">
                            <swiper-slide v-for="(message, mIndex) in articles" width="270" class="slide p-0" :key="'message_' + message.type_id + '_' + mIndex">
                                <div>
                                    <div
                                        class="article_head d-flex"
                                        :class="{
                                            news_head: message.type_id === 1,
                                            work_head: message.type_id === 2,
                                            update_head: message.type_id === 3
                                        }">
                                        <img src="/images/lifebuoy.svg" alt="" />
                                        <router-link class="cursor-pointer" tag="p" replace :to="'/articles/' + message.id">
                                            {{ message.title }}
                                        </router-link>
                                    </div>
                                    <div class="article_body">
                                        <p class="m-0" style="white-space: pre-line">
                                            {{ lengthControl(message.description, 120) }}
                                        </p>
                                    </div>
                                    <div class="article_footer">
                                        <span>{{ getDateFormat(message.published_at) }}</span>
                                        <router-link class="btn btn-light btn-sm fs-12 mt-1 px-4 fw-100" :to="'/articles/' + message.id" tag="button" replace style="text-transform: uppercase; color: var(--grey-color); background-color: #f5f5f7" variant="light" size="sm"> Подробнее </router-link>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div slot="button-prev" class="swiper-button-prev custom-icon">
                            <i class="fas fa-chevron-left"></i>
                        </div>
                        <div slot="button-next" class="swiper-button-next custom-icon">
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<style src="swiper/dist/css/swiper.css"></style>

<script>
import ArticlesApi from '@/services/api/Articles';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Article',
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            loading: true,
            article: null,
            articles: [],
            swiperMultipleSlides: {
                slidesPerView: 4,
                spaceBetween: 35,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        };
    },
    created() {
        this.setWindowWidth();
        ArticlesApi.getFrontArticleCard(this.$route.params.id)
            .then((article) => {
                this.article = article.data;
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
            .finally(() => {
                this.loading = false;
            });
        ArticlesApi.getArticles()
            .then((resp) => {
                this.articles = resp.articles;
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        getDateFormat(date) {
            return moment.tz(date, 'Asia/Yekaterinburg').format('DD.MM.YYYY');
        },
        lengthControl(str, length) {
            if (str.length <= length) {
                return str;
            } else {
                return str.slice(0, length) + '...';
            }
        },
        setWindowWidth() {
            if (window.innerWidth < 559) {
                this.swiperMultipleSlides.slidesPerView = 1;
            } else if (window.innerWidth < 769) {
                this.swiperMultipleSlides.slidesPerView = 2;
            } else if (window.innerWidth < 1200) {
                this.swiperMultipleSlides.slidesPerView = 3;
            } else this.swiperMultipleSlides.slidesPerView = 4;
        }
    },
    watch: {
        $route: function (val) {
            ArticlesApi.getFrontArticleCard(val.params.id)
                .then((article) => {
                    this.article = article.data;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}

.img-box img {
    max-width: 100%;
}

.news_head {
    background-color: var(--green-color);
}

.work_head {
    background-color: #f9cd45;
}

.update_head {
    background-color: var(--grey-color);
}

.article_head {
    padding: 25px;
}

.article_head p {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-left: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.slide {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
    height: auto;
}

.slide > div {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.article_head {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.article_body {
    padding: 20px;
}

.article_body p {
    font-size: 12px;
    line-height: 24px;
}

.article_footer {
    padding: 0 20px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: auto;
}

.article_footer span {
    color: var(--grey-color);
    font-size: 12px;
}

.custom-icon {
    background-image: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(38, 184, 154, 0.21);
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.custom-icon i {
    color: var(--green-color);
}

.custom-icon i.fa-chevron-right {
    margin-left: 2px;
}

.custom-icon i.fa-chevron-left {
    margin-right: 2px;
}

.news_swiper {
    position: relative;
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .img-box {
        overflow-x: auto;
        margin-bottom: -17px;
    }
}

@media screen and (max-width: 568px) {
    .greyBg {
        margin-bottom: 40px;
    }

    .article_head {
        padding: 18px;
    }

    .article_body {
        padding: 18px;
    }

    .article_body p {
        line-height: 20px;
    }

    .article_footer {
        padding: 0 20px 20px;
    }

    .news_swiper .swiper-container {
        margin-bottom: 25px !important;
    }
}
</style>

<style>
.swiper-button-prev::before,
.custom_slider_btn-prev::before {
    content: '';
    width: 10px;
    height: 10px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: transparent transparent var(--green-color) var(--green-color);
    position: absolute;
    top: 18px;
    left: 40%;
}

.swiper-button-next::before,
.custom_slider_btn-next::before {
    content: '';
    width: 10px;
    height: 10px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: var(--green-color) var(--green-color) transparent transparent;
    position: absolute;
    top: 18px;
    left: 30%;
}
</style>
