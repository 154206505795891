<template>
    <div v-if="isAvailable">
        <b-btn @click="showModal" class="btn-card-action btn-card-action-orange my-1">Отменить закупку</b-btn>
        <b-modal ref="cancel-modal" :title="title" v-model="isModalVisible" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg">
            <b-row>
                <b-col md="12">
                    <p class="fs-16 font-weight-bold grey-color">Введите причину отмены закупки и/или приложите документ с обоснованием причины отмены закупки. Данная информация будет отправлена поставщикам, подавшим предложения, а также будет видна сотрудникам ГК и Вашего ГРБС.</p>
                </b-col>
            </b-row>
            <form-row-edit-textarea label="Введите причину отмены закупки" v-model="purchaseCancelingReasonMessage" :rows="8" />
            <form-row-single-document-upload title="Обоснование причины отмены закупки" v-model="purchaseCancelingDocument" :v="{}" header-class="fs-14 fw-700" />
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelModal">Нет</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" :disabled="!isDataValid" @click="cancelPurchase">Отменить закупку</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { PurchaseStatus } from '@/const';
import Purchases from '@/services/api/Purchases';
import FormRowSingleDocumentUpload from '@/components/common/form-rows/form-row-single-document-upload';

export default {
    name: 'CancelPurchaseButton',
    components: { FormRowSingleDocumentUpload },
    props: {
        purchase: {
            type: Object
        }
    },
    data() {
        return {
            isModalVisible: false,
            purchaseCancelingReasonMessage: '',
            purchaseCancelingDocument: null,
            isDataSending: false
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
            this.purchaseCancelingReasonMessage = '';
            this.purchaseCancelingDocument = null;
        },
        cancelModal() {
            this.isModalVisible = false;
        },
        cancelPurchase() {
            this.isDataSending = true;
            Purchases.cancel(this.purchase.id, {
                purchase_canceling_reason_message: this.purchaseCancelingReasonMessage,
                purchase_canceling_stored_document_id: this.purchaseCancelingDocument ? this.purchaseCancelingDocument.id : null
            })
                .then((resp) => {
                    if (resp.data.message) {
                        this.pushToast({
                            text: resp.data.message,
                            title: 'Успех',
                            variant: 'green'
                        });
                    }
                    this.isModalVisible = false;
                    this.$emit('cancel');
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        this.pushToast({
                            text: err.response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        }
    },
    computed: {
        title() {
            return `Отменить закупку № ${this.purchase.reg_number}?`;
        },
        isAvailable() {
            return this.isPurchaseCreator() && this.purchase.status_id === PurchaseStatus.STATUS_START;
        },
        isDataValid() {
            return this.purchaseCancelingReasonMessage.trim().length > 0 || (this.purchaseCancelingDocument !== null && this.purchaseCancelingDocument.id !== null);
        }
    }
};
</script>

<style></style>
