<template>
    <div class="my-2">
        <div class="purchase_part">
            <div class="purchase_part-header" :class="statusColor">
                <b-row class="w-100 m-0">
                    <b-col lg="8" md="8" class="px-1">
                        <p class="purchase_part-header_number" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                            <b-badge class="fs-14 mr-2">{{ purchase.product.title }}</b-badge>
                            <template v-if="isPurchasePubliclyAvailable">
                                <router-link :to="{ name: 'Fl223PurchaseShow', params: { id: purchase.id } }" class="white-system_link-style" target="_blank">{{ purchase.reg_number }}</router-link>
                            </template>
                            <template v-else>
                                <span>{{ purchase.reg_number }}</span>
                            </template>
                            <span v-if="purchase.purchase_object" style="text-overflow: ellipsis ellipsis">&nbsp;На закупку "{{ purchase.purchase_object }}"</span>
                        </p>
                    </b-col>
                    <b-col lg="4" md="4" class="px-1">
                        <p class="purchase_part-header_label fw-600 float-right text-right my-auto">
                            {{ purchase.status.title }}
                            <i class="atmo-icon ml-2" :class="statusIconClass"></i>
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div class="purchase_part-subheader">
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-11 green-color"
                        >Сумма закупки: <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.start_price) }} руб.</span></span
                    >
                </b-col>
                <template v-if="purchase.status.id !== 'draft' && purchase.status.id !== 'signed' && purchase.status.id !== 'revoked' && purchase.status.id !== 'accepting'">
                    <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                        <span class="fs-11 green-color"
                            >Предложений: <span class="fw-600 ws-nowrap">{{ purchase.proposals_count ? purchase.proposals_count : '-' }}</span></span
                        >
                    </b-col>
                </template>
            </div>
            <div class="purchase_part-body row">
                <b-col class="purchase_part-body_col" md="4">
                    <div class="my-2">
                        <cabinet-notice-card-row-dual v-if="purchase.status.id === 'accepting' && !!purchase.purchase_end_datetime" label="До окончания подачи предложений">
                            <timer-new :data="purchase.purchase_end_datetime" />
                        </cabinet-notice-card-row-dual>
                        <cabinet-notice-card-row-single v-if="purchase.planned_contract_execution_datetime" label="Планируемая дата исполнения">{{ getDateFormat(purchase.planned_contract_execution_datetime) }}</cabinet-notice-card-row-single>
                        <fl223-purchase-comment-edit-block v-if="purchase.flags.is_customer" class="pb-2" :purchase="purchase" @refresh="refresh" />
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col" md="4">
                    <div class="my-2">
                        <cabinet-notice-card-row-dual v-if="purchase.deliverable_group_title" label="Предмет закупки">{{ purchase.deliverable_group_title }}</cabinet-notice-card-row-dual>
                        <div class="pb-2" v-if="purchase.delivery_addresses && purchase.delivery_addresses.length">
                            <p class="fs-12 m-0 grey-color">Адреса для доставки товаров/выполнения работ/оказания услуг:</p>
                            <p class="fs-12 m-0 grey-color fw-600" v-for="address in purchase.delivery_addresses" :key="address">&mdash;&nbsp;{{ address }}</p>
                        </div>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col" md="4">
                    <div class="h-100">
                        <div class="my-2">
                            <b-btn v-if="purchase.actions.customer.edit" :to="{ name: 'Fl223PurchaseEdit', params: { id: purchase.id } }" class="btn-card-action btn-card-action-green my-1"> Редактировать</b-btn>
                            <cabinet-fl223-notice-card-publish-button :purchase="purchase" @publish="refresh" />
                            <cabinet-fl223-notice-card-cancel-button :purchase="purchase" @cancel="refresh" />
                            <cabinet-fl223-notice-card-unpublish-button :purchase="purchase" @unpublish="refresh" />
                            <b-button v-if="purchase.actions.customer.copy" class="btn-card-action btn-card-action-green my-1" @click="copyPurchase">Копировать</b-button>
                            <cabinet-fl223-notice-card-delete-button :purchase="purchase" @delete="refresh" />
                        </div>
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>

<script>
import TimerNew from '@/components/elements/TimerNew.vue';
import CabinetNoticeCardRowDual from '@/components/cabinets/partials/cabinet-notice-card-row-dual.vue';
import CabinetFl223NoticeCardPublishButton from '@/components/cabinets/fl223/cards/partials/cabinet-fl223-notice-card-publish-button.vue';
import CabinetFl223NoticeCardUnpublishButton from '@/components/cabinets/fl223/cards/partials/cabinet-fl223-notice-card-unpublish-button.vue';
import Fl223PurchaseCommentEditBlock from '@/components/cabinets/fl223/partials/fl223-purchase-comment-edit-block.vue';
import CabinetFl223NoticeCardDeleteButton from '@/components/cabinets/fl223/cards/partials/cabinet-fl223-notice-card-delete-button.vue';
import CabinetFl223NoticeCardCancelButton from '@/components/cabinets/fl223/cards/partials/cabinet-fl223-notice-card-cancel-button.vue';
import CabinetNoticeCardRowSingle from '@/components/cabinets/partials/cabinet-notice-card-row-single.vue';

export default {
    name: 'cabinet-fl223-notice-card',
    components: { CabinetNoticeCardRowSingle, CabinetFl223NoticeCardCancelButton, CabinetFl223NoticeCardDeleteButton, Fl223PurchaseCommentEditBlock, CabinetFl223NoticeCardUnpublishButton, CabinetFl223NoticeCardPublishButton, CabinetNoticeCardRowDual, TimerNew },
    props: ['purchase'],
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        async copyPurchase() {
            const response = await this.$api.products.fl223.purchases.copy(this.purchase.id);
            if (response && response.id) {
                await this.$router.push({ name: 'Fl223PurchaseEdit', params: { id: response.id } });
            }
        }
    },
    computed: {
        isPurchasePubliclyAvailable() {
            return !this.purchase.status.stage.includes('non_public');
        },
        statusColor() {
            switch (this.purchase.status.id) {
                case 'contract:unfulfilled':
                case 'contract:terminated':
                case 'cancelled':
                case 'failed':
                    return 'status_color_yellow';
                case 'accepting':
                    return 'status_color_grey';
                case 'summarizing':
                case 'summarized':
                    return 'status_color_red';

                case 'draft':
                case 'signed':
                case 'contract:active':
                case 'contract:fulfilled':
                default:
                    return 'status_color_green';
            }
        },
        statusIconClass() {
            switch (this.purchase.status.id) {
                case 'draft':
                case 'signed':
                case 'revoked':
                    return '';
                case 'accepting':
                    return 'atmo-icon-stopwatch';
                case 'summarizing':
                    return 'atmo-icon-summation';
                case 'summarized':
                    return 'atmo-icon-shield';
                case 'failed':
                case 'cancelled':
                    return 'atmo-icon-cancelled';
                case 'contract:active':
                    return 'atmo-icon-contract';
                case 'contract:fulfilled':
                    return 'atmo-icon-fulfilled';
                case 'contract:unfulfilled':
                    return 'atmo-icon-cancelled';
                case 'contract:terminated':
                    return 'atmo-icon-cancelled';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-subheader {
    background-color: #e4e6e8;
    padding: 12px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 5px 15px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}

.purchase_part-header {
    background-color: #959ba4;
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px !important;
}

.status_color_2,
.status_color_3,
.status_color_4 {
    background-color: var(--green-color);
}

.status_color_7,
.status_color_8,
.status_color_5,
.status_color_6 {
    background-color: #f6b33a;
}
</style>
<style>
.draft.purchase_part-header {
    background-color: var(--green-color);
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.draft .purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.draft .purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-header {
    background-color: #959ba4;
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
</style>
