<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <text-header-new>Пользователи организации</text-header-new>
                <div>
                    <b-btn :to="{ name: 'UserCreate' }" size="sm" variant="custom-green">
                        <b-icon aria-hidden="true" class="my-auto mr-2" icon="plus-circle" />
                        Добавить пользователя
                    </b-btn>
                </div>
            </b-col>
        </b-row>

        <div class="table-responsive">
            <b-pagination v-if="users && pagination.total > 0 && pagination.last_page > 1" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="my-1" />
            <b-table :busy="loading" :fields="fields" :items="users" :per-page="pagination.per_page" empty-text="Данные отсутствуют" show-empty>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(name)="row">
                    <b-icon v-if="row.item.is_blocked" v-b-popover.hover.v-danger="'Заблокирован'" class="mr-1" icon="lock-fill" variant="danger" />
                    {{ row.item.name }}
                </template>
                <template #cell(username)="row">
                    {{ row.item.username }}<br />
                    {{ row.item.email }}
                </template>
                <template #cell(created_at)="row">
                    {{ $formatDateTime(row.item.created_at) }}
                </template>
                <template #cell(actions)="row">
                    <div class="float-right">
                        <b-dropdown no-caret right size="sm" toggle-class="text-decoration-none" variant="link">
                            <template slot="button-content">
                                <b-icon icon="gear-fill" variant="secondary" />
                            </template>
                            <b-dropdown-item :to="{ name: 'UserEdit', params: { id: row.item.id } }" class="fs-14">Редактировать</b-dropdown-item>
                            <b-dropdown-item v-if="!row.item.is_blocked" class="fs-14" @click="blockUser(row.item)">Заблокировать</b-dropdown-item>
                            <b-dropdown-item v-if="row.item.is_blocked" class="fs-14" @click="unblockUser(row.item)">Разблокировать</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </template>
            </b-table>
            <b-pagination v-if="users && pagination.total > 0 && pagination.last_page > 1" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="my-1" @input="fillData" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UsersList',
    metaInfo: {
        title: 'Пользователи организации'
    },
    data() {
        return {
            loading: true,
            pagination: {},
            fields: [
                { key: 'name', label: 'ФИО пользователя' },
                { key: 'username', label: 'Логин / Email', tdClass: 'text-nowrap' },
                { key: 'post', sortField: 'post', label: 'Должность' },
                { key: 'actions', label: '', width: '1px' }
            ],
            users: [],
            sortOrder: [{ field: 'id', direction: 'asc' }],
            perPage: 10,
            showBlockModal: false,
            showUnblockModal: false,
            selectedUser: null,
            data: []
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        applyFilters() {
            this.loading = true;
            this.fillData();
        },
        async fillData() {
            this.loading = true;
            const response = await this.$api.cabinet.users.fetch({ page: this.pagination.current_page || 1 });
            this.users = response.data;
            this.pagination = response.meta;
            this.loading = false;
            this.$scrollToTop();
        },

        blockUser(user) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите заблокировать пользователя "' + user.name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'custom-danger',
                    okTitle: 'Заблокировать',
                    cancelVariant: 'custom-outline-secondary',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    bodyClass: 'fs-14',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async (value) => {
                    if (value) {
                        if (await this.$api.cabinet.users.block(user.id)) {
                            await this.fillData();
                        }
                    }
                });
        },

        unblockUser(user) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите разблокировать пользователя "' + user.name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'custom-danger',
                    okTitle: 'Разблокировать',
                    cancelVariant: 'custom-outline-secondary',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    bodyClass: 'fs-14',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async (value) => {
                    if (value) {
                        if (await this.$api.cabinet.users.unblock(user.id)) {
                            await this.fillData();
                        }
                    }
                });
        }
    }
};
</script>

<style>
.table-atmo {
    border-collapse: collapse;
}

.table-atmo thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table-atmo thead th.sortable:hover {
    color: var(--green-color) !important;
}

.table-atmo tbody td {
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

.table-atmo .active {
    color: var(--green-color);
}

.table-atmo .blocked {
    color: #f6b33a;
}

@media screen and (max-width: 1200px) {
    .main_text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .column-992 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .start-992 {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .add_btn {
        margin-top: 15px !important;
    }
}
</style>
