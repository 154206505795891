'use strict';

// WARNING: Temporary.
import ApplicationServiceLocator from '../../../ddriven/application/services/ApplicationServiceLocator';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import Config from '@core/js/ddriven/application/config/Config';
import OrganizationReliabilityReportVMDService from '@core/js/ddriven/domain/services/viewmodel-data/organization-reliability-report/OrganizationReliabilityReportVMD.service';
import { INNStateInvalid } from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportEditable.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import SignOrganizationReliabilityReportDownloadPopupController from './SignOrganizationReliabilityReportDownloadPopupController.viewmodel';
import ToastDataVO, { TToastType } from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';
import { sprintf } from 'sprintf-js';

interface IData {
    vmdservice: OrganizationReliabilityReportVMDService;
}

@Component
export default class OrganizationReliabilityReportController extends BaseViewModel {
    private viewmodelDataService?: OrganizationReliabilityReportVMDService;

    constructor() {
        super();
        this.name = 'OrganizationReliabilityReportController';
    }

    beforeCreate(): void {
        this.viewmodelDataService = new OrganizationReliabilityReportVMDService(this.$store.getters['rearchitected/users/user']);
    }

    async created() {
        this.$data.vmdservice = this.viewmodelDataService;
        await this.viewmodelDataService?.initialize();

        // this.$data.vmdservice.editable.inn = '7702070139'; // WARNING: Temporary.
    }

    data(): IData {
        return {
            vmdservice: this.viewmodelDataService!
        };
    }

    /**
     * Computed
     */
    get report_example_link() {
        return Config.get('theme.variables.links.other.reliability_report_example');
    }

    get is_form_visible(): boolean {
        return !this.$data.vmdservice.viewables.financials.is_empty && this.$data.vmdservice.viewables.financials.has_enough_balance;
    }

    get is_not_found_message_visble(): boolean {
        return this.$data.vmdservice.editable.is_inn_valid && !this.$data.vmdservice.viewables.is_loading.organization && !this.$data.vmdservice.viewables.is_found;
    }

    /**
     * Watch
     */
    @Watch('vmdservice.editable.inn')
    public async onEditableINNChanged() {
        this.viewmodelDataService?.resetLoadingState();
        this.$data.vmdservice.editable.inn_state !== INNStateInvalid.Invalid && (await this.viewmodelDataService?.search());
    }

    /**
     * Methods
     */
    public async invokeSignReportDownloadPopup() {
        const isDownloadConfirmed = await new Promise((resolve) => {
            this.$root.$emit('public:onoff:toggle', {
                id: SignOrganizationReliabilityReportDownloadPopupController.popupId,
                ison: true,
                respond: resolve,
                viewmodelDataService: this.viewmodelDataService
            } as IOnOffOnToggleEventPayload);
        });
        if (!isDownloadConfirmed) {
            return;
        }

        const message = sprintf('Вы успешно скачали отчет о надежности организации%s.', this.viewmodelDataService!.editable.has_to_email_report ? ` (дополнительно отправлен на email: ${this.viewmodelDataService!.editable.email})` : '');
        ApplicationServiceLocator.get('uitoasts').show(new ToastDataVO({ type: TToastType.Success, message: message }));

        this.viewmodelDataService?.resetSearch();
        await this.viewmodelDataService?.initialize();
    }

    /**
     * Prototype general methods.
     */
}
