<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-container class="d-flex flex-column justify-content-between h-100">
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mt-3 mb-4 main_text">Все новости</p>
                    </b-col>
                </b-row>
                <ul class="tabs" style="margin-top: 115px">
                    <li v-for="(tab, index) in tabs" :key="'key_' + tab.type_id" :class="{ 'active-tab': activeTab === index }" @click="setActiveTab(index, tab.type_id)">
                        {{ tab.type_title }}
                    </li>
                </ul>
            </b-container>
        </b-container>
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="greyBg">
            <empty-request v-if="!loading && !getActiveNews.length"></empty-request>
            <b-container style="padding-top: 40px">
                <b-row>
                    <b-col md="3" class="mb-5" v-for="(message, mIndex) in getActiveNews" :key="'message_' + message.type_id + '_' + mIndex">
                        <div class="slide h-100">
                            <div>
                                <div
                                    class="article_head d-flex"
                                    :class="{
                                        news_head: message.type_id === 1,
                                        work_head: message.type_id === 2,
                                        update_head: message.type_id === 3
                                    }">
                                    <img src="/images/lifebuoy.svg" alt="" />
                                    <router-link class="cursor-pointer" tag="p" :to="'/articles/' + message.id">{{ message.title }} </router-link>
                                </div>
                                <div class="article_body">
                                    <p class="m-0">
                                        {{ lengthControl(message.description, 120) }}
                                    </p>
                                </div>
                                <div class="article_footer">
                                    <span>{{ getDateFormat(message.published_at) }}</span>
                                    <router-link class="btn btn-light btn-sm fs-12 mt-1 px-4 fw-100" :to="'/articles/' + message.id" tag="button" style="text-transform: uppercase; color: var(--grey-color); background-color: #f5f5f7" variant="light" size="sm"> Подробнее </router-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import ArticlesApi from '@/services/api/Articles';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export default {
    name: 'ArticlesList',
    data() {
        return {
            loading: true,
            baseUrl: Config.get('api.config.legacy.url'),
            activeTab: 0,
            activeId: 0,
            tabs: [],
            articles: []
        };
    },
    created() {
        ArticlesApi.getArticles()
            .then((articles) => {
                this.articles = articles.articles;
                this.tabs = articles.tabs;
                this.tabs.unshift({
                    type_id: 0,
                    type_title: 'Все'
                });
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        setActiveTab(index, type_id) {
            this.activeTab = index;
            this.activeId = type_id;
        },
        lengthControl(str, length) {
            if (str.length <= length) {
                return str;
            } else {
                return str.slice(0, length) + '...';
            }
        },
        getDateFormat(date) {
            return moment.tz(date, 'Asia/Yekaterinburg').format('DD.MM.YYYY');
        }
    },
    computed: {
        getActiveNews() {
            return this.articles.filter((elem) => {
                if (this.activeId === 0) {
                    return elem;
                } else {
                    return elem.type_id === this.activeId;
                }
            });
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}

.slide {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
    height: auto;
}

.slide > div {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.article_head {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.article_body {
    padding: 20px;
}

.article_body p {
    font-size: 12px;
    line-height: 24px;
}

.article_footer {
    padding: 0 20px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: auto;
}

.article_footer span {
    color: var(--grey-color);
    font-size: 12px;
}

.news_head {
    background-color: var(--green-color);
}

.work_head {
    background-color: #f9cd45;
}

.update_head {
    background-color: var(--grey-color);
}

.article_head {
    padding: 25px;
}

.article_head p {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-left: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tabs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tabs li {
    border: 2px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    min-height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 8px;
    text-align: center;
}

.tabs li + li {
    margin-left: 40px;
}

.active-tab {
    color: var(--green-color) !important;
    background-color: #fff;
}

@media screen and (max-width: 568px) {
    .tabs {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 20px !important;
    }

    .tabs li {
        margin: 10px 0;
    }

    .tabs li + li {
        margin-left: 0px;
    }
}

@media screen and (max-width: 470px) {
    .main_text {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 370px) {
    .main_text {
        font-size: 32px !important;
    }
}
</style>
