var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-2", attrs: { fluid: "" } },
            [
              _c("b-container", [
                _c("p", { staticClass: "fs-40 fw-600 white-color my-2" }, [
                  _vm._v(
                    "Коммерческая закупка - № " +
                      _vm._s(_vm.purchase.reg_number)
                  ),
                ]),
                _c("p", { staticClass: "white-color fs-14" }, [
                  _c("b", [_vm._v("Статус")]),
                  _vm._v(": " + _vm._s(_vm.purchase.status.title)),
                ]),
              ]),
            ],
            1
          ),
          _vm.purchase.status.id === "cancelled" &&
          _vm.purchase.cancellation_info
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _vm.purchase.cancellation_info.reason
                        ? _c("text-row", {
                            attrs: {
                              compact: true,
                              label: "Причина отмены закупки",
                            },
                            model: {
                              value: _vm.purchase.cancellation_info.reason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "reason",
                                  $$v
                                )
                              },
                              expression: "purchase.cancellation_info.reason",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.cancellation_info.attachment
                        ? _c("text-row-single-document", {
                            attrs: {
                              compact: true,
                              label: "Документ с обоснованием отмены закупки",
                            },
                            model: {
                              value: _vm.purchase.cancellation_info.attachment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "attachment",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.cancellation_info.attachment",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Наименование",
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.purchase.customer.id },
                      },
                    },
                    model: {
                      value: _vm.purchase.customer.full_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "full_name", $$v)
                      },
                      expression: "purchase.customer.full_name",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ИНН",
                    },
                    model: {
                      value: _vm.purchase.customer.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "inn", $$v)
                      },
                      expression: "purchase.customer.inn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "КПП",
                    },
                    model: {
                      value: _vm.purchase.customer.kpp,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "kpp", $$v)
                      },
                      expression: "purchase.customer.kpp",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ОГРН",
                    },
                    model: {
                      value: _vm.purchase.customer.ogrn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "ogrn", $$v)
                      },
                      expression: "purchase.customer.ogrn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Юридический адрес",
                    },
                    model: {
                      value: _vm.purchase.customer.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "address", $$v)
                      },
                      expression: "purchase.customer.address",
                    },
                  }),
                  _vm.purchase.purchase_terms.contact_info
                    ? _c("text-row", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.purchase_terms.contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.purchase_terms,
                              "contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.purchase_terms.contact_info",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Объект закупки",
                      value: _vm.purchase.purchase_terms.purchase_object,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Предмет закупки",
                      value: _vm.purchase.purchase_terms.deliverable_group.name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Тип закупочной сессии",
                      value: _vm.purchase.purchase_terms.duration_type.name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label:
                        "Продолжительность закупочной сессии" +
                        _vm.purchase.purchase_terms.duration_type.suffix,
                      value: _vm.purchase.purchase_terms.duration,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Дата и время размещения извещения",
                      value:
                        _vm.purchase.purchase_terms.purchase_start_datetime,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Дата и время окончания приема предложений",
                      value: _vm.purchase.purchase_terms.purchase_end_datetime,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Вид оплаты",
                      value: _vm.purchase.purchase_terms.payment_form,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Условия оплаты",
                      value: _vm.purchase.purchase_terms.payment_term,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата заключения договора",
                      value:
                        _vm.purchase.purchase_terms
                          .planned_contract_conclusion_datetime,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата исполнения договора",
                      value:
                        _vm.purchase.purchase_terms
                          .planned_contract_execution_datetime,
                    },
                  }),
                  _c("text-row-price", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Сумма закупки",
                      value: _vm.purchase.purchase_terms.start_price,
                    },
                  }),
                  _c("text-row-single-document", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Проект договора",
                      value:
                        _vm.purchase.purchase_terms.contract_draft_attachment,
                    },
                  }),
                  _vm.purchase.additional_documents.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительные документы",
                        },
                        model: {
                          value: _vm.purchase.additional_documents,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "additional_documents", $$v)
                          },
                          expression: "purchase.additional_documents",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Требования к поставщикам")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Отсутствие в реестре недобросовестных поставщиков",
                      value: _vm.purchase.supplier_requirements.only_not_rnp
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                      value: _vm.purchase.supplier_requirements.only_smp
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label: "Дополнительные требования к участникам закупки",
                      value: _vm.purchase.supplier_requirements
                        .has_additional_requirements
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.purchase.supplier_requirements
                              .has_additional_requirements,
                          expression:
                            "purchase.supplier_requirements.has_additional_requirements",
                        },
                      ],
                    },
                    [
                      _c("section", { staticStyle: { width: "100%" } }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "atmo-content-table mb-0" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "atmo-ctc-width-45pct" },
                                    [_vm._v("Описание")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "atmo-ctc-width-45pct" },
                                    [
                                      _vm._v(
                                        "Причина или цель установки требования"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.purchase.supplier_requirements.additional,
                                  function (requirement, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "td",
                                        { staticClass: "atmo-ctc-width-45pct" },
                                        [
                                          _vm._v(
                                            _vm._s(requirement.description)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "atmo-ctc-width-45pct" },
                                        [_vm._v(_vm._s(requirement.reason))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.purchase.delivery_terms.addresses.length > 0 ||
                  _vm.purchase.delivery_terms.schedule
                    ? [
                        _c("text-header", [_vm._v("Условия поставки")]),
                        _vm.purchase.delivery_terms.addresses.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-sm": 4,
                                  "label-cols-lg": 4,
                                  label: "Адреса для доставки/самовывоза:",
                                  "label-class":
                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.purchase.delivery_terms.addresses,
                                    function (address, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "fs-14 grey-color my-auto col-form-label",
                                        },
                                        [_vm._v(_vm._s(address))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("text-row", {
                          attrs: {
                            compact: true,
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label:
                              "График поставки товаров (выполнения работ, оказания услуг)",
                            value: _vm.purchase.delivery_terms.schedule,
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }, [_vm._v("№")]),
                          _c("th", [
                            _vm._v("Наименование товара (работ, услуг)"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "160px" },
                            },
                            [_vm._v("Количество")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "100px" },
                            },
                            [_vm._v("Ед.изм")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "160px" },
                            },
                            [_vm._v("Цена за ед., руб.")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "140px" },
                            },
                            [_vm._v("Стоимость, руб.")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.purchase.deliverables,
                          function (item, index) {
                            return _c(
                              "fragment",
                              _vm._b({ key: item.id }, "fragment", item, false),
                              [
                                _c(
                                  "tr",
                                  {
                                    class: _vm.visible[index]
                                      ? "border-bottom-0"
                                      : "",
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c(
                                      "td",
                                      [
                                        _c("action-button-small", {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value: "collapse-" + index,
                                              expression: "'collapse-' + index",
                                            },
                                          ],
                                          attrs: {
                                            title:
                                              (!_vm.visible[index]
                                                ? "Показать"
                                                : "Скрыть") + " характеристики",
                                            icon: !_vm.visible[index]
                                              ? "eye"
                                              : "eye-slash",
                                          },
                                        }),
                                        _c("b-collapse", {
                                          staticClass: "d-none",
                                          attrs: { id: "collapse-" + index },
                                          model: {
                                            value: _vm.visible[index],
                                            callback: function ($$v) {
                                              _vm.$set(_vm.visible, index, $$v)
                                            },
                                            expression: "visible[index]",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.ktru_item.name) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [_vm._v(_vm._s(item.amount))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.ktru_item
                                                .okei_local_symbol ?? "—"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$formatPrice(item.price))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatPrice(item.total_price)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.visible[index],
                                        expression: "visible[index]",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "align-text-top",
                                        attrs: { colspan: "8" },
                                      },
                                      [
                                        item.ktru_item
                                          ? [
                                              _c("p", [
                                                _c("b", [
                                                  _vm._v("Рег.номер ТРУ:"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.ktru_item.reg_number
                                                    )
                                                ),
                                              ]),
                                              _c("p", [
                                                _c("b", [
                                                  _vm._v("Группа ТРУ:"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.ktru_item
                                                        .ktru_group_name
                                                    )
                                                ),
                                              ]),
                                              _c("p", [
                                                _c("b", [
                                                  _vm._v("Наименование:"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.ktru_item.name)
                                                ),
                                              ]),
                                              _c("p", [
                                                _c("b", [_vm._v("Тип:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.ktru_item.type)
                                                ),
                                              ]),
                                              item.ktru_item.okpd_code
                                                ? _c("p", [
                                                    _c("b", [
                                                      _vm._v("Код ОКПД2:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.ktru_item
                                                            .okpd_code
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              item.ktru_item.description
                                                ? _c("p", [
                                                    _c("b", [
                                                      _vm._v("Описание:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.ktru_item
                                                            .description
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              item.ktru_item.specs &&
                                              item.ktru_item.specs.length > 0
                                                ? _c("p", [
                                                    _c("b", [
                                                      _vm._v("Характеристики:"),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              item.ktru_item.specs &&
                                              item.ktru_item.specs.length > 0
                                                ? _c(
                                                    "ul",
                                                    _vm._l(
                                                      item.ktru_item.specs,
                                                      function (
                                                        spec,
                                                        specIndex
                                                      ) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key:
                                                              index +
                                                              "_spec_" +
                                                              specIndex,
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  spec.name
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " - " +
                                                                _vm._s(
                                                                  spec.value
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          : [_vm._v("—")],
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ]),
                  ]),
                  _c("private-commercial-proposals-block", {
                    attrs: { purchase: _vm.purchase },
                  }),
                  _c("public-commercial-protocol", {
                    attrs: { protocol: _vm.purchase.protocol },
                  }),
                  _c("private-commercial-winner-selection-block", {
                    attrs: { purchase: _vm.purchase },
                    on: { confirm: _vm.refresh, refresh: _vm.refresh },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticClass: "my-4",
              attrs: { fluid: "", id: "action_buttons_row" },
            },
            [
              _c(
                "b-container",
                { staticClass: "mb-5" },
                [
                  _vm.$store.getters.isLoggedIn
                    ? _c("commercial-new-proposal-button", {
                        attrs: { noticeitem: _vm.purchase },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }