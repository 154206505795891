<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="grey-color font-weight-bold my-3" :class="headerClass">{{ title }}</h2>
            <file-uploader :is-disabled="isLoading" class="fix-pos" :is-main="false" @set_file="addFile">Загрузить</file-uploader>
        </div>

        <div class="table-responsive py-2" :class="v.$invalid ? 'is-invalid' : ''">
            <table class="table">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th width="1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in documents" :key="index">
                        <td>
                            <a class="fs-14 col-form-label d-inline-block" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
                        </td>
                        <td>
                            <action-button-small :disabled="isLoading" icon="trash" @click="removeFile(index, true)" />
                        </td>
                    </tr>
                    <tr v-if="documents.length === 0">
                        <td colspan="2" align="center">Не добавлено документов</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-if="v.$invalid" class="invalid-feedback">Прикрепите документ</p>
    </div>
</template>

<script>
export default {
    name: 'FormRowUploadMultipleDocuments',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        v: {
            type: Object,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        headerClass: {
            type: String,
            default: 'fs-32'
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: []
        };
    },
    methods: {
        addFile(file) {
            this.documents.push(file);
            this.$emit('add', file);
        },
        removeFile(index) {
            this.$emit('remove', this.documents[index]);
            this.documents.splice(index, 1);
        }
    },
    computed: {
        documents: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style scoped>
.table-responsive {
    padding-left: 5px;
    padding-right: 5px;
}
.fix-pos {
    margin-left: 5px;
    margin-right: 5px;
}
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 8px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

a {
    color: var(--green-color) !important;
}
</style>
