<template>
    <div class="create">
        <form-header title="Запрос импортозамещения" />
        <form-content-group title="Информация о запросе">
            <form-row-single-select v-model="form.purchase_category_id" :options="purchaseCategories" :v="$v.form.purchase_category_id" label="Предмет запроса" placeholder="Выберите предмет запроса" />
            <form-row-edit-date v-model="form.deadline" :minDate="new Date().toJSON().slice(0, 10)" :v="$v.form.deadline" label="Срок сбора предложений" placeholder="Укажите срок сбора предложений" />
        </form-content-group>
        <import-substitution-request-create-ktru-item-block v-model="form.ktru_item" :v="$v.form.ktru_item" title="ТРУ для импортозамещения" />
        <form-row-single-document-upload class="container" v-model="form.stored_document" :v="$v.form.stored_document" title="Техническое описание" />
        <b-container>
            <form-action-buttons-group>
                <b-button class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="onCancel">Отменить</b-button>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-green" @click="sendData">Разместить запрос</b-button>
                </b-overlay>
            </form-action-buttons-group>
        </b-container>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minValue, required } from 'vuelidate/lib/validators';
import FormHeader from '@/components/common/form-rows/form-header';
import FormContentGroup from '@/components/common/form-rows/form-content-group';
import FormRowEditDate from '@/components/common/form-rows/form-row-edit-date';
import FormRowSingleDocumentUpload from '@/components/common/form-rows/form-row-single-document-upload';
import FormActionButtonsGroup from '@/components/common/form-rows/form-action-buttons-group';
import ImportSubstitutionRequestCreateKtruItemBlock from '@/components/import-substitution/import-substitution-request-create-ktru-item-block';
// import FormRowInviteProvidersSelect from '@/components/common/form-rows/form-row-invite-providers-select';

export default {
    name: 'ImportSubstitutionRequestCreate',
    mixins: [validationMixin],
    components: {
        ImportSubstitutionRequestCreateKtruItemBlock,
        // FormRowInviteProvidersSelect,
        FormActionButtonsGroup,
        FormRowSingleDocumentUpload,
        FormRowEditDate,
        FormContentGroup,
        FormHeader
    },
    data() {
        return {
            loading: true,
            form: {
                purchase_category_id: null,
                deadline: null,
                stored_document: null,
                ktru_item: null
            },
            isDataSending: false,
            purchaseCategories: []
        };
    },
    validations: {
        form: {
            purchase_category_id: { required },
            deadline: { required },
            stored_document: { required },
            ktru_item: {
                required,
                price: {
                    required,
                    minValue: minValue(0.01)
                }
            }
        }
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            const responseData = await this.$api.directories.purchaseCategories.getSelectItems();
            this.purchaseCategories = responseData.data.filter((item) => item.is_active);
        },
        onCancel() {
            this.$router.push({ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }).catch(() => {});
        },
        async sendData() {
            this.isDataSending = true;
            if (
                await this.$api.importSubstitutionRequests.store({
                    purchase_category_id: this.form.purchase_category_id,
                    ktru_item_id: this.form.ktru_item.id,
                    price: this.form.ktru_item.price,
                    deadline: this.form.deadline,
                    stored_document_id: this.form.stored_document.id
                })
            ) {
                this.onCancel();
            }
            this.isDataSending = false;
        }
    }
};
</script>
