<template>
    <div>
        <b-row>
            <b-col md="7" class="d-flex justify-content-between">
                <h3 class="grey-color mb-3 main_text font-weight-bold">Контракты 44-ФЗ</h3>
            </b-col>
            <b-col md="5">
                <b-nav class="atmo-status-menu-controller atmo-menu nav-menu">
                    <b-nav-item v-for="(tab, cIndex) in tabs" :key="'category_' + cIndex" :to="{ name: 'ProviderContractsCabinet', params: { cat: tab.path } }" exact exact-active-class="disabled">{{ tab.title }}</b-nav-item>
                </b-nav>
            </b-col>
        </b-row>

        <b-row class="mb-2 cabinet_filter">
            <filter-new-multiple-select v-model="filter.status" :options="filterStatuses" label="Статус закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" />
            <filter-new-text v-model="filter.regNumber" label="Рег.номер закупки" md="6" placeholder="Введите рег.номер закупки" />
            <filter-new-multiple-select v-model="filter.purchaseCategories" :options="$globalDictionaries.deliverableGroups" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />
            <filter-new-text v-model="filter.customer" label="Заказчик" md="6" placeholder="Введите название организации или ИНН" />

            <b-col class="my-2 text-right" lg="12" md="12">
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getContracts(true)">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
            </b-col>
        </b-row>

        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" />

            <empty-request v-if="isLoading || !contracts.length" />
            <template v-else v-for="contract in contracts">
                <provider-fl44-external-purchase-card v-if="contract.type === 'fl44_external'" :key="`${contract.type}_${contract.id}`" :contract="contract" @refresh="getContracts" />
                <provider-fl44-contract-card v-else :contract="contract" :key="`${contract.type}_${contract.id}`" @refresh="getContracts" />
            </template>

            <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" @input="getContracts()" />
        </b-overlay>
    </div>
</template>

<script>
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';
import ProviderFl44ContractCard from "@/components/cabinets/provider/contracts/partials/provider-fl44-contract-card.vue";
import ProviderFl44ExternalPurchaseCard from "@/components/cabinets/provider/contracts/partials/provider-fl44-external-purchase-card.vue";

const emptyFilter = {
    status: [],
    purchaseCategories: [],
    regNumber: '',
    customer: '',
    type: ''
};
export default {
    name: 'ProviderContractsList',
    components: {
        ProviderFl44ExternalPurchaseCard,
        ProviderFl44ContractCard,
        FilterNewText,
        FilterNewMultipleSelect,
    },
    data() {
        return {
            tabs: [
                { title: 'Все закупки', path: 'all', id: '' },
                { title: 'Закупки в ИС', path: 'in_atmo', id: 'fl44' },
                { title: 'Внесистемные закупки', path: 'outside_atmo', id: 'fl44_external' }
            ],
            filterStatuses: [
                { name: 'Договор заключен', id: 'contract:active' },
                { name: 'Договор исполнен', id: 'contract:fulfilled' },
                { name: 'Договор не исполнен', id: 'contract:unfulfilled' },
                { name: 'Договор расторгнут', id: 'contract:terminated' },
            ],
            contracts: [],
            isLoading: false,
            limit: 10,
            count: 0,
            currentPage: 1,
            number: '',
            id: null,
            showDeleteModal: false,
            showPublishModal: false,
            showCancelModal: false,
            filter: {
                ...emptyFilter
            },
            forFilters: {
                purchaseCategories: []
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 10
            }
        };
    },
    async mounted() {
        await this.fillFilters();
        await this.getContracts();
        this.$scrollToTop();
    },
    watch: {
        $route: 'getContracts'
    },
    methods: {
        async fillFilters() {
            this.forFilters.purchaseCategories = this.$globalDictionaries.deliverableGroups;
        },
        getFilters(page = true) {
            this.filter.type = this.tabs.find((value) => value.path === this.$route.params.cat).id;
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1,
            };
        },
        async getContracts(page = true) {
            this.isLoading = true;
            const contractsData = await this.$api.cabinet.provider.contracts.getList(this.getFilters(page));
            this.contracts = contractsData.data;
            this.meta = contractsData.meta;
            this.isLoading = false;
        },
        async cleanFilters() {
            this.filter = { ...emptyFilter };
            await this.getContracts();
        }
    },
};
</script>
<style scoped>
.nav-link,
.nav-item,
.nav-menu {
    padding: 0 !important;
    margin-bottom: -0.04rem !important;
}

.nav-item .nav-link {
    color: #666;
}

.nav-item .nav-link:hover {
    color: #444;
}

.nav-menu {
    font-family: unset;
    font-weight: unset;
    font-size: 14px;
    color: #666666;
}

.nav-menu :hover {
    color: #555555;
}

.nav-link.disabled {
    color: #26b89a !important;
    border-bottom: 2px solid #26b89a;
    font-weight: 600;
}

input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}
</style>
