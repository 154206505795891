var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-5", attrs: { fluid: "" } },
        [
          _c("b-container", [
            _c("p", { staticClass: "fs-52 fw-600 white-color mt-2 mb-4" }, [
              _vm._v("Сброс пароля"),
            ]),
          ]),
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "b-container",
            { staticClass: "pl-0 py-4" },
            [
              _vm.errorMessage
                ? _c("b-container", [
                    _c(
                      "p",
                      { staticClass: "fs-32 fw-600 grey-color mt-2 mb-4" },
                      [_vm._v("Не удалось сбросить пароль.")]
                    ),
                    _c(
                      "p",
                      { staticClass: "fs-32 fw-400 text-danger mt-2 mb-4" },
                      [_vm._v(_vm._s(_vm.errorMessage))]
                    ),
                  ])
                : _c("b-container", [
                    _c(
                      "p",
                      { staticClass: "fs-32 fw-600 grey-color mt-2 mb-4" },
                      [_vm._v("Произведён сброс пароля Вашей учётной записи.")]
                    ),
                    _c(
                      "p",
                      { staticClass: "fs-32 fw-400 grey-color mt-2 mb-4" },
                      [_vm._v("На Ваш Email отправлен новый пароль.")]
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }