<template>
    <div>
        <template v-if="!isShowDetails">
            <b-row>
                <b-col md="6">
                    <h3 class="grey-color main_text font-weight-bold">Лимиты бюджетных обязательств</h3>
                </b-col>
                <b-col class="text-right" md="6">
                    <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
                        <span class="d-inline-block" tabindex="0" v-b-popover.hover.top="'Обновление доступно не чаще чем раз в 30 минут'">
                            <b-button :disabled="!allowRefresh" variant="custom-green" size="sm" @click="refreshLimits()">
                                <b-icon icon="arrow-clockwise" class="my-auto mr-2" aria-hidden="true" />
                                Обновить
                            </b-button>
                        </span>
                    </b-overlay>
                </b-col>
            </b-row>

            <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
                <b-row class="cabinet_filter">
                    <b-col md-12>
                        <b-row>
                            <FilterNewMultipleSelect v-model="filter.ppp_plan_year" :options="forFilters.pppPlanYears" label="Год ПГ" label-class="grey-color" label-field="name" lg="6" md="6" placeholder="Выберите год ПГ" />
                            <FilterNewText v-model="filter.kbk" label="КБК" md="6" placeholder="Введите КБК" @enter="getLimits(false)" />
                            <FilterNewMultipleSelect v-model="filter.order_type_id" :options="$globalDictionaries.orderTypesWithLimits" label="Тип закупки" label-class="grey-color" label-field="title" lg="6" md="6" placeholder="Выберите тип закупки" />
                            <FilterNewText v-model="filter.funds_type" label="Код типа средств" md="6" placeholder="Введите код типа средств" @enter="getLimits(false)" />
                            <FilterNewText v-model="filter.action_code" label="Код мероприятия" md="6" placeholder="Введите код мероприятия" @enter="getLimits(false)" />
                            <FilterNewText v-model="filter.subkosgu_code" label="Код СубКОСГУ" md="6" placeholder="Введите код СубКОСГУ" @enter="getLimits(false)" />
                        </b-row>
                        <b-row>
                            <b-col class="my-2 text-right" lg="12" md="12">
                                <b-btn class="mr-2" variant="custom-green" @click.prevent="getLimits(false)">Найти</b-btn>
                                <b-btn variant="custom-outline-secondary" @click.prevent="cleanFilters">Сброс</b-btn>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-pagination v-if="limits && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" />
                <b-table :busy="isLoading" :fields="fields" :items="limits" class="table-atmo" empty-text="Нет данных" show-empty>
                    <template #cell(total_amount)="row">
                        {{ $formatPrice(row.item.total_amount) }}
                    </template>
                    <template #cell(subkosgu_code)="row">
                        {{ row.item.subkosgu_code ?? '&mdash;' }}
                    </template>
                    <template #cell(funds_type)="row">
                        {{ row.item.funds_type ?? '&mdash;' }}
                    </template>
                    <template #cell(action_code)="row">
                        {{ row.item.action_code ?? '&mdash;' }}
                    </template>
                    <template #cell(actions)="row">
                        <action-button-small icon="eye" @click="showDetails(row.item)" />
                    </template>
                </b-table>
                <b-pagination v-if="limits && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="getLimits()" />
            </b-overlay>
        </template>
        <template v-else>
            <text-header-new>Информация о лимите</text-header-new>
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.ppp_plan_year" force-show label="Год ПГ" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.ppp_year_ident" force-show label="Год размещения извещения об осуществлении закупки" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.order_type_name" force-show label="Тип закупки" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.ppp_ident_no" force-show label="ИКЗ" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.kbk" force-show label="КБК" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.funds_type" force-show label="Код типа средств" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.action_code" force-show label="Код мероприятия" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="currentLimit.limit.subkosgu_code" force-show label="СубКОСГУ" />
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="$formatPrice(currentLimit.limit.total_amount)" label="Общая сумма, руб." />
            <b-form-group :label-cols-lg="6" :label-cols-sm="6" class="mb-0" label="Сумма по годам (доступно / всего), руб.:" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                <p v-for="record in filterOutZeroAmount(currentLimit.limit.available_by_years)" :key="currentLimit.limit.id + '_' + record.year" class="fs-14 grey-color my-auto col-form-label">
                    <b>{{ record.year }}</b
                    >: {{ $formatPrice(record.amount_available) }} / {{ $formatPrice(record.amount_total) }}
                </p>
            </b-form-group>
            <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" :value="$formatDateTime(currentLimit.limit.updated_at)" label="	Дата/время обновления" />

            <text-header-new>Использование лимита</text-header-new>
            <b-pagination v-if="currentLimit.limit.used_purchases && currentLimit.meta.total > 0 && currentLimit.meta.last_page > 1" v-model="currentLimit.meta.current_page" :per-page="currentLimit.meta.per_page" :total-rows="currentLimit.meta.total" align="center" class="my-1" />
            <b-table :current-page="currentLimit.meta.current_page" :fields="currentLimit.fields" :items="currentLimit.limit.used_purchases" :per-page="currentLimit.meta.per_page" class="table-atmo" empty-text="Нет данных" show-empty>
                <template #cell(purchase_reg_number)="row">
                    <router-link v-if="row.item.purchasable_type === 'purchase'" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: row.item.purchasable_id } }" class="atmo-button-link" target="_blank">{{ row.item.purchase_reg_number  }}</router-link>
                    <router-link v-else :to="{ name: 'ExternalPurchaseShow', params: { id: row.item.purchasable_id } }" class="atmo-button-link" target="_blank">{{ row.item.purchase_reg_number }}</router-link>
                </template>
                <template #cell(amount)="row">
                    {{ $formatPrice(row.item.amount) }}
                </template>
                <template #cell(created_at)="row">
                    {{ $formatDateTime(row.item.created_at) }}
                </template>
            </b-table>
            <b-pagination v-if="currentLimit.limit.used_purchases && currentLimit.meta.total > 0 && currentLimit.meta.last_page > 1" v-model="currentLimit.meta.current_page" :per-page="currentLimit.meta.per_page" :total-rows="currentLimit.meta.total" align="center" class="my-1" />
            <b-button class="mt-2" variant="custom-green" @click="isShowDetails = false">Назад</b-button>
        </template>
    </div>
</template>

<script>
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import range from 'lodash.range';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';

export default {
    name: 'Fl44LimitsList',
    components: { FilterNewText, FilterNewMultipleSelect },
    metaInfo: {
        title: 'Лимиты бюджетных обязательств'
    },
    data() {
        return {
            isLoading: true,
            limits: [],
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            },
            allowRefresh: false,
            fields: [
                { key: 'ppp_plan_year', label: 'Год ПГ' },
                { key: 'kbk', label: 'КБК' },
                { key: 'subkosgu_code', label: 'СубКОСГУ' },
                { key: 'funds_type', label: 'Код типа средств' },
                { key: 'action_code', label: 'Код мероприятия' },
                { key: 'total_amount', label: 'Общая сумма, руб.', tdClass: 'text-right', thClass: 'text-right' },
                { key: 'actions', label: '', tdClass: 'my-auto text-right' }
            ],
            isShowDetails: false,
            currentLimit: {
                limit: {},
                meta: {
                    current_page: 1,
                    total: 0,
                    per_page: 15,
                    last_page: 1
                },
                fields: [
                    { key: 'purchase_reg_number', label: 'Закупка' },
                    { key: 'year', label: 'Год финансирования' },
                    { key: 'amount', label: 'Сумма, руб.' },
                    { key: 'created_at', label: 'Дата/время' }
                ]
            },
            isFiltersVisible: false,
            forFilters: {
                pppPlanYears: []
            },
            filter: {
                ppp_plan_year: [],
                order_type_id: [],
                kbk: '',
                funds_type: '',
                action_code: '',
                subkosgu_code: ''
            }
        };
    },
    async mounted() {
        await this.fillFilters();
        await this.getLimits();
        this.$scrollToTop();
    },
    methods: {
        async fillFilters() {
            range(2019, new Date().getFullYear() + 2).forEach((year) => {
                this.forFilters.pppPlanYears.push({ id: year, name: year });
            });
        },
        getFilters(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1,
            };
        },
        async getLimits(page = true) {
            this.isLoading = true;
            const response = await this.$api.personal.limits.getLimitsList(this.getFilters(page));
            this.limits = response.data;
            this.meta = response.meta;
            this.allowRefresh = response.allow_refresh;
            this.isLoading = false;
        },
        async refreshLimits() {
            this.isLoading = true;
            if (await this.$api.personal.limits.refreshLimits()) {
                await this.getLimits(false);
            } else {
                this.isLoading = false;
            }
        },
        showDetails(row) {
            this.currentLimit.limit = row;
            this.currentLimit.meta.current_page = 1;
            this.currentLimit.meta.total = row.used_purchases.length;
            this.currentLimit.meta.last_page = Math.ceil(this.currentLimit.meta.total / this.currentLimit.meta.per_page);
            this.isShowDetails = true;
        },
        filterOutZeroAmount(amountRecords) {
            return amountRecords.filter((elem) => {
                return elem.amount_total > 0;
            });
        },
        async cleanFilters() {
            this.filter.ppp_plan_year = [];
            this.filter.order_type_id = [];
            this.filter.kbk = '';
            this.filter.funds_type = '';
            this.filter.subkosgu_code = '';
            this.filter.action_code = '';
            await this.getLimits(false);
        }
    }
};
</script>
