var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-offer-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v("Подача предложения на закупку 44ФЗ "),
                            _c("br"),
                            _vm._v("№ " + _vm._s(_vm.purchase.reg_number)),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _vm._v("Форма подачи предложения"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-container", [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "fs-14 my-1 my-3",
                    staticStyle: { color: "white !important" },
                    attrs: {
                      variant: "danger",
                      size: "lg",
                      href: "https://app.hinted.me/simulations/669e4103-056e-40fd-9586-f0e0c63a2bba/view",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mx-1",
                      staticStyle: { height: "16px" },
                      attrs: {
                        src: "/images/youtube.svg",
                        alt: "documentation",
                      },
                    }),
                    _vm._v(" Инструкция по формированию и подаче предложения"),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: "",
                      label: "Наименование",
                      value: _vm.purchase.customer.name,
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.purchase.customer.id },
                      },
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "ИНН",
                      value: _vm.purchase.customer.inn,
                    },
                  }),
                  _vm.purchase.customer.kpp
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "КПП",
                          value: _vm.purchase.customer.kpp,
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.customer.ogrn
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "ОГРН",
                          value: _vm.purchase.customer.ogrn,
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.additional_contact_info
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "Дополнительная контактная информация",
                          value: _vm.purchase.additional_contact_info,
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Дата и время размещения закупки",
                      value: _vm.purchase.purchase_start_datetime,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Дата и время окончания приема предложений",
                      value: _vm.purchase.purchase_end_datetime,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Предмет закупки",
                      value:
                        _vm.purchase.terms_of_purchase.purchase_category_name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Объект закупки",
                      value: _vm.purchase.terms_of_purchase.purchase_object,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Тип закупки",
                      value: _vm.purchase.terms_of_purchase.order_type_name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Продолжительность закупочной сессии",
                      value: _vm.purchase.terms_of_purchase.duration_type_title,
                    },
                  }),
                  _vm.purchase.terms_of_purchase.with_advance
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "Размер аванса, %",
                          value:
                            _vm.purchase.terms_of_purchase.advance_percentage,
                        },
                      })
                    : _vm._e(),
                  _c("text-row-price", {
                    attrs: {
                      compact: "",
                      label: "Начальная цена контракта",
                      value: _vm.purchase.start_price,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: "",
                      label: "Планируемая дата заключения договора",
                      value:
                        _vm.purchase.terms_of_purchase.planned_contract_date,
                    },
                  }),
                  _c("text-row-documents", {
                    attrs: {
                      compact: "",
                      label: "Проект договора",
                      value: _vm.purchase.contract_project_document,
                    },
                  }),
                  _vm.purchase.additional_documents.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          compact: "",
                          label: "Дополнительные документы",
                          value: _vm.purchase.additional_documents,
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.terms_of_purchase.additional_info
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label:
                            "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ",
                          value: _vm.purchase.terms_of_purchase.additional_info,
                        },
                      })
                    : _vm._e(),
                  (_vm.purchase.terms_of_delivery.delivery_addresses &&
                    _vm.purchase.terms_of_delivery.delivery_addresses.length >
                      0) ||
                  _vm.purchase.terms_of_delivery.delivery_information
                    ? [
                        _c("text-header", [_vm._v("Условия поставки")]),
                        _vm.purchase.terms_of_delivery.delivery_addresses &&
                        _vm.purchase.terms_of_delivery.delivery_addresses
                          .length > 0
                          ? _c("text-row-addresses", {
                              attrs: {
                                compact: "",
                                label:
                                  "Адреса для доставки товаров/выполнения работ/оказания услуг",
                              },
                              model: {
                                value:
                                  _vm.purchase.terms_of_delivery
                                    .delivery_addresses,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.terms_of_delivery,
                                    "delivery_addresses",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.terms_of_delivery.delivery_addresses",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.terms_of_delivery.delivery_information
                          ? _c("text-row", {
                              attrs: {
                                compact: "",
                                label:
                                  "График поставки товаров (выполнения работ, оказания услуг)",
                                value:
                                  _vm.purchase.terms_of_delivery
                                    .delivery_information,
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c("text-header", [
                    _vm._v("Требования к участникам закупки"),
                  ]),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      "label-cols-sm": 11,
                      "label-cols-lg": 11,
                      "value-class": "text-right mr-2",
                      label:
                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                      value: _vm.purchase.supplier_requirements.only_msp
                        ? "Да"
                        : "Нет",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      "label-cols-sm": 11,
                      "label-cols-lg": 11,
                      "value-class": "text-right mr-2",
                      label:
                        "Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла",
                      value: _vm.purchase.supplier_requirements
                        .supplier_reqs_rating
                        ? "Да"
                        : "Нет",
                    },
                  }),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c(
                        "b-form-group",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_a
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_a.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_a,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_a",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_a",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c(
                        "div",
                        {
                          staticClass: "form-row",
                          staticStyle: { "word-break": "break-all" },
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                              _c("strong", [
                                _vm._v(
                                  "Участник закупки должен соответствовать следующим требованиям"
                                ),
                              ]),
                              _vm._v(
                                " - " +
                                  _vm._s(
                                    _vm.purchase.supplier_requirements
                                      .supplier_reqs_a_text
                                  )
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                            _c("strong", [
                              _vm._v(
                                "Участник закупки должен подтвердить соответствие указанным требованиям, приложив следующие документы:"
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.purchase.supplier_requirements
                      .supplier_required_documents,
                    function (item, index) {
                      return _c("form-row-single-line-document-upload", {
                        key: index,
                        attrs: {
                          title: item.name,
                          v: _vm.$v.form.supplier_requirements
                            .supplier_required_documents.$each[index]
                            .storedDocument,
                        },
                        model: {
                          value:
                            _vm.form.supplier_requirements
                              .supplier_required_documents[index]
                              .storedDocument,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.supplier_requirements
                                .supplier_required_documents[index],
                              "storedDocument",
                              $$v
                            )
                          },
                          expression:
                            "form.supplier_requirements.supplier_required_documents[index].storedDocument",
                        },
                      })
                    }
                  ),
                  _vm.purchase.supplier_requirements.supplier_reqs_b
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_b
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_b.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_b,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_b",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_b",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_c
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_c
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_c.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_c,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_c",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_c",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_sonko
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements
                                .supplier_reqs_sonko.$invalid,
                            label:
                              "Подтверждаю, оргчнизация является социально ориентированной некоммерческой организацией (СОНКО)",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_sonko.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements
                                  .supplier_reqs_sonko,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_sonko",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_sonko",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.additional.state[0]
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                            _c("strong", [
                              _vm._v(
                                "Участник должен подтвердить соответствие следующим дополнительным требованиям, приложив документы:"
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.purchase.supplier_requirements.additional.requirements,
                    function (requirement, index) {
                      return _c("form-row-single-line-document-upload", {
                        key: `req-additional-${index}`,
                        attrs: {
                          title: `${index + 1}. ${
                            requirement.description
                          } (причина: ${requirement.reason})`,
                          v: _vm.$v.form.supplier_requirements.additional
                            .requirements.$each[index].attachment,
                        },
                        model: {
                          value:
                            _vm.form.supplier_requirements.additional
                              .requirements[index].attachment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.supplier_requirements.additional
                                .requirements[index],
                              "attachment",
                              $$v
                            )
                          },
                          expression:
                            "form.supplier_requirements.additional.requirements[index].attachment",
                        },
                      })
                    }
                  ),
                  _c("text-header", [_vm._v("Информация об организации")]),
                  _c("form-row-edit-text", {
                    attrs: {
                      label: "Контактное лицо поставщика",
                      v: _vm.$v.form.contact_info,
                    },
                    model: {
                      value: _vm.form.contact_info,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact_info", $$v)
                      },
                      expression: "form.contact_info",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    attrs: {
                      "label-cols-sm": 3,
                      "label-cols-lg": 3,
                      title: "Карточка предприятия",
                      v: _vm.$v.form.organization_card,
                    },
                    model: {
                      value: _vm.form.organization_card,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "organization_card", $$v)
                      },
                      expression: "form.organization_card",
                    },
                  }),
                  _c("form-row-edit-checkbox", {
                    attrs: { label: "Организация освобождена от уплаты НДС" },
                    model: {
                      value: _vm.form.without_vat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "without_vat", $$v)
                      },
                      expression: "form.without_vat",
                    },
                  }),
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c(
                    "b-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                { attrs: { rowspan: "2", width: "1" } },
                                [_vm._v("№")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: { "min-width": "400px" },
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v("Наименование товара (работ, услуг)")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: {
                                    "text-align": "center",
                                    "border-right": "2px solid #dee2e6",
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v("Извещение")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: "3" },
                                },
                                [_vm._v("Предложение")]
                              ),
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { width: "110px" } }, [
                                _vm._v("Количество /"),
                                _c("br"),
                                _vm._v("Ед.изм"),
                              ]),
                              _c("th", { attrs: { width: "110px" } }, [
                                _vm._v("Цена за единицу /"),
                                _c("br"),
                                _vm._v("Стоимость"),
                              ]),
                              _c("th", { attrs: { width: "160px" } }, [
                                _vm._v("Цена за ед. с НДС, руб."),
                              ]),
                              _c("th", { attrs: { width: "175px" } }, [
                                _vm._v("НДС, %"),
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  attrs: { width: "140px" },
                                },
                                [_vm._v("Стоимость с НДС, руб.")]
                              ),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.purchase.specifications,
                              function (item, index) {
                                return _c("fragment", { key: item.id }, [
                                  _c(
                                    "tr",
                                    {
                                      class: _vm.visible[index]
                                        ? "border-bottom-0 border-top-0"
                                        : "",
                                    },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [_vm._v(_vm._s(index + 1))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [
                                          _c("action-button-small", {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle",
                                                value: "collapse-" + index,
                                                expression:
                                                  "'collapse-' + index",
                                              },
                                            ],
                                            attrs: {
                                              title:
                                                (!_vm.visible[index]
                                                  ? "Показать"
                                                  : "Скрыть") +
                                                " характеристики",
                                              icon: !_vm.visible[index]
                                                ? "eye"
                                                : "eye-slash",
                                            },
                                          }),
                                          _c("b-collapse", {
                                            staticClass: "d-none",
                                            attrs: { id: "collapse-" + index },
                                            model: {
                                              value: _vm.visible[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.visible,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression: "visible[index]",
                                            },
                                          }),
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap",
                                        },
                                        [
                                          _vm._v(_vm._s(item.count)),
                                          _c("br"),
                                          _vm._v(_vm._s(item.okei_name)),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.price > 0
                                                ? _vm.$formatPrice(item.price)
                                                : "—"
                                            )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              item.price > 0
                                                ? _vm.$formatPrice(
                                                    _vm.purchaseSpecificationItemPriceTotal(
                                                      item.price,
                                                      item.count
                                                    )
                                                  )
                                                : "—"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("currency-input", {
                                                directives: [
                                                  {
                                                    name: "stop-number-mousewheel",
                                                    rawName:
                                                      "v-stop-number-mousewheel",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control fs-14",
                                                class: _vm.$v.form.items.$each[
                                                  item.id
                                                ].price.$invalid
                                                  ? "is-invalid"
                                                  : "is-valid",
                                                staticStyle: { width: "140px" },
                                                attrs: {
                                                  "allow-negative": false,
                                                  currency: "RUB",
                                                  locale: "ru",
                                                },
                                                on: {
                                                  input:
                                                    _vm.recalculateTotalSum,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.items[item.id]
                                                      .price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.items[item.id],
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.items[item.id].price",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-top ws-nowrap" },
                                        [
                                          _vm.form.without_vat
                                            ? [
                                                _c("input", {
                                                  staticClass:
                                                    "form-control fs-14",
                                                  attrs: {
                                                    disabled: "",
                                                    type: "text",
                                                    value: "Без НДС",
                                                  },
                                                }),
                                              ]
                                            : [
                                                _c("v-select", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.$v.form.items.$each[
                                                        item.id
                                                      ].vat.$invalid,
                                                    "d-inline-block": true,
                                                    "w-75": true,
                                                  },
                                                  attrs: {
                                                    label: "title",
                                                    options:
                                                      _vm.dictionaries.vat,
                                                    reduce: (item) => item.id,
                                                    "append-to-body": "",
                                                    "calculate-position":
                                                      _vm.withPopper,
                                                    searchable: false,
                                                    clearable: false,
                                                    placeholder: "НДС",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "no-options",
                                                        fn: function ({
                                                          search,
                                                          searching,
                                                          loading,
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              " Записей, соответствующих вашему запросу, не найдено. "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.form.items[item.id]
                                                        .vat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.items[item.id],
                                                        "vat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.items[item.id].vat",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-popover",
                                                        rawName:
                                                          "v-b-popover.hover.top",
                                                        value:
                                                          "Применить ко всем позициям спецификации",
                                                        expression:
                                                          "'Применить ко всем позициям спецификации'",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "d-inline-block pl-1",
                                                  },
                                                  [
                                                    _c("action-button-small", {
                                                      attrs: {
                                                        disabled:
                                                          _vm.form.items[
                                                            item.id
                                                          ].vat === null,
                                                        icon: "chevron-double-down",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.applyAll(
                                                            _vm.form.items[
                                                              item.id
                                                            ].vat
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap text-right",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatPrice(
                                                _vm.sumTotalItem(item, item.id)
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.visible[index],
                                          expression: "visible[index]",
                                        },
                                      ],
                                    },
                                    [
                                      _c("td", { attrs: { colspan: "8" } }, [
                                        item.ktru_reg_number
                                          ? _c("p", [
                                              _c("strong", [
                                                _vm._v("Рег. номер ТРУ:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru_reg_number)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.okpd_name
                                          ? _c("p", [
                                              _c("strong", [
                                                _vm._v("Код ОКПД2: "),
                                              ]),
                                              _vm._v(_vm._s(item.okpd_name)),
                                            ])
                                          : _vm._e(),
                                        item.ktru_group_name
                                          ? _c("p", [
                                              _c("strong", [
                                                _vm._v("Группа ТРУ: "),
                                              ]),
                                              _vm._v(
                                                _vm._s(item.ktru_group_name)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru_name
                                          ? _c("p", [
                                              _c("strong", [
                                                _vm._v("Наименование: "),
                                              ]),
                                              _vm._v(_vm._s(item.ktru_name)),
                                            ])
                                          : _vm._e(),
                                        item.ktru_type_name
                                          ? _c("p", [
                                              _c("strong", [_vm._v("Тип: ")]),
                                              _vm._v(
                                                _vm._s(item.ktru_type_name)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru_description
                                          ? _c("p", [
                                              _c("b", [_vm._v("Описание:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru_description)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru_specifications &&
                                        item.ktru_specifications.length > 0
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Характеристики:"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        item.ktru_specifications &&
                                        item.ktru_specifications.length > 0
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                item.ktru_specifications,
                                                function (spec, specIndex) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key:
                                                        index +
                                                        "_spec_" +
                                                        specIndex,
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(spec.name)
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(spec.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("text-row-price", {
                    attrs: { label: "Итоговая стоимость предложения с НДС" },
                    model: {
                      value: _vm.totalSum,
                      callback: function ($$v) {
                        _vm.totalSum = $$v
                      },
                      expression: "totalSum",
                    },
                  }),
                  _vm.$v.form.$invalid
                    ? [
                        _vm.$v.form.items.$invalid
                          ? _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                'Одна или нескольких позиций спецификации содержит недопустимое значение поля "Цена за ед., руб.". Значение должно быть больше 0, и не должно превышать цену позиции, указанную в извещении.'
                              ),
                            ])
                          : _vm._e(),
                        _vm.$v.form.total_cost.$invalid &&
                        !_vm.$v.form.total_cost.maxValue
                          ? _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                "Итоговая стоимость предложения не должна превышать стартовую цену извещения или стоимость ранее поданного вами предложения."
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "AccordionItem",
                    { attrs: { title: "Запросы разъяснений по закупке" } },
                    [
                      _c("ExplanationRequestList", {
                        attrs: { "purchase-item": _vm.purchase },
                      }),
                    ],
                    1
                  ),
                  _c("form-row-upload-multiple-documents", {
                    attrs: { title: "Документы", v: _vm.$v.form.documents },
                    model: {
                      value: _vm.form.documents,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "documents", $$v)
                      },
                      expression: "form.documents",
                    },
                  }),
                  _c(
                    "b-row",
                    { staticClass: "my-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelOffer },
                            },
                            [_vm._v("ОТМЕНИТЬ")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled:
                                  _vm.$v.form.$invalid ||
                                  !_vm.$store.getters.getCanSign,
                              },
                              on: { click: _vm.checkResources },
                            },
                            [_vm._v("ПОДАТЬ ПРЕДЛОЖЕНИЕ")]
                          ),
                          !_vm.$store.getters.getCanSign
                            ? [
                                _vm.$store.getters.getLoginType === "cert"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                      },
                                      [
                                        _vm._v(
                                          "Для действия требуется загрузить/заменить МЧД на странице: "
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "green-link",
                                            attrs: {
                                              to: { name: "UserProfile" },
                                            },
                                          },
                                          [_vm._v("Данные учетной записи")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                      },
                                      [
                                        _vm._v(
                                          "Подача предложения возможна только при авторизации при помощи ЭЦП"
                                        ),
                                      ]
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "attention-modal",
              attrs: { title: "Внимание!", size: "lg" },
              model: {
                value: _vm.showAttentionModal,
                callback: function ($$v) {
                  _vm.showAttentionModal = $$v
                },
                expression: "showAttentionModal",
              },
            },
            [
              _c("div", { staticClass: "d-block" }, [
                _c("div", { staticClass: "py-3 d-flex" }, [
                  _c("div", { staticClass: "fs-14 grey-color text-center" }, [
                    _vm._v(
                      "С вашего лицевого счета будут заблокированы денежные средства для обеспечения проведения торгов, согласно оферты ИС " +
                        _vm._s(_vm.application_name_short)
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.showAttentionModal = false
                        },
                      },
                    },
                    [_vm._v("ОТМЕНИТЬ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-green" },
                      on: { click: _vm.acceptConfirmation },
                    },
                    [_vm._v("ПРОДОЛЖИТЬ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "attention-modal",
              attrs: { "hide-footer": "", title: "Внимание!", size: "lg" },
              model: {
                value: _vm.showDepositModal,
                callback: function ($$v) {
                  _vm.showDepositModal = $$v
                },
                expression: "showDepositModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block" },
                [
                  _c(
                    "div",
                    { staticClass: "w-100 fs-14 grey-color text-left" },
                    [
                      _vm._v(
                        "Для подачи предложения в закупочной сессии на лицевом счете Вашей организации не хватает " +
                          _vm._s(_vm.needDep) +
                          " рублей."
                      ),
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "w-100 fs-14 fw-600 grey-color form-group" },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "stop-number-mousewheel",
                            rawName: "v-stop-number-mousewheel",
                          },
                        ],
                        attrs: {
                          type: "number",
                          step: "any",
                          min: _vm.needDep,
                          state: _vm.checkDepositValid,
                        },
                        model: {
                          value: _vm.neededDeposit,
                          callback: function ($$v) {
                            _vm.neededDeposit = $$v
                          },
                          expression: "neededDeposit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-input", {
                            attrs: {
                              type: "email",
                              placeholder: "Введите почту",
                              state: _vm.checkEmailValid,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "custom-green" },
                              on: { click: _vm.sendInvoiceToEmail },
                            },
                            [_vm._v("ОТПРАВИТЬ НА E-MAIL")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase ml-2",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.downloadInvoice },
                            },
                            [_vm._v("СКАЧАТЬ PDF")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                title: "Подписать предложение",
                size: "lg",
                centered: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { close: _vm.cancelSignModal },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("text-row", {
                            attrs: { label: "Закупочная сессия" },
                            model: {
                              value: _vm.purchase.reg_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase, "reg_number", $$v)
                              },
                              expression: "purchase.reg_number",
                            },
                          }),
                          _c("text-row-price", {
                            attrs: { label: "Итоговая стоимость предложения" },
                            model: {
                              value: _vm.form.total_cost,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total_cost", $$v)
                              },
                              expression: "form.total_cost",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("XML")]
                            ),
                          ]),
                          !_vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: {
                                  disabled: "",
                                  rows: "20",
                                  "no-resize": "",
                                  "no-auto-shrink": "",
                                  wrap: "off",
                                },
                                model: {
                                  value: _vm.formXml,
                                  callback: function ($$v) {
                                    _vm.formXml = $$v
                                  },
                                  expression: "formXml",
                                },
                              })
                            : _vm._e(),
                          _vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: { disabled: "", rows: "20" },
                                model: {
                                  value: _vm.signedFormXml,
                                  callback: function ($$v) {
                                    _vm.signedFormXml = $$v
                                  },
                                  expression: "signedFormXml",
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c("text-row", {
                    attrs: {
                      label: "Сертификат",
                      value: _vm.certificate?.subjectName,
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: {
                        variant: "custom-outline-secondary",
                        disabled: _vm.isDataSending,
                      },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("ОТМЕНИТЬ")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isSigningXml,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled: !_vm.certificate || !_vm.summLimit,
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("ПОДПИСАТЬ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled: !_vm.certificate || !_vm.summLimit,
                              },
                              on: { click: _vm.sendData },
                            },
                            [_vm._v("ОТПРАВИТЬ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }