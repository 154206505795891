<template>
    <div v-if="purchase">
        <b-container fluid class="main_layer py-2">
            <b-container>
                <p class="fs-40 fw-600 white-color my-2">Закупка 223ФЗ - № {{ purchase.reg_number }}</p>
                <p class="white-color fs-14"><b>Статус</b>: {{ purchase.status.title }}</p>
            </b-container>
        </b-container>
        <b-container fluid class="my-2" v-if="purchase.status.id === 'cancelled' && purchase.cancellation_info">
            <b-container>
                <text-row :compact="true" v-if="purchase.cancellation_info.reason" label="Причина отмены закупки" v-model="purchase.cancellation_info.reason" />
                <text-row-single-document :compact="true" v-if="purchase.cancellation_info.attachment" label="Документ с обоснованием отмены закупки" v-model="purchase.cancellation_info.attachment" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Наименование" v-model="purchase.customer.full_name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="ИНН" v-model="purchase.customer.inn" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="КПП" v-model="purchase.customer.kpp" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="ОГРН" v-model="purchase.customer.ogrn" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Юридический адрес" v-model="purchase.customer.address" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дополнительная контактная информация" v-model="purchase.purchase_terms.contact_info" v-if="purchase.purchase_terms.contact_info" />

                <text-header>Условия закупки</text-header>
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Объект закупки" :value="purchase.purchase_terms.purchase_object" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Предмет закупки" :value="purchase.purchase_terms.deliverable_group.name" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Тип закупочной сессии" :value="purchase.purchase_terms.duration_type.name" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" :label="'Продолжительность закупочной сессии' + purchase.purchase_terms.duration_type.suffix" :value="purchase.purchase_terms.duration" />
                <text-row-datetime :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дата и время размещения извещения" :value="purchase.purchase_terms.purchase_start_datetime" />
                <text-row-datetime :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дата и время окончания приема предложений" :value="purchase.purchase_terms.purchase_end_datetime" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Вид оплаты" :value="purchase.purchase_terms.payment_form" />
                <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Условия оплаты" :value="purchase.purchase_terms.payment_term" />
                <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Планируемая дата заключения договора" :value="purchase.purchase_terms.planned_contract_conclusion_datetime" />
                <text-row-date :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Планируемая дата исполнения договора" :value="purchase.purchase_terms.planned_contract_execution_datetime" />
                <text-row-price :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Сумма закупки" :value="purchase.purchase_terms.start_price" />
                <text-row-single-document :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Проект договора" :value="purchase.purchase_terms.contract_draft_attachment" />
                <text-row-documents :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="Дополнительные документы" v-model="purchase.additional_documents" v-if="purchase.additional_documents.length > 0" />

                <text-header>Требования к поставщикам</text-header>
                <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Отсутствие в реестре недобросовестных поставщиков" :value="purchase.supplier_requirements.only_not_rnp ? 'ДА' : 'НЕТ'" />
                <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Участником закупки могут быть только субъекты малого предпринимательства (СМП)" :value="purchase.supplier_requirements.only_smp ? 'ДА' : 'НЕТ'" />
                <text-row :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Дополнительные требования к участникам закупки" :value="purchase.supplier_requirements.has_additional_requirements ? 'ДА' : 'НЕТ'" />
                <div v-show="purchase.supplier_requirements.has_additional_requirements">
                    <section style="width: 100%">
                        <div class="table-responsive">
                            <table class="atmo-content-table mb-0">
                                <thead>
                                    <tr>
                                        <th class="atmo-ctc-width-45pct">Описание</th>
                                        <th class="atmo-ctc-width-45pct">Причина или цель установки требования</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(requirement, index) in purchase.supplier_requirements.additional" v-bind:key="index">
                                        <td class="atmo-ctc-width-45pct">{{ requirement.description }}</td>
                                        <td class="atmo-ctc-width-45pct">{{ requirement.reason }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>

                <template v-if="purchase.delivery_terms.addresses.length > 0 || purchase.delivery_terms.schedule">
                    <text-header>Условия поставки</text-header>
                    <b-form-group v-if="purchase.delivery_terms.addresses.length > 0" :label-cols-sm="4" :label-cols-lg="4" label="Адреса для доставки/самовывоза:" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                        <ul>
                            <li v-for="(address, index) in purchase.delivery_terms.addresses" :key="index" class="fs-14 grey-color my-auto col-form-label">{{ address }}</li>
                        </ul>
                    </b-form-group>
                    <text-row :compact="true" :label-cols-lg="4" :label-cols-sm="4" label="График поставки товаров (выполнения работ, оказания услуг)" :value="purchase.delivery_terms.schedule" />
                </template>

                <text-header>Спецификация</text-header>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1">№</th>
                                <th>Наименование товара (работ, услуг)</th>
                                <th width="160px" class="text-right">Количество</th>
                                <th width="100px" class="text-right">Ед.изм</th>
                                <th width="160px" class="text-right">Цена за ед., руб.</th>
                                <th width="140px" class="text-right">Стоимость, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in purchase.deliverables" :key="item.id" v-bind="item">
                                <tr :class="visible[index] ? 'border-bottom-0' : ''">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <action-button-small :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" />
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        {{ item.ktru_item.name }}
                                    </td>
                                    <td style="text-align: right">{{ item.amount }}</td>
                                    <td style="text-align: right">
                                        {{ item.ktru_item.okei_local_symbol ?? '&mdash;' }}
                                    </td>
                                    <td style="text-align: right">{{ $formatPrice(item.price) }}</td>
                                    <td style="text-align: right">{{ $formatPrice(item.total_price) }}</td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td class="align-text-top" colspan="8">
                                        <template v-if="item.ktru_item">
                                            <p><b>Рег.номер ТРУ:</b> {{ item.ktru_item.reg_number }}</p>
                                            <p><b>Группа ТРУ:</b> {{ item.ktru_item.ktru_group_name }}</p>
                                            <p><b>Наименование:</b> {{ item.ktru_item.name }}</p>
                                            <p><b>Тип:</b> {{ item.ktru_item.type }}</p>
                                            <p v-if="item.ktru_item.okpd_code"><b>Код ОКПД2:</b> {{ item.ktru_item.okpd_code }}</p>
                                            <p v-if="item.ktru_item.description"><b>Описание:</b> {{ item.ktru_item.description }}</p>
                                            <p v-if="item.ktru_item.specs && item.ktru_item.specs.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru_item.specs && item.ktru_item.specs.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru_item.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.name }}</b> - {{ spec.value }}
                                                </li>
                                            </ul>
                                        </template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                </tr>
                            </fragment>
                        </tbody>
                    </table>
                </div>

                <private-fl223-proposals-block :purchase="purchase" />
                <public-fl223-protocol :protocol="purchase.protocol" />
                <private-fl223-winner-selection-block :purchase="purchase" @confirm="refresh" @refresh="refresh" />
            </b-container>
        </b-container>
        <b-container fluid class="my-4" id="action_buttons_row">
            <b-container class="mb-5">
                <fl223-new-proposal-button :noticeitem="purchase" v-if="$store.getters.isLoggedIn" />
            </b-container>
        </b-container>
    </div>
</template>

<script>
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import Fl223NewProposalButton from '@/components/global/notices/partials/fl223-new-proposal-button.vue';
import PrivateFl223ProposalsBlock from '@/components/products/fl223/partials/private-fl223-proposals-block.vue';
import PrivateFl223WinnerSelectionBlock from '@/components/products/fl223/partials/private-fl223-winner-selection-block.vue';
import PublicFl223Protocol from '@/components/products/fl223/partials/public-fl223-protocol.vue';

export default {
    name: 'fl223-purchase-show',
    components: {
        PublicFl223Protocol,
        PrivateFl223WinnerSelectionBlock,
        PrivateFl223ProposalsBlock,
        Fl223NewProposalButton,
        TextRowSingleDocument,
        TextRowLink
    },
    metaInfo() {
        return {
            title: 'Закупка 223ФЗ № ' + this.getRegNumber
        };
    },
    data() {
        return {
            id: this.$route.params.id,
            purchase: null,
            visible: []
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.purchase = await this.$api.products.fl223.purchases.show(this.id);
        },
        refresh() {
            this.fillData();
        }
    },
    computed: {
        getRegNumber() {
            return this.purchase?.reg_number ?? '';
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}
.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    color: var(--grey-color);
    border-top: 0;
}
.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
