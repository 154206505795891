<template>
    <div>
        <b-overlay :show="loading" rounded="sm">
            <h3 class="grey-color mb-4 main_text font-weight-bold">Участие организации в закупках по 223-ФЗ</h3>
            <div v-show="!loading">
                <form-row-edit-text label="Положения о закупках" v-model="$v.form.purchasing_rules_url.$model" :v="$v.form.purchasing_rules_url" placeholder="Укажите ссылку на Положение о закупках, опубликованное в ЕИС" description="Ссылка на Положение о закупках, опубликованное в ЕИС" />
                <form-row-edit-price label="Лимит закупки" v-model="$v.form.limit.$model" :v="$v.form.limit" :limit="999999999999999999" />
            </div>
            <div class="text-right mx-1">
                <b-button variant="custom-green" class="text-uppercase" :disabled="$v.$invalid || !$v.$anyDirty" @click="updateData">Сохранить информацию о закупках по 223-ФЗ</b-button>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { minValue, required, url } from 'vuelidate/lib/validators';
import store from '@/store';

export default {
    name: 'cabinet-fl223-settings',
    metaInfo: {
        title: 'Закупки 223-ФЗ. Настройки'
    },
    data() {
        return {
            loading: true,
            form: {
                purchasing_rules_url: null,
                limit: 0
            }
        };
    },
    validations: {
        form: {
            purchasing_rules_url: { required, url },
            limit: { required, minValue: minValue(0.01) }
        }
    },
    created() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.loading = true;
            this.form = await this.$api.cabinet.fl223.settings.get();
            this.$v.$reset();
            await store.dispatch('getUserData');
            this.loading = false;
        },
        async updateData() {
            if (await this.$api.cabinet.fl223.settings.store(this.form)) {
                await this.fillData();
            }
        }
    }
};
</script>

<style>
.table-atmo {
    border-collapse: collapse;
}

.table-atmo thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
    vertical-align: middle;
}

.table-atmo thead th.sortable:hover {
    color: var(--green-color) !important;
}

.table-atmo tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

.table-atmo .active {
    color: var(--green-color);
}

.table-atmo .blocked {
    color: #f6b33a;
}

@media screen and (max-width: 1200px) {
    .main_text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .column-992 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .start-992 {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .add_btn {
        margin-top: 15px !important;
    }
}
</style>
