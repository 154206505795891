<template>
    <div v-if="!loading">
        <h3 class="grey-color mb-3 main_text font-weight-bold fs-28">Редактирование пользователя {{ form.name }}</h3>

        <form autocomplete="off">
            <text-row :value="form.name" label="ФИО" />
            <text-row-phone :value="form.phone" label="Телефон" />
            <form-row-single-select v-model="form.post" :options="postOptions" :v="$v.form.post" autocomplete="organization-title" label="Должность" placeholder="Выберите должность" />
            <text-row :value="form.inn" force-show label="ИНН" />
            <text-row :value="form.snils" force-show label="СНИЛС" />
            <text-row :value="form.username" description="Запомните или запишите логин - он будет нужен в случае необходимости сброса пароля" label="Логин" />
            <form-row-edit-text v-model="form.password" :v="form.password ? $v.form.password : null" autocomplete="new-password" label="Новый пароль" type="password" @value="form.password = $event" />
            <form-row-edit-text v-model="form.password_confirmation" :v="form.password ? $v.form.password_confirmation : null" autocomplete="new-password" label="Подтверждение пароля" type="password" @value="form.password_confirmation = $event" />
            <text-row :value="form.email" label="Email" />
            <b-row>
                <b-col class="text-right" md="12">
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button class="mx-1" variant="custom-outline-secondary" @click="onCancel"> Отмена</b-button>
                    </b-overlay>
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="$v.form.$invalid" class="mx-1" variant="custom-green" @click="onSubmit"> Сохранить</b-button>
                    </b-overlay>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import { helpers, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators';

const allowable = helpers.withParams({ type: 'allowable' }, (value) => (value ? /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/.test(value) : true));
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserEdit',
    metaInfo: {
        title: 'Пользователи организации - Редактирование пользователя'
    },
    data() {
        return {
            showConfirmModal: false,
            loading: true,
            isDataSending: false,
            form: {
                name: '',
                email: '',
                inn: null,
                snils: null,
                phone: '',
                post: '',
                username: '',
                password: '',
                password_confirmation: ''
            },
            postOptions: [
                { id: 'Руководитель организации', name: 'Руководитель организации' },
                { id: 'Администратор организации', name: 'Администратор организации' },
                { id: 'Лицо, уполномоченное на размещение информации и документов', name: 'Лицо, уполномоченное на размещение информации и документов' },
                { id: 'Лицо, имеющее право подписи документов от имени организации', name: 'Лицо, имеющее право подписи документов от имени организации' }
            ]
        };
    },
    validations: {
        form: {
            post: { required },
            password: { minLength: minLength(8), allowable, containsSymbols },
            password_confirmation: {
                required: requiredIf((form) => form.password),
                confirmPassword: sameAs((form) => (form.password ? form.password : form.password_confirmation))
            }
        }
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            const response = await this.$api.cabinet.users.get(this.$route.params.id);
            this.form = response.user;
            this.loading = false;
        },
        onCancel() {
            this.$router.push({ name: 'UsersList' }).catch(() => {});
        },
        async onSubmit() {
            this.isDataSending = true;
            if (await this.$api.cabinet.users.put(this.$route.params.id, this.form)) {
                this.$router.push({ name: 'UsersList' }).catch(() => {});
            }
            this.isDataSending = false;
        }
    }
};
</script>

<style>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>
