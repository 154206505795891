var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "mb-1", attrs: { md: _vm.md } },
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            label: _vm.label + ":",
            description: _vm.description,
            "label-class": "filter-header",
          },
        },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                staticClass: "fs-12 px-2 py-0 input-height",
                attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
                on: {
                  input: _vm.onFilterChange,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onEnter.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "py-0 px-1",
                      attrs: {
                        disabled: !_vm.text || _vm.disabled,
                        size: "xs",
                      },
                      on: { click: _vm.clearValue },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "x-circle",
                          scale: "0.6",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }