var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#f5f5f7" } },
    [
      !!_vm.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-start justify-content-between column-768",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center fs-32 fw-600 white-color main_text",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.organization.short_name ||
                                    _vm.organization.full_name
                                )
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "d-flex info_block mt-3" }, [
                            _vm.organization.is_isp
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value: {
                                          variant: "custom-green",
                                          show: true,
                                        },
                                        expression:
                                          "{ variant: 'custom-green', show: true }",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "lable",
                                    attrs: {
                                      title:
                                        "Данный поставщик является прямым производителем товаров, исполнителем услуг или подрядчиком работ",
                                    },
                                  },
                                  [_vm._v("ИСП")]
                                )
                              : _vm._e(),
                            _vm.organization.is_smp
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value: { variant: "custom-green" },
                                        expression:
                                          "{ variant: 'custom-green' }",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "lable",
                                    attrs: {
                                      title:
                                        "Является субъектом малого предпринимательства",
                                    },
                                  },
                                  [_vm._v("СМП")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        [
          !!_vm.organization
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-5" },
                    [
                      _c("text-header", [_vm._v("Сведения об организации")]),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.inn,
                          label: "ИНН",
                        },
                      }),
                      _vm.organization.kpp
                        ? _c("text-row", {
                            attrs: {
                              compact: true,
                              value: _vm.organization.kpp,
                              label: "КПП",
                            },
                          })
                        : _vm._e(),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.ogrn,
                          label: "ОГРН / ОГРНИП",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.short_name,
                          label: "Сокращенное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.full_name,
                          label: "Полное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.post_address,
                          label: "Почтовый адрес",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.fact_address,
                          label: "Фактический адрес",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.manager,
                          label: "Руководитель",
                        },
                      }),
                      _c("text-header", [_vm._v("Контактная информация")]),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.email,
                          label: "Адрес электронной почты",
                        },
                      }),
                      _c("text-row-phone", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.phone,
                          label: "Телефон",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: true,
                          value: _vm.organization.contact_name,
                          label: "Контактное лицо",
                        },
                      }),
                      _vm.organization.is_provider
                        ? [
                            _c("text-header", [_vm._v("Статистика")]),
                            _c(
                              "b-row",
                              { staticClass: "py-3 statistic_block mx-0" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "px-4 py-1",
                                    attrs: { md: "4" },
                                  },
                                  [
                                    _vm.organization.rating
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "h-100 d-flex flex-column justify-content-between",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-uppercase grey-color fs-14 mb-4",
                                              },
                                              [_vm._v("РЕЙТИНГ ПОСТАВЩИКА")]
                                            ),
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "fs-32 fw-600",
                                                  style: `color: ${_vm.ratingColor()}`,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$formatPrice(
                                                        _vm.organization.rating
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fs-14 grey-color text-lowercase ml-2",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.ratingText())
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "px-4 py-1",
                                    attrs: { md: "4" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-100 d-flex flex-column justify-content-between align-items-start",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "text-uppercase grey-color fs-14 mb-4 cursor-pointer",
                                            attrs: {
                                              tag: "a",
                                              to: {
                                                name: "ContractsList",
                                                params: { contract: "all" },
                                                query: _vm.getQueries,
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " КОЛИЧЕСТВО ЗАКЛЮЧЕННЫХ ДОГОВОРОВ "
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-32 green-color fw-600",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organization
                                                    .contracts_count || 0
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-14 grey-color text-lowercase ml-2",
                                            },
                                            [_vm._v("договоров")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "px-4 py-1",
                                    attrs: { md: "4" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-100 d-flex flex-column justify-content-between align-items-start",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "text-uppercase grey-color fs-14 mb-4 cursor-pointer",
                                            attrs: {
                                              tag: "a",
                                              to: {
                                                name: "ContractsList",
                                                params: { contract: "all" },
                                                query: _vm.getQueries,
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " СУММА ЗАКЛЮЧЕННЫХ ДОГОВОРОВ "
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-32 green-color fw-600",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    _vm.organization
                                                      .contracts_sum
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-14 grey-color text-lowercase ml-2",
                                            },
                                            [_vm._v("руб.")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }