<template>
    <b-row class="border-bottom py-3 account_block" no-gutters v-if="statistics">
        <b-col lg="4" class="px-4 py-3">
            <div class="d-flex flex-column align-items-center px-3">
                <div class="cust_label total">
                    {{ $formatPrice(statistics.total) }}
                </div>
                <p class="m-0 text-center fs-14 grey-color">Депозит</p>
            </div>
        </b-col>
        <b-col lg="4" class="px-4 py-3">
            <div class="d-flex flex-column align-items-center px-3">
                <div class="cust_label available">
                    {{ $formatPrice(statistics.available) }}
                </div>
                <p class="m-0 text-center fs-14 grey-color">Доступные</p>
            </div>
        </b-col>
        <b-col lg="4" class="px-4 py-3">
            <div class="d-flex flex-column align-items-center px-3">
                <div class="cust_label blocked">
                    {{ $formatPrice(statistics.blocked) }}
                </div>
                <p class="m-0 text-center fs-14 grey-color">Заблокированные</p>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'AccountingStatistics',
    data() {
        return {
            statistics: null
        };
    },
    async created() {
        this.statistics = await this.$api.cabinet.provider.accounting.getStatistics();
    }
};
</script>

<style scoped>
.cust_label {
    min-width: 169px;
    margin-bottom: 18px;
    border-radius: 4px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    padding: 7px 34px;
    white-space: nowrap;
}
.available {
    background-color: var(--green-color);
}
.total {
    background-color: #959ba4;
}
.blocked {
    background-color: #f6b33a;
}
.account_block {
    background-color: #fff;
    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 4px;
}
</style>
