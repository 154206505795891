<template>
    <b-form-group :label="label + ':'" :state="state" class="w-100" :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <b-form-radio-group :id="id" v-model="radio" :options="options" :stacked="stacked" :disabled="disabled" class="fs-14 mt-2" :class="stateClass" :value-field="valueField" :text-field="textField"></b-form-radio-group>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowEditRadio',
    props: {
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        value: {},
        v: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        stacked: {
            type: Boolean,
            default: false
        },
        valueField: {
            type: String,
            default: 'value'
        },
        textField: {
            type: String,
            default: 'text'
        }
    },
    data() {
        return {};
    },
    mounted() {},
    computed: {
        id() {
            return 'form_row_edit_radio_' + this._uid;
        },
        radio: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        stateClass() {
            if (!this.v) {
                return '';
            } else {
                return this.v.$invalid ? 'is-invalid' : 'is-valid';
            }
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        }
    }
};
</script>
