var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      staticClass: "d-inline-block",
      attrs: {
        show: _vm.isDataSending,
        opacity: "0.6",
        rounded: "",
        "spinner-small": "",
        "spinner-variant": "primary",
      },
    },
    [
      _c(
        "b-button",
        {
          staticClass: "text-uppercase",
          attrs: { size: "sm", variant: "custom-green" },
          on: { click: _vm.sendRecalculateRequest },
        },
        [_vm._v("Пересчитать")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }