var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase.actions.customer.publish
        ? _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-green my-1",
              on: {
                click: function ($event) {
                  _vm.showModal = true
                },
              },
            },
            [_vm._v(" Опубликовать ")]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "publish-modal",
          attrs: {
            size: "lg",
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Опубликовать закупку",
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(" Вы действительно хотите опубликовать закупку "),
              _c("strong", [_vm._v(_vm._s(_vm.purchase.reg_number))]),
              _vm._v("? "),
            ]),
            _c(
              "p",
              [
                _c("strong", [_vm._v("Объект закупки")]),
                _vm._v(": " + _vm._s(_vm.purchase.purchase_object)),
                _c("br"),
                _c("strong", [_vm._v("Предмет закупки")]),
                _vm._v(": " + _vm._s(_vm.purchase.deliverable_group_title)),
                _c("br"),
                _vm.purchase.duration_type.id !== "quotation_request"
                  ? [
                      _c("strong", [_vm._v("Сумма закупки, руб.")]),
                      _vm._v(
                        ": " +
                          _vm._s(_vm.$formatPrice(_vm.purchase.start_price))
                      ),
                      _c("br"),
                    ]
                  : _vm._e(),
                _c("strong", [_vm._v("Планируемая дата заключения договора")]),
                _vm._v(
                  ": " +
                    _vm._s(
                      _vm.getDateFormat(
                        _vm.purchase.planned_contract_conclusion_date
                      )
                    ) +
                    " "
                ),
              ],
              2
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _vm._v("  "),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-green" },
                  on: { click: _vm.publishPurchase },
                },
                [_vm._v("Опубликовать")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }