<template>
    <b-modal v-model="value" title="Запрос блокировки внесистемной закупки" @hide="onHide" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header-close>
            <button-close-modal />
        </template>
        <div class="d-block text-left">
            <p>Вы уверены, что хотите отправить запрос на блокировку закупки №{{ purchaseRegNumber }}?</p>
            <form-row-edit-textarea label="Введите причину запроса блокировки" v-model="blockMessage" :v="$v.blockMessage" :rows="4" label-on-top />
        </div>
        <template #modal-footer>
            <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button variant="custom-outline-secondary" @click="onHide">Отмена </b-button>&nbsp;
            </b-overlay>
            <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button :disabled="$v.$invalid" variant="custom-danger" @click="onSubmit">Подтвердить</b-button>
            </b-overlay>
        </template>
    </b-modal>
</template>

<script>

import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
    name: 'block-request-modal',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        purchaseId: {
            type: Number,
            required: true
        },
        purchaseRegNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            blockMessage: '',
        };
    },
    validations() {
        return {
            blockMessage: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(200)
            }
        }
    },
    methods: {
        onHide() {
            this.blockMessage = '';
            this.$emit('hide');
        },
        async onSubmit() {
            this.isLoading = true;
            await this.$api.cabinet.customer.contracts.storeBlockRequest(this.blockMessage, this.purchaseId)
                .then(() => {
                    this.$emit('submit');
                })
                .catch(() => {})
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
