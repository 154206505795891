<template>
    <div>
        <text-header-new>Лицевой счет</text-header-new>

        <div class="d-flex align-items-center justify-content-between my-4">
            <b-btn variant="new-grey" class="fs-14 total" :to="{ name: 'CabinetProviderAccountingRefundRequestsCreate' }">
                <img src="/images/minus-white.svg" alt="minus" class="mr-2" />
                Запрос на возврат средств
            </b-btn>
            <b-btn variant="new-green" class="fs-14" @click="showDepositModal">
                <img src="/images/plus-white.svg" alt="plus" class="mr-2" />
                Пополнение счета
            </b-btn>
            <b-overlay :show="isExportLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                <b-btn variant="new-yellow" class="fs-14" @click="downloadAccountingXLS">
                    <img src="/images/xlsx-white.svg" alt="xls" class="mr-2" />
                    Выгрузка в .XLSX
                </b-btn>
            </b-overlay>
        </div>

        <accounting-statistics></accounting-statistics>
        <accounting-details></accounting-details>

        <!--DEPOSIT MODAL WINDOW -->
        <b-modal centered ref="attention-modal" id="attention-modal" size="lg" v-model="isDepositModalVisible">
            <div slot="modal-title" class="text-left">
                <div class="w-100 fs-24 fw-600 green-color text-left">Внимание!</div>
                <p class="grey-color fw-400 fs-14 mt-2">Пополнение ЛС в ИС с целью обеспечения возможности участия в закупках.</p>
            </div>
            <div class="d-block">
                <form-row-edit-price label="Сумма пополнения" v-model="form.amount" :v="$v.form.amount" />
                <form-row-edit-checkbox label="Отправить на email" v-model="form.send_email" />
                <form-row-edit-email v-if="form.send_email" label="Email" :value="form.email" @value="form.email = $event" :v="$v.form.email" />
            </div>
            <div slot="modal-footer" class="w-100 text-left">
                <b-overlay :show="isDownloading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase ml-2" @click="downloadInvoice" :disabled="$v.form.$invalid">СКАЧАТЬ PDF {{ form.send_email ? 'и отправить на email' : '' }}</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import AccountingStatistics from '@/components/cabinets/provider/accounting/accounting-statistics';
import AccountingDetails from '@/components/cabinets/provider/accounting/accounting-details';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import { required, email, minValue, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import FileDownload from 'js-file-download';

export default {
    name: 'CabinetProviderAccount',
    components: {
        FormRowEditEmail,
        AccountingDetails,
        AccountingStatistics
    },
    data() {
        return {
            isDepositModalVisible: false,
            form: {
                amount: 0,
                send_email: false,
                email: ''
            },
            isExportLoading: false,
            account: null,
            isDownloading: false
        };
    },
    validations: {
        form: {
            amount: { required, minValue: minValue(0.01) },
            email: { required: requiredIf((form) => form.send_email), email }
        }
    },
    async mounted() {
        if (!this.$store.getters.isLoggedIn || this.isUserCustomer) {
            await this.$router.replace({ name: '404' }).catch(() => {});
        }
    },
    methods: {
        showDepositModal() {
            this.form.email = this.getUser.email;
            this.form.send_email = false;
            this.form.amount = 0;
            this.isDepositModalVisible = true;
        },
        //deposit
        downloadInvoice() {
            this.isDownloading = true;
            this.$api.cabinet.provider.accounting
                .downloadInvoicePdf(this.form)
                .then(() => (this.isDepositModalVisible = false))
                .finally(() => (this.isDownloading = false));
        },
        downloadAccountingXLS() {
            this.isExportLoading = true;
            this.$api.cabinet.provider.accounting
                .downloadHistoryXls()
                .then((response) => {
                    FileDownload(response.data, 'Операции по ЛС.xlsx');
                })
                .catch((error) => {
                    this.showError(error);
                })
                .finally(() => {
                    this.isExportLoading = false;
                });
        }
    },
    computed: {
        ...mapGetters(['getUser']),
        isUserCustomer() {
            return this.$store.state.organization && !this.$store.state.organization.is_provider;
        }
    }
};
</script>

<style scoped>
.account_block div + div {
    border-left: 1px solid #959ba450;
}
@media screen and (max-width: 992px) {
    .account_block div + div {
        border-top: 1px solid var(--grey-color);
        border-left: none;
    }
}
</style>
