var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.form.organization
    ? _c(
        "div",
        [
          _c(
            "h3",
            { staticClass: "grey-color mb-3 main_text font-weight-bold fs-28" },
            [_vm._v("Регистрационные данные организации")]
          ),
          _c("form-row-edit-text", {
            attrs: {
              value: _vm.form.organization.full_name,
              disabled: "",
              label: "Полное наименование",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              value: _vm.form.organization.short_name,
              disabled: "",
              label: "Сокращенное наименование",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.organization.manager,
              label: "Руководитель",
            },
            model: {
              value: _vm.$v.form.organization.manager.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.organization.manager, "$model", $$v)
              },
              expression: "$v.form.organization.manager.$model",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "ИНН" },
            model: {
              value: _vm.form.organization.inn,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "inn", $$v)
              },
              expression: "form.organization.inn",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "КПП" },
            model: {
              value: _vm.form.organization.kpp,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "kpp", $$v)
              },
              expression: "form.organization.kpp",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "ОГРН/ОГРНИП" },
            model: {
              value: _vm.form.organization.ogrn,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "ogrn", $$v)
              },
              expression: "form.organization.ogrn",
            },
          }),
          _c("form-row-edit-checkbox", {
            attrs: {
              "label-cols-lg": 4,
              label: "Организация освобождена от уплаты НДС",
            },
            model: {
              value: _vm.$v.form.organization.without_vat.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.organization.without_vat, "$model", $$v)
              },
              expression: "$v.form.organization.without_vat.$model",
            },
          }),
          _vm.isUserProvider
            ? _c("form-row-edit-checkbox", {
                attrs: {
                  "label-cols-lg": 4,
                  label: "Является местным товаропроизводителем",
                },
                model: {
                  value: _vm.$v.form.organization.is_local_producer.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.form.organization.is_local_producer,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.form.organization.is_local_producer.$model",
                },
              })
            : _vm._e(),
          _c(
            "h3",
            { staticClass: "grey-color my-3 main_text font-weight-bold fs-28" },
            [_vm._v("Контактные данные организации")]
          ),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.organization.contact_name,
              label: "ФИО контактного лица",
            },
            model: {
              value: _vm.$v.form.organization.contact_name.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.organization.contact_name, "$model", $$v)
              },
              expression: "$v.form.organization.contact_name.$model",
            },
          }),
          _c("form-row-edit-dadata-address", {
            attrs: {
              v: _vm.$v.form.organization.post_address,
              label: "Адрес почтовый",
            },
            model: {
              value: _vm.$v.form.organization.post_address.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.organization.post_address, "$model", $$v)
              },
              expression: "$v.form.organization.post_address.$model",
            },
          }),
          _c("form-row-edit-dadata-address", {
            attrs: {
              v: _vm.$v.form.organization.fact_address,
              label: "Адрес фактический",
            },
            model: {
              value: _vm.$v.form.organization.fact_address.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.organization.fact_address, "$model", $$v)
              },
              expression: "$v.form.organization.fact_address.$model",
            },
          }),
          _c("form-row-edit-email", {
            attrs: {
              "email-confirmed": _vm.form.organization.email_confirmed,
              v: _vm.$v.form.organization.email,
              value: _vm.form.organization.email,
              label: "Email",
              "with-validation": "",
              "is-edit": "",
            },
            on: {
              input: _vm.onEmailInput,
              value: function ($event) {
                _vm.form.organization.email = $event
              },
              "email-confirmed": function ($event) {
                _vm.form.organization.email_confirmed = $event
              },
            },
          }),
          _c("form-row-edit-phone", {
            attrs: {
              v: _vm.$v.form.organization.phone,
              value: _vm.form.organization.phone,
              label: "Телефон",
            },
            on: {
              value: function ($event) {
                _vm.form.organization.phone = $event
              },
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { md: "12" } },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.isDataSending,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: _vm.$v.$invalid || !_vm.$v.$anyDirty,
                            variant: "custom-green",
                          },
                          on: { click: _vm.sendData },
                        },
                        [_vm._v("Сохранить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }