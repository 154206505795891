var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between",
              attrs: { md: "7" },
            },
            [
              _c(
                "h3",
                { staticClass: "grey-color mb-3 main_text font-weight-bold" },
                [_vm._v("Контракты 44-ФЗ")]
              ),
            ]
          ),
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-nav",
                {
                  staticClass: "atmo-status-menu-controller atmo-menu nav-menu",
                },
                _vm._l(_vm.tabs, function (tab, cIndex) {
                  return _c(
                    "b-nav-item",
                    {
                      key: "category_" + cIndex,
                      attrs: {
                        to: {
                          name: "ProviderContractsCabinet",
                          params: { cat: tab.path },
                        },
                        exact: "",
                        "exact-active-class": "disabled",
                      },
                    },
                    [_vm._v(_vm._s(tab.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2 cabinet_filter" },
        [
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.filterStatuses,
              label: "Статус закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус",
            },
            model: {
              value: _vm.filter.status,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status", $$v)
              },
              expression: "filter.status",
            },
          }),
          _c("filter-new-text", {
            attrs: {
              label: "Рег.номер закупки",
              md: "6",
              placeholder: "Введите рег.номер закупки",
            },
            model: {
              value: _vm.filter.regNumber,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "regNumber", $$v)
              },
              expression: "filter.regNumber",
            },
          }),
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.$globalDictionaries.deliverableGroups,
              label: "Предмет закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите предметы закупки",
            },
            model: {
              value: _vm.filter.purchaseCategories,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "purchaseCategories", $$v)
              },
              expression: "filter.purchaseCategories",
            },
          }),
          _c("filter-new-text", {
            attrs: {
              label: "Заказчик",
              md: "6",
              placeholder: "Введите название организации или ИНН",
            },
            model: {
              value: _vm.filter.customer,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "customer", $$v)
              },
              expression: "filter.customer",
            },
          }),
          _c(
            "b-col",
            { staticClass: "my-2 text-right", attrs: { lg: "12", md: "12" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getContracts(true)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cleanFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            rounded: "",
            opacity: "0.6",
            "spinner-variant": "primary",
          },
        },
        [
          _vm.contracts && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1 py-0",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
          _vm.isLoading || !_vm.contracts.length
            ? _c("empty-request")
            : _vm._l(_vm.contracts, function (contract) {
                return [
                  contract.type === "fl44_external"
                    ? _c("provider-fl44-external-purchase-card", {
                        key: `${contract.type}_${contract.id}`,
                        attrs: { contract: contract },
                        on: { refresh: _vm.getContracts },
                      })
                    : _c("provider-fl44-contract-card", {
                        key: `${contract.type}_${contract.id}`,
                        attrs: { contract: contract },
                        on: { refresh: _vm.getContracts },
                      }),
                ]
              }),
          _vm.contracts && _vm.meta.total > 0 && _vm.meta.last_page > 1
            ? _c("b-pagination", {
                staticClass: "my-1 py-0",
                attrs: {
                  "per-page": _vm.meta.per_page,
                  "total-rows": _vm.meta.total,
                  align: "center",
                },
                on: {
                  input: function ($event) {
                    return _vm.getContracts()
                  },
                },
                model: {
                  value: _vm.meta.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "current_page", $$v)
                  },
                  expression: "meta.current_page",
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }