<template>
    <div>
        <b-button size="sm" variant="custom-danger" @click="showModal">ЗАПОЛНИТЬ</b-button>
        <b-modal v-model="isModalVisible" :title="title" centered hide-header-close no-close-on-backdrop no-close-on-esc size="xl">
            <div class="table-responsive">
                <b-table :busy="isLoading" :fields="fields" :items="form.details" class="table-atmo">
                    <template #cell(total_purchases_count)="row">
                        <b-input v-model.number="form.details[row.index].total_purchases_count" v-stop-number-mousewheel :state="!$v.form.details.$each.$iter[row.index].total_purchases_count.$invalid" class="fs-14" type="number" />
                    </template>
                    <template #cell(total_purchases_sum)="row">
                        <currency-input
                            v-model="form.details[row.index].total_purchases_sum"
                            v-stop-number-mousewheel
                            :allow-negative="false"
                            :class="!$v.form.details.$each.$iter[row.index].total_purchases_sum.$invalid ? 'is-valid' : 'is-invalid'"
                            class="form-control fs-14"
                            currency="RUB"
                            locale="ru" />
                    </template>
                    <template #cell(local_purchases_count)="row">
                        <b-input v-model.number="form.details[row.index].local_purchases_count" v-stop-number-mousewheel :state="!$v.form.details.$each.$iter[row.index].local_purchases_count.$invalid" class="fs-14" type="number" />
                    </template>
                    <template #cell(local_purchases_sum)="row">
                        <currency-input
                            v-model="form.details[row.index].local_purchases_sum"
                            v-stop-number-mousewheel
                            :allow-negative="false"
                            :class="!$v.form.details.$each.$iter[row.index].local_purchases_sum.$invalid ? 'is-valid' : 'is-invalid'"
                            class="form-control fs-14"
                            currency="RUB"
                            locale="ru" />
                    </template>
                </b-table>
            </div>
            <!--            <p v-if="!$v.form.details.minValue" class="text-danger fs-14">Хотя бы одна строка отчета должна быть заполнена</p>-->
            <p v-if="!$v.form.details.totalGreaterThanLocal" class="text-danger fs-14">Количество/сумма закупок у поставщиков из РБ не может быть больше общего количества/суммы.</p>
            <p class="grey-color fs-14">Необходимо внести информацию о суммах закупок по каждому основанию с начала текущего года, либо за весь предшествующий отправке отчета год.</p>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelModal">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.$invalid" class="text-uppercase" variant="custom-green" @click="sendReport">Отправить отчет</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'fl44-report-fill-button',
    props: {
        fl44PurchaseReport: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            fields: [
                { key: 'order_type_name', label: 'Основание', thStyle: 'width: 28%;' },
                { key: 'total_purchases_count', label: 'Количество закупок, ед.', thStyle: 'width: 18%;' },
                { key: 'total_purchases_sum', label: 'Общая сумма закупок, руб.', thStyle: 'width: 18%;' },
                { key: 'local_purchases_count', label: 'Количество закупок у поставщиков из РБ, ед.', thStyle: 'width: 18%;' },
                { key: 'local_purchases_sum', label: 'Сумма закупок у поставщиков из РБ, руб.', thStyle: 'width: 18%;' }
            ],
            isModalVisible: false,
            isDataSending: false,
            form: {
                details: []
            }
        };
    },
    validations() {
        return {
            form: {
                details: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        total_purchases_count: {
                            required: requiredIf((row) => row.total_purchases_sum > 0),
                            minValue: function (value, row) {
                                return row.total_purchases_sum > 0 ? value >= 1 : true;
                            },
                            maxValue: function (value, row) {
                                return value >= row.local_purchases_count;
                            }
                        },
                        total_purchases_sum: {
                            required: requiredIf((row) => row.total_purchases_count > 0),
                            minValue: function (value, row) {
                                return row.total_purchases_count > 0 ? value >= 0.01 : true;
                            },
                            maxValue: function (value, row) {
                                return value >= row.local_purchases_sum;
                            }
                        },
                        local_purchases_count: {
                            required: requiredIf((row) => row.local_purchases_sum > 0),
                            minValue: function (value, row) {
                                return row.local_purchases_sum > 0 ? value >= 1 : true;
                            },
                            maxValue: function (value, row) {
                                return value <= row.total_purchases_count;
                            }
                        },
                        local_purchases_sum: {
                            required: requiredIf((row) => row.local_purchases_count > 0),
                            minValue: function (value, row) {
                                return row.local_purchases_count > 0 ? value >= 0.01 : true;
                            },
                            maxValue: function (value, row) {
                                return value <= row.total_purchases_sum;
                            }
                        }
                    },
                    totalGreaterThanLocal: function (_, form) {
                        return form.details.every((row) => row.total_purchases_count >= row.local_purchases_count && row.total_purchases_sum >= row.local_purchases_sum);
                    }
                    // minValue: function (_, form) {
                    //     return form.details.some((row) => row.total_purchases_count > 0 && row.total_purchases_sum >= 0.01);
                    // }
                }
            }
        };
    },
    methods: {
        async showModal() {
            const detailsResponse = await $api.personal.fl44PurchaseReports.getReport(this.fl44PurchaseReport.id);
            if (detailsResponse) {
                this.form.details = detailsResponse;
                this.isLoading = false;
                this.isModalVisible = true;
            }
        },
        cancelModal() {
            this.isModalVisible = false;
            this.form.details = [];
        },
        async sendReport() {
            const confirmed = await this.$bvModal.msgBoxConfirm('Внимание! Дальнейшее внесение изменений в отчет будет невозможно!', {
                title: 'Подтвердите отправку отчета',
                bodyTextVariant: 'danger',
                bodyClass: 'font-weight-bold fs-14',
                okTitle: 'Отправить',
                okVariant: 'custom-green',
                cancelTitle: 'Отмена',
                cancelVariant: 'custom-outline-secondary',
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true
            });
            if (!confirmed) return;
            this.isDataSending = true;
            if (await $api.personal.fl44PurchaseReports.storeReport(this.fl44PurchaseReport.id, this.form)) {
                this.isModalVisible = false;
                this.refresh();
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        title() {
            return `Укажите количество контрактов и общую сумму по каждому основанию для проведения закупки на ${this.fl44PurchaseReport.quarter} квартал ${this.fl44PurchaseReport.year}`;
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    vertical-align: middle;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
