<template>
    <div>
        <b-container class="main_layer py-4" fluid>
            <b-container>
                <organizations-list-header />
                <organizations-list-filter :form="filter" @applyFilters="applyFilters" @clearForm="clearFilter" />
            </b-container>
        </b-container>
        <system-preloader v-if="loading"></system-preloader>
        <b-container class="greyBg pb-4" fluid>
            <b-container v-if="!loading && !organizations.length">
                <empty-request></empty-request>
            </b-container>
            <b-container>
                <b-pagination v-if="organizations && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" />

                <b-row class="my-2">
                    <organization-list-card v-for="organization in organizations" :key="organization.id" :organization="organization" />
                </b-row>

                <b-pagination v-if="organizations && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="getOrganizations()" />
            </b-container>
        </b-container>
    </div>
</template>

<script>
import OrganizationListCard from '@/components/organizations/partials/organization-list-card.vue';
import OrganizationsListHeader from '@/components/organizations/partials/organizations-list-header.vue';
import OrganizationsListFilter from '@/components/organizations/partials/organizations-list-filter.vue';

export default {
    name: 'organizations-list',
    components: {
        OrganizationsListFilter,
        OrganizationsListHeader,
        OrganizationListCard
    },
    metaInfo: {
        title: 'Реестр заказчиков и поставщиков'
    },
    data() {
        return {
            loading: true,
            organizations: [],
            filter: {
                name: '',
                address: '',
                inn: '',
                kpp: ''
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    mounted() {
        this.applyFilters();
    },
    methods: {
        getFilters(page = true) {
            const filterValues = [];
            for (let obj of Object.entries(this.filter)) {
                if (obj[1]) {
                    filterValues[obj[0]] = obj[1];
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        async getOrganizations(page = true, loading = true) {
            this.loading = loading;
            const response = await this.$api.organizations.getOrganizationsList(this.getFilters(page));
            this.organizations = response.data;
            this.meta = response.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        refresh() {
            this.getOrganizations();
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getOrganizations();
        },
        clearFilter() {
            this.filter.name = null;
            this.filter.address = null;
            this.filter.inn = null;
            this.filter.kpp = null;
            this.getOrganizations(false);
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/providers_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 150px;
}
</style>
