var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "atmo-nested-section p-2" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
                _vm._v("Добавление дополнительного соглашения"),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "col-md-auto" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "fs-14 my-1 text-uppercase",
                  staticStyle: { color: "white !important" },
                  attrs: {
                    href: _vm.$links
                      .external_purchase_supplementary_agreement_manual,
                    size: "lg",
                    target: "_blank",
                    variant: "danger",
                  },
                },
                [
                  _c("i", { staticClass: "atmo-icon-play" }),
                  _vm._v(" Инструкция "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            opacity: "0.6",
            rounded: "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("h5", { staticClass: "grey-color fw-600" }, [
            _vm._v("Измените спецификацию"),
          ]),
          _c(
            "b-form-group",
            [
              _c("supplementary-agreement-specifications-edit-table", {
                attrs: {
                  v: _vm.$v.form.deliverables,
                  "is-loading": _vm.isLoading,
                },
                model: {
                  value: _vm.form.deliverables,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliverables", $$v)
                  },
                  expression: "form.deliverables",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "mt-3",
                  attrs: { state: !_vm.$v.form.deliverables.$invalid },
                },
                [_vm._v(_vm._s(_vm.specErrorMessage))]
              ),
              _c("form-row-edit-date", {
                attrs: {
                  label: "Дата заключения дополнительного соглашения",
                  v: _vm.$v.form.supplementary_agreement_date,
                  "min-date": _vm.contract_conclusion_date,
                  "max-date": _vm.$now(),
                },
                model: {
                  value: _vm.form.supplementary_agreement_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "supplementary_agreement_date", $$v)
                  },
                  expression: "form.supplementary_agreement_date",
                },
              }),
              _c("form-row-single-line-document-upload", {
                attrs: {
                  "label-cols-lg": 3,
                  v: _vm.$v.form.supplementary_agreement_attachment,
                  title: "Дополнительное соглашение",
                  hint: "Загрузите подписанное обеими сторонами дополнительное соглашение",
                },
                model: {
                  value: _vm.form.supplementary_agreement_attachment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "supplementary_agreement_attachment",
                      $$v
                    )
                  },
                  expression: "form.supplementary_agreement_attachment",
                },
              }),
              !_vm.withoutLimits
                ? _c("limits-editable-table", {
                    attrs: {
                      "total-cost": _vm.specSum,
                      "limit-year": _vm.limitYear,
                      "order-type": _vm.orderType,
                      "purchase-type": "fl44_external",
                      "purchase-id": _vm.purchaseId,
                      "is-supplementary-agreement": "",
                    },
                    on: { validate: _vm.limitsValidate },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("h5", { staticClass: "grey-color fw-600" }, [
                                _vm._v("Измените лимиты"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3796081880
                    ),
                    model: {
                      value: _vm.form.limits,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "limits", $$v)
                      },
                      expression: "form.limits",
                    },
                  })
                : _vm._e(),
              !_vm.withoutLimits && !_vm.isAdvanceReport
                ? _c("form-row-edit-supplier-accounting", {
                    on: { validate: _vm.supplierAccountingValidate },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("h5", { staticClass: "grey-color fw-600" }, [
                                _vm._v(
                                  "Измените банковские реквизиты поставщика"
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1785067314
                    ),
                    model: {
                      value: _vm.form.supplier_accounting,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supplier_accounting", $$v)
                      },
                      expression: "form.supplier_accounting",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          staticClass: "d-inline-block",
          attrs: {
            show: _vm.isLoading,
            opacity: "0.6",
            rounded: "",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "text-uppercase mt-1 mr-3",
              attrs: { variant: "custom-outline-secondary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("Отмена")]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-uppercase mt-1",
              attrs: { variant: "custom-green", disabled: _vm.$v.$invalid },
              on: { click: _vm.createSupplementaryAgreement },
            },
            [_vm._v("Сохранить")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }