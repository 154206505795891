var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `purchase_part_header purchase_part-header_number ${_vm.cardCssClass} pr-2 py-2 position-relative`,
            "body-class": "purchase_part-body p-0",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v("44-ФЗ")]
                          ),
                          _c("b-badge", { staticClass: "my-auto fs-14 mr-2" }, [
                            _vm._v("Вне ИС"),
                          ]),
                          _vm._v(" Запрос блокировки закупки № "),
                          _c(
                            "router-link",
                            {
                              staticClass: "white-system_link-style",
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseShow",
                                  params: { id: _vm.request.purchase.id },
                                },
                                exact: "",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.request.purchase.reg_number))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-nowrap text-uppercase",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.request.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-1" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "6" } },
                [
                  _c("div", [
                    _c("div", { staticClass: "my-1" }, [
                      _c("p", { staticClass: "fs-12 m-0 fw-600 grey-color" }, [
                        _vm._v("Дата, время: "),
                        _c("span", { staticClass: "fw-400" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getDateTimeFormat(_vm.request.created_at)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "my-1" }, [
                      _c("p", { staticClass: "fs-12 m-0 fw-600 grey-color" }, [
                        _vm._v("Причина:"),
                      ]),
                      _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                        _vm._v(" " + _vm._s(_vm.request.request) + " "),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm.request.status.id !== "new"
                ? _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "6" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-1" }, [
                          _c(
                            "p",
                            { staticClass: "fs-12 m-0 fw-600 grey-color" },
                            [
                              _vm._v("Исполнитель: "),
                              _c("span", { staticClass: "fw-400" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.request.executant_name) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "my-1" }, [
                          _c(
                            "p",
                            { staticClass: "fs-12 m-0 fw-600 grey-color" },
                            [
                              _vm._v("Дата, время исполнения: "),
                              _c("span", { staticClass: "fw-400" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDateTimeFormat(
                                        _vm.request.updated_at
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm.request.answer
                          ? _c("div", { staticClass: "my-1" }, [
                              _c(
                                "p",
                                { staticClass: "fs-12 m-0 fw-600 grey-color" },
                                [_vm._v("Обоснование:")]
                              ),
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" " + _vm._s(_vm.request.answer) + " "),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }