var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info_preloader" },
    [
      _c(
        "b-row",
        _vm._l(4, function (item, index) {
          return _c(
            "b-col",
            {
              key: index,
              staticClass: "info_materials-part mb-3",
              attrs: { md: "6", lg: "3" },
            },
            [
              _c(
                "div",
                { staticClass: "wrapper h-100" },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "d-flex flex-nowrap align-items-start",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/images/file.svg", alt: "file" },
                      }),
                      _c(
                        "div",
                        { staticClass: "pl-2 w-100" },
                        [
                          _c("b-progress", {
                            staticClass: "my-1 w-100",
                            attrs: {
                              value: 100,
                              variant: "secondary",
                              animated: "",
                            },
                          }),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-0 mt-3 fs-12 fw-400 grey-color position-relative info-desc w-100",
                            },
                            [
                              _c("b-progress", {
                                staticClass: "my-1 w-50",
                                attrs: {
                                  value: 100,
                                  variant: "secondary",
                                  animated: "",
                                },
                              }),
                              _c("b-progress", {
                                staticClass: "my-1 w-25",
                                attrs: {
                                  value: 100,
                                  variant: "secondary",
                                  animated: "",
                                },
                              }),
                              _c("b-progress", {
                                staticClass: "my-1 w-75",
                                attrs: {
                                  value: 100,
                                  variant: "secondary",
                                  animated: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }