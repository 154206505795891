var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "pb-5 news_preloader" },
    [
      _c("b-col", { staticClass: "mb-3", attrs: { md: "6", lg: "3" } }, [
        _c("div", { staticClass: "slide" }, [
          _c("div", { staticClass: "article_head d-flex news_head" }, [
            _c("img", {
              attrs: { src: "/images/lifebuoy.svg", alt: "lifebuoy" },
            }),
          ]),
          _c("div", { staticClass: "article_body" }, [
            _c(
              "p",
              { staticClass: "m-0 w-100" },
              [
                _c("b-progress", {
                  staticClass: "my-1 w-100",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-50",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-75",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "article_footer" },
            [
              _c("b-progress", {
                staticClass: "w-25 mr-5",
                attrs: { value: 100, variant: "secondary", animated: "" },
              }),
              _c(
                "span",
                { staticClass: "w-100" },
                [
                  _c("b-progress", {
                    staticClass: "w-25",
                    attrs: { value: 100, variant: "secondary", animated: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("b-col", { staticClass: "mb-3", attrs: { md: "6", lg: "3" } }, [
        _c("div", { staticClass: "slide" }, [
          _c("div", { staticClass: "article_head d-flex work_head" }, [
            _c("img", {
              attrs: { src: "/images/lifebuoy.svg", alt: "lifebuoy" },
            }),
          ]),
          _c("div", { staticClass: "article_body" }, [
            _c(
              "p",
              { staticClass: "m-0 w-100" },
              [
                _c("b-progress", {
                  staticClass: "my-1 w-100",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-50",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-75",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "article_footer" },
            [
              _c("b-progress", {
                staticClass: "w-25 mr-5",
                attrs: { value: 100, variant: "secondary", animated: "" },
              }),
              _c(
                "span",
                { staticClass: "w-100" },
                [
                  _c("b-progress", {
                    staticClass: "w-25",
                    attrs: { value: 100, variant: "secondary", animated: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("b-col", { staticClass: "mb-3", attrs: { md: "6", lg: "3" } }, [
        _c("div", { staticClass: "slide" }, [
          _c("div", { staticClass: "article_head d-flex update_head" }, [
            _c("img", {
              attrs: { src: "/images/lifebuoy.svg", alt: "lifebuoy" },
            }),
          ]),
          _c("div", { staticClass: "article_body" }, [
            _c(
              "p",
              { staticClass: "m-0 w-100" },
              [
                _c("b-progress", {
                  staticClass: "my-1 w-100",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-50",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-75",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "article_footer" },
            [
              _c("b-progress", {
                staticClass: "w-25 mr-5",
                attrs: { value: 100, variant: "secondary", animated: "" },
              }),
              _c(
                "span",
                { staticClass: "w-100 ml-auto" },
                [
                  _c("b-progress", {
                    staticClass: "w-25",
                    attrs: { value: 100, variant: "secondary", animated: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("b-col", { staticClass: "mb-3", attrs: { md: "6", lg: "3" } }, [
        _c("div", { staticClass: "slide" }, [
          _c(
            "div",
            {
              staticClass: "article_head d-flex update_head",
              staticStyle: { "background-color": "#40acb4" },
            },
            [
              _c("img", {
                attrs: { src: "/images/lifebuoy.svg", alt: "lifebuoy" },
              }),
            ]
          ),
          _c("div", { staticClass: "article_body" }, [
            _c(
              "p",
              { staticClass: "m-0 w-100" },
              [
                _c("b-progress", {
                  staticClass: "my-1 w-100",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-50",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-25",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
                _c("b-progress", {
                  staticClass: "my-1 w-75",
                  attrs: { value: 100, variant: "secondary", animated: "" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "article_footer" },
            [
              _c("b-progress", {
                staticClass: "w-25 mr-5",
                attrs: { value: 100, variant: "secondary", animated: "" },
              }),
              _c(
                "span",
                { staticClass: "w-100 ml-auto" },
                [
                  _c("b-progress", {
                    staticClass: "w-25",
                    attrs: { value: 100, variant: "secondary", animated: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }