var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color main_text",
                  staticStyle: { "line-height": "1", "margin-bottom": "30px" },
                },
                [_vm._v("Настройка уведомлений")]
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "80%",
                                "min-width": "250px",
                              },
                            },
                            [_vm._v("Событие")]
                          ),
                          _c("th", { attrs: { width: "100px;" } }, [
                            _vm._v("Email"),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.notificationSettings, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "td",
                              [
                                _c("b-form-checkbox", {
                                  attrs: { disabled: !item.allowed_email },
                                  model: {
                                    value: item.email,
                                    callback: function ($$v) {
                                      _vm.$set(item, "email", $$v)
                                    },
                                    expression: "item.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "btn btn-custom-outline-secondary mr-2 text-uppercase",
                          attrs: {
                            to: { name: "NotificationsList" },
                            tag: "button",
                          },
                        },
                        [_vm._v("Отмена ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { type: "submit", variant: "custom-green" },
                        },
                        [_vm._v("Сохранить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }