<template>
    <div v-if="canBeCanceled && !purchase.change_length">
        <b-btn class="btn-card-action text-white bg-secondary my-1" @click="cancelClick"
            >Снять с публикации<template v-if="purchase.can_be_canceled_till"><br />(До {{ getDateTimeFormat(purchase.can_be_canceled_till) }})</template></b-btn
        >
        <cancel-purchase-publishing-modal :purchase="purchase" @cancel="cancelModal" @ok="cancelPurchasePublishing" :show="showModal"></cancel-purchase-publishing-modal>
    </div>
</template>

<script>
import moment from 'moment';
import CancelPurchasePublishingModal from '@/components/common/CancelPurchasePublishingModal';

export default {
    name: 'CancelPurchasePublishingButton',
    props: ['purchase'],
    components: {
        CancelPurchasePublishingModal
    },
    data() {
        return {
            timer: null,
            showModal: false
        };
    },
    created() {
        this.countdown();
        this.timer = setInterval(this.countdown, 1000);
    },
    methods: {
        countdown() {
            if (!this.canBeCanceled) {
                this.cancelModal();
                clearInterval(this.timer);
            }
        },
        cancelClick() {
            this.showModal = true;
        },
        cancelModal() {
            this.showModal = false;
        },
        cancelPurchasePublishing() {
            this.showModal = false;
            this.$emit('click');
        }
    },
    computed: {
        canBeCanceled() {
            return moment(this.purchase.can_be_canceled_till) - moment() > 0 && this.purchase.status.id === 'accepting';
        }
    }
};
</script>

<style></style>
