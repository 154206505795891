var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      class: _vm.compact ? "mb-0" : "",
      attrs: {
        id: _vm.id,
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("b-form-checkbox", {
        staticClass: "fs-14 grey-color mt-2 text-right",
        attrs: { disabled: "" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }