<template>
    <b-modal title="Уважаемый пользователь!" centered hide-footer no-close-on-backdrop no-close-on-esc @close="cancelModal" @cancel="cancelModal" :visible="show" hide-header-close>
        <b-row class="mt-5">
            <b-col>
                <p class="fs-14 grey-color">Обращаем Ваше внимание, что данное действие необратимо. Нажимая кнопку "Сформировать итоговый протокол" Вы подтверждаете выбор поставщика для данной закупки.</p>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col class="text-right">
                <b-button type="reset" class="mr-2 text-uppercase" variant="custom-outline-secondary" :disabled="buttonsDisabled" @click="cancelModal">Отмена</b-button>
                <b-button type="submit" class="text-uppercase" variant="custom-green" :disabled="buttonsDisabled" @click="okModal">Сформировать итоговый протокол</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    name: 'CancelHoldTimeModal',
    props: ['purchase', 'show'],
    data() {
        return {
            buttonsDisabled: false
        };
    },
    methods: {
        cancelModal() {
            this.$emit('cancel');
        },
        okModal() {
            this.buttonsDisabled = true;
            this.$emit('ok');
        }
    }
};
</script>

<style></style>
