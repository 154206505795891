var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loader
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-2", attrs: { fluid: "" } },
            [
              _c("b-container", [
                _c("p", { staticClass: "fs-40 fw-600 white-color my-2" }, [
                  _vm._v(
                    "Внесистемная закупка № " + _vm._s(_vm.purchase.reg_number)
                  ),
                ]),
                _c("p", { staticClass: "white-color fs-14" }, [
                  _c("b", [_vm._v("Статус")]),
                  _vm._v(": " + _vm._s(_vm.purchase.status.title)),
                ]),
              ]),
            ],
            1
          ),
          _vm.purchase
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("text-header", [_vm._v("Информация о заказчике")]),
                      _c("text-row-link", {
                        attrs: {
                          compact: "",
                          label: "Наименование",
                          "router-link": {
                            name: "OrganizationShow",
                            params: { id: _vm.purchase.customer.id },
                          },
                        },
                        model: {
                          value: _vm.purchase.customer.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.customer, "name", $$v)
                          },
                          expression: "purchase.customer.name",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.customer.inn,
                          compact: "",
                          label: "ИНН",
                        },
                      }),
                      _vm.purchase.customer.kpp
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.kpp,
                              compact: "",
                              label: "КПП",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.ogrn
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.ogrn,
                              compact: "",
                              label: "ОГРН",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.address
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.address,
                              compact: "",
                              label: "Почтовый адрес",
                            },
                          })
                        : _vm._e(),
                      _c("text-header", [_vm._v("Информация о закупке")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.order_type_name,
                          compact: "",
                          label: "Тип закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value:
                            _vm.purchase.fl44_external_purchase_category_name,
                          compact: "",
                          label: "Категория закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.purchase_name,
                          compact: "",
                          label: "Предмет закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.purchase_object,
                          compact: "",
                          label: "Объект закупки",
                        },
                      }),
                      _vm.purchase.failed_fl44_purchase_id
                        ? _c("text-row-link", {
                            attrs: {
                              "router-link": {
                                name: "purchases.grouped.item.view",
                                params: {
                                  group: "fl44",
                                  id: _vm.purchase.failed_fl44_purchase_id,
                                },
                              },
                              value:
                                _vm.purchase.failed_fl44_purchase_reg_number,
                              compact: "",
                              label: "Номер несостоявшейся закупки",
                            },
                          })
                        : _vm._e(),
                      _c("text-row-price", {
                        attrs: {
                          value: _vm.purchase.price,
                          compact: "",
                          label: "Общая стоимость",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: {
                          value: _vm.purchase.contract_conclusion_date,
                          compact: "",
                          label: "Дата заключения контракта",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: {
                          value: _vm.purchase.contract_execution_date,
                          compact: "",
                          label: "Дата исполнения контракта",
                        },
                      }),
                      _c("specifications-table", {
                        attrs: {
                          items: _vm.purchase.deliverables,
                          "total-cost": _vm.purchase.price,
                        },
                      }),
                      !_vm.purchase.without_limits &&
                      _vm.purchase.limits.length > 0
                        ? [
                            _c("limits-table", {
                              attrs: { items: _vm.purchase.limits },
                            }),
                          ]
                        : _vm._e(),
                      _c("text-header", { attrs: { id: "supplier-info" } }, [
                        _vm._v("Поставщик"),
                      ]),
                      !_vm.purchase.is_private_provider
                        ? [
                            _c("text-row-link", {
                              attrs: {
                                compact: "",
                                label: "Наименование",
                                "router-link": {
                                  name: "OrganizationShow",
                                  params: { id: _vm.purchase.supplier.id },
                                },
                              },
                              model: {
                                value: _vm.purchase.supplier.full_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier,
                                    "full_name",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier.full_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.purchase.supplier.inn,
                                compact: "",
                                label: "ИНН",
                              },
                            }),
                            _vm.purchase.supplier.kpp
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.purchase.supplier.kpp,
                                    compact: "",
                                    label: "КПП",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier.ogrn
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.purchase.supplier.ogrn,
                                    compact: "",
                                    label: "ОГРН / ОГРНИП",
                                  },
                                })
                              : _vm._e(),
                            _c("text-row", {
                              attrs: {
                                value: _vm.purchase.supplier.address,
                                compact: "",
                                label: "Фактический адрес",
                              },
                            }),
                          ]
                        : [
                            _c("text-row", {
                              attrs: {
                                compact: "",
                                label: "Название",
                                value: "Физическое лицо",
                              },
                            }),
                            _vm.isPurchaseCreator()
                              ? [
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data.inn,
                                      compact: "",
                                      label: "ИНН",
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data.name,
                                      compact: "",
                                      label: "ФИО",
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data
                                          .address,
                                      compact: "",
                                      label: "Адрес регистрации",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                      _c("text-header", { attrs: { id: "contract" } }, [
                        _vm._v("Информация о контракте"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.status.title,
                          compact: "",
                          label: "Статус контракта",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "Номер контракта в системе учета заказчика",
                        },
                        model: {
                          value: _vm.purchase.customer_contract_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "customer_contract_number",
                              $$v
                            )
                          },
                          expression: "purchase.customer_contract_number",
                        },
                      }),
                      _vm.purchase.contract_attachment &&
                      (!_vm.purchase.is_private_provider ||
                        (_vm.purchase.is_private_provider &&
                          _vm.isPurchaseCreator()))
                        ? _c("text-row-single-document", {
                            attrs: { label: "Контракт", compact: "" },
                            model: {
                              value: _vm.purchase.contract_attachment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase,
                                  "contract_attachment",
                                  $$v
                                )
                              },
                              expression: "purchase.contract_attachment",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.supplier_accounting
                        ? [
                            _c("text-header", [
                              _vm._v("Банковские реквизиты поставщика"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_type
                                    .title,
                                compact: "",
                                label: "Вид обслуживающей организации",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "БИК банка" },
                              model: {
                                value: _vm.purchase.supplier_accounting.bik,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bik",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.bik",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Название банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Город банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_city",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_city",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Кор. счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.ks,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "ks",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.ks",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Расчетный счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.rs,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "rs",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.rs",
                              },
                            }),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: { compact: "", label: "Лицевой счет" },
                                  model: {
                                    value: _vm.purchase.supplier_accounting.ls,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "ls",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.ls",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: {
                                    compact: "",
                                    label: "ИНН финансового органа",
                                  },
                                  model: {
                                    value:
                                      _vm.purchase.supplier_accounting.fo_inn,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "fo_inn",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.fo_inn",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: {
                                    compact: "",
                                    label:
                                      "Полное наименование финансового органа",
                                  },
                                  model: {
                                    value:
                                      _vm.purchase.supplier_accounting.fo_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "fo_name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.fo_name",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.purchase.supplementary_agreements.length > 0 ||
                      (_vm.isPurchaseCreator() &&
                        _vm.purchase.status.id === "contract:active")
                        ? _c("supplementary-agreements", {
                            attrs: { purchase: _vm.purchase },
                            on: { refresh: _vm.refresh },
                          })
                        : _vm._e(),
                      _c(
                        "text-header",
                        { attrs: { id: "contract-fulfillment" } },
                        [_vm._v("Исполнение контракта")]
                      ),
                      _vm.isPurchaseCreator()
                        ? _c(
                            "div",
                            { staticClass: "atmo-content-group" },
                            [
                              _c(
                                "form-row-single-select",
                                {
                                  attrs: {
                                    options: _vm.dictionaries.purchaseStatuses,
                                    "label-field": "title",
                                    label: "Изменить статус закупки",
                                    "content-cols-lg": 8,
                                    "content-cols-sm": 9,
                                  },
                                  model: {
                                    value: _vm.form.purchase_status_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "purchase_status_id",
                                        $$v
                                      )
                                    },
                                    expression: "form.purchase_status_id",
                                  },
                                },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isDataSending,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase ml-3",
                                          attrs: {
                                            variant: "custom-green",
                                            disabled:
                                              (_vm.form.purchase_status_id ===
                                                _vm.purchase.status.id &&
                                                _vm.form
                                                  .contract_execution_date ===
                                                  (_vm.purchase.status.id ===
                                                  "contract:fulfilled"
                                                    ? _vm.$toFormatDate(
                                                        _vm.purchase
                                                          .contract_execution_date
                                                      )
                                                    : null)) ||
                                              _vm.$v.form.$invalid,
                                          },
                                          on: { click: _vm.changeStatus },
                                        },
                                        [_vm._v("Изменить статус")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show:
                                      _vm.isDataSending &&
                                      _vm.form.purchase_status_id ===
                                        "contract:fulfilled",
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _vm.form.purchase_status_id ===
                                  "contract:fulfilled"
                                    ? _c("form-row-edit-date", {
                                        attrs: {
                                          "max-date": _vm.$now(),
                                          "min-date":
                                            _vm.purchase
                                              .contract_conclusion_date,
                                          v: _vm.$v.form
                                            .contract_execution_date,
                                          label: "Дата исполнения контракта",
                                        },
                                        model: {
                                          value:
                                            _vm.form.contract_execution_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "contract_execution_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.contract_execution_date",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("form-row-upload-multiple-documents", {
                                attrs: {
                                  "is-loading": _vm.isWaiting,
                                  v: _vm.$v.executionDocuments,
                                  "header-class": "fs-14 fix-pos",
                                  title: "Документы об исполнении",
                                },
                                on: {
                                  add: _vm.onAddFile,
                                  remove: _vm.onRemoveFile,
                                },
                                model: {
                                  value: _vm.executionDocuments,
                                  callback: function ($$v) {
                                    _vm.executionDocuments = $$v
                                  },
                                  expression: "executionDocuments",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              !_vm.purchase.is_private_provider
                                ? _c("text-row-documents", {
                                    attrs: { label: "Документы об исполнении" },
                                    model: {
                                      value: _vm.executionDocuments,
                                      callback: function ($$v) {
                                        _vm.executionDocuments = $$v
                                      },
                                      expression: "executionDocuments",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }