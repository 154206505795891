var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "create" },
        [
          _vm.loading ? _c("system-preloader") : _vm._e(),
          _c(
            "b-container",
            { staticClass: "main_layer", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v("Коммерческое предложение"),
                            _c("br"),
                            _vm._v("№ " + _vm._s(_vm.form.reg_number)),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _c("b", [
                            _vm._v(
                              "К запросу КП № " +
                                _vm._s(_vm.form.quotation_request.reg_number)
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: "",
                      label: "Наименование",
                      value: _vm.form.quotation_request.organization.name,
                      "router-link": {
                        name: "OrganizationShow",
                        params: {
                          id: _vm.form.quotation_request.organization.id,
                        },
                      },
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "ИНН",
                      value: _vm.form.quotation_request.organization.inn,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "КПП",
                      value: _vm.form.quotation_request.organization.kpp,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "ОГРН",
                      value: _vm.form.quotation_request.organization.ogrn,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Адрес",
                      value: _vm.form.quotation_request.organization.address,
                    },
                  }),
                  _c(
                    "text-row-raw",
                    {
                      attrs: { compact: "", label: "Адрес электронной почты" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "fs-14 col-form-label d-inline-block",
                          attrs: {
                            href:
                              "mailto:" +
                              _vm.form.quotation_request.organization.email,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.quotation_request.organization.email
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "text-row-raw",
                    {
                      attrs: {
                        compact: "",
                        label: "Контактный номер телефона",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "fs-14 col-form-label d-inline-block",
                          attrs: {
                            href:
                              "tel:+" +
                              _vm.form.quotation_request.organization.phone,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$parsePhone(
                                _vm.form.quotation_request.organization.phone
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("text-header", [_vm._v("Информация о запросе КП")]),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Предмет закупки",
                      value: _vm.form.quotation_request.name,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Дата/время размещения",
                      value: _vm.form.quotation_request.created_at,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Срок сбора КП",
                      value: _vm.form.quotation_request.deadline,
                    },
                  }),
                  _c("text-header", [_vm._v("Информация о поставщике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: "",
                      label: "Наименование",
                      value: _vm.form.organization.name,
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.form.organization.id },
                      },
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "ИНН",
                      value: _vm.form.organization.inn,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "КПП",
                      value: _vm.form.organization.kpp,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "ОГРН",
                      value: _vm.form.organization.ogrn,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: "",
                      label: "Адрес",
                      value: _vm.form.organization.address,
                    },
                  }),
                  _c(
                    "text-row-raw",
                    {
                      attrs: { compact: "", label: "Адрес электронной почты" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "fs-14 col-form-label d-inline-block",
                          attrs: {
                            href: "mailto:" + _vm.form.organization.email,
                          },
                        },
                        [_vm._v(_vm._s(_vm.form.organization.email))]
                      ),
                    ]
                  ),
                  _c(
                    "text-row-raw",
                    {
                      attrs: {
                        compact: "",
                        label: "Контактный номер телефона",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "fs-14 col-form-label d-inline-block",
                          attrs: {
                            href: "tel:+" + _vm.form.organization.phone,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$parsePhone(_vm.form.organization.phone))
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("text-header", [
                    _vm._v("Информация о коммерческом предложении"),
                  ]),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Дата/время размещения",
                      value: _vm.form.created_at,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: "",
                      label: "Срок действия коммерческого предложения",
                      value: _vm.form.deadline,
                    },
                  }),
                  _c("text-row-price", {
                    attrs: {
                      compact: "",
                      label: "Итоговая сумма коммерческого предложения, руб",
                      value: _vm.form.cost,
                    },
                  }),
                  _c("text-row-single-document", {
                    attrs: {
                      compact: "",
                      label: "Документ",
                      value: _vm.form.document,
                    },
                  }),
                  _c("text-header", [_vm._v("Спецификации")]),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "fs-14 grey-color" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("№"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Наименование товара/работы/услуги"),
                              ]),
                              _c("th", { staticClass: "fw-600 text-right" }, [
                                _vm._v("Количество"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Ед.изм"),
                              ]),
                              _c("th", { staticClass: "fw-600 text-right" }, [
                                _vm._v("Цена за ед., руб."),
                              ]),
                              _c("th", { staticClass: "fw-600 text-right" }, [
                                _vm._v("Стоимость, руб."),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.form.quotation_items,
                              function (item, index) {
                                return _c("fragment", { key: index }, [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "align-text-top" },
                                      [_vm._v(_vm._s(index + 1))]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "align-text-top" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle",
                                                value: "collapse-" + index,
                                                expression:
                                                  "'collapse-' + index",
                                              },
                                            ],
                                            staticClass: "atmo-button-icon",
                                            staticStyle: {
                                              "font-size": "0.8em",
                                            },
                                          },
                                          [
                                            !_vm.visible[index]
                                              ? _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "Показать характеристики",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "atmo-icon-show",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "Скрыть характеристики",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "atmo-icon-hide",
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c("b-collapse", {
                                          staticClass: "d-none",
                                          attrs: { id: "collapse-" + index },
                                          model: {
                                            value: _vm.visible[index],
                                            callback: function ($$v) {
                                              _vm.$set(_vm.visible, index, $$v)
                                            },
                                            expression: "visible[index]",
                                          },
                                        }),
                                        _vm._v(" " + _vm._s(item.name) + " "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "align-text-top text-right text-nowrap",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            parseFloat(item.count).toPrecision()
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "align-text-top" },
                                      [_vm._v(_vm._s(item.okei?.local_symbol))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "align-text-top text-right text-nowrap",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$formatPrice(item.price))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "align-text-top text-right text-nowrap",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatPrice(item.total_sum)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.visible[index],
                                          expression: "visible[index]",
                                        },
                                      ],
                                    },
                                    [
                                      _c("td", { attrs: { colspan: 6 } }, [
                                        item.ktru?.reg_number
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Рег.номер ТРУ:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru?.reg_number)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru?.okpd_code
                                          ? _c("p", [
                                              _c("b", [_vm._v("Код ОКПД2:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru?.okpd_code)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru?.description
                                          ? _c("p", [
                                              _c("b", [_vm._v("Описание:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru?.description)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru?.specs &&
                                        item.ktru?.specs?.length > 0
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Характеристики:"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        item.ktru?.specs &&
                                        item.ktru?.specs?.length > 0
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                item.ktru?.specs,
                                                function (spec, specIndex) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key:
                                                        index +
                                                        "_spec_" +
                                                        specIndex,
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(spec.name)
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(spec.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.pdfLoading,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.downloadPDF },
                        },
                        [_vm._v("СКАЧАТЬ ПЕЧАТНУЮ ФОРМУ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }