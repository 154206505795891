<template>
    <div v-if="!!form.organization">
        <h3 class="grey-color mb-3 main_text font-weight-bold fs-28">Регистрационные данные организации</h3>
        <form-row-edit-text :value="form.organization.full_name" disabled label="Полное наименование" />
        <form-row-edit-text :value="form.organization.short_name" disabled label="Сокращенное наименование" />
        <form-row-edit-text v-model="$v.form.organization.manager.$model" :v="$v.form.organization.manager" label="Руководитель" />
        <form-row-edit-text v-model="form.organization.inn" disabled label="ИНН" />
        <form-row-edit-text v-model="form.organization.kpp" disabled label="КПП" />
        <form-row-edit-text v-model="form.organization.ogrn" disabled label="ОГРН/ОГРНИП" />
        <form-row-edit-checkbox v-model="$v.form.organization.without_vat.$model" :label-cols-lg="4" label="Организация освобождена от уплаты НДС" />
        <form-row-edit-checkbox v-if="isUserProvider" v-model="$v.form.organization.is_local_producer.$model" :label-cols-lg="4" label="Является местным товаропроизводителем" />

        <h3 class="grey-color my-3 main_text font-weight-bold fs-28">Контактные данные организации</h3>
        <form-row-edit-text v-model="$v.form.organization.contact_name.$model" :v="$v.form.organization.contact_name" label="ФИО контактного лица" />
        <form-row-edit-dadata-address v-model="$v.form.organization.post_address.$model" :v="$v.form.organization.post_address" label="Адрес почтовый" />
        <form-row-edit-dadata-address v-model="$v.form.organization.fact_address.$model" :v="$v.form.organization.fact_address" label="Адрес фактический" />
        <form-row-edit-email
            :email-confirmed="form.organization.email_confirmed"
            :v="$v.form.organization.email"
            :value="form.organization.email"
            label="Email"
            with-validation
            is-edit
            @input="onEmailInput"
            @value="form.organization.email = $event"
            @email-confirmed="form.organization.email_confirmed = $event" />
        <form-row-edit-phone :v="$v.form.organization.phone" :value="form.organization.phone" label="Телефон" @value="form.organization.phone = $event" />

        <b-row>
            <b-col md="12" class="text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.$invalid || !$v.$anyDirty" variant="custom-green" class="text-uppercase" @click="sendData">Сохранить</b-button>
                </b-overlay>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { email, helpers, minLength, required } from 'vuelidate/lib/validators';
import CabinetOrganization from '@/services/api/cabinet/CabinetOrganization';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);

export default {
    name: 'OrganizationData',
    data() {
        return {
            isDataSending: false,
            form: {
                organization: {
                    full_name: '',
                    short_name: '',
                    manager: '',
                    inn: null,
                    kpp: null,
                    ogrn: null,
                    is_local_producer: false,
                    contact_name: '',
                    post_address: '',
                    fact_address: '',
                    phone: '',
                    email: '',
                    email_confirmed: false,
                    without_vat: false
                }
            },
            certificate: null
        };
    },
    validations: {
        form: {
            organization: {
                manager: { required, minLength: minLength(3) },
                phone: { required, phone },
                email: { required, email },
                email_confirmed: { checked: (value) => value === true },
                post_address: { required },
                fact_address: { required },
                contact_name: { required, minLength: minLength(3) },
                is_local_producer: {},
                without_vat: {}
            }
        }
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.form.organization = {
                ...this.$store.state.organization,
                email_confirmed: true
            };
            await this.$nextTick();
            await this.$v.$reset();
        },
        onEmailInput() {
            this.form.organization.email_confirmed = false;
        },
        async onCancel() {
            await this.$store.dispatch('getUserData');
            await this.fillData();
        },
        async sendData() {
            this.isDataSending = true;
            const formData = {
                manager: this.form.organization.manager,
                fact_address: this.form.organization.fact_address,
                post_address: this.form.organization.post_address,
                email: this.form.organization.email,
                email_confirmed: this.form.organization.email_confirmed,
                phone: this.form.organization.phone,
                contact_name: this.form.organization.contact_name,
                without_vat: this.form.organization.without_vat
            };
            if (this.isProvider()) {
                formData.is_local_producer = this.form.organization.is_local_producer;
            }

            if (await CabinetOrganization.sendChangeRequest(formData, this.signedFormXml, this.certificate)) {
                this.onCancel();
            }
            this.isDataSending = false;
        }
    },
    computed: {
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        }
    }
};
</script>
