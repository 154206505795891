<template>
    <b-container class="atmo-nested-section p-2">
        <b-row>
            <b-col>
                <text-header margin-class="mb-3">Добавление дополнительного соглашения</text-header>
            </b-col>
            <b-col class="col-md-auto">
                <b-btn :href="$links.external_purchase_supplementary_agreement_manual" class="fs-14 my-1 text-uppercase" size="lg" style="color: white !important" target="_blank" variant="danger">
                    <i class="atmo-icon-play" />
                    Инструкция
                </b-btn>
            </b-col>
        </b-row>
        <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
            <h5 class="grey-color fw-600">Измените спецификацию</h5>
            <b-form-group>
                <supplementary-agreement-specifications-edit-table v-model="form.deliverables" :v="$v.form.deliverables" :is-loading="isLoading" />
                <b-form-invalid-feedback :state="!$v.form.deliverables.$invalid" class="mt-3">{{ specErrorMessage }}</b-form-invalid-feedback>
                <form-row-edit-date label="Дата заключения дополнительного соглашения" v-model="form.supplementary_agreement_date" :v="$v.form.supplementary_agreement_date" :min-date="contract_conclusion_date" :max-date="$now()" />
                <form-row-single-line-document-upload v-model="form.supplementary_agreement_attachment" :label-cols-lg="3" :v="$v.form.supplementary_agreement_attachment" title="Дополнительное соглашение" hint="Загрузите подписанное обеими сторонами дополнительное соглашение" />
                <limits-editable-table v-if="!withoutLimits" v-model="form.limits" :total-cost="specSum" @validate="limitsValidate" :limit-year="limitYear" :order-type="orderType" purchase-type="fl44_external" :purchase-id="purchaseId" is-supplementary-agreement>
                    <template #title>
                        <h5 class="grey-color fw-600">Измените лимиты</h5>
                    </template>
                </limits-editable-table>
                <form-row-edit-supplier-accounting v-if="!withoutLimits && !isAdvanceReport" v-model="form.supplier_accounting" @validate="supplierAccountingValidate">
                    <template #title>
                        <h5 class="grey-color fw-600">Измените банковские реквизиты поставщика</h5>
                    </template>
                </form-row-edit-supplier-accounting>
            </b-form-group>
        </b-overlay>
        <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
            <b-button variant="custom-outline-secondary" class="text-uppercase mt-1 mr-3" @click="$emit('cancel')">Отмена</b-button>
            <b-button variant="custom-green" class="text-uppercase mt-1" :disabled="$v.$invalid" @click="createSupplementaryAgreement">Сохранить</b-button>
        </b-overlay>
    </b-container>
</template>

<script>
import SupplementaryAgreementSpecificationsEditTable from "@/components/externalPurchases/partials/supplementary-agreement-specifications-edit-table.vue";
import {minLength, minValue, required} from "vuelidate/lib/validators";
import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";
import LimitsEditableTable from "@/components/externalPurchases/partials/limits-editable-table.vue";
import FormRowEditSupplierAccounting from "@/components/externalPurchases/partials/form-row-edit-supplier-accounting.vue";

export default {
    name: 'create-supplementary-agreements',
    components: { FormRowEditSupplierAccounting, LimitsEditableTable, SupplementaryAgreementSpecificationsEditTable },
    props: {
        purchaseId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            form: {
                supplementary_agreement_date: null,
                supplementary_agreement_attachment: null,
                deliverables: [],
                limits: [],
                supplier_accounting: {
                    bank_type: 'bank',
                    bik: null,
                    bank_name: null,
                    bank_city: null,
                    ks: null,
                    rs: null,
                    ls: null,
                    fo_inn: null,
                    fo_name: null
                }
            },
            isLimitsValid: false,
            isSupplierAccountingValid: false,
            withoutLimits: false,
            isAdvanceReport: false,
            orderType: {
                id: 0,
                name: ''
            },
            limitYear: 0,
            price: 0,
            contract_conclusion_date: null,
            priceLimit: 0
        }
    },
    validations() {
        return {
            form: {
                supplementary_agreement_date: {
                    required
                },
                supplementary_agreement_attachment: {
                    required
                },
                deliverables: {
                    required,
                    minLength: minLength(1),
                    priceLimit() {
                        return this.specSum <= this.priceLimit;
                    },
                    $each: {
                        ktru_item_id: { required },
                        amount: { required, minValue: minValue(0) },
                        minAmount(item, arr) {
                            const items = arr.filter(element => element.id === item.id);
                            const sum = items.reduce((sum, element) => sum + element.amount * 1, 0);
                            return sum >= item.min_amount;
                        },
                        maxAmount(item, arr) {
                            const items = arr.filter(element => element.id === item.id);
                            const sum = items.reduce((sum, element) => sum + element.amount * 1, 0);
                            return sum <= item.max_amount;
                        },
                        price: {
                            required,
                            minValue: minValue(0.01)
                        },
                        maxPrice(item) {
                            return item.price <= item.max_price;
                        },
                    }
                },
                limits: {
                    isLimitsValid() {
                        return this.withoutLimits || this.isLimitsValid;
                    },
                },
                supplier_accounting: {
                    isSupplierAccountingValid() {
                        return this.withoutLimits || !this.withoutLimits && this.isAdvanceReport || this.isSupplierAccountingValid;
                    }
                }
            }
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            await this.$api.personal.fl44ExternalPurchases.getSupplementaryAgreementEditData(this.purchaseId)
                .then((response) => {
                    this.form.deliverables = response.deliverables;
                    this.form.limits = response.limits;
                    this.form.supplier_accounting = response.supplier_accounting;
                    this.withoutLimits = response.without_limits;
                    this.isAdvanceReport = response.is_advance_report;
                    this.limitYear = response.purchase_limit_year;
                    this.orderType = response.order_type;
                    this.contract_conclusion_date = response.contract_conclusion_date;
                    this.price = response.price;
                    this.priceLimit = response.price_limit;
                })
                .catch(() => {
                    this.$emit('cancel');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async createSupplementaryAgreement() {
            this.isLoading = true;
            await this.$api.personal.fl44ExternalPurchases.storeSupplementaryAgreement(this.purchaseId, { form: this.prepareForm() })
                .then(() => {
                    this.$emit('done');
                })
                .catch(() => {})
                .finally(() => {
                    this.isLoading = false;
                });
        },
        limitsValidate(value) {
            this.isLimitsValid = !value;
        },
        supplierAccountingValidate(value) {
            this.isSupplierAccountingValid = !value;
        },
        prepareForm() {
            const form = {
                supplementary_agreement_date: this.form.supplementary_agreement_date,
                supplementary_agreement_attachment: { id: this.form.supplementary_agreement_attachment.id },
                deliverables: this.form.deliverables.map((item) => {
                    return {
                        fl44_external_purchase_deliverable_id: item.id,
                        amount: item.amount,
                        price: item.price,
                        is_manually_added: item.is_manually_added
                    };
                })
            };
            if (!this.withoutLimits) {
                form.limits = this.form.limits.map((item) => {
                    return {
                        id: item.id,
                        year: item.year,
                        amount_assigned: item.amount_assigned
                    };
                });
                if (!this.isAdvanceReport) {
                    form.supplier_accounting = this.form.supplier_accounting;
                }
            }
            return form;
        }
    },
    computed: {
        specSum() {
            return roundToTwoDecimals(this.form.deliverables.reduce((sum, item) => {
                return sum += item.amount * item.price;
            }, 0));
        },
        specErrorMessage() {
            const deliverables = Object.values(this.$v.form.deliverables.$each.$iter);
            if (deliverables.some((e) => e.amount.$invalid)) return 'У одной или более позиций в таблице спецификации не заполнено поле "Количество"';
            if (deliverables.some((e) => e.price.$invalid)) return 'У одной или более позиций в таблице спецификации не заполнено поле "Цена"';
            if (deliverables.some((e) => !e.maxPrice)) return 'Цена единицы ТРУ в спецификации не может быть больше указанной в контракте.';
            if (deliverables.some((e) => !e.maxAmount)) return 'Максимальное увеличение количества может составлять не более 10%.';
            if (deliverables.some((e) => !e.minAmount)) return 'Максимальное снижение количества может составлять не более 10%.';
            if (!this.$v.form.deliverables.priceLimit) return `Превышена максимальная сумма закупки в ${this.$formatPrice(this.priceLimit)} руб.`;
            return '';
        }
    }
};
</script>

<style scoped>
</style>
