var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("p", { staticClass: "fs-18 grey-color my-2" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c("div", [
              _c("table", { staticClass: "table" }, [
                _c("thead", [_c("tr", [_c("th", [_vm._v("Наименование")])])]),
                _vm.documents && _vm.documents.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.documents, function (document) {
                        return _c("tr", { key: document.id }, [
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  color: "var(--green-color) !important",
                                },
                                attrs: {
                                  href: _vm.$getAttachmentDownloadLink(
                                    document.id
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(document.name) +
                                    " " +
                                    _vm._s(
                                      document.is_archived ? "(Архив)" : ""
                                    )
                                ),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { align: "center" } }, [
                          _vm._v("Не добавлено документов"),
                        ]),
                      ]),
                    ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }