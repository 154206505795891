var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", [
    _vm.limit.budget_name
      ? _c("li", [
          _c("b", [_vm._v("Наименование бюджета:")]),
          _vm._v(" " + _vm._s(_vm.limit.budget_name)),
        ])
      : _vm._e(),
    _vm.limit.funds_type
      ? _c("li", [
          _c("b", [_vm._v("Код типа средств:")]),
          _vm._v(" " + _vm._s(_vm.limit.funds_type)),
        ])
      : _vm._e(),
    _vm.limit.action_code
      ? _c("li", [
          _c("b", [_vm._v("Код мероприятия:")]),
          _vm._v(" " + _vm._s(_vm.limit.action_code)),
        ])
      : _vm._e(),
    _vm.limit.kosgu_code
      ? _c("li", [
          _c("b", [_vm._v("Код КОСГУ:")]),
          _vm._v(" " + _vm._s(_vm.limit.kosgu_code)),
        ])
      : _vm._e(),
    _vm.limit.source_funds
      ? _c("li", [
          _c("b", [_vm._v("Бюджет - источник трансферта:")]),
          _vm._v(" " + _vm._s(_vm.limit.source_funds)),
        ])
      : _vm._e(),
    _vm.limit.subsidy_code
      ? _c("li", [
          _c("b", [_vm._v("Код субсидии:")]),
          _vm._v(" " + _vm._s(_vm.limit.subsidy_code)),
        ])
      : _vm._e(),
    _vm.limit.invest_object
      ? _c("li", [
          _c("b", [_vm._v("Объект:")]),
          _vm._v(" " + _vm._s(_vm.limit.invest_object)),
        ])
      : _vm._e(),
    _vm.limit.direction_code
      ? _c("li", [
          _c("b", [_vm._v("Код направления:")]),
          _vm._v(" " + _vm._s(_vm.limit.direction_code)),
        ])
      : _vm._e(),
    _vm.limit.regional_project_result
      ? _c("li", [
          _c("b", [_vm._v("Результат проекта:")]),
          _vm._v(" " + _vm._s(_vm.limit.regional_project_result)),
        ])
      : _vm._e(),
    _vm.limit.additional_information_code
      ? _c("li", [
          _c("b", [_vm._v("Код доп. информации:")]),
          _vm._v(" " + _vm._s(_vm.limit.additional_information_code)),
        ])
      : _vm._e(),
    _vm.limit.eis_ident_no
      ? _c("li", [
          _c("b", [_vm._v("ИКЗ:")]),
          _vm._v(" " + _vm._s(_vm.limit.eis_ident_no)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }