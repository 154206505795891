<template>
    <div v-if="isAvailable">
        <b-btn class="btn-danger my-1 text-uppercase fs-12" @click="showModal" :disabled="errors">Сформировать итоговый протокол закупочной сессии</b-btn>
        <p class="text-danger fs-14">{{ errorMessage }}</p>
        <b-modal ref="cancel-modal" v-model="isModalVisible" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Сформировать итоговый протокол закупочной сессии">
            <p class="text-danger fw-600 fs-16 my-1">Внимание! Данная операция необратима. Убедитесь в корректности заполненных данных перед формированием итогового протокола!</p>
            <p class="text-danger fw-600 fs-16 my-1">Если Вы производили рейтингование заявок участников, убедитесь в корректности проставленных значений перед нажатием кнопки "СФОРМИРОВАТЬ ПРОТОКОЛ"</p>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelModal">Отменить</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase" variant="custom-danger" @click="store">Сформировать протокол</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'fl223-purchase-store-winner-selection-button',
    props: ['purchase', 'ratings', 'proposals'],
    validations: {},
    data() {
        return {
            isModalVisible: false,
            isDataSending: false
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
        },
        async store() {
            this.isDataSending = true;
            if (await this.$api.products.fl223.purchases.storeWinnerSelection(this.purchase.id, { ratings: this.ratings })) {
                this.isModalVisible = false;
                this.$emit('store');
            }
            this.isDataSending = false;
        }
    },
    computed: {
        title() {
            return `Сформировать итоговый протокол закупочной сессии?`;
        },
        isAvailable() {
            return this.$store.getters.isLoggedIn && this.purchase.actions.customer.select_winner && this.purchase.private_data && this.purchase.private_data.winner_selection && !this.purchase.private_data.winner_selection.confirmed;
        },
        errors() {
            return !!this.errorMessage;
        },
        winnerProposalsCount() {
            return this.proposals.filter((proposal) => proposal.status.id === 'winner').length;
        },
        rejectedProposalsCount() {
            return this.proposals.filter((proposal) => proposal.status.id === 'rejected').length;
        },
        nonRejectedProposalsCount() {
            return this.proposals.filter((proposal) => proposal.status.id !== 'rejected').length;
        },
        ratingFilledProposalsCount() {
            return this.proposals.filter((proposal) => proposal.status.id !== 'rejected' && !!proposal.rating).length;
        },
        errorMessage() {
            if (this.winnerProposalsCount === 0 && this.rejectedProposalsCount < this.proposals.length) return 'Необходимо выбрать победителя или отклонить все предложения';
            if (this.ratingFilledProposalsCount > 0 && this.ratingFilledProposalsCount !== this.nonRejectedProposalsCount) return 'Рейтинг необходимо проставить всем не отклоненным заявкам, либо не проставлять ни одной заявке';
            return '';
        }
    }
};
</script>
