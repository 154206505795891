'use strict';

import OrganizationReliabilityReportEditableVO from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportEditable.valueobject';
import OrganizationReliabilityReportFinancialsVO from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportFinancials.valueobject';
import OrganizationReliabilityReportOrganizationVO, { TOrganizationTypes } from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportOrganization.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import { IViewmodelDataService } from '@core/js/ddriven/application/abstractions/vue/IViewmodelDataService';

export default class OrganizationReliabilityReportVMDService implements IViewmodelDataService {
    private _user: UserVO;
    public editable: OrganizationReliabilityReportEditableVO;
    public viewables: {
        financials: OrganizationReliabilityReportFinancialsVO;
        organization: OrganizationReliabilityReportOrganizationVO;
        refresh_key: string;
        is_found: boolean;
        is_loading: {
            financials: boolean;
            organization: boolean;
            report: boolean;
        };
    };

    constructor(user: UserVO) {
        this._user = user;
        this.editable = new OrganizationReliabilityReportEditableVO(user);
        this.viewables = {
            financials: new OrganizationReliabilityReportFinancialsVO(),
            organization: new OrganizationReliabilityReportOrganizationVO(),
            refresh_key: this.refreshKey,
            is_found: false,
            is_loading: {
                financials: true,
                organization: false,
                report: false
            }
        };
    }

    private get refreshKey(): string {
        return Math.random().toString();
    }

    public get user(): UserVO {
        return this._user;
    }

    public async initialize() {
        this.viewables.is_loading.financials = true;
        const response = await ApplicationServiceLocator.get('api').supplier.getOrganizationReliabilityReportFinancials();
        response.isSuccess && (this.viewables.financials = new OrganizationReliabilityReportFinancialsVO(response.original.response!.data));
        this.viewables.is_loading.financials = false;
    }

    public resetLoadingState(): void {
        this.viewables.is_found = false;
        this.viewables.is_loading.organization = false;
        this.viewables.organization = new OrganizationReliabilityReportOrganizationVO();
    }

    public async search() {
        this.viewables.organization = new OrganizationReliabilityReportOrganizationVO();
        this.viewables.is_found = false;
        this.viewables.is_loading.organization = true;

        const response = await ApplicationServiceLocator.get('dadata').findByInn(this.editable.inn!, this.editable.inn_state as TOrganizationTypes);

        setTimeout(() => {
            this.viewables.is_loading.organization = false;
        }, 200); // NB: To avoid parasite loading spinner binking.

        if (response) {
            this.viewables.is_found = true;
            this.viewables.organization = OrganizationReliabilityReportOrganizationVO.fromDadataResponse(response, this.editable.inn_state as TOrganizationTypes);
        }
    }

    public async xml(): Promise<string> {
        const response = await ApplicationServiceLocator.get('api').supplier.getOrganizationXML(this.editable.inn!);

        // REFACTOR: Should process response somehow meaningfully.
        if (!response.isSuccess) {
            throw new Error('SVTA Error: unexpected response');
        }

        return response.original.response?.data.xml;
    }

    public resetSearch(): void {
        this.editable = new OrganizationReliabilityReportEditableVO(this.user);
        this.viewables.organization = new OrganizationReliabilityReportOrganizationVO();
        this.viewables.is_found = false;
        this.viewables.is_loading.organization = false;
        this.viewables.refresh_key = this.refreshKey;
    }
}
