var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("p", { class: _vm.headerClass + " grey-color my-4" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              !_vm.ktruItem
                ? _c(
                    "b-button",
                    {
                      staticStyle: { color: "var(--green-color) !important" },
                      attrs: { variant: "custom-unstyle-grey" },
                      on: {
                        click: function ($event) {
                          _vm.showSpec = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "atmo-icon-attachment" }),
                      _vm._v("  Выбрать позицию КТРУ "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-2", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "grey-color" }, [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                class:
                  _vm.v.$invalid || !!_vm.specErrorMessage ? "is-invalid" : "",
              },
              [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Наименование товара/работы/услуги")]),
                      _c("th", { attrs: { width: "1" } }),
                      _c("th", { attrs: { nowrap: "", width: "100px" } }, [
                        _vm._v("Ед.изм"),
                      ]),
                      _c("th", { attrs: { nowrap: "", width: "100px" } }, [
                        _vm._v("Страна происхождения"),
                      ]),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "160px" },
                          attrs: { width: "160px" },
                        },
                        [_vm._v("Цена за ед., руб.")]
                      ),
                      _c("th", { attrs: { width: "1" } }),
                    ]),
                  ]),
                  _c("tbody", [
                    _vm.ktruItem
                      ? _c("tr", [
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(" " + _vm._s(_vm.ktruItem.name) + " "),
                          ]),
                          _c("td", { attrs: { width: "1" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button-icon float-right",
                                on: {
                                  click: function ($event) {
                                    _vm.visibleDetails = !_vm.visibleDetails
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      title: "Показать описание/характеристики",
                                    },
                                  },
                                  [
                                    !_vm.visibleDetails
                                      ? _c("i", {
                                          staticClass: "atmo-icon-show",
                                        })
                                      : _c("i", {
                                          staticClass: "atmo-icon-hide",
                                        }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(_vm.ktruItem.okei_local_symbol)),
                          ]),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(_vm.ktruItem.country_of_origin_name)),
                          ]),
                          _c(
                            "td",
                            { staticClass: "align-text-top" },
                            [
                              _c("currency-input", {
                                staticClass: "form-control fs-14",
                                class:
                                  _vm.ktruItem.price > 0 ? "" : "is-invalid",
                                staticStyle: {
                                  "padding-top": "0",
                                  "padding-bottom": "0",
                                  height: "auto",
                                },
                                attrs: { currency: "RUB", locale: "ru" },
                                model: {
                                  value: _vm.ktruItem.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ktruItem, "price", $$v)
                                  },
                                  expression: "ktruItem.price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "align-text-top",
                              attrs: { nowrap: "" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: { background: "transparent" },
                                  attrs: { size: "sm", variant: "light" },
                                  on: { click: _vm.removeSpec },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "trash_icon",
                                      src: "/images/trash_icon.svg",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.ktruItem && _vm.visibleDetails
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "align-text-top",
                              attrs: { colspan: "6" },
                            },
                            [
                              _vm.ktruItem
                                ? [
                                    _vm.ktruItem.description
                                      ? _c("p", [
                                          _c("b", [_vm._v("Описание:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.ktruItem.description)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.ktruItem.specs &&
                                    _vm.ktruItem.specs.length > 0
                                      ? _c("p", [
                                          _c("b", [_vm._v("Характеристики:")]),
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "ml-4" }, [
                                      _vm.ktruItem.specs &&
                                      _vm.ktruItem.specs.length > 0
                                        ? _c(
                                            "ul",
                                            _vm._l(
                                              _vm.ktruItem.specs,
                                              function (spec, specIndex) {
                                                return _c(
                                                  "li",
                                                  { key: "spec_" + specIndex },
                                                  [
                                                    _c("b", [
                                                      _vm._v(_vm._s(spec.name)),
                                                    ]),
                                                    _vm._v(
                                                      " - " +
                                                        _vm._s(spec.value) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                : [_vm._v("—")],
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    !_vm.ktruItem
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { align: "center", colspan: "6" } },
                            [_vm._v("Выберите позицию КТРУ")]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
            _vm.specErrorMessage
              ? _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.specErrorMessage)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("purchase-specification-modal", {
        attrs: {
          "is-import-substitution-reply": this.isImportSubstitutionReply,
          "is-import-substitution-request": !this.isImportSubstitutionReply,
          show: _vm.showSpec,
        },
        on: { add: _vm.selectKtruItem, cancel: _vm.cancelSpecModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }