var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", { attrs: { id: "supplementary-agreements" } }, [
        _vm._v("Дополнительные соглашения"),
      ]),
      _vm.purchase.supplementary_agreements.length > 0
        ? _c(
            "div",
            { staticClass: "accordion", attrs: { role: "tablist" } },
            _vm._l(
              _vm.purchase.supplementary_agreements,
              function (agreement, key) {
                return _c("supplementary-agreement-item", {
                  key: `agreement-${key}`,
                  attrs: {
                    title: `Дополнительное соглашение №${agreement.number}`,
                    purchase: _vm.purchase,
                    "supplementary-agreement": agreement,
                    visible:
                      _vm.purchase.last_supplementary_agreement_id ===
                      agreement.id,
                  },
                  on: { refresh: _vm.done },
                })
              }
            ),
            1
          )
        : _vm._e(),
      _vm.isPurchaseCreator() && _vm.purchase.status.id === "contract:active"
        ? [
            !_vm.isCreate
              ? _c(
                  "b-button",
                  {
                    staticClass: "text-uppercase mt-1",
                    attrs: { variant: "custom-green" },
                    on: {
                      click: function ($event) {
                        _vm.isCreate = true
                      },
                    },
                  },
                  [_vm._v("Добавить дополнительное соглашение")]
                )
              : _c("create-supplementary-agreements", {
                  attrs: { "purchase-id": _vm.purchase.id },
                  on: {
                    cancel: function ($event) {
                      _vm.isCreate = false
                    },
                    done: _vm.done,
                  },
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }