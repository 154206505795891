var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "py-5 w-100" },
    [
      _c("b-col", [
        _c("div", { staticClass: "purchase_part" }, [
          _c(
            "div",
            { staticClass: "purchase_part-header" },
            [
              _c("b-progress", {
                staticClass: "w-25",
                attrs: { value: 100, variant: "light", animated: "" },
              }),
              _c("p", { staticClass: "purchase_part-header_label" }, [
                _c("img", {
                  staticClass: "mr-1",
                  attrs: { src: "/images/check.svg", alt: "check_label" },
                }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "purchase_part-body row" },
            [
              _c("b-col", { staticClass: "purchase_part-body_col" }, [
                _c("div", [
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-75 my-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-18 m-0 fw-600 mb-5" },
                    [
                      _c("b-progress", {
                        staticClass: "w-50",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 fw-600" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("b-col", { staticClass: "purchase_part-body_col" }, [
                _c("div", [
                  _c(
                    "p",
                    { staticClass: "fs-12 fw-600 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25 my-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 mb-3" },
                    [
                      _c("b-progress", {
                        staticClass: "w-75",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25 my-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "green-color text-uppercase fs-14" },
                    [
                      _c("b-progress", {
                        staticClass: "w-75",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("b-col", { staticClass: "purchase_part-body_col" }, [
                _c("div", [
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25 my-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-50 mb-2",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 mb-3" },
                    [
                      _c("b-progress", {
                        staticClass: "w-75 mb-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0" },
                    [
                      _c("b-progress", {
                        staticClass: "w-100 mb-2",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "fs-12 m-0 fw-600" },
                    [
                      _c("b-progress", {
                        staticClass: "w-25 mb-2",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("b-col", { staticClass: "purchase_part-body_col" }, [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "d-flex align-items-center green-color fs-12 fw-600",
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/info.svg", alt: "info" },
                      }),
                      _c("b-progress", {
                        staticClass: "w-50",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "d-flex align-items-center green-color fs-12 fw-600",
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/chart.svg", alt: "chart" },
                      }),
                      _c("b-progress", {
                        staticClass: "w-50",
                        attrs: { value: 100, variant: "dark", animated: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "d-flex align-items-center green-color fs-12 fw-600",
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/coins.svg", alt: "coins" },
                      }),
                      _c("b-progress", {
                        staticClass: "w-50",
                        attrs: {
                          value: 100,
                          variant: "secondary",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }