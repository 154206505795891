<template>
    <div>
        <system-preloader v-if="isLoading"></system-preloader>
        <div v-if="!isLoading">
            <text-header>Создание запроса на возврат средств</text-header>
            <h5 class="font-weight-bold my-3 text-danger">Предупреждаем Вас о том, что с момента отправки запроса на возврат средств сумма, указанная в нем, перестанет быть доступной на лицевом счете Вашей организации.</h5>
            <text-row-price label="Доступные на ЛС средства" v-model="statistics.available" />
            <form-row-edit-price label="Сумма возврата" v-model="form.amount" :v="$v.form.amount" :limit="statistics.available" />
            <form-row-single-document-upload-new title="Реквизиты для возврата средств" v-model="form.document" :v="$v.form.document" />
            <div class="text-left">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2 fs-14" @click="onCancel">Отмена</b-button>
                <b-button variant="custom-green" class="text-uppercase mx-2 fs-14" @click="validateForm" :disabled="$v.form.$invalid || !$store.getters.getCanSign">Подписать и отправить</b-button>
                <template v-if="!$store.getters.getCanSign">
                    <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                    <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                </template>
            </div>
        </div>
        <b-modal ref="sign-form-modal" title="Подписать заявку" size="lg" v-model="showSignModal" scrollable centered no-close-on-esc no-close-on-backdrop @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3 px-3">
                    <b-tab active>
                        <template #title>
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <text-header>Организация</text-header>
                        <text-row compact label="Наименование организации" v-model="$store.state.organization.name" />
                        <text-row compact label="ИНН" v-model="$store.state.organization.inn" />
                        <text-row compact label="КПП" v-model="$store.state.organization.kpp" />
                        <text-row compact label="ОГРН / ОГРНИП" v-model="$store.state.organization.ogrn" />
                        <text-row compact label="Адрес" v-model="$store.state.organization.post_address" />
                        <text-row compact label="Адрес электронной почты" v-model="$store.state.organization.email" />
                        <text-row compact label="Номер контактного телефона" v-model="$store.state.organization.phone" />
                        <text-header>Запрос на возврат средств</text-header>
                        <text-row-price compact label="Сумма возврата" v-model="form.amount" />
                        <text-row-documents compact label="Реквизиты для возврата средств" v-model="formDocuments" />
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <span class="system_link-style fs-16">Xml представление</span>
                        </template>
                        <b-form-textarea disabled v-if="!isSignedXml" v-model="formXml" rows="20" class="text-monospace fs-12" no-resize no-auto-shrink wrap="off" />
                        <b-form-textarea disabled v-if="isSignedXml" v-model="signedFormXml" rows="20" class="text-monospace fs-12" no-resize no-auto-shrink wrap="off" />
                    </b-tab>
                </b-tabs>
            </div>
            <div slot="modal-footer" class="w-100 px-3">
                <text-row label="Сертификат" :value="certificate?.subjectName" />
                <b-button variant="custom-outline-secondary" class="mr-2 text-uppercase" @click="cancelSignModal" :disabled="isDataSending">Отмена</b-button>
                <b-overlay :show="isSigningXml" v-if="!isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="!certificate" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" v-if="isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="!certificate" @click="sendData">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { getCertificate } from 'crypto-pro';
import { $api } from '@/services/backend/api';

export default {
    name: 'cabinet-provider-accounting-refund-request-create',
    data() {
        return {
            isLoading: true,
            statistics: {
                total: 0,
                available: 0,
                blocked: 0
            },
            form: {
                amount: 0,
                document: null
            },
            formXml: '',
            showSignModal: false,
            isSigningXml: false,
            isSignedXml: false,
            isDataSending: false,
            signedFormXml: '',
            certificate: null
        };
    },
    validations() {
        return {
            form: {
                amount: { required, minValue: minValue(0.01), maxValue: maxValue(this.statistics.available) },
                document: { required }
            }
        };
    },
    async created() {
        if (!this.$store.getters.isLoggedIn || this.isUserCustomer) {
            await this.$router.replace({ name: '404' }).catch(() => {});
        }
    },
    async mounted() {
        this.statistics = await this.$api.cabinet.provider.accounting.getStatistics();
        this.isLoading = false;
    },
    methods: {
        async validateForm() {
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (!this.certificate) {
                return;
            }
            await this.showSignForm();
        },
        async showSignForm() {
            this.formXml = await this.$api.cabinet.provider.accounting.getXml(this.form);
            this.showSignModal = true;
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await $api.signXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
        },
        async sendData() {
            this.isDataSending = true;
            if (await this.$api.cabinet.provider.accounting.storeSignedRefundRequest(this.form, this.signedFormXml, this.certificate)) {
                this.onCancel();
            }
            this.isDataSending = false;
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        onCancel() {
            this.$router.push({ name: 'ProviderAccountCabinet' });
        }
    },
    computed: {
        formDocuments() {
            return [this.form.document];
        }
    }
};
</script>
