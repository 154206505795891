var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.showModal,
            "hide-footer": "",
            "no-close-on-backdrop": "",
            scrollable: "",
            "no-close-on-esc": "",
            size: "xl",
            title: "Выбрать позицию спецификации",
          },
          on: { show: _vm.fillData, close: _vm.onClose },
          scopedSlots: _vm._u([
            {
              key: "modal-header-close",
              fn: function () {
                return [_c("button-close-modal")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c("filter-text", {
                attrs: {
                  md: 3,
                  label: "Рег.номер",
                  placeholder: "Введите рег.номер",
                },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.filter.reg_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "reg_number", $$v)
                  },
                  expression: "filter.reg_number",
                },
              }),
              _c("filter-text", {
                attrs: {
                  md: 3,
                  label: "Название",
                  placeholder: "Введите название",
                },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.filter.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "name", $$v)
                  },
                  expression: "filter.name",
                },
              }),
              _c("filter-text", {
                attrs: {
                  md: 3,
                  label: "Характеристики",
                  placeholder: "Введите текст характеристик",
                },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.filter.specs,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "specs", $$v)
                  },
                  expression: "filter.specs",
                },
              }),
              _c("filter-text", {
                attrs: {
                  md: 3,
                  label: "Код ОКПД2",
                  placeholder: "Введите код ОКПД2 или его часть",
                },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.filter.okpd_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "okpd_code", $$v)
                  },
                  expression: "filter.okpd_code",
                },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              !_vm.items.length
                ? _c("b-col", { staticClass: "text-center mt-5" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/empty_box.svg",
                        alt: "empty-data",
                      },
                    }),
                    _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                      _vm._v("По вашему запросу ничего не найдено"),
                    ]),
                  ])
                : _c(
                    "b-col",
                    { staticClass: "mb-3" },
                    [
                      _c("b-table", {
                        staticClass: "table-atmo",
                        attrs: {
                          id: "ktru-table",
                          busy: _vm.isLoading,
                          fields: _vm.fields,
                          items: _vm.items,
                          small: "",
                          hover: "",
                          responsive: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "row-details",
                            fn: function (data) {
                              return [
                                data.item.description
                                  ? _c("p", [
                                      _c("b", [_vm._v("Описание:")]),
                                      _vm._v(
                                        " " + _vm._s(data.item.description)
                                      ),
                                    ])
                                  : _vm._e(),
                                data.item.specs && data.item.specs.length > 0
                                  ? _c("p", [
                                      _c("b", [_vm._v("Характеристики:")]),
                                    ])
                                  : _vm._e(),
                                data.item.specs && data.item.specs.length > 0
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        data.item.specs,
                                        function (spec, key) {
                                          return _c(
                                            "li",
                                            { key: "spec" + key },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "900",
                                                  },
                                                },
                                                [_vm._v(_vm._s(spec.name))]
                                              ),
                                              _vm._v(
                                                ": " + _vm._s(spec.value) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(reg_num)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.reg_number) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(okpd)",
                            fn: function (data) {
                              return [
                                _vm._v(" " + _vm._s(data.item.okpd_code) + " "),
                              ]
                            },
                          },
                          {
                            key: "cell(specs)",
                            fn: function (data) {
                              return [
                                _c("action-button-small", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        (data.detailsShowing
                                          ? "Скрыть"
                                          : "Показать") + " характеристики",
                                      expression:
                                        "(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  attrs: {
                                    icon: data.detailsShowing
                                      ? "eye-slash"
                                      : "eye",
                                  },
                                  on: { click: data.toggleDetails },
                                }),
                                _vm._v(" " + _vm._s(data.item.name) + " "),
                              ]
                            },
                          },
                          {
                            key: "cell(okei)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(data.item.okei_local_symbol) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(country)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(data.item.country_of_origin_name) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(actions)",
                            fn: function (data) {
                              return [
                                !data.item.is_favorite
                                  ? _c("action-button-small", {
                                      attrs: { icon: "plus" },
                                      on: {
                                        click: function ($event) {
                                          _vm
                                            .addItem(data.item.id)
                                            .then(
                                              () =>
                                                (data.item.is_favorite = true)
                                            )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.items && _vm.meta.total > 0 && _vm.meta.last_page > 1
                        ? _c("b-pagination", {
                            staticClass: "my-0 py-1",
                            attrs: {
                              "per-page": _vm.meta.per_page,
                              "total-rows": _vm.meta.total,
                              align: "center",
                              "aria-controls": "ktru-table",
                            },
                            on: { input: _vm.fillData },
                            model: {
                              value: _vm.meta.current_page,
                              callback: function ($$v) {
                                _vm.$set(_vm.meta, "current_page", $$v)
                              },
                              expression: "meta.current_page",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }