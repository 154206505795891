var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between" },
            [_c("text-header-new", [_vm._v("Уведомления")])],
            1
          ),
          _c(
            "b-col",
            { staticClass: "col-auto" },
            [
              _c(
                "b-btn",
                {
                  attrs: {
                    variant: "custom-green",
                    size: "sm",
                    to: { name: "NotificationSettings" },
                  },
                },
                [
                  _c("b-icon-gear-fill", {
                    staticClass: "my-auto mr-2",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Настроить получение уведомлений "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.notifications.length
        ? [
            _c(
              "b-overlay",
              {
                attrs: {
                  show: _vm.isDataSending && _vm.isNotificationsLoad,
                  opacity: "0.6",
                  rounded: "",
                  "spinner-small": "",
                  "spinner-variant": "primary",
                },
              },
              [
                _c(
                  "b-container",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-1", attrs: { fluid: "" } },
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-auto px-1 fs-14 my-auto" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "p-0",
                                staticStyle: { width: "24px", height: "24px" },
                                attrs: { variant: "btn btn-outline" },
                                on: { click: _vm.selectDeselectAllRows },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "checkbox-icon my-auto",
                                  attrs: {
                                    "aria-hidden": "true",
                                    icon: _vm.isAllSelected
                                      ? "check2-square"
                                      : "square",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.isAnySelected
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "p-0 ml-1",
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                    },
                                    attrs: {
                                      title: "Прочитать",
                                      variant: "outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.markNotificationsAsRead(null)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass:
                                        "green-color read-icon my-auto",
                                      attrs: {
                                        icon: "circle",
                                        scale: "0.6",
                                        "aria-hidden": "true",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "b-button",
                                  {
                                    staticClass: "p-0 ml-1",
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                    },
                                    attrs: {
                                      title: "Отметить все прочитанными",
                                      variant: "outline",
                                    },
                                    on: {
                                      click: _vm.markAllNotificationsAsRead,
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass:
                                        "green-color read-icon my-auto",
                                      attrs: {
                                        icon: "circle",
                                        scale: "0.6",
                                        "aria-hidden": "true",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                        _c("b-col", { staticClass: "col-auto px-1" }),
                        _c("b-col"),
                        _c(
                          "b-col",
                          { staticClass: "col-auto px-1" },
                          [
                            _vm.isAnySelected
                              ? _c("action-button-small", {
                                  attrs: {
                                    icon: "trash",
                                    variant: "outline",
                                    title: "Удалить выбранные",
                                  },
                                  on: { click: _vm.deleteNotifications },
                                })
                              : _c("action-button-small", {
                                  attrs: {
                                    icon: "trash",
                                    variant: "outline",
                                    title: "Удалить все",
                                  },
                                  on: { click: _vm.deleteAllNotifications },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.notifications.length
        ? [
            _c(
              "b-overlay",
              {
                attrs: {
                  show: _vm.isNotificationsLoad,
                  opacity: "0.6",
                  rounded: "",
                  "spinner-variant": "primary",
                },
              },
              [_c("empty-request")],
              1
            ),
          ]
        : [
            _c(
              "b-overlay",
              {
                attrs: {
                  show: _vm.isDataSending && _vm.isNotificationsLoad,
                  opacity: "0.6",
                  rounded: "",
                  "spinner-variant": "primary",
                },
              },
              [
                _c(
                  "b-container",
                  _vm._l(_vm.notifications, function (notification) {
                    return _c("notification-row", {
                      key: notification.id,
                      attrs: {
                        notification: notification,
                        "row-selected": notification.row_selected,
                        "with-checkbox": "",
                      },
                      on: {
                        select: _vm.select,
                        read: _vm.markNotificationsAsRead,
                        unread: _vm.markNotificationsAsUnread,
                        delete: _vm.deleteNotifications,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
      _vm.notifications.length && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? [
            _c(
              "b-row",
              { staticClass: "w-100 m-0" },
              [
                _c(
                  "b-col",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "b-overlay",
                      {
                        staticClass: "d-inline-block",
                        attrs: {
                          show:
                            _vm.isNotificationsLoad &&
                            _vm.isDataSending &&
                            _vm.notifications.length &&
                            _vm.meta.total > 0 &&
                            _vm.meta.last_page > 1,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-small": "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c("b-pagination", {
                          staticClass: "my-0 py-0",
                          attrs: {
                            "per-page": _vm.meta.per_page,
                            "total-rows": _vm.meta.total,
                            align: "center",
                          },
                          on: { input: _vm.onPaginate },
                          model: {
                            value: _vm.meta.current_page,
                            callback: function ($$v) {
                              _vm.$set(_vm.meta, "current_page", $$v)
                            },
                            expression: "meta.current_page",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }