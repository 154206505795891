var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "8" } },
            [_c("text-header-new", [_vm._v("Избранное")])],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { size: "sm", variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      _vm.showAddModal = true
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { "aria-hidden": "true", icon: "plus-circle" },
                  }),
                  _vm._v(" Добавить позицию КТРУ "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.items.length
            ? _c("b-col", { staticClass: "text-center mt-5" }, [
                _c("img", {
                  attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
                }),
                _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                  _vm._v("В данный момент избранных позиций КТРУ нет"),
                ]),
              ])
            : _c(
                "b-col",
                { staticClass: "mb-3" },
                [
                  _vm.items && _vm.meta.total > 0 && _vm.meta.last_page > 1
                    ? _c("b-pagination", {
                        staticClass: "my-2",
                        attrs: {
                          "per-page": _vm.meta.per_page,
                          "total-rows": _vm.meta.total,
                          align: "center",
                          "aria-controls": "my-table",
                        },
                        model: {
                          value: _vm.meta.current_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.meta, "current_page", $$v)
                          },
                          expression: "meta.current_page",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", [
                        _c("span", [
                          _vm._v("Выбрано позиций "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.allSelected.length)),
                          ]),
                          _vm._v(" из "),
                          _c("strong", [_vm._v(_vm._s(_vm.meta.total))]),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.allowCreatePurchase,
                                variant: "custom-green",
                                text: "Создать на основе выбранных позиций",
                                right: "",
                              },
                            },
                            [
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL44
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft("fl44")
                                        },
                                      },
                                    },
                                    [_vm._v("Закупку 44ФЗ")]
                                  )
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL44
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft(
                                            "fl44_external"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Внесистемную закупку 44ФЗ")]
                                  )
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL223
                              )
                                ? [
                                    _vm.$store.state.organization.settings.fl223
                                      .allowed
                                      ? [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass: "text-normal-case",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createDraft(
                                                    "fl223"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Закупку 223-ФЗ")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass: "text-normal-case",
                                              attrs: {
                                                id: "popover-create-fl223-item",
                                              },
                                            },
                                            [_vm._v("Закупку 223-ФЗ")]
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "popover-create-fl223-item",
                                                triggers: "hover",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fs-12" },
                                                [
                                                  _vm._v(
                                                    " Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в "
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "var(--green-color)",
                                                        "text-decoration":
                                                          "underline",
                                                      },
                                                      attrs: {
                                                        to: {
                                                          name: "CabinetFl223Settings",
                                                        },
                                                        "active-class": "",
                                                        "exact-active-class":
                                                          "",
                                                      },
                                                    },
                                                    [_vm._v("настройки")]
                                                  ),
                                                  _vm._v(". "),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                  ]
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_COMMERCIAL
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft("commercial")
                                        },
                                      },
                                    },
                                    [_vm._v("Коммерческую закупку")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "atmo-badge",
                              staticStyle: { top: "-14px", "z-index": "1" },
                            },
                            [_vm._v("Новое")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-table", {
                    ref: "selectableTable",
                    staticClass: "table-atmo",
                    attrs: {
                      id: "my-table",
                      busy: _vm.isLoading,
                      fields: _vm.fields,
                      items: _vm.items,
                      selectable: "",
                      "select-mode": "multi",
                      small: "",
                      hover: "",
                      responsive: "",
                    },
                    on: { "row-selected": _vm.onRowSelected },
                    scopedSlots: _vm._u([
                      {
                        key: "head(selected)",
                        fn: function () {
                          return [
                            _vm.items.length === _vm.pageSelected.length
                              ? [
                                  _c("b-icon", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "aria-hidden": "true",
                                      icon: "check2-square",
                                    },
                                    on: { click: _vm.selectDeselectAllRows },
                                  }),
                                ]
                              : [
                                  _c("b-icon", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      "aria-hidden": "true",
                                      icon: "square",
                                    },
                                    on: { click: _vm.selectDeselectAllRows },
                                  }),
                                ],
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(selected)",
                        fn: function ({ rowSelected }) {
                          return [
                            rowSelected
                              ? [
                                  _c("b-icon", {
                                    attrs: {
                                      "aria-hidden": "true",
                                      icon: "check2-square",
                                    },
                                  }),
                                ]
                              : [
                                  _c("b-icon", {
                                    attrs: {
                                      "aria-hidden": "true",
                                      icon: "square",
                                    },
                                  }),
                                ],
                          ]
                        },
                      },
                      {
                        key: "row-details",
                        fn: function (data) {
                          return [
                            data.item.ktru_item.description
                              ? _c("p", [
                                  _c("b", [_vm._v("Описание:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.ktru_item.description)
                                  ),
                                ])
                              : _vm._e(),
                            data.item.ktru_item.specs &&
                            data.item.ktru_item.specs.length > 0
                              ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                              : _vm._e(),
                            data.item.ktru_item.specs &&
                            data.item.ktru_item.specs.length > 0
                              ? _c(
                                  "ul",
                                  _vm._l(
                                    data.item.ktru_item.specs,
                                    function (spec, key) {
                                      return _c("li", { key: "spec" + key }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "900",
                                            },
                                          },
                                          [_vm._v(_vm._s(spec.name))]
                                        ),
                                        _vm._v(": " + _vm._s(spec.value) + " "),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "cell(reg_num)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.ktru_item.reg_number)
                            ),
                            _c("br"),
                            _vm._v(_vm._s(data.item.ktru_item.okpd_code) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(specs)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    (data.detailsShowing
                                      ? "Скрыть"
                                      : "Показать") + " характеристики",
                                  expression:
                                    "(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                icon: data.detailsShowing ? "eye-slash" : "eye",
                              },
                              on: { click: data.toggleDetails },
                            }),
                            _vm._v(
                              " " + _vm._s(data.item.ktru_item.name) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(okei)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(data.item.ktru_item.okei_local_symbol) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(country)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.ktru_item.country_of_origin_name
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              attrs: { icon: "trash" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDeleteItem(data.item.id)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("span", [
                          _vm._v("Выбрано позиций "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.allSelected.length)),
                          ]),
                          _vm._v(" из "),
                          _c("strong", [_vm._v(_vm._s(_vm.meta.total))]),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.allowCreatePurchase,
                                variant: "custom-green",
                                text: "Создать на основе выбранных позиций",
                                right: "",
                              },
                            },
                            [
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL44
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft("fl44")
                                        },
                                      },
                                    },
                                    [_vm._v("Закупку 44ФЗ")]
                                  )
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL44
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft(
                                            "fl44_external"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Внесистемную закупку 44ФЗ")]
                                  )
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_FL223
                              )
                                ? [
                                    _vm.$store.state.organization.settings.fl223
                                      .allowed
                                      ? [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass: "text-normal-case",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createDraft(
                                                    "fl223"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Закупку 223-ФЗ")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass: "text-normal-case",
                                              attrs: {
                                                id: "popover-create-fl223-item1",
                                              },
                                            },
                                            [_vm._v("Закупку 223-ФЗ")]
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "popover-create-fl223-item1",
                                                triggers: "hover",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fs-12" },
                                                [
                                                  _vm._v(
                                                    " Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в "
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "var(--green-color)",
                                                        "text-decoration":
                                                          "underline",
                                                      },
                                                      attrs: {
                                                        to: {
                                                          name: "CabinetFl223Settings",
                                                        },
                                                        "active-class": "",
                                                        "exact-active-class":
                                                          "",
                                                      },
                                                    },
                                                    [_vm._v("настройки")]
                                                  ),
                                                  _vm._v(". "),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                  ]
                                : _vm._e(),
                              _vm.orgRoleIsPermitted(
                                _vm.$orgRoles.CUSTOMER_COMMERCIAL
                              )
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "text-normal-case",
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDraft("commercial")
                                        },
                                      },
                                    },
                                    [_vm._v("Коммерческую закупку")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "atmo-badge",
                              staticStyle: { top: "-14px", "z-index": "1" },
                            },
                            [_vm._v("Новое")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.items && _vm.meta.total > 0 && _vm.meta.last_page > 1
                    ? _c("b-pagination", {
                        staticClass: "my-2",
                        attrs: {
                          "per-page": _vm.meta.per_page,
                          "total-rows": _vm.meta.total,
                          align: "center",
                          "aria-controls": "my-table",
                        },
                        on: { input: _vm.fillSelected },
                        model: {
                          value: _vm.meta.current_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.meta, "current_page", $$v)
                          },
                          expression: "meta.current_page",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _c("add-favorite-modal", {
        attrs: {
          "add-item": _vm.addItem,
          "show-modal": _vm.showAddModal,
          "on-close": _vm.onCloseModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }