var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-2" }, [
    _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
      _vm._v(_vm._s(_vm.label) + ":"),
    ]),
    _c(
      "p",
      { staticClass: "fs-12 m-0 green-color fw-600" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }