var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-table", {
    staticClass: "table-atmo",
    class: { "is-table-invalid": _vm.v.$invalid },
    attrs: {
      id: "my-table",
      fields: _vm.fields,
      items: _vm.items,
      busy: _vm.isLoading,
      "details-td-class": "py-1",
      small: "",
      responsive: "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "cell(index)",
          fn: function (data) {
            return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
          },
        },
        {
          key: "row-details",
          fn: function (data) {
            return [
              data.item.ktru
                ? [
                    _c("p", [
                      _c("b", [_vm._v("Рег.номер ТРУ:")]),
                      _vm._v(" " + _vm._s(data.item.ktru.reg_number)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Группа ТРУ:")]),
                      _vm._v(" " + _vm._s(data.item.ktru.ktru_group_name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Наименование:")]),
                      _vm._v(" " + _vm._s(data.item.ktru.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Тип:")]),
                      _vm._v(" " + _vm._s(data.item.ktru.type)),
                    ]),
                    data.item.okpd
                      ? _c("p", [
                          _c("b", [_vm._v("Код ОКПД2:")]),
                          _vm._v(
                            " " +
                              _vm._s(data.item.okpd ? data.item.okpd.code : "—")
                          ),
                        ])
                      : _vm._e(),
                    data.item.ktru.country_of_origin_name
                      ? _c("p", [
                          _c("b", [_vm._v("Страна происхождения:")]),
                          _vm._v(
                            " " + _vm._s(data.item.ktru.country_of_origin_name)
                          ),
                        ])
                      : _vm._e(),
                    data.item.ktru.description
                      ? _c("p", [
                          _c("b", [_vm._v("Описание:")]),
                          _vm._v(" " + _vm._s(data.item.ktru.description)),
                        ])
                      : _vm._e(),
                    data.item.ktru.specs && data.item.ktru.specs.length > 0
                      ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                      : _vm._e(),
                    data.item.ktru.specs && data.item.ktru.specs.length > 0
                      ? _c(
                          "ul",
                          _vm._l(data.item.ktru.specs, function (spec, key) {
                            return _c("li", { key: "spec" + key }, [
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(_vm._s(spec.name)),
                              ]),
                              _vm._v(": " + _vm._s(spec.value) + " "),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                : [_vm._v("—")],
            ]
          },
        },
        {
          key: "cell(specs)",
          fn: function (data) {
            return [
              _c("action-button-small", {
                attrs: {
                  title:
                    (data.detailsShowing ? "Скрыть" : "Показать") +
                    " характеристики",
                  icon: data.detailsShowing ? "eye-slash" : "eye",
                },
                on: { click: data.toggleDetails },
              }),
              _vm._v(" " + _vm._s(data.item.ktru.name) + " "),
            ]
          },
        },
        {
          key: "cell(amount)",
          fn: function (data) {
            return [
              _c("b-form-input", {
                staticClass: "mb-0 form-control fs-14 text-right amount-input",
                class:
                  !_vm.v.$each[data.index].amount.$invalid &&
                  _vm.v.$each[data.index].maxAmount &&
                  _vm.v.$each[data.index].minAmount
                    ? "is-valid"
                    : "is-invalid",
                attrs: {
                  min: "0.000000001",
                  step: "0.000000001",
                  type: "number",
                },
                on: {
                  focus: function ($event) {
                    $event.target?.select()
                  },
                },
                model: {
                  value: data.item.amount,
                  callback: function ($$v) {
                    _vm.$set(data.item, "amount", _vm._n($$v))
                  },
                  expression: "data.item.amount",
                },
              }),
            ]
          },
        },
        {
          key: "cell(okei)",
          fn: function (data) {
            return [
              data.item.okei
                ? [_vm._v(_vm._s(data.item.okei.local_symbol))]
                : [_vm._v("—")],
            ]
          },
        },
        {
          key: "cell(price)",
          fn: function (data) {
            return [
              _c("currency-input", {
                staticClass:
                  "form-control fs-14 text-right currency-input mb-0",
                class:
                  !_vm.v.$each[data.index].price.$invalid &&
                  _vm.v.$each[data.index].maxPrice
                    ? "is-valid"
                    : "is-invalid",
                attrs: { currency: "RUB", locale: "ru" },
                on: {
                  focus: function ($event) {
                    $event.target?.select()
                  },
                },
                model: {
                  value: data.item.price,
                  callback: function ($$v) {
                    _vm.$set(data.item, "price", $$v)
                  },
                  expression: "data.item.price",
                },
              }),
            ]
          },
        },
        {
          key: "cell(cost)",
          fn: function (data) {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.$formatPrice(data.item.amount * data.item.price)) +
                  " "
              ),
            ]
          },
        },
        {
          key: "cell(actions)",
          fn: function (data) {
            return [
              data.item.is_manually_added
                ? _c("action-button-small", {
                    attrs: {
                      variant: "outline",
                      icon: "trash",
                      title: "Удалить",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteItem(data.index)
                      },
                    },
                  })
                : _c("action-button-small", {
                    attrs: {
                      icon: "diagram2-fill",
                      title: "Дублировать спецификацию",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.duplicateItem(data.index)
                      },
                    },
                  }),
            ]
          },
        },
        _vm.totalCost
          ? {
              key: "bottom-row",
              fn: function () {
                return [
                  _c(
                    "b-td",
                    { staticClass: "text-right py-1", attrs: { colspan: "6" } },
                    [
                      _c("span", { staticClass: "fw-600" }, [
                        _vm._v(
                          "Итого, руб.: " +
                            _vm._s(_vm.$formatPrice(_vm.totalCost))
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }