<template>
    <li class="nav-item" @click="expanded = !expanded">
        <a :class="{ 'router-link-active': checkPath }" class="nav-link" href="javascript:void(0)" target="_self">
            <template v-if="strong"
                ><strong>{{ title }}</strong></template
            >
            <template v-else>{{ title }}</template>
            <b-collapse :visible="expanded || checkPath" class="mt-2 cabinet_submenu">
                <slot></slot>
            </b-collapse>
        </a>
    </li>
</template>

<script>
export default {
    name: 'cabinet-submenu',
    props: {
        title: {
            required: true,
            type: String
        },
        path: {},
        strong: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false
        };
    },
    computed: {
        checkPath() {
            if (Array.isArray(this.path)) {
                return this.path.some((item) => this.$route.path.startsWith(item));
            } else {
                return Boolean(this.$route.path.startsWith(this.path));
            }
        }
    }
};
</script>

<style scoped>
.cabinet_menu li {
    position: relative;
    background: #fff;
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}
</style>
<style scoped>
.cabinet_menu .atmo-badge {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 2px 6px;
    font-size: 0.8em;
    color: white;
    background-color: #dc3545;
    border-radius: 3px;
}

.cabinet_menu {
    position: -webkit-sticky;
    position: sticky;
    top: 160px;
    -webkit-box-shadow: 0 0 10px -5px #aaa;
    -moz-box-shadow: 0 0 10px -5px #aaa;
    box-shadow: 0 0 10px -5px #aaa;
}

.cabinet_menu li {
    position: relative;
    background: #fff;
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li .router-link-active,
.cabinet_menu li .active,
.cabinet_menu li.notify_active {
    border-left: 8px solid var(--green-color);
    /*border-radius: 4px 0 0 4px;*/
    color: var(--green-color);
    font-weight: 600;
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

@media screen and (max-width: 769px) {
    .if-tablet {
        font-size: 40px !important;
    }
}
</style>

<style>
#privider_info .dropdown-item {
    white-space: unset !important;
}

.provider_menu .dropdown-item.active {
    background: transparent;
    color: var(--green-color);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}

.button-badge {
    position: relative;
    top: 15px;
    right: -30px;
    line-height: 0;
    padding: 2px 6px;
    font-size: 0.8em;
    color: white;
    background-color: #dc3545;
    border-radius: 3px;
}
</style>
