<template>
    <b-container fluid :class="`${grey ? 'greyBg' : ''} pb-4`">
        <b-container>
            <b-row>
                <b-col class="d-flex justify-content-between align-items-center">
                    <p class="fs-32 grey-color my-4">{{ title }}</p>
                    <img v-if="img" :src="img" :alt="imgAlt" />
                </b-col>
            </b-row>
            <slot></slot>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'FormContentGroup',
    props: {
        title: {
            type: String,
            required: true
        },
        img: {
            type: String,
            default: null
        },
        imgAlt: {
            type: String,
            default: null
        },
        grey: {
            type: Boolean,
            default: false
        }
    }
};
</script>
