var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase
    ? _c(
        "div",
        [
          _c(
            "p",
            { staticClass: "fs-12 m-0 grey-color text-break" },
            [
              _c(
                "span",
                { staticClass: "mr-2", on: { click: _vm.showModal } },
                [
                  _c("img", {
                    staticStyle: { width: "16px", cursor: "pointer" },
                    attrs: { src: "/images/edit_icon.svg", alt: "check_label" },
                  }),
                ]
              ),
              _c("b", [_vm._v("Примечание: ")]),
              _vm.comment
                ? [_vm._v(" " + _vm._s(_vm.comment) + " ")]
                : [_vm._v(" — ")],
            ],
            2
          ),
          _c(
            "b-modal",
            {
              attrs: {
                title:
                  "Редактирование примечания к закупке " +
                  _vm.purchase.reg_number,
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "no-close-on-ok": "",
                "hide-footer": "",
              },
              on: {
                cancel: _vm.handleModalCancel,
                close: _vm.handleModalCancel,
                ok: _vm.handleModalAccept,
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("form-row-edit-textarea", {
                        attrs: { label: "Примечание к закупке", rows: 8 },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.handleModalCancel },
                        },
                        [_vm._v("Отмена")]
                      ),
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataUpdating,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { variant: "custom-green" },
                              on: { click: _vm.handleModalAccept },
                            },
                            [_vm._v("Сохранить")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }