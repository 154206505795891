var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "pb-5 greyBg", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c("img", { attrs: { src: "/images/404.svg", alt: "404" } }),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-center mt-3" }, [
                _c("span", { staticClass: "fs-12 grey-color" }, [
                  _vm._v(
                    "Неправильно набран адрес, или такой страницы на сайте больше не существует."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center mt-5" },
                [
                  _c(
                    "router-link",
                    { staticClass: "btn btn-custom-green", attrs: { to: "/" } },
                    [_vm._v("ГЛАВНАЯ СТРАНИЦА")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }