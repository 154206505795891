<template>
    <b-container>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <p class="fs-32 grey-color my-4">ТРУ для импортозамещения</p>
            </b-col>
        </b-row>
        <b-row class="py-2" no-gutters>
            <b-col class="grey-color">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="1"></th>
                                <th nowrap width="100px">Ед.изм</th>
                                <th nowrap width="100px">Страна происхождения</th>
                                <th style="min-width: 160px" width="160px">Цена за ед., руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ktruItem">
                                <td class="align-text-top">
                                    {{ ktruItem.name }}
                                </td>
                                <td width="1">
                                    <button class="atmo-button-icon float-right" @click="visibleDetails = !visibleDetails">
                                        <span title="Показать описание/характеристики">
                                            <i v-if="!visibleDetails" class="atmo-icon-show" />
                                            <i v-else class="atmo-icon-hide" />
                                        </span>
                                    </button>
                                </td>
                                <td class="align-text-top">{{ ktruItem.okei_local_symbol }}</td>
                                <td class="align-text-top">{{ ktruItem.country_of_origin_name }}</td>
                                <td class="align-text-top">{{ price }}</td>
                            </tr>
                            <tr v-if="ktruItem && visibleDetails">
                                <td class="align-text-top" colspan="5">
                                    <p v-if="ktruItem.description"><b>Описание:</b> {{ ktruItem.description }}</p>
                                    <p v-if="ktruItem.specs && ktruItem.specs.length > 0"><b>Характеристики:</b></p>
                                    <div class="ml-4">
                                        <ul v-if="ktruItem.specs && ktruItem.specs.length > 0">
                                            <li v-for="(spec, specIndex) of ktruItem.specs" :key="'spec_' + specIndex">
                                                <strong>{{ spec.name }}</strong> - {{ spec.value }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'ImportSubstitutionRequestShowKtruItemBlock',
    props: {
        ktruItem: {
            type: Object
        },
        price: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            visibleDetails: false
        };
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
