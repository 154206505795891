<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <text-header-new>Адресная книга</text-header-new>
                <div>
                    <b-btn variant="custom-green" size="sm" @click="onAddClick">
                        <b-icon icon="plus-circle" class="my-auto mr-2" aria-hidden="true" />
                        Добавить адрес
                    </b-btn>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!addresses.length" class="text-center mt-5">
                <img src="/images/empty_box.svg" alt="empty-data" />
                <p class="fs-14 grey-color my-4">В данный момент адресная книга пуста</p>
            </b-col>
            <b-col v-else class="mb-3">
                <b-table :busy="isLoading" :fields="fields" :items="addresses" class="table-atmo">
                    <template #cell(index)="row">{{ row.index + 1 }}</template>
                    <template #cell(full_address)="row">
                        {{ row.item.full_address.address }}
                    </template>
                    <template #cell(actions)="row">
                        <action-button-small icon="trash" @click="removeAddress(row.item.id)" />
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-modal v-model="form.showModal" title="Добавить адрес" size="lg" hide-footer centered>
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                <b-row class="py-3">
                    <b-col>
                        <form-row-edit-text v-model="form.short_address" :v="$v.form.short_address" :max-length="50" label="Короткое название" />
                        <form-row-edit-address :value.sync="form.full_address" :v="$v.form.full_address" label="Полный адрес из ФИАС" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button :disabled="$v.form.$invalid" @click="onSubmit" class="text-uppercase" variant="custom-green">Добавить</b-button>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'PersonalAddressesCabinet',
    metaInfo: {
        title: 'Адресная книга'
    },
    data() {
        return {
            isLoading: true,
            fields: [
                { key: 'index', label: '', thStyle: 'width: 1px;', tdClass: 'text-nowrap' },
                { key: 'short_address', label: 'Короткое название', thStyle: 'width: 20%;' },
                { key: 'full_address', label: 'Адрес' },
                { key: 'actions', label: '', thStyle: 'width: 1px;', tdClass: 'text-right', thClass: 'py-1' }
            ],
            form: {
                short_address: '',
                full_address: null,
                showModal: false
            },
            addresses: []
        };
    },
    validations: {
        form: {
            short_address: { required: requiredIf((form) => form.showModal) },
            full_address: { required: requiredIf((form) => form.showModal) }
        }
    },
    async mounted() {
        await this.fillData();
        this.isLoading = false;
    },
    methods: {
        async fillData() {
            this.addresses = await this.$api.personal.addresses.getAddressesList();
        },
        async onSubmit() {
            this.isLoading = true;
            const formData = {
                short_address: this.form.short_address,
                full_address: this.form.full_address
            };
            if (await this.$api.personal.addresses.createAddress(formData)) await this.fillData();
            this.isLoading = false;
            this.form.showModal = false;
        },
        async removeAddress(id) {
            this.isLoading = true;
            if (await this.$api.personal.addresses.deleteAddress(id)) await this.fillData();
            this.isLoading = false;
        },
        onAddClick() {
            this.form.short_address = '';
            this.form.full_address = null;
            this.form.showModal = true;
        }
    }
};
</script>
