<template>
    <div v-if="protocol" class="my-2">
        <text-header>Итоговый протокол закупочной сессии</text-header>
        <text-row :compact="true" label="Регистрационный номер" :value="protocol.reg_number" />
        <text-row :compact="true" label="Статус" :value="protocol.status.title" />
        <text-row-single-document :compact="true" label="Подписанный членами комиссии итоговый протокол закупочной сессии" v-model="protocol.attachment" />
    </div>
</template>

<script>
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';

export default {
    name: 'public-fl223-protocol',
    components: { TextRowSingleDocument },
    props: {
        protocol: {}
    }
};
</script>
