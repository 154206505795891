<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`purchase_part_header purchase_part-header_number ${cardCssClass} pr-2 py-2 position-relative`" body-class="purchase_part-body p-0" border-variant="light">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">44-ФЗ</b-badge>
                        <b-badge class="my-auto fs-14 mr-2">Вне ИС</b-badge>
                        <router-link v-if="contract.status.id !== 'draft'" class="white-system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: contract.id } }" exact target="_blank">{{ contract.reg_number }}</router-link>
                        <template v-else>{{ contract.reg_number }}</template>
                        <template v-if="contract.purchase_object"> На закупку "{{ contract.purchase_object }}"</template>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase" md="auto">
                        {{ contract.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <b-row class="purchase_part-body py-1">
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-1" v-if="contract.status.id === 'contract:active' || contract.status.id === 'contract:fulfilled' || contract.status.id === 'contract:unfulfilled' || contract.status.id === 'contract:terminated'">
                            <p class="fs-12 m-0 grey-color">Контракт заключен:</p>
                            <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                {{ getDateFormat(contract.contract_conclusion_date) }}
                            </p>
                        </div>
                        <div class="my-1" v-if="contract.status.id === 'contract:fulfilled' && contract.contract_execution_date">
                            <p class="fs-12 m-0 grey-color">Контракт исполнен:</p>
                            <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                {{ getDateFormat(contract.contract_execution_date) }}
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-1">
                            <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                            <p class="fs-12 grey-color fw-600">
                                {{ contract.purchase_name }}
                            </p>
                        </div>
                        <div class="my-1 d-flex flex-column">
                            <p class="fs-12 grey-color m-0">Заказчик:</p>
                            <router-link class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + contract.organization_id" target="_blank">
                                {{ contract.customer_short_name }}
                            </router-link>
                        </div>
                        <div class="my-1">
                            <p class="fs-12 m-0 grey-color">
                                Количество позиций:
                                <span class="fw-600">{{ contract.deliverables_count }}</span>
                            </p>
                        </div>
                        <div class="my-1">
                            <p class="fs-12 m-0 grey-color">
                                Сумма закупки:
                                <span class="fw-600">{{ $formatPrice(contract.price) }} руб.</span>
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col atmo-column atmo-special-style">
                    <div>
                        <template>
                            <p class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                                <i class="atmo-icon-search link-icon"></i>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: contract.id } }" exact target="_blank">Информация о закупке</router-link>
                            </p>
                            <p class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                                <i class="atmo-icon-contract link-icon"></i>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: contract.id }, hash: '#contract' }" exact target="_blank">Контракт</router-link>
                            </p>
                            <p v-if="contract.there_is_supplementary_agreement" class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                                <i class="atmo-icon-contract link-icon"></i>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: contract.id }, hash: '#supplementary-agreements' }" exact target="_blank">Дополнительные соглашения</router-link>
                            </p>
                        </template>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-modal ref="delete-modal" title="Удалить закупку" v-model="showDeleteModal" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-left">Вы действительно хотите удалить закупку {{ contract.reg_number }}?</div>
            <div slot="modal-footer" class="d-inline-flex">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showDeleteModal = false">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                    <b-button variant="custom-danger" class="text-uppercase" @click="onDelete(contract.id)">Удалить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'provider-fl44-external-purchase-card',
    props: {
        contract: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDataSending: false,
            showDeleteModal: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        async onDelete(id) {
            this.isDataSending = true;
            if (await this.$api.personal.fl44ExternalPurchases.deleteDraft(id)) {
                this.showDeleteModal = false;
                this.refresh();
            }
            this.isDataSending = false;
        }
    },
    computed: {
        cardCssClass() {
            switch (this.contract.status.id) {
                case 'contract:unfulfilled':
                case 'contract:terminated':
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        },
        cardIcon() {
            switch (this.contract.status.id) {
                case 'contract:active':
                    return 'file-earmark-ruled';
                case 'contract:fulfilled':
                    return 'file-earmark-check';
                case 'contract:unfulfilled':
                case 'contract:failed':
                case 'contract:terminated':
                    return 'x-circle';
                default:
                    return '';
            }
        },
        isCreator() {
            return this.$store.state.user.organization_id === this.contract.organization_id;
        }
    }
};
</script>

<style scoped>
.link-icon {
    padding-right: 5px;
    color: #d5d5d5;
    font-size: medium;
}
.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.purchase_part {
    background: #fff;
}
.card-header-grey {
    background-color: #959ba4 !important;
}
.card-header-green {
    background-color: var(--green-color);
}
.card-header-blue {
    background-color: #40acb4 !important;
}
.card-header-yellow {
    background-color: #f6b33a !important;
}
.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
