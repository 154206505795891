<template>
    <div class="my-2">
        <div class="purchase_part">
            <div class="purchase_part-header" :class="{ discontinued: purchase.status_id === 6 || purchase.status_id === 5 }">
                <b-col md="8" lg="8" class="px-1">
                    <p class="purchase_part-header_number" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                        <b-badge class="fs-14 mr-2">44-ФЗ</b-badge>
                        <b-badge class="fs-14 mr-2">Вне ИС</b-badge>
                        <span v-if="purchase.status_id === 11">{{ purchase.reg_number }}</span>
                        <router-link v-else class="white-system_link-style fw-600" :to="{ name: 'ExternalPurchaseShow', params: { id: purchase.id } }" exact>{{ purchase.reg_number }}</router-link>
                        <span v-if="purchase.purchase_object" style="text-overflow: ellipsis ellipsis">&nbsp;На закупку "{{ purchase.purchase_object }}"</span>
                    </p>
                </b-col>
                <b-col lg="4" md="4" class="px-1">
                    <p class="purchase_part-header_label fw-600 float-right">
                        {{ purchase.status }}
                        <img v-if="purchase.status_id === 6 || purchase.status_id === 5" class="ml-2" src="/images/white_times.svg" alt="" />
                        <img v-if="purchase.status_id === 4" class="ml-2" src="/images/file_check.svg" alt="" />
                        <img v-if="purchase.status_id === 3" class="ml-2" src="/images/white_contract.svg" alt="" />
                    </p>
                </b-col>
            </div>
            <div class="purchase_part-body row">
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-2" v-if="purchase.planned_contract_date">
                            <p class="fs-12 m-0 grey-color">Договор заключен:</p>
                            <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                <span class="fw-600">{{ getDateFormat(purchase.planned_contract_date) }}</span>
                            </p>
                        </div>
                        <div class="my-2" v-if="purchase.planned_end_date">
                            <p class="fs-12 m-0 grey-color">Дата исполнения договора:</p>
                            <p class="fs-12 m-0 grey-color fw-600 mb-4 time_to_end">
                                <span class="fw-600">{{ getDateFormat(purchase.planned_end_date) }}</span>
                            </p>
                        </div>
                        <div class="my-2" v-if="isPurchaseCreator()">
                            <purchase-comment-edit-block :purchase="purchase" @refresh="refresh" />
                        </div>
                        <div class="my-2" v-if="purchase.status_id !== 11 && isPurchaseCreator()">
                            <b-btn :to="{ name: 'ExternalPurchaseEdit', params: { id: purchase.id } }" class="btn btn-card-action btn-card-action my-3">Редактировать</b-btn>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-2">
                            <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                            <p class="fs-12 green-color fw-600">{{ purchase.purchase_name }}</p>
                        </div>
                        <div class="my-2 d-flex flex-column">
                            <p class="fs-12 grey-color m-0">Заказчик:</p>
                            <router-link class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + purchase.org_id">
                                {{ purchase.organization }}
                            </router-link>
                        </div>
                        <div class="my-2">
                            <p class="fs-12 grey-color my-2">
                                Количество позиций:
                                <span class="fw-600">{{ purchase.items_count }}</span>
                            </p>
                            <p class="fs-12 grey-color my-2">
                                Сумма закупки:
                                <span class="fw-600"> {{ $formatPrice(purchase.start_price) }} руб.</span>
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <template v-if="purchase.status_id === 11">
                            <b-btn :to="{ name: 'ExternalPurchaseCreate', params: { mode: 'edit_draft', id: purchase.id } }" class="btn btn-card-action btn-card-action-green my-1">Редактировать</b-btn>
                            <b-btn @click="showDeleteModal = true" class="btn btn-card-action btn-card-action-light my-1">Удалить</b-btn>
                        </template>
                        <template v-else>
                            <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                <span style="width: 30px">
                                    <img src="/images/search.svg" alt="check_label" />
                                </span>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: purchase.id } }" exact>Информация о закупке</router-link>
                            </p>
                            <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                <span style="width: 30px">
                                    <img src="/images/small_box.svg" alt="check_label" />
                                </span>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: purchase.id }, hash: '#supplier-info' }" exact>Поставщик</router-link>
                            </p>
                            <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                <span style="width: 30px">
                                    <img src="/images/contract.svg" alt="check_label" />
                                </span>
                                <router-link class="system_link-style" :to="{ name: 'ExternalPurchaseShow', params: { id: purchase.id }, hash: '#contract' }" exact>Договор</router-link>
                            </p>
                        </template>
                    </div>
                </b-col>
            </div>
        </div>
        <b-modal ref="delete-modal" title="Удалить закупку" v-model="showDeleteModal" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-left">Вы действительно хотите удалить закупку {{ purchase.reg_number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showDeleteModal = false">Отмена</b-button>&nbsp;
                <b-button variant="custom-danger" class="text-uppercase" @click="onDelete(purchase.id)">Удалить</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import PurchaseCommentEditBlock from '@/components/common/purchase-comment-edit-block';

export default {
    name: 'PurchaseCardForCabinet',
    props: ['purchase'],
    components: {
        PurchaseCommentEditBlock
    },
    data() {
        return {
            showDeleteModal: false,
            tablet: false
        };
    },
    created() {
        if (window.innerWidth < 768) {
            this.tablet = true;
        }
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        async onDelete(id) {
            if (await this.$api.personal.fl44ExternalPurchases.deleteDraft(id)) {
                this.showDeleteModal = false;
                this.refresh();
            }
        }
    }
};
</script>

<style scoped>
.discontinued {
    background-color: #f6b33a !important;
}
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}
.purchase_part-header {
    background-color: var(--green-color);
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    background-color: #e4e6e8;
    padding: 12px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 5px 15px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px !important;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
