<template>
    <div v-if="!loader">
        <b-container class="main_layer py-2" fluid>
            <b-container>
                <p class="fs-40 fw-600 white-color my-2">Внесистемная закупка № {{ purchase.reg_number }}</p>
                <p class="white-color fs-14"><b>Статус</b>: {{ purchase.status.title }}</p>
            </b-container>
        </b-container>
        <b-container v-if="purchase" class="my-2" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link compact label="Наименование" v-model="purchase.customer.name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" />
                <text-row :value="purchase.customer.inn" compact label="ИНН" />
                <text-row v-if="purchase.customer.kpp" :value="purchase.customer.kpp" compact label="КПП" />
                <text-row v-if="purchase.customer.ogrn" :value="purchase.customer.ogrn" compact label="ОГРН" />
                <text-row v-if="purchase.customer.address" :value="purchase.customer.address" compact label="Почтовый адрес" />

                <text-header>Информация о закупке</text-header>
                <text-row :value="purchase.order_type_name" compact label="Тип закупки" />
                <text-row :value="purchase.fl44_external_purchase_category_name" compact label="Категория закупки" />
                <text-row :value="purchase.purchase_name" compact label="Предмет закупки" />
                <text-row :value="purchase.purchase_object" compact label="Объект закупки" />
                <text-row-link v-if="purchase.failed_fl44_purchase_id" :router-link="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.failed_fl44_purchase_id } }" :value="purchase.failed_fl44_purchase_reg_number" compact label="Номер несостоявшейся закупки" />
                <text-row-price :value="purchase.price" compact label="Общая стоимость" />
                <text-row-date :value="purchase.contract_conclusion_date" compact label="Дата заключения договора" />
                <text-row-date :value="purchase.contract_execution_date" compact label="Дата исполнения договора" />

                <text-header>Спецификация</text-header>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 60px">№</th>
                                <th style="min-width: 400px">Наименование товара (работ, услуг)</th>
                                <th style="width: 120px">Количество</th>
                                <th style="width: 120px">Ед.изм</th>
                                <th style="width: 120px">Цена за ед., руб.</th>
                                <th style="width: 120px">Стоимость, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in purchase.deliverables" :key="item.id" v-bind="item">
                                <tr :class="visible[index] ? 'border-bottom-0' : ''">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <template v-if="item.ktru">
                                            <action-button-small v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" />
                                            <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        </template>
                                        {{ item.name }}
                                    </td>
                                    <td style="text-align: right">{{ item.count }}</td>
                                    <td style="text-align: right">
                                        <template v-if="item.okei">{{ item.okei.local_symbol }}</template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                    <td style="text-align: right">{{ $formatPrice(item.price) }}</td>
                                    <td style="text-align: right">{{ $formatPrice(item.count * item.price) }}</td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td class="align-text-top" colspan="6">
                                        <template v-if="item.ktru">
                                            <p><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</p>
                                            <p><b>Группа ТРУ:</b> {{ item.ktru.ktru_group_name }}</p>
                                            <p><b>Наименование:</b> {{ item.ktru.name }}</p>
                                            <p><b>Тип:</b> {{ item.ktru.type }}</p>
                                            <p v-if="item.okpd"><b>Код ОКПД2:</b> {{ item.okpd ? item.okpd.code : '&mdash;' }}</p>

                                            <p v-if="item.ktru.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                            <p v-if="item.ktru.specs && item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru.specs && item.ktru.specs.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.name }}</b> - {{ spec.value }}
                                                </li>
                                            </ul>
                                        </template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                </tr>
                            </fragment>
                        </tbody>
                    </table>
                </div>

                <div v-if="this.hasLimits" class="my-2 atmo-page-content">
                    <KBKLimitsList.view v-bind:limits="$data.limits" />
                </div>

                <text-header id="supplier-info">Поставщик</text-header>
                <template v-if="!purchase.is_private_provider">
                    <text-row-link compact label="Наименование" v-model="purchase.supplier.full_name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.supplier.id } }" />
                    <text-row :value="purchase.supplier.inn" compact label="ИНН" />
                    <text-row v-if="purchase.supplier.kpp" :value="purchase.supplier.kpp" compact label="КПП" />
                    <text-row v-if="purchase.supplier.ogrn" :value="purchase.supplier.ogrn" compact label="ОГРН / ОГРНИП" />
                    <text-row :value="purchase.supplier.address" compact label="Фактический адрес" />
                </template>
                <template v-else>
                    <text-row compact label="Название" value="Физическое лицо" />
                    <template v-if="isPurchaseCreator()">
                        <text-row :value="purchase.private_provider_data.inn" compact label="ИНН" />
                        <text-row :value="purchase.private_provider_data.name" compact label="ФИО" />
                        <text-row :value="purchase.private_provider_data.address" compact label="Адрес регистрации" />
                    </template>
                </template>

                <text-header id="contract">Информация о договоре</text-header>
                <text-row :value="purchase.status.title" compact label="Статус договора" />
                <text-row v-model="purchase.customer_contract_number" compact label="Номер договора в системе учета заказчика" />
                <text-row-raw v-if="purchase.contract_attachment && (!purchase.is_private_provider || (purchase.is_private_provider && isPurchaseCreator()))" label="Договор">
                    <a :href="$getAttachmentDownloadLink(purchase.contract_attachment.id)" class="fs-14 col-form-label d-inline-block" target="_blank">{{ purchase.contract_attachment.name }}</a>
                </text-row-raw>

                <template v-if="purchase.supplier_accounting">
                    <text-header>Банковские реквизиты поставщика</text-header>
                    <text-row :value="purchase.supplier_accounting.bank_type.title" compact label="Вид обслуживающей организации" />
                    <text-row v-model="purchase.supplier_accounting.bik" compact label="БИК банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_name" compact label="Название банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_city" compact label="Город банка" />
                    <text-row v-model="purchase.supplier_accounting.ks" compact label="Кор. счет" />
                    <text-row v-model="purchase.supplier_accounting.rs" compact label="Расчетный счет" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.ls" compact label="Лицевой счет" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.fo_inn" compact label="ИНН финансового органа" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.fo_name" compact label="Полное наименование финансового органа" />
                </template>

                <text-header id="contract-fulfillment">Исполнение договора</text-header>
                <div v-if="isPurchaseCreator()" class="atmo-content-group">
                    <form-row-single-select v-model="purchaseStatus" :options="dictionaries.purchaseStatuses" label-field="title" label="Изменить статус закупки" :content-cols-lg="8" :content-cols-sm="9">
                        <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                            <b-button variant="custom-green" @click="changeStatus" class="text-uppercase ml-3" :disabled="purchaseStatus === purchase.status.id">Изменить статус</b-button>
                        </b-overlay>
                    </form-row-single-select>
                    <form-row-upload-multiple-documents v-model="executionDocuments" :is-loading="isWaiting" :v="$v.executionDocuments" header-class="fs-14 ml-1" title="Документы об исполнении" @add="onAddFile" @remove="onRemoveFile" />
                </div>
                <div v-else>
                    <text-row-documents v-if="!purchase.is_private_provider" v-model="executionDocuments" label="Документы об исполнении" />
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import { __PURCHASE_STATUS } from '@/const';
import KBKLimitsListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/view/KBKLimitsList.view.vue';
import KBKLimitsSpecificationItemsEditableCollection from '@core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';
import FormRowUploadMultipleDocuments from '@/components/common/form-rows/form-row-upload-multiple-documents.vue';

export default {
    name: 'ExternalPurchaseShow',
    components: {
        FormRowUploadMultipleDocuments,
        TextRowRaw,
        'KBKLimitsList.view': KBKLimitsListView
    },
    metaInfo() {
        return {
            title: 'Закупка вне ИС ' + this.getRegNumber
        };
    },
    data() {
        return {
            order: this.$route.params.purchase,
            purchase: null,
            loader: true,
            PURCHASE_STATUS: {},
            isWaiting: false,
            isDataSending: false,
            executionDocuments: [],
            purchaseStatus: null,
            visible: [],
            limits: [],
            dictionaries: {
                purchaseStatuses: []
            }
        };
    },
    validations() {
        return {
            executionDocuments: {}
        };
    },
    async created() {
        await this.refresh();
        if (this.$route.hash && document.querySelector(this.$route.hash)) {
            this.$scrollToHash();
        } else {
            this.$scrollToTop();
        }
    },
    methods: {
        async refresh() {
            await this.$api.products.fl44.purchases
                .showExternalPurchase(this.$route.params.id)
                .then((res) => {
                    this.purchase = res;
                    this.limits = new KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(this.purchase.limits);
                    this.loader = false;
                    this.executionDocuments = this.purchase.execution_documents;
                    this.purchaseStatus = this.purchase.status.id;
                    this.dictionaries.purchaseStatuses = this.$globalDictionaries.purchaseStatuses.filter((item) => item.stage === 'contract' && item.id !== 'contract:failed');
                })
                .catch((error) => {
                    if (error && error.response && error.response.status === 404) {
                        this.$router.push({ name: '404' }).catch(() => {});
                    }
                });
            this.PURCHASE_STATUS = __PURCHASE_STATUS;
        },
        async onAddFile(file) {
            try {
                this.isWaiting = true;
                await this.$api.personal.fl44ExternalPurchases.storeExecutionDocument(this.purchase.id, file);
            } finally {
                this.isWaiting = false;
            }
        },
        async onRemoveFile(file) {
            try {
                this.isWaiting = true;
                await this.$api.personal.fl44ExternalPurchases.deleteExecutionDocument(this.purchase.id, file.document_id);
            } finally {
                this.isWaiting = false;
            }
        },
        async changeStatus() {
            this.isDataSending = true;
            try {
                if (await this.$api.personal.fl44ExternalPurchases.changeStatus(this.purchase.id, this.purchaseStatus)) await this.refresh();
            } finally {
                this.isDataSending = false;
            }
        }
    },
    computed: {
        hasLimits() {
            return this.purchase && this.purchase.limits && this.purchase.limits.length > 0;
        },
        getRegNumber() {
            return this.purchase?.reg_number ?? '';
        }
    }
};
</script>

<style scoped>
a {
    color: var(--green-color) !important;
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    color: var(--grey-color);
    border-top: 0;
}

.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
