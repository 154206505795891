var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAvailable
    ? _c(
        "div",
        [
          _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-orange my-1",
              on: { click: _vm.showModal },
            },
            [_vm._v("Отменить закупку")]
          ),
          _c(
            "b-modal",
            {
              ref: "cancel-modal",
              attrs: {
                title: _vm.title,
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "hide-header-close": "",
                centered: "",
              },
              model: {
                value: _vm.isModalVisible,
                callback: function ($$v) {
                  _vm.isModalVisible = $$v
                },
                expression: "isModalVisible",
              },
            },
            [
              _c("div", [
                _c("p", [
                  _vm._v(" Вы действительно хотите отменить закупку "),
                  _c("strong", [_vm._v(_vm._s(_vm.purchase.reg_number))]),
                  _vm._v("? "),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "w-100 text-right",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mr-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancelModal },
                        },
                        [_vm._v("Нет")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        rounded: "",
                        opacity: "0.6",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-danger" },
                          on: { click: _vm.cancelPurchase },
                        },
                        [_vm._v("Отменить закупку")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }