import axios from 'axios';
import store from '@/store';

export default {
    async sendChangeRequest(form) {
        try {
            const response = await axios.put('/cabinet/organization', {
                form: form
            });
            await store.dispatch('showSuccessToast', 'Данные организации успешно обновлены');
            return response.data;
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    }
};
