var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav-item",
    { attrs: { to: _vm.to, active: _vm.isActive(_vm.to.name) } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }