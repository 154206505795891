<template>
    <div v-if="!loader">
        <b-container class="main_layer py-2" fluid>
            <b-container>
                <p class="fs-40 fw-600 white-color my-2">Внесистемная закупка № {{ purchase.reg_number }}</p>
                <p class="white-color fs-14"><b>Статус</b>: {{ purchase.status.title }}</p>
            </b-container>
        </b-container>
        <b-container v-if="purchase" class="my-2" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link compact label="Наименование" v-model="purchase.customer.name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" />
                <text-row :value="purchase.customer.inn" compact label="ИНН" />
                <text-row v-if="purchase.customer.kpp" :value="purchase.customer.kpp" compact label="КПП" />
                <text-row v-if="purchase.customer.ogrn" :value="purchase.customer.ogrn" compact label="ОГРН" />
                <text-row v-if="purchase.customer.address" :value="purchase.customer.address" compact label="Почтовый адрес" />

                <text-header>Информация о закупке</text-header>
                <text-row :value="purchase.order_type_name" compact label="Тип закупки" />
                <text-row :value="purchase.fl44_external_purchase_category_name" compact label="Категория закупки" />
                <text-row :value="purchase.purchase_name" compact label="Предмет закупки" />
                <text-row :value="purchase.purchase_object" compact label="Объект закупки" />
                <text-row-link v-if="purchase.failed_fl44_purchase_id" :router-link="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.failed_fl44_purchase_id } }" :value="purchase.failed_fl44_purchase_reg_number" compact label="Номер несостоявшейся закупки" />
                <text-row-price :value="purchase.price" compact label="Общая стоимость" />
                <text-row-date :value="purchase.contract_conclusion_date" compact label="Дата заключения контракта" />
                <text-row-date :value="purchase.contract_execution_date" compact label="Дата исполнения контракта" />

                <specifications-table :items="purchase.deliverables" :total-cost="purchase.price"/>

                <template v-if="!purchase.without_limits && purchase.limits.length > 0">
                    <limits-table :items="purchase.limits" />
                </template>

                <text-header id="supplier-info">Поставщик</text-header>
                <template v-if="!purchase.is_private_provider">
                    <text-row-link compact label="Наименование" v-model="purchase.supplier.full_name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.supplier.id } }" />
                    <text-row :value="purchase.supplier.inn" compact label="ИНН" />
                    <text-row v-if="purchase.supplier.kpp" :value="purchase.supplier.kpp" compact label="КПП" />
                    <text-row v-if="purchase.supplier.ogrn" :value="purchase.supplier.ogrn" compact label="ОГРН / ОГРНИП" />
                    <text-row :value="purchase.supplier.address" compact label="Фактический адрес" />
                </template>
                <template v-else>
                    <text-row compact label="Название" value="Физическое лицо" />
                    <template v-if="isPurchaseCreator()">
                        <text-row :value="purchase.private_provider_data.inn" compact label="ИНН" />
                        <text-row :value="purchase.private_provider_data.name" compact label="ФИО" />
                        <text-row :value="purchase.private_provider_data.address" compact label="Адрес регистрации" />
                    </template>
                </template>

                <text-header id="contract">Информация о контракте</text-header>
                <text-row :value="purchase.status.title" compact label="Статус контракта" />
                <text-row v-model="purchase.customer_contract_number" compact label="Номер контракта в системе учета заказчика" />
                <text-row-single-document v-if="purchase.contract_attachment && (!purchase.is_private_provider || (purchase.is_private_provider && isPurchaseCreator()))" label="Контракт" v-model="purchase.contract_attachment" compact />

                <template v-if="purchase.supplier_accounting">
                    <text-header>Банковские реквизиты поставщика</text-header>
                    <text-row :value="purchase.supplier_accounting.bank_type.title" compact label="Вид обслуживающей организации" />
                    <text-row v-model="purchase.supplier_accounting.bik" compact label="БИК банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_name" compact label="Название банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_city" compact label="Город банка" />
                    <text-row v-model="purchase.supplier_accounting.ks" compact label="Кор. счет" />
                    <text-row v-model="purchase.supplier_accounting.rs" compact label="Расчетный счет" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.ls" compact label="Лицевой счет" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.fo_inn" compact label="ИНН финансового органа" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.fo_name" compact label="Полное наименование финансового органа" />
                </template>

                <supplementary-agreements v-if="purchase.supplementary_agreements.length > 0 || isPurchaseCreator() && purchase.status.id === 'contract:active'" :purchase="purchase" @refresh="refresh"/>

                <text-header id="contract-fulfillment">Исполнение контракта</text-header>
                <div v-if="isPurchaseCreator()" class="atmo-content-group">
                    <form-row-single-select v-model="form.purchase_status_id" :options="dictionaries.purchaseStatuses" label-field="title" label="Изменить статус закупки" :content-cols-lg="8" :content-cols-sm="9">
                        <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                            <b-button variant="custom-green" @click="changeStatus" class="text-uppercase ml-3" :disabled="(form.purchase_status_id === purchase.status.id && form.contract_execution_date === (purchase.status.id === 'contract:fulfilled' ? $toFormatDate(purchase.contract_execution_date) : null)) || $v.form.$invalid">Изменить статус</b-button>
                        </b-overlay>
                    </form-row-single-select>
                    <b-overlay :show="isDataSending && form.purchase_status_id === 'contract:fulfilled'" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <form-row-edit-date v-if="form.purchase_status_id === 'contract:fulfilled'" v-model="form.contract_execution_date" :max-date="$now()" :min-date="purchase.contract_conclusion_date" :v="$v.form.contract_execution_date" label="Дата исполнения контракта" />
                    </b-overlay>
                    <form-row-upload-multiple-documents v-model="executionDocuments" :is-loading="isWaiting" :v="$v.executionDocuments" header-class="fs-14 fix-pos" title="Документы об исполнении" @add="onAddFile" @remove="onRemoveFile" />
                </div>
                <div v-else>
                    <text-row-documents v-if="!purchase.is_private_provider" v-model="executionDocuments" label="Документы об исполнении" />
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import { __PURCHASE_STATUS } from '@/const';
import FormRowUploadMultipleDocuments from '@/components/common/form-rows/form-row-upload-multiple-documents.vue';
import SupplementaryAgreements from "@/components/externalPurchases/partials/supplementary-agreements.vue";
import SpecificationsTable from "@/components/externalPurchases/partials/specifications-table.vue";
import LimitsTable from "@/components/externalPurchases/partials/limits-table.vue";
import { requiredIf } from "vuelidate/lib/validators";

export default {
    name: 'ExternalPurchaseShow',
    components: {
        LimitsTable,
        SpecificationsTable,
        SupplementaryAgreements,
        FormRowUploadMultipleDocuments,
    },
    metaInfo() {
        return {
            title: 'Закупка вне ИС ' + this.getRegNumber
        };
    },
    data() {
        return {
            order: this.$route.params.purchase,
            purchase: null,
            loader: true,
            PURCHASE_STATUS: {},
            isWaiting: false,
            isDataSending: false,
            executionDocuments: [],
            form: {
                contract_execution_date: null,
                purchase_status_id: null,
            },
            visible: [],
            limits: [],
            dictionaries: {
                purchaseStatuses: []
            }
        };
    },
    validations() {
        return {
            executionDocuments: {},
            form: {
                contract_execution_date: { required: requiredIf(() => this.form.purchase_status_id === 'contract:fulfilled') },
            }
        };
    },
    async created() {
        await this.refresh();
        if (this.$route.hash && document.querySelector(this.$route.hash)) {
            this.$scrollToHash();
        } else {
            this.$scrollToTop();
        }
    },
    methods: {
        async refresh() {
            await this.$api.products.fl44.purchases
                .showExternalPurchase(this.$route.params.id)
                .then((res) => {
                    this.purchase = res;
                    this.loader = false;
                    this.executionDocuments = this.purchase.execution_documents;
                    this.form.purchase_status_id = this.purchase.status.id;
                    this.form.contract_execution_date = this.purchase.status.id === 'contract:fulfilled' ? this.$toFormatDate(this.purchase.contract_execution_date) : null;
                    this.dictionaries.purchaseStatuses = this.$globalDictionaries.purchaseStatuses.filter((item) => item.stage === 'contract' && item.id !== 'contract:failed');
                })
                .catch((error) => {
                    if (error && error.response && error.response.status === 404) {
                        this.$router.push({ name: '404' }).catch(() => {});
                    }
                });
            this.PURCHASE_STATUS = __PURCHASE_STATUS;
        },
        async onAddFile(file) {
            try {
                this.isWaiting = true;
                await this.$api.personal.fl44ExternalPurchases.storeExecutionDocument(this.purchase.id, file);
            } finally {
                this.isWaiting = false;
                await this.refresh();
            }
        },
        async onRemoveFile(file) {
            try {
                this.isWaiting = true;
                await this.$api.personal.fl44ExternalPurchases.deleteExecutionDocument(this.purchase.id, file.document_id);
            } finally {
                this.isWaiting = false;
                await this.refresh();
            }
        },
        async changeStatus() {
            this.isDataSending = true;
            try {
                if (await this.$api.personal.fl44ExternalPurchases.changeStatus(this.purchase.id, this.form)) await this.refresh();
            } finally {
                this.isDataSending = false;
            }
        }
    },
    computed: {
        getRegNumber() {
            return this.purchase?.reg_number ?? '';
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    color: var(--grey-color);
    border-top: 0;
}

.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
