<template>
    <b-col cols="12" :md="md" :lg="lg" class="mb-1">
        <b-form-group :label="label + ':'" :description="description" label-class="filter-header" class="mb-0">
            <multiselect v-model="text" :options="options" :multiple="true" :allow-empty="true" :close-on-select="false" :clear-on-select="false" :preserve-search="false" :show-labels="false" :placeholder="placeholder" :label="labelField" :track-by="trackBy">
                <span slot="noOptions">Нет данных.</span>
                <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
            </multiselect>
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'filter-new-multiple-select',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        labelField: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        md: {
            type: String,
            default: '3'
        },
        lg: {
            type: String,
            default: '3'
        },
        labelClass: {
            type: String,
            default: 'halfWhite-color'
        }
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            }
        },
        id() {
            return 'filter-multiple-select_' + this._uid;
        }
    }
};
</script>
<style scoped>
.multiselect {
    min-height: 30px;
    font-size: 14px !important;
}
.multiselect__tags {
    min-height: 30px !important;
    font-size: 14px !important;
    padding-top: 0 !important;
    border: 1px solid #ced4da !important;
}
.multiselect,
.multiselect__input,
.multiselect__single {
    line-height: 16px !important;
    font-size: 14px !important;
    margin-bottom: 0 !important;
}

.multiselect__tag {
    background-color: var(--green-color) !important;
    color: #fff !important;
    margin-top: 7px;
    margin-bottom: 0 !important;
}
.multiselect__tag-icon:hover {
    background-color: var(--green-color) !important;
}
.multiselect__placeholder {
    padding: 0 !important;
    margin: 9px 0 !important;
}
.multiselect__option {
    white-space: normal !important;
}

/*.multiselect__input{*/
/*    margin-top: 8px;*/
/*}*/
.multiselect__option--highlight {
    background-color: var(--green-color) !important;
}
.change_highlight .multiselect__option--highlight {
    background-color: var(--orange-color) !important;
}
.multiselect__option--highlight::after {
    background-color: var(--green-color) !important;
}
.change_highlight .multiselect__option--highlight::after {
    background-color: var(--orange-color) !important;
}

.multiselect__option--highlight.multiselect__option--selected {
    background-color: #dc3545 !important;
}
.multiselect__option--highlight.multiselect__option--selected::after {
    background-color: #dc3545 !important;
}

.multiselect__input {
    margin-top: 7px !important;
    font-size: 14px !important;
    /*}*/
    /*.multiselect * {*/
    /*    font-size: 14px !important;*/
}

.multiselect__select {
    height: 30px !important;
}

.multiselect__select:before {
    position: absolute !important;
    left: 50% !important;
    top: 45% !important;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate(-50%, -50%) rotate(135deg) !important;
    -ms-transform: translate(-50%, -50%) rotate(135deg) !important;
    -moz-transform: translate(-50%, -50%) rotate(135deg) !important;
    -o-transform: translate(-50%, -50%) rotate(135deg) !important;
    transform: translate(-50%, -50%) rotate(135deg) !important;
    content: '' !important;
    width: 8px !important;
    height: 8px !important;
    border-width: 2px 2px 0px 0px !important;
    border-style: solid !important;
    border-color: var(--grey-color) var(--grey-color) transparent transparent !important;
    margin: 0 !important;
    display: block !important;
}
</style>
