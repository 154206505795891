'use strict';

import { INNStateInvalid, INNStates } from './OrganizationReliabilityReportEditable.valueobject';

type TSelfPOJO = Omit<OrganizationReliabilityReportOrganizationVO, 'is_empty'>;

export interface IRawDadataOrganization {
    inn: string;
    name: { full_with_opf: string };
    address: { unrestricted_value: string };
}

export enum TOrganizationTypes {
    Legal = 'legal',
    Individual = 'individual'
}

export default class OrganizationReliabilityReportOrganizationVO {
    public inn: string | null;
    public title_full: string | null;
    public address: string | null;
    public type: INNStates;

    constructor(data?: TSelfPOJO) {
        this.inn = data?.inn ?? null;
        this.title_full = data?.title_full ?? null;
        this.address = data?.address ?? null;
        this.type = data?.type ?? INNStateInvalid.Invalid;

        Object.seal(this);
    }

    public get is_empty(): boolean {
        return this.inn === null;
    }

    public static fromDadataResponse(data: IRawDadataOrganization, organizationType: TOrganizationTypes): OrganizationReliabilityReportOrganizationVO {
        const pojo = {
            inn: data.inn,
            title_full: data.name.full_with_opf,
            address: data.address.unrestricted_value,
            type: organizationType
        };

        return new OrganizationReliabilityReportOrganizationVO(pojo);
    }
}
