var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            { staticClass: "d-flex flex-column justify-content-between h-100" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-52 fw-600 white-color mt-3 mb-4 main_text",
                      },
                      [_vm._v("Все новости")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "tabs", staticStyle: { "margin-top": "115px" } },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "li",
                    {
                      key: "key_" + tab.type_id,
                      class: { "active-tab": _vm.activeTab === index },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab(index, tab.type_id)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(tab.type_title) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg", attrs: { fluid: "" } },
        [
          !_vm.loading && !_vm.getActiveNews.length
            ? _c("empty-request")
            : _vm._e(),
          _c(
            "b-container",
            { staticStyle: { "padding-top": "40px" } },
            [
              _c(
                "b-row",
                _vm._l(_vm.getActiveNews, function (message, mIndex) {
                  return _c(
                    "b-col",
                    {
                      key: "message_" + message.type_id + "_" + mIndex,
                      staticClass: "mb-5",
                      attrs: { md: "3" },
                    },
                    [
                      _c("div", { staticClass: "slide h-100" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "article_head d-flex",
                              class: {
                                news_head: message.type_id === 1,
                                work_head: message.type_id === 2,
                                update_head: message.type_id === 3,
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: "/images/lifebuoy.svg", alt: "" },
                              }),
                              _c(
                                "router-link",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    tag: "p",
                                    to: "/articles/" + message.id,
                                  },
                                },
                                [_vm._v(_vm._s(message.title) + " ")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "article_body" }, [
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.lengthControl(message.description, 120)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "article_footer" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDateFormat(message.published_at)
                                  )
                                ),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-light btn-sm fs-12 mt-1 px-4 fw-100",
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                    color: "var(--grey-color)",
                                    "background-color": "#f5f5f7",
                                  },
                                  attrs: {
                                    to: "/articles/" + message.id,
                                    tag: "button",
                                    variant: "light",
                                    size: "sm",
                                  },
                                },
                                [_vm._v(" Подробнее ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }