import axios from 'axios';

export default {
    getFavorite() {
        return axios.get('/favorites').then((response) => {
            return response.data;
        });
    },
    removeFavorite(organization) {
        return axios.delete('/favorites/' + organization.id).then((response) => {
            return response.data;
        });
    },
    downloadOrganizationReportPDF(organizationId) {
        return axios.get(`/organizations/${organizationId}/report`, { responseType: 'blob' });
    }
};
