<template>
    <div v-if="proposal" class="purchases_create atmo-page">
        <b-container fluid class="purchase-offer-header-block">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Предложение на закупку 223ФЗ № {{ proposal.purchase.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14"><b>Статус</b>: {{ proposal.status.title }}</p>
                        <p class="white-color fs-14" v-if="proposal.status.id === 'rejected' && proposal.rejection_reason"><b>Причина отклонения</b>: {{ proposal.rejection_reason }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header v-if="!(proposal.purchase.status.id === 'draft' || proposal.purchase.status.id === 'signed' || proposal.purchase.status.id === 'revoked')">
                    Закупка 223ФЗ №
                    <router-link class="system_link-style" target="_blank" :to="{ name: 'Fl223PurchaseShow', params: { id: proposal.purchase.id } }">{{ proposal.purchase.reg_number }} ({{ proposal.purchase.status.title }})</router-link>
                </text-header>
                <text-header v-else> Закупка 223ФЗ № {{ proposal.purchase.reg_number }} ({{ proposal.purchase.status.title }}) </text-header>
                <text-row-single-document :compact="true" label="Карточка предприятия" v-model="proposal.organization_card" />
                <template v-if="proposal.purchase.supplier_requirements.only_smp || proposal.purchase.supplier_requirements.only_not_rnp || proposal.purchase.supplier_requirements.has_additional_requirements">
                    <text-header>Подтверждение требований к поставщикам</text-header>
                    <text-row-checkbox :compact="true" label="Подтверждаю, что организация является субъектом малого предпринимательства (СМП)" v-if="proposal.purchase.supplier_requirements.only_smp" :value="proposal.supplier_requirements_confirmed.is_smp" />
                    <text-row-checkbox :compact="true" label="Подтверждаю, что организация не присутствует в реестре недобросовестных поставщиков" v-if="proposal.purchase.supplier_requirements.only_not_rnp" :value="proposal.supplier_requirements_confirmed.is_not_rnp" />
                    <template v-if="proposal.purchase.supplier_requirements.has_additional_requirements">
                        <div class="form-row">
                            <div class="col">
                                <p class="fs-14 grey-color mt-2">
                                    <strong>Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:</strong>
                                </p>
                            </div>
                        </div>
                        <text-row-single-document
                            :compact="true"
                            :label-cols-lg="6"
                            :label-cols-sm="6"
                            v-for="document in proposal.supplier_requirements_confirmed.additional_requirements"
                            :label="document.description + ' (Причина: ' + document.reason + ')'"
                            v-model="document.attachment"
                            :key="document.id" />
                    </template>
                </template>
                <text-row-documents :compact="true" label="Дополнительные документы" v-model="proposal.attachments" v-if="proposal.attachments.length > 0" />

                <text-header>Спецификация</text-header>

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="120px">Количество /<br />Ед.изм</th>
                                <th width="160px" class="text-right">Цена за ед. с НДС / без НДС, руб.</th>
                                <th width="160px">НДС, %</th>
                                <th width="140px" class="text-right">Стоимость с НДС / без НДС, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in proposal.deliverables" :key="index">
                                <tr>
                                    <td class="align-top">{{ index + 1 }}</td>
                                    <td class="align-top">
                                        <button v-b-toggle="'collapse-' + index" style="font-size: 0.8em" class="atmo-button-icon">
                                            <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                            <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                        </button>
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        {{ item.ktru_item?.name }}
                                    </td>
                                    <td class="align-top text-nowrap">{{ item.amount }}<br />{{ item.ktru_item.okei_local_symbol }}</td>
                                    <td class="align-top text-right">
                                        {{ $formatPrice(item.price_per_unit_with_vat) }}<br />
                                        {{ $formatPriceOrMdash(item.price_per_unit_wo_vat) }}
                                    </td>
                                    <td class="align-top">{{ item.vat ? item.vat.title : '&mdash;' }}</td>
                                    <td class="align-top text-right">
                                        {{ $formatPrice(item.sum_with_vat) }}<br />
                                        {{ $formatPriceOrMdash(item.sum_wo_vat) }}
                                    </td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td :colspan="7">
                                        <p v-if="item.ktru_item.reg_number"><b>Рег.номер ТРУ:</b> {{ item.ktru_item.reg_number }}</p>
                                        <p v-if="item.ktru_item?.okpd_code"><b>Код ОКПД2:</b> {{ item.ktru_item?.okpd_code }}</p>
                                        <p v-if="item.ktru_item.description"><b>Описание:</b> {{ item.ktru_item.description }}</p>
                                        <p v-if="item.ktru_item.specs && item.ktru_item.specs.length > 0"><b>Характеристики:</b></p>
                                        <ul v-if="item.ktru_item.specs && item.ktru_item.specs.length > 0">
                                            <li v-for="(spec, specIndex) of item.ktru_item.specs" :key="index + '_spec_' + specIndex">
                                                <b>{{ spec.name }}</b> - {{ spec.value }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </fragment>
                        </tbody>
                    </table>
                </div>

                <text-row-price compact label="Стоимость предложения с НДС, руб." v-model="proposal.price_with_vat" />
                <text-row-price compact label="Стоимость предложения без НДС, руб." v-model="proposal.price_wo_vat" />
                <template v-if="proposal.purchase.duration_type.id === 'quotation_request'">
                    <text-row compact label="Срок доставки, рабочих дней" description="Срок доставки товаров / выполнения работ / оказания услуг" v-model="proposal.delivery_time_days" />
                    <text-row-price v-if="proposal.delivery_cost" compact label="Стоимость доставки" description="Стоимость доставки товаров / выполнения работ / оказания услуг" v-model="proposal.delivery_cost" />
                </template>
            </b-container>
        </b-container>
        <b-container fluid class="my-2" id="action_buttons_row">
            <b-container class="mb-5">
                <template v-if="$store.getters.isLoggedIn && proposal.actions.supplier.edit">
                    <b-button variant="custom-green" class="text-nowrap text-uppercase" :to="{ name: 'Fl223PurchaseNewProposal', params: { id: proposal.purchase.id } }"> Изменить предложение </b-button>
                </template>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
import TextRowCheckbox from '@/components/common/form-rows/text-row-checkbox.vue';

export default {
    name: 'fl223-proposal-show',
    components: { TextRowCheckbox, TextRowSingleDocument },
    metaInfo() {
        return {
            title: 'Предложение на закупку 223ФЗ'
        };
    },
    data() {
        return {
            id: this.$route.params.id,
            proposal: null,
            visible: []
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.proposal = await this.$api.proposals.fl223.get(this.id);
            this.$scrollToHash();
        },
        refresh() {
            this.fillData();
        }
    }
};
</script>

<style scoped>
.purchase-offer-header-block {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.link {
    color: var(--green-color) !important;
}
.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
