var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c("text-header-new", [_vm._v(_vm._s(_vm.notification.type_name))]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("text-row-datetime", {
                    attrs: { label: "Отправлено", compact: "" },
                    model: {
                      value: _vm.notification.created_at,
                      callback: function ($$v) {
                        _vm.$set(_vm.notification, "created_at", $$v)
                      },
                      expression: "notification.created_at",
                    },
                  }),
                  _c(
                    "text-row-raw",
                    { attrs: { label: "Уведомление", compact: "" } },
                    [
                      _c("div", {
                        staticClass: "my-auto col-form-label",
                        domProps: { innerHTML: _vm._s(_vm.message) },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Назад")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }