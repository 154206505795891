var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      staticClass: "d-inline-block",
      attrs: {
        show: _vm.pdfLoading,
        rounded: "",
        opacity: "0.6",
        "spinner-small": "",
        "spinner-variant": "primary",
      },
    },
    [
      _c(
        "b-button",
        {
          staticClass: "fs-14",
          attrs: { variant: "custom-outline-secondary", size: "sm" },
          on: { click: _vm.downloadOrganizationReportPDF },
        },
        [_vm._v(" Скачать аналитический отчет об организации ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }