<template>
    <b-row class="py-5 w-100">
        <b-col>
            <div class="purchase_part">
                <div class="purchase_part-header">
                    <b-progress :value="100" variant="light" animated class="w-25"></b-progress>

                    <p class="purchase_part-header_label">
                        <img class="mr-1" src="/images/check.svg" alt="check_label" />
                    </p>
                </div>
                <div class="purchase_part-body row">
                    <b-col class="purchase_part-body_col">
                        <div>
                            <p class="fs-12 m-0">
                                <b-progress :value="100" variant="secondary" animated class="w-75 my-2"></b-progress>
                            </p>
                            <p class="fs-18 m-0 fw-600 mb-5">
                                <b-progress :value="100" variant="dark" animated class="w-50"></b-progress>
                            </p>
                            <p class="fs-12">
                                <b-progress :value="100" variant="secondary" animated class="w-25"></b-progress>
                            </p>
                            <p class="fs-12 fw-600">
                                <b-progress :value="100" variant="dark" animated class="w-25"></b-progress>
                            </p>
                        </div>
                    </b-col>
                    <b-col class="purchase_part-body_col">
                        <div>
                            <p class="fs-12 fw-600 m-0">
                                <b-progress :value="100" variant="secondary" animated class="w-25 my-2"></b-progress>
                            </p>
                            <p class="fs-12 mb-3">
                                <b-progress :value="100" variant="dark" animated class="w-75"></b-progress>
                            </p>
                            <p class="fs-12 m-0">
                                <b-progress :value="100" variant="secondary" animated class="w-25 my-2"></b-progress>
                            </p>
                            <p class="green-color text-uppercase fs-14">
                                <b-progress :value="100" variant="dark" animated class="w-75"></b-progress>
                            </p>
                        </div>
                    </b-col>
                    <b-col class="purchase_part-body_col">
                        <div>
                            <p class="fs-12 m-0">
                                <b-progress :value="100" variant="secondary" animated class="w-25 my-2"></b-progress>
                            </p>
                            <p class="fs-12 m-0">
                                <b-progress :value="100" variant="dark" animated class="w-50 mb-2"></b-progress>
                            </p>
                            <p class="fs-12 mb-3">
                                <b-progress :value="100" variant="secondary" animated class="w-75 mb-2"></b-progress>
                            </p>
                            <p class="fs-12 m-0">
                                <b-progress :value="100" variant="dark" animated class="w-100 mb-2"></b-progress>
                            </p>
                            <p class="fs-12 m-0 fw-600">
                                <b-progress :value="100" variant="secondary" animated class="w-25 mb-2"></b-progress>
                            </p>
                        </div>
                    </b-col>
                    <b-col class="purchase_part-body_col">
                        <div>
                            <p class="d-flex align-items-center green-color fs-12 fw-600">
                                <img class="mr-1" src="/images/info.svg" alt="info" />
                                <b-progress :value="100" variant="secondary" animated class="w-50"></b-progress>
                            </p>
                            <p class="d-flex align-items-center green-color fs-12 fw-600">
                                <img class="mr-1" src="/images/chart.svg" alt="chart" />
                                <b-progress :value="100" variant="dark" animated class="w-50"></b-progress>
                            </p>
                            <p class="d-flex align-items-center green-color fs-12 fw-600">
                                <img class="mr-1" src="/images/coins.svg" alt="coins" />
                                <b-progress :value="100" variant="secondary" animated class="w-50"></b-progress>
                            </p>
                        </div>
                    </b-col>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    data() {
        return {
            values: [25, 50, 75, 100],
            max: 100
        };
    }
};
</script>

<style scoped>
.show_bg {
    background-color: #f5f5f7;
}

.purchase_part {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 12px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 24px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
</style>
