var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.statistics
    ? _c(
        "b-row",
        {
          staticClass: "border-bottom py-3 account_block",
          attrs: { "no-gutters": "" },
        },
        [
          _c("b-col", { staticClass: "px-4 py-3", attrs: { lg: "4" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center px-3" },
              [
                _c("div", { staticClass: "cust_label total" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$formatPrice(_vm.statistics.total)) + " "
                  ),
                ]),
                _c("p", { staticClass: "m-0 text-center fs-14 grey-color" }, [
                  _vm._v("Депозит"),
                ]),
              ]
            ),
          ]),
          _c("b-col", { staticClass: "px-4 py-3", attrs: { lg: "4" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center px-3" },
              [
                _c("div", { staticClass: "cust_label available" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatPrice(_vm.statistics.available)) +
                      " "
                  ),
                ]),
                _c("p", { staticClass: "m-0 text-center fs-14 grey-color" }, [
                  _vm._v("Доступные"),
                ]),
              ]
            ),
          ]),
          _c("b-col", { staticClass: "px-4 py-3", attrs: { lg: "4" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center px-3" },
              [
                _c("div", { staticClass: "cust_label blocked" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$formatPrice(_vm.statistics.blocked)) + " "
                  ),
                ]),
                _c("p", { staticClass: "m-0 text-center fs-14 grey-color" }, [
                  _vm._v("Заблокированные"),
                ]),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }