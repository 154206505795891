var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("title", function () {
        return [_c("text-header", [_vm._v("Банковские реквизиты поставщика")])]
      }),
      _vm._t("info"),
      _c(
        "b-form-row",
        [
          _c("frc-select", {
            attrs: {
              options: _vm.dictionaries.bankTypes,
              v: _vm.$v.supplier_accounting.bank_type,
              "label-field": "title",
              label: "Вид обслуживающей организации",
            },
            model: {
              value: _vm.supplier_accounting.bank_type,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "bank_type", $$v)
              },
              expression: "supplier_accounting.bank_type",
            },
          }),
          _c(
            "b-col",
            { attrs: { lg: 6, sm: 12 } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.$v.supplier_accounting.bik.$invalid,
                    label: "БИК банка:",
                    "label-class": "fs-14 grey-color fw-700",
                    "label-for": "supplier-accounting-bik",
                  },
                },
                [
                  _c("VueSuggestions", {
                    staticClass: "fs-14 form-control",
                    class: _vm.$v.supplier_accounting.bik.$invalid
                      ? "is-invalid"
                      : "is-valid",
                    attrs: {
                      id: "supplier-accounting-bik",
                      model: _vm.supplier_accounting.bik,
                      options: _vm.suggestionOptionsBank,
                      placeholder: "Начните вводить",
                    },
                  }),
                  _vm.$v.supplier_accounting.bik.$invalid
                    ? [
                        _c("b-form-invalid-feedback", [
                          _vm._v("Поле обязательно для заполнения"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("form-row-edit-text-compact", {
            attrs: {
              v: _vm.$v.supplier_accounting.bank_name,
              disabled: "disabled",
              label: "Название банка",
            },
            model: {
              value: _vm.supplier_accounting.bank_name,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "bank_name", $$v)
              },
              expression: "supplier_accounting.bank_name",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: {
              v: _vm.$v.supplier_accounting.bank_city,
              disabled: "",
              label: "Город банка",
            },
            model: {
              value: _vm.supplier_accounting.bank_city,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "bank_city", $$v)
              },
              expression: "supplier_accounting.bank_city",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: { v: _vm.$v.supplier_accounting.ks, label: "Кор. счет" },
            model: {
              value: _vm.supplier_accounting.ks,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "ks", $$v)
              },
              expression: "supplier_accounting.ks",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: {
              v: _vm.$v.supplier_accounting.rs,
              label: "Расчетный счет",
            },
            model: {
              value: _vm.supplier_accounting.rs,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "rs", $$v)
              },
              expression: "supplier_accounting.rs",
            },
          }),
          _c("form-row-edit-text-compact", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.supplier_accounting.bank_type !== "bank",
                expression: "supplier_accounting.bank_type !== 'bank'",
              },
            ],
            attrs: { v: _vm.$v.supplier_accounting.ls, label: "Лицевой счет" },
            model: {
              value: _vm.supplier_accounting.ls,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "ls", $$v)
              },
              expression: "supplier_accounting.ls",
            },
          }),
          _c("form-row-edit-text-compact", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.supplier_accounting.bank_type !== "bank",
                expression: "supplier_accounting.bank_type !== 'bank'",
              },
            ],
            attrs: {
              v: _vm.$v.supplier_accounting.fo_inn,
              label: "ИНН финансового органа",
            },
            model: {
              value: _vm.supplier_accounting.fo_inn,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "fo_inn", $$v)
              },
              expression: "supplier_accounting.fo_inn",
            },
          }),
          _c("form-row-edit-text-compact", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.supplier_accounting.bank_type !== "bank",
                expression: "supplier_accounting.bank_type !== 'bank'",
              },
            ],
            attrs: {
              v: _vm.$v.supplier_accounting.fo_name,
              label: "Полное наименование финансового органа",
            },
            model: {
              value: _vm.supplier_accounting.fo_name,
              callback: function ($$v) {
                _vm.$set(_vm.supplier_accounting, "fo_name", $$v)
              },
              expression: "supplier_accounting.fo_name",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }