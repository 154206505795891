<template>
    <div v-if="purchase.is_hold">
        <b-btn :class="buttonClass" @click="cancelClick" :disabled="purchase.hold_canceled">Сформировать итоговый протокол</b-btn>
        <cancel-hold-time-modal :purchase="purchase" @cancel="cancelModal" @ok="cancelHoldTime" :show="showModal"></cancel-hold-time-modal>
    </div>
</template>

<script>
import CancelHoldTimeModal from '@/components/common/CancelHoldTimeModal';

export default {
    name: 'CancelHoldTimeButton',
    props: {
        purchase: {
            type: Object
        },
        buttonStyle: {
            type: String,
            default: 'cabinet'
        }
    },
    components: {
        CancelHoldTimeModal
    },
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        cancelClick() {
            this.showModal = true;
        },
        cancelModal() {
            this.showModal = false;
        },
        cancelHoldTime() {
            this.showModal = false;
            this.$emit('click');
        }
    },
    computed: {
        buttonClass() {
            switch (this.buttonStyle) {
                case 'proposal-dynamics-form':
                    return 'btn-new-green my-1';
                default:
                    return 'btn-card-action btn-card-action-green my-1';
            }
        }
    }
};
</script>

<style></style>
