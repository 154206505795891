<template>
    <div>
        <slot name="title">
            <text-header>Спецификация</text-header>
        </slot>
        <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" details-td-class="py-1" small responsive>
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #row-details="data">
                <template v-if="data.item.ktru">
                    <p><b>Рег.номер ТРУ:</b> {{ data.item.ktru.reg_number }}</p>
                    <p><b>Группа ТРУ:</b> {{ data.item.ktru.ktru_group_name }}</p>
                    <p><b>Наименование:</b> {{ data.item.ktru.name }}</p>
                    <p><b>Тип:</b> {{ data.item.ktru.type }}</p>
                    <p v-if="data.item.okpd"><b>Код ОКПД2:</b> {{ data.item.okpd ? data.item.okpd.code : '&mdash;' }}</p>
                    <p v-if="data.item.ktru.country_of_origin_name"><b>Страна происхождения:</b> {{ data.item.ktru.country_of_origin_name }}</p>
                    <p v-if="data.item.ktru.description"><b>Описание:</b> {{ data.item.ktru.description }}</p>
                    <p v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                    <ul v-if="data.item.ktru.specs && data.item.ktru.specs.length > 0">
                        <li v-for="(spec, key) in data.item.ktru.specs" :key="'spec' + key">
                            <span class="fw-600">{{ spec.name }}</span>: {{ spec.value }}
                        </li>
                    </ul>
                </template>
                <template v-else>&mdash;</template>
            </template>
            <template #cell(specs)="data">
                <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                {{ data.item.ktru.name }}
            </template>
            <template #cell(okei)="data">
                <template v-if="data.item.okei">{{ data.item.okei.local_symbol }}</template>
                <template v-else>&mdash;</template>
            </template>
            <template #cell(price)="data">
                {{ $formatPrice(data.item.price) }}
            </template>
            <template #cell(cost)="data">
                {{ $formatPrice(data.item.count * data.item.price) }}
            </template>
            <template v-if="items.length > 0" #bottom-row>
                <b-td colspan="6" class="text-right py-1">
                    <span class="fw-600">Итого, руб.: {{ $formatPrice(totalCost) }}</span>
                </b-td>
            </template>
        </b-table>
    </div>
</template>

<script>

export default {
    name: 'specifications-table',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        totalCost: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            fields: [
                { key: 'index', label: '№', tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '60px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { minWidth: '400px' } },
                { key: 'count', label: 'Количество', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '120px' } },
                { key: 'okei', label: 'Ед.изм.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '120px' } },
                { key: 'price', label: 'Цена за ед., руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '130px' } },
                { key: 'cost', label: 'Стоимость, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '130px' } },
            ]
        };
    },
};
</script>
