var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-title",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { md: "6" } },
            [
              _vm._v(" Поставщик:"),
              _c("br"),
              _c(
                "router-link",
                {
                  staticClass: "font-weight-bold",
                  attrs: {
                    to: {
                      name: "OrganizationShow",
                      params: { id: _vm.proposal.supplier.id },
                    },
                    target: "_blank",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.proposal.supplier.name) + " "),
                  _vm.proposal.supplier.country_code
                    ? [
                        _vm._v(
                          "(" + _vm._s(_vm.proposal.supplier.country_code) + ")"
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("b-col", { staticClass: "my-1 text-right", attrs: { md: "6" } }, [
            _vm._v(" Стоимость предложения с НДС: "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.$formatPrice(_vm.proposal.price_with_vat)) + " руб."
              ),
            ]),
            _c("br"),
            _vm._v(" Стоимость предложения без НДС: "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.$formatPriceOrMdash(_vm.proposal.price_wo_vat)) +
                  " руб."
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }