<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-container>
                <p class="fs-36 fw-600 white-color mb-2">{{ title }}</p>

                <ul class="atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner">
                    <li>
                        <dt>Организация:</dt>
                        <dd v-text="this.$props.organization.name"></dd>
                    </li>
                    <li>
                        <dt>ИНН:</dt>
                        <dd v-text="this.$props.organization.inn"></dd>
                    </li>
                    <li v-if="this.$props.organization.kpp">
                        <dt>КПП:</dt>
                        <dd v-text="this.$props.organization.kpp"></dd>
                    </li>
                    <li v-if="this.$props.organization.ogrn">
                        <dt>ОГРН:</dt>
                        <dd v-text="this.$props.organization.ogrn"></dd>
                    </li>
                    <li>
                        <dt>Адрес:</dt>
                        <dd v-text="this.$props.organization.legal_address"></dd>
                    </li>
                </ul>
            </b-container>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'PurchaseHeaderBlock',
    props: {
        title: {
            type: String
        },
        organization: { required: true, type: Object }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}
</style>
