<template>
    <b-overlay :show="pdfLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
        <b-button variant="custom-outline-secondary" class="fs-14" size="sm" @click="downloadOrganizationReportPDF"> Скачать аналитический отчет об организации </b-button>
    </b-overlay>
</template>

<script>
import Organizations from '@/services/api/Organizations';
import FileDownload from 'js-file-download';

export default {
    name: 'download-organization-analytics-report-button',
    props: ['organization'],
    data() {
        return {
            pdfLoading: false
        };
    },
    methods: {
        downloadOrganizationReportPDF() {
            this.pdfLoading = true;
            Organizations.downloadOrganizationReportPDF(this.organization.id).then((response) => {
                FileDownload(response.data, 'Аналитический отчет об организации ' + this.organization.inn + '.pdf');
                this.pdfLoading = false;
            });
        }
    }
};
</script>
