<template>
    <b-dropdown-item :to="to" :active="isActive(to.name)"><slot></slot></b-dropdown-item>
</template>

<script>
export default {
    name: 'cabinet-submenu-item',
    props: {
        to: {
            required: true
        }
    },
    methods: {
        isActive(path) {
            return this.$route.name === path;
        }
    }
};
</script>

<style scoped>
.cabinet_menu li {
    position: relative;
    background: #fff;
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}

.cabinet_menu li .router-link-active,
.cabinet_menu li .active,
.cabinet_menu li.notify_active {
    border-left: 8px solid var(--green-color);
    color: var(--green-color);
    font-weight: 600;
}
</style>
