var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$store.getters.isLoggedIn &&
      _vm.purchase.actions.customer.select_winner &&
      _vm.purchase.private_data &&
      _vm.purchase.private_data.winner_selection &&
      _vm.purchase.status.id === "summarizing"
        ? [
            _c(
              "div",
              { staticClass: "mx-1 mb-2" },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "fs-14 my-2 text-uppercase",
                    staticStyle: { color: "white !important" },
                    attrs: {
                      variant: "danger",
                      size: "lg",
                      href: _vm.$links.fl223_purchase_winner_selection_manual,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-play" }),
                    _vm._v(" Инструкция по формированию итогового протокола"),
                  ]
                ),
              ],
              1
            ),
            _vm.purchase.private_data.proposals &&
            _vm.purchase.private_data.proposals.length > 0
              ? [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _c(
                          "h2",
                          { staticClass: "grey-color font-weight-bold my-3" },
                          [_vm._v("Предложения участников")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.purchase.private_data.proposals,
                    function (proposal, index) {
                      return _c(
                        "private-fl223-winner-selection-proposal-card",
                        {
                          key: "proposal_" + index,
                          attrs: {
                            proposal: proposal,
                            "purchase-id": _vm.purchase.id,
                            "supplier-requirements":
                              _vm.purchase.supplier_requirements,
                            purchase: _vm.purchase,
                            index: index,
                            "with-rating": true,
                            confirmed:
                              _vm.purchase.private_data.winner_selection
                                .confirmed,
                          },
                          on: { refresh: _vm.refresh },
                          model: {
                            value: proposal.rating,
                            callback: function ($$v) {
                              _vm.$set(proposal, "rating", $$v)
                            },
                            expression: "proposal.rating",
                          },
                        }
                      )
                    }
                  ),
                  _c("fl223-purchase-store-winner-selection-button", {
                    attrs: {
                      purchase: _vm.purchase,
                      ratings: _vm.proposalRatings,
                      proposals: _vm.purchase.private_data.proposals,
                    },
                    on: { store: _vm.refresh },
                  }),
                  _c("p", { staticClass: "text-danger" }, [
                    _vm._v(
                      "Процедура выбора победителя не завершена пока не загружен файл итогового протокола с подписями членов комиссии по выбору победителя"
                    ),
                  ]),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.$store.getters.isLoggedIn &&
      _vm.purchase.actions.customer.select_winner &&
      _vm.purchase.private_data &&
      _vm.purchase.private_data.winner_selection &&
      _vm.purchase.private_data.winner_selection.confirmed &&
      _vm.purchase.status.id === "summarizing"
        ? [
            _c("text-header", [_vm._v("Итоговый протокол закупочной сессии")]),
            _c(
              "a",
              {
                staticClass: "btn btn-custom-green text-uppercase",
                attrs: {
                  href: _vm.purchase.private_data.winner_selection
                    .protocol_template_link,
                },
              },
              [_vm._v("Скачать итоговый протокол закупочной сессии")]
            ),
            _c("form-row-upload-single-document", {
              attrs: {
                title:
                  "Подписанный членами комиссии итоговый протокол закупочной сессии",
                v: _vm.$v.form.attachment,
                "header-class": "fs-14 fw-700",
              },
              model: {
                value: _vm.form.attachment,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "attachment", $$v)
                },
                expression: "form.attachment",
              },
            }),
            _c(
              "div",
              { staticClass: "w-100 text-right" },
              [
                _c(
                  "b-overlay",
                  {
                    staticClass: "d-inline-block",
                    attrs: {
                      show: _vm.isDataSending,
                      rounded: "",
                      opacity: "0.6",
                      "spinner-small": "",
                      "spinner-variant": "primary",
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase",
                        attrs: {
                          variant: "custom-danger",
                          disabled: _vm.$v.$invalid,
                        },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmModal = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "Отправить подписанный членами комиссии итоговый протокол закупочной сессии"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-modal",
              {
                ref: "confirm-modal",
                attrs: {
                  centered: "",
                  "hide-header-close": "",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  size: "lg",
                  title:
                    "Отправка подписанного итогового протокола закупочной сессии",
                },
                model: {
                  value: _vm.showConfirmModal,
                  callback: function ($$v) {
                    _vm.showConfirmModal = $$v
                  },
                  expression: "showConfirmModal",
                },
              },
              [
                _c("p", { staticClass: "text-left" }, [
                  _vm._v(
                    "Вы действительно хотите отправить подписанный членами комиссии итоговый протокол закупочной сессии?"
                  ),
                ]),
                _c("p", { staticClass: "text-left text-danger fw-600" }, [
                  _vm._v(
                    "ВНИМАНИЕ! Данное действие является необратимым, отменить его будет невозможно!"
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-right",
                    attrs: { slot: "modal-footer" },
                    slot: "modal-footer",
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase mr-2",
                        attrs: { variant: "custom-outline-secondary" },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmModal = false
                          },
                        },
                      },
                      [_vm._v("Отмена")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase",
                        attrs: { variant: "custom-danger" },
                        on: { click: _vm.confirmWinnerSelection },
                      },
                      [
                        _vm._v(
                          "Отправить подписанный членами комиссии итоговый протокол закупочной сессии"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }