<template>
    <div style="background-color: var(--greyBg-color)" v-if="$store.state.user && $store.state.organization">
        <cabinet-header />
        <b-container fluid>
            <b-container>
                <b-row class="py-3">
                    <b-col md="3" sm="12">
                        <cabinet-sidenav />
                    </b-col>
                    <b-col md="9" sm="12">
                        <router-view></router-view>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import CabinetHeader from '@/components/cabinets/CabinetHeader.vue';
import CabinetSidenav from '@/components/cabinets/CabinetSidenav.vue';

export default {
    components: { CabinetSidenav, CabinetHeader },
    metaInfo: {
        title: 'Личный кабинет'
    },
    name: 'Cabinet',
    data() {
        return {
            current_route: null
        };
    },
    mounted() {
        if (!this.$store.state.organization) {
            this.$router.push('/').catch(() => {});
        }
        if (this.$route.path === '/cabinet') {
            if (this.$store.state.organization.is_provider) {
                this.$router.push({ name: 'CabinetFl44ProposalsList' }).catch(() => {});
            } else {
                this.$router.push({ name: 'CustomerPurchasesCabinet', params: { cat: 'all' } }).catch(() => {});
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.path === '/cabinet') {
                if (this.$store.state.organization.is_provider) {
                    this.$router.push({ name: 'CabinetFl44ProposalsList' }).catch(() => {});
                } else {
                    this.$router.push({ name: 'CustomerPurchasesCabinet', params: { cat: 'all' } }).catch(() => {});
                }
            }
        }
    }
};
</script>

<style scoped>
.cabinet_menu .atmo-badge {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 2px 6px;
    font-size: 0.8em;
    color: white;
    background-color: #dc3545;
    border-radius: 3px;
}

.cabinet_menu {
    position: -webkit-sticky;
    position: sticky;
    top: 160px;
    -webkit-box-shadow: 0 0 10px -5px #aaa;
    -moz-box-shadow: 0 0 10px -5px #aaa;
    box-shadow: 0 0 10px -5px #aaa;
}

.cabinet_menu li {
    position: relative;
    background: #fff;
    /*border-radius: 4px;*/
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li .router-link-active,
.cabinet_menu li .active,
.cabinet_menu li.notify_active {
    border-left: 8px solid var(--green-color);
    /*border-radius: 4px 0 0 4px;*/
    color: var(--green-color);
    font-weight: 600;
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

@media screen and (max-width: 769px) {
    .if-tablet {
        font-size: 40px !important;
    }
}
</style>

<style>
#privider_info .dropdown-item {
    white-space: unset !important;
}

.provider_menu .dropdown-item.active {
    background: transparent;
    color: var(--green-color);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}

.button-badge {
    position: relative;
    top: 15px;
    right: -30px;
    line-height: 0;
    padding: 2px 6px;
    font-size: 0.8em;
    color: white;
    background-color: #dc3545;
    border-radius: 3px;
}
</style>
