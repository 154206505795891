<template>
    <div>
        <slot name="title">
            <text-header>Банковские реквизиты поставщика</text-header>
        </slot>
        <slot name="info"></slot>
        <b-form-row>
            <frc-select v-model="supplier_accounting.bank_type" :options="dictionaries.bankTypes" :v="$v.supplier_accounting.bank_type" label-field="title" label="Вид обслуживающей организации" />
            <b-col :lg="6" :sm="12">
                <b-form-group :state="$v.supplier_accounting.bik.$invalid" label="БИК банка:" label-class="fs-14 grey-color fw-700" label-for="supplier-accounting-bik">
                    <VueSuggestions
                        id="supplier-accounting-bik"
                        :class="$v.supplier_accounting.bik.$invalid ? 'is-invalid' : 'is-valid'"
                        :model="supplier_accounting.bik"
                        :options="suggestionOptionsBank"
                        :placeholder="'Начните вводить'"
                        class="fs-14 form-control" />
                    <template v-if="$v.supplier_accounting.bik.$invalid">
                        <b-form-invalid-feedback>Поле обязательно для заполнения</b-form-invalid-feedback>
                    </template>
                </b-form-group>
            </b-col>
            <form-row-edit-text-compact v-model="supplier_accounting.bank_name" :v="$v.supplier_accounting.bank_name" disabled="disabled" label="Название банка" />
            <form-row-edit-text-compact v-model="supplier_accounting.bank_city" :v="$v.supplier_accounting.bank_city" disabled label="Город банка" />
            <form-row-edit-text-compact v-model="supplier_accounting.ks" :v="$v.supplier_accounting.ks" label="Кор. счет" />
            <form-row-edit-text-compact v-model="supplier_accounting.rs" :v="$v.supplier_accounting.rs" label="Расчетный счет" />
            <form-row-edit-text-compact v-show="supplier_accounting.bank_type !== 'bank'" v-model="supplier_accounting.ls" :v="$v.supplier_accounting.ls" label="Лицевой счет" />
            <form-row-edit-text-compact v-show="supplier_accounting.bank_type !== 'bank'" v-model="supplier_accounting.fo_inn" :v="$v.supplier_accounting.fo_inn" label="ИНН финансового органа" />
            <form-row-edit-text-compact v-show="supplier_accounting.bank_type !== 'bank'" v-model="supplier_accounting.fo_name" :v="$v.supplier_accounting.fo_name" label="Полное наименование финансового органа" />
        </b-form-row>
    </div>
</template>

<script>

import { maxLength, minLength, required, requiredIf } from "vuelidate/lib/validators";
import FormRowEditTextCompact from "@/components/common/form-rows/form-row-edit-text-compact.vue";
import FrcSelect from "@/components/common/form-rows/frc-select.vue";
import VueSuggestions from "vue-suggestions";

export default {
    name: 'form-row-edit-supplier-accounting',
    components: { VueSuggestions, FrcSelect, FormRowEditTextCompact },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            suggestionOptionsBank: {
                token: process.env.VUE_APP_DADATA_TOKEN,
                type: ['BANK'],
                scrollOnFocus: false,
                triggerSelectOnBlur: true,
                triggerSelectOnEnter: false,
                addon: 'none',
                onSelect: this.onBankSelect,
                onInvalidateSelection: this.onBankInvalidateSelection,
                onSelectNothing: this.onBankSelectNothing,
                noCache: true,
                formatSelected: this.bankFormatSelected
            },
            dictionaries: {
                bankTypes: [],
            }
        };
    },
    validations() {
        return {
            supplier_accounting: {
                required,
                bank_type: { required },
                bik: {
                    required,
                    minLength: minLength(9),
                    maxLength: maxLength(9)
                },
                bank_name: { required },
                bank_city: { required },
                ks: {
                    required,
                    minLength: minLength(20),
                    maxLength: maxLength(20)
                },
                rs: {
                    required,
                    minLength: minLength(20),
                    maxLength: maxLength(20)
                },
                ls: { required: requiredIf(() => this.supplier_accounting.bank_type !== 'bank') },
                fo_inn: {
                    required: requiredIf(() => this.supplier_accounting.bank_type !== 'bank'),
                    minLength: minLength(10),
                    maxLength: maxLength(12)
                },
                fo_name: { required: requiredIf(() => this.supplier_accounting.bank_type !== 'bank') }
            }
        }
    },
    async mounted() {
        await this.fillData();
        this.$emit("validate", this.$v.$invalid);
    },
    methods: {
        async fillData() {
            const data = await this.$api.dictionary.getList(['bank_types']);
            this.dictionaries.bankTypes = data.bank_types;
        },
        onBankSelect(suggestion) {
            const isTreasury = suggestion.data.opf.type === 'TREASURY';
            let ks = suggestion.data.correspondent_account;
            if (isTreasury) {
                if (suggestion.data.treasury_accounts && suggestion.data.treasury_accounts.length > 0) {
                    ks = suggestion.data.treasury_accounts[0];
                }
            }
            this.supplier_accounting.bik = suggestion.data.bic;
            this.supplier_accounting.bank_name = suggestion.data.name.payment;
            this.supplier_accounting.bank_city = suggestion.data.payment_city;
            this.supplier_accounting.ks = ks;
        },
        onBankInvalidateSelection() {
            this.supplier_accounting.bik = null;
            this.supplier_accounting.bank_name = null;
            this.supplier_accounting.bank_city = null;
            this.supplier_accounting.ks = null;
            this.supplier_accounting.rs = null;
            this.supplier_accounting.ls = null;
            this.supplier_accounting.fo_inn = null;
            this.supplier_accounting.fo_name = null;
        },
        onBankSelectNothing() {
            this.supplier_accounting.bik = null;
        },
        bankFormatSelected(suggestion) {
            return (suggestion.value = suggestion.data.bic);
        }
    },
    watch: {
        supplier_accounting: {
            handler(value) {
                this.$emit("input", value);
            },
            deep: true
        },
        $v: {
            handler(value) {
                this.$emit("validate", value.$invalid);
            },
            deep: true
        }
    },
    computed: {
        supplier_accounting: {
            get() {
                return this.value;
            }
        }
    }
};
</script>
