var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase.conditions.customer_need_send_contract_to_provider
        ? _c(
            "b-button",
            {
              staticClass: "btn btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "purchases.grouped.item.view",
                  params: { group: "fl44", id: _vm.purchase.id },
                  hash: "#atmo-contract",
                },
                exact: "",
                target: "_blank",
              },
            },
            [_vm._v("Отправить договор")]
          )
        : _vm._e(),
      _vm.purchase.conditions.customer_need_answer_contract_dispute ||
      _vm.purchase.conditions
        .customer_need_answer_supplementary_agreement_dispute
        ? _c(
            "b-button",
            {
              staticClass: "btn btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "purchases.grouped.item.view",
                  params: { group: "fl44", id: _vm.purchase.id },
                  hash: "#atmo-disputes-resolution",
                },
                exact: "",
                target: "_blank",
              },
            },
            [_vm._v("Ответить на протокол разногласий")]
          )
        : _vm._e(),
      _vm.purchase.conditions.customer_need_sign_contract ||
      _vm.purchase.conditions.provider_need_sign_contract
        ? _c(
            "b-button",
            {
              staticClass: "btn btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "purchases.grouped.item.view",
                  params: { group: "fl44", id: _vm.purchase.id },
                  hash: "#atmo-contract",
                },
                exact: "",
                target: "_blank",
              },
            },
            [_vm._v("Подписать договор")]
          )
        : _vm._e(),
      _vm.purchase.conditions.customer_need_sign_supplementary_agreement ||
      _vm.purchase.conditions.provider_need_sign_supplementary_agreement
        ? _c(
            "b-button",
            {
              staticClass: "btn btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "purchases.grouped.item.view",
                  params: { group: "fl44", id: _vm.purchase.id },
                  hash: "#atmo-contract-annexes",
                },
                exact: "",
                target: "_blank",
              },
            },
            [_vm._v("Подписать дополнительное соглашение")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }