var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _vm._t("header", function () {
                return [_c("text-header", [_vm._v("Спецификация")])]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "table-atmo",
        class: { "is-table-invalid": _vm.v.$invalid || _vm.specErrors },
        attrs: {
          id: "my-table",
          fields: _vm.fields,
          items: _vm.items,
          busy: _vm.isLoading,
          "details-td-class": "py-1",
          "show-empty": "",
          small: "",
          responsive: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(index)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
            },
          },
          {
            key: "row-details",
            fn: function (data) {
              return [
                data.item.ktru
                  ? [
                      _c("p", [
                        _c("b", [_vm._v("Рег.номер ТРУ:")]),
                        _vm._v(" " + _vm._s(data.item.ktru.reg_number)),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Группа ТРУ:")]),
                        _vm._v(" " + _vm._s(data.item.ktru.ktru_group_name)),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Наименование:")]),
                        _vm._v(" " + _vm._s(data.item.ktru.name)),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Тип:")]),
                        _vm._v(" " + _vm._s(data.item.ktru.type)),
                      ]),
                      data.item.okpd
                        ? _c("p", [
                            _c("b", [_vm._v("Код ОКПД2:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.okpd ? data.item.okpd.code : "—"
                                )
                            ),
                          ])
                        : _vm._e(),
                      data.item.ktru.country_of_origin_name
                        ? _c("p", [
                            _c("b", [_vm._v("Страна происхождения:")]),
                            _vm._v(
                              " " +
                                _vm._s(data.item.ktru.country_of_origin_name)
                            ),
                          ])
                        : _vm._e(),
                      data.item.ktru.description
                        ? _c("p", [
                            _c("b", [_vm._v("Описание:")]),
                            _vm._v(" " + _vm._s(data.item.ktru.description)),
                          ])
                        : _vm._e(),
                      data.item.ktru.specs && data.item.ktru.specs.length > 0
                        ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                        : _vm._e(),
                      data.item.ktru.specs && data.item.ktru.specs.length > 0
                        ? _c(
                            "ul",
                            _vm._l(data.item.ktru.specs, function (spec, key) {
                              return _c("li", { key: "spec" + key }, [
                                _c("span", { staticClass: "fw-600" }, [
                                  _vm._v(_vm._s(spec.name)),
                                ]),
                                _vm._v(": " + _vm._s(spec.value) + " "),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  : [_vm._v("—")],
              ]
            },
          },
          {
            key: "cell(specs)",
            fn: function (data) {
              return [
                _c("action-button-small", {
                  attrs: {
                    title:
                      (data.detailsShowing ? "Скрыть" : "Показать") +
                      " характеристики",
                    icon: data.detailsShowing ? "eye-slash" : "eye",
                  },
                  on: { click: data.toggleDetails },
                }),
                _vm._v(" " + _vm._s(data.item.ktru.name) + " "),
              ]
            },
          },
          {
            key: "cell(count)",
            fn: function (data) {
              return [
                _vm.withCountEdit
                  ? _c("b-form-input", {
                      staticClass:
                        "mb-0 form-control fs-14 text-right amount-input",
                      class: !_vm.v.$each[data.index].count.$invalid
                        ? "is-valid"
                        : "is-invalid",
                      attrs: {
                        min: "0.000000001",
                        step: "0.000000001",
                        type: "number",
                      },
                      on: {
                        focus: function ($event) {
                          $event.target?.select()
                        },
                      },
                      model: {
                        value: data.item.count,
                        callback: function ($$v) {
                          _vm.$set(data.item, "count", _vm._n($$v))
                        },
                        expression: "data.item.count",
                      },
                    })
                  : [_vm._v(" " + _vm._s(data.item.count) + " ")],
              ]
            },
          },
          {
            key: "cell(okei)",
            fn: function (data) {
              return [
                data.item.okei
                  ? [_vm._v(_vm._s(data.item.okei.local_symbol))]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(price)",
            fn: function (data) {
              return [
                _c("currency-input", {
                  staticClass:
                    "form-control fs-14 text-right currency-input mb-0",
                  class:
                    _vm.v.$each[data.index]?.price &&
                    !_vm.v.$each[data.index].price.$invalid
                      ? "is-valid"
                      : "is-invalid",
                  attrs: { currency: "RUB", locale: "ru" },
                  on: {
                    focus: function ($event) {
                      $event.target?.select()
                    },
                  },
                  model: {
                    value: data.item.price,
                    callback: function ($$v) {
                      _vm.$set(data.item, "price", $$v)
                    },
                    expression: "data.item.price",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(cost)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatPrice(
                        _vm.roundToTwoDecimals(
                          data.item.count * data.item.price
                        )
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c("action-button-small", {
                  attrs: { icon: "trash", title: "Удалить" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem(data.index)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "bottom-row",
            fn: function () {
              return [
                _c(
                  "b-td",
                  {
                    staticClass: "align-text-top py-1 pl-1",
                    attrs: { colspan: "2" },
                  },
                  [
                    !_vm.noAddSpec
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "custom-green" },
                            on: {
                              click: function ($event) {
                                _vm.showSpec = true
                              },
                            },
                          },
                          [_vm._v("Добавить позицию спецификации")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.withPrice
                  ? _c(
                      "b-td",
                      {
                        staticClass: "text-right py-1",
                        attrs: { colspan: "4" },
                      },
                      [
                        _vm.items.length > 0
                          ? _c("span", { staticClass: "fw-600" }, [
                              _vm._v(
                                "Общая стоимость, руб.: " +
                                  _vm._s(_vm.$formatPrice(_vm.totalCost))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "w-100 text-center" }, [
                  _vm._v("Не добавлено позиций спецификации"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-form-invalid-feedback",
        {
          staticClass: "mt-3",
          attrs: { state: !_vm.v.$invalid && !_vm.specErrors },
        },
        [_vm._v(_vm._s(_vm.specErrorMessage))]
      ),
      _c("purchase-specification-modal", {
        attrs: { show: _vm.showSpec },
        on: {
          add: _vm.addSpec,
          cancel: function ($event) {
            _vm.showSpec = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }