var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-right" },
    [
      _c(
        "b-btn",
        {
          staticClass: "fs-14 my-1 text-uppercase",
          staticStyle: { color: "white !important" },
          attrs: {
            href: _vm.href,
            size: "lg",
            target: "_blank",
            variant: "danger",
          },
        },
        [
          _c("img", {
            staticClass: "mx-1",
            staticStyle: { height: "16px" },
            attrs: { alt: "documentation", src: "/images/youtube.svg" },
          }),
          _vm._v(" " + _vm._s(_vm.text) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }