var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "nav-item",
      on: {
        click: function ($event) {
          _vm.expanded = !_vm.expanded
        },
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link",
          class: { "router-link-active": _vm.checkPath },
          attrs: { href: "javascript:void(0)", target: "_self" },
        },
        [
          _vm.strong
            ? [_c("strong", [_vm._v(_vm._s(_vm.title))])]
            : [_vm._v(_vm._s(_vm.title))],
          _c(
            "b-collapse",
            {
              staticClass: "mt-2 cabinet_submenu",
              attrs: { visible: _vm.expanded || _vm.checkPath },
            },
            [_vm._t("default")],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }