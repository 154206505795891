'use strict';

import * as EmailValidator from 'email-validator';

import { TOrganizationTypes } from './OrganizationReliabilityReportOrganization.valueobject';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';

export enum INNStateInvalid {
    Invalid = 'invalid'
}

export type INNStates = TOrganizationTypes | INNStateInvalid;

export default class OrganizationReliabilityReportEditableVO {
    public inn: string | null;
    public email: string | null;
    public has_to_email_report: boolean;

    constructor(user?: UserVO) {
        this.inn = null;
        this.email = user?.email ?? null;
        this.has_to_email_report = false;
    }

    public static getOrganizationType(inn: string): TOrganizationTypes {
        const dictionary = new Map([
            [10, TOrganizationTypes.Legal],
            [12, TOrganizationTypes.Individual]
        ]);
        const type = dictionary.get(inn!.length);
        if (!type) {
            throw new Error(`SVTA Error: invalid INN ${inn}`);
        }
        return type;
    }

    private get is_inn_valid(): boolean {
        return this.inn !== null && [10, 12].includes(this.inn.length);
    }

    private get is_email_valid(): boolean {
        return !this.has_to_email_report || (this.email !== null && EmailValidator.validate(this.email));
    }

    public get inn_state(): INNStates {
        return this.is_inn_valid ? OrganizationReliabilityReportEditableVO.getOrganizationType(this.inn!) : INNStateInvalid.Invalid;
    }
}
