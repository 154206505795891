<template>
    <div v-if="canBeAdd">
        <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#explanation-requests' }" class="btn btn-card-action btn-danger my-1" tag="button"
            >Ответить на запрос<br />(До {{ getDateTimeFormat(purchase.can_be_add_explanation_request_answer_end_date) }})
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'AddExplanationRequestAnswerButton',
    props: ['purchase'],
    data() {
        return {
            timer: null,
            secondsLeft: 0
        };
    },
    created() {
        this.secondsLeft = this.purchase.can_be_add_explanation_request_answer_seconds_left;
        if (this.purchase.can_be_add_explanation_request_answer) {
            setTimeout(() => (this.secondsLeft = 0), this.secondsLeft * 1000);
        }
    },
    computed: {
        canBeAdd() {
            return this.purchase.can_be_add_explanation_request_answer && this.secondsLeft > 0;
        }
    }
};
</script>

<style></style>
