var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "form-header", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "p",
                  {
                    staticClass: "fw-600 white-color mb-3 mt-3",
                    class: _vm.titleClass,
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
              ]),
            ],
            1
          ),
          !_vm.withoutCurrentOrganization
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "white-color fs-14" }, [
                      _vm._v(
                        "«" +
                          _vm._s(_vm.$store.state.organization.short_name) +
                          "»"
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }