var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("title", function () {
        return [_c("text-header", [_vm._v("Спецификация")])]
      }),
      _c("b-table", {
        staticClass: "table-atmo",
        attrs: {
          id: "my-table",
          fields: _vm.fields,
          items: _vm.items,
          "details-td-class": "py-1",
          small: "",
          responsive: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "cell(index)",
              fn: function (data) {
                return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
              },
            },
            {
              key: "row-details",
              fn: function (data) {
                return [
                  data.item.ktru
                    ? [
                        _c("p", [
                          _c("b", [_vm._v("Рег.номер ТРУ:")]),
                          _vm._v(" " + _vm._s(data.item.ktru.reg_number)),
                        ]),
                        _c("p", [
                          _c("b", [_vm._v("Группа ТРУ:")]),
                          _vm._v(" " + _vm._s(data.item.ktru.ktru_group_name)),
                        ]),
                        _c("p", [
                          _c("b", [_vm._v("Наименование:")]),
                          _vm._v(" " + _vm._s(data.item.ktru.name)),
                        ]),
                        _c("p", [
                          _c("b", [_vm._v("Тип:")]),
                          _vm._v(" " + _vm._s(data.item.ktru.type)),
                        ]),
                        data.item.okpd
                          ? _c("p", [
                              _c("b", [_vm._v("Код ОКПД2:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.item.okpd ? data.item.okpd.code : "—"
                                  )
                              ),
                            ])
                          : _vm._e(),
                        data.item.ktru.country_of_origin_name
                          ? _c("p", [
                              _c("b", [_vm._v("Страна происхождения:")]),
                              _vm._v(
                                " " +
                                  _vm._s(data.item.ktru.country_of_origin_name)
                              ),
                            ])
                          : _vm._e(),
                        data.item.ktru.description
                          ? _c("p", [
                              _c("b", [_vm._v("Описание:")]),
                              _vm._v(" " + _vm._s(data.item.ktru.description)),
                            ])
                          : _vm._e(),
                        data.item.ktru.specs && data.item.ktru.specs.length > 0
                          ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                          : _vm._e(),
                        data.item.ktru.specs && data.item.ktru.specs.length > 0
                          ? _c(
                              "ul",
                              _vm._l(
                                data.item.ktru.specs,
                                function (spec, key) {
                                  return _c("li", { key: "spec" + key }, [
                                    _c("span", { staticClass: "fw-600" }, [
                                      _vm._v(_vm._s(spec.name)),
                                    ]),
                                    _vm._v(": " + _vm._s(spec.value) + " "),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    : [_vm._v("—")],
                ]
              },
            },
            {
              key: "cell(specs)",
              fn: function (data) {
                return [
                  _c("action-button-small", {
                    attrs: {
                      title:
                        (data.detailsShowing ? "Скрыть" : "Показать") +
                        " характеристики",
                      icon: data.detailsShowing ? "eye-slash" : "eye",
                    },
                    on: { click: data.toggleDetails },
                  }),
                  _vm._v(" " + _vm._s(data.item.ktru.name) + " "),
                ]
              },
            },
            {
              key: "cell(okei)",
              fn: function (data) {
                return [
                  data.item.okei
                    ? [_vm._v(_vm._s(data.item.okei.local_symbol))]
                    : [_vm._v("—")],
                ]
              },
            },
            {
              key: "cell(price)",
              fn: function (data) {
                return [
                  _vm._v(" " + _vm._s(_vm.$formatPrice(data.item.price)) + " "),
                ]
              },
            },
            {
              key: "cell(cost)",
              fn: function (data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatPrice(data.item.count * data.item.price)
                      ) +
                      " "
                  ),
                ]
              },
            },
            _vm.items.length > 0
              ? {
                  key: "bottom-row",
                  fn: function () {
                    return [
                      _c(
                        "b-td",
                        {
                          staticClass: "text-right py-1",
                          attrs: { colspan: "6" },
                        },
                        [
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              "Итого, руб.: " +
                                _vm._s(_vm.$formatPrice(_vm.totalCost))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }