<template>
    <b-card no-body border-variant="light" class="mb-2">
        <b-card-header header-tag="header" class="p-0 border-light custom-header" role="tab">
            <b-row class="mb-2">
                <b-col class="col-auto">
            <b-button :variant="visible ? 'custom-green' : 'outline-secondary'" class="text-uppercase fs-14 fw-600" @click="displayed = !displayed">
                {{ title }}
                <span class="float-right ml-2"><b-icon :icon="displayed ? 'chevron-up' : 'chevron-down'" /> </span>
            </b-button>
                </b-col>
                <b-col class="my-auto">
                    <span v-if="!displayed" class="fs-14 fw-600">
                        от {{ $formatDate(supplementaryAgreement.supplementary_agreement_date) }}, на сумму {{ $formatPrice(supplementaryAgreement.price) }} руб.
                    </span>
                </b-col>
            </b-row>
        </b-card-header>
        <b-collapse v-model="displayed" :id="id" accordion="supplementary-agreements-accordion" role="tabpanel">
            <b-card-body class="py-0 px-2">
                <text-row-price :value="supplementaryAgreement.price" compact label="Общая стоимость" />
                <text-row-date :value="supplementaryAgreement.supplementary_agreement_date" compact label="Дата заключения дополнительного соглашения" />
                <text-row-single-document v-if="!purchase.is_private_provider || (purchase.is_private_provider && isPurchaseCreator())" label="Документы" v-model="supplementaryAgreement.supplementary_agreement_file" compact />
                <specifications-table :items="supplementaryAgreement.deliverables" :total-cost="supplementaryAgreement.price">
                    <template #title>
                        <h5 class="grey-color fw-600">Спецификация дополнительного соглашения</h5>
                    </template>
                </specifications-table>
                <template v-if="!supplementaryAgreement.without_limits && supplementaryAgreement.limits.length > 0 && isPurchaseCreator()">
                    <limits-editable-table v-if="purchase.status.id === 'contract:active' && isLimitsEdit" v-model="form.limits" :total-cost="supplementaryAgreement.price" @validate="limitsValidate" :limit-year="purchase.purchase_limit_year" :order-type="{id: purchase.order_type_id, name: purchase.order_type_name}" purchase-type="fl44_external" :purchase-id="purchase.id">
                        <template #title>
                            <h5 class="grey-color fw-600">Измените лимиты</h5>
                        </template>
                    </limits-editable-table>
                    <limits-table v-else :items="supplementaryAgreement.limits">
                        <template #title>
                            <h5 class="grey-color fw-600">Лимиты по КБК</h5>
                        </template>
                    </limits-table>
                    <template v-if="purchase.status.id === 'contract:active' && purchase.last_supplementary_agreement_id === supplementaryAgreement.id">
                        <template v-if="isLimitsEdit">
                            <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                                <b-button variant="custom-outline-secondary" class="text-uppercase mt-1 mb-3 mr-3" @click="isLimitsEdit = false">Отмена</b-button>
                                <b-button variant="custom-green" :disabled="!isLimitsValid" class="text-uppercase mt-1 mb-3" @click="updateLimits">Сохранить</b-button>
                            </b-overlay>
                        </template>
                        <template v-else>
                            <b-button variant="custom-green" class="text-uppercase mt-1 mb-3" @click="editLimits">Изменить лимиты</b-button>
                        </template>
                    </template>
                </template>
                <template v-if="supplementaryAgreement.supplier_accounting && isPurchaseCreator()">
                    <h5 class="grey-color fw-600">Банковские реквизиты поставщика</h5>
                    <text-row :value="supplementaryAgreement.supplier_accounting.bank_type.title" compact label="Вид обслуживающей организации" />
                    <text-row v-model="supplementaryAgreement.supplier_accounting.bik" compact label="БИК банка" />
                    <text-row v-model="supplementaryAgreement.supplier_accounting.bank_name" compact label="Название банка" />
                    <text-row v-model="supplementaryAgreement.supplier_accounting.bank_city" compact label="Город банка" />
                    <text-row v-model="supplementaryAgreement.supplier_accounting.ks" compact label="Кор. счет" />
                    <text-row v-model="supplementaryAgreement.supplier_accounting.rs" compact label="Расчетный счет" />
                    <text-row v-if="supplementaryAgreement.supplier_accounting.bank_type.id !== 'bank'" v-model="supplementaryAgreement.supplier_accounting.ls" compact label="Лицевой счет" />
                    <text-row v-if="supplementaryAgreement.supplier_accounting.bank_type.id !== 'bank'" v-model="supplementaryAgreement.supplier_accounting.fo_inn" compact label="ИНН финансового органа" />
                    <text-row v-if="supplementaryAgreement.supplier_accounting.bank_type.id !== 'bank'" v-model="supplementaryAgreement.supplier_accounting.fo_name" compact label="Полное наименование финансового органа" />
                </template>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>

import SpecificationsTable from "@/components/externalPurchases/partials/specifications-table.vue";
import LimitsTable from "@/components/externalPurchases/partials/limits-table.vue";
import LimitsEditableTable from "@/components/externalPurchases/partials/limits-editable-table.vue";
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'supplementary-agreement-item',
    components: {LimitsEditableTable, LimitsTable, SpecificationsTable},
    props: {
        supplementaryAgreement: {
            type: Object,
            required: true
        },
        purchase: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            displayed: this.visible,
            isLoading: false,
            isLimitsEdit: false,
            isLimitsValid: false,
            form: {
                limits: []
            }
        }
    },
    methods: {
        limitsValidate(value) {
            this.isLimitsValid = !value;
        },
        editLimits() {
            this.form.limits = cloneDeep(this.supplementaryAgreement.limits);
            this.isLimitsEdit = true;
        },
        async updateLimits() {
            const form = {};
            form.limits = this.form.limits.map((item) => {
                return {
                    id: item.id,
                    year: item.year,
                    amount_assigned: item.amount_assigned
                };
            });
            this.isLoading = true;
            this.$api.personal.fl44ExternalPurchases.updateSupplementaryAgreementLimits(this.purchase.id, this.supplementaryAgreement.id, {form: form})
                .then(() => {
                    this.isLimitsEdit = false;
                    this.$emit('refresh');
                })
                .catch(() => {})
                .finally(() => {
                    this.isLoading = false;
                })
        }
    },
    computed: {
        id() {
            return 'supplementary_agreement_' + this._uid;
        }
    }
};
</script>

<style scoped>
.custom-header {
    background-color: var(--greyBg-color) !important;
}
.btn-custom-secondary {
    background-color: var(--greyBg-color) !important;
    color: var(--grey-color) !important;
    min-width: 130px;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
}

.btn-custom-secondary:hover {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(85%);
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    color: var(--grey-color);
    border-top: 0;
}

.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
