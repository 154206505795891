<template>
    <div v-if="!loading">
        <form-header :title="title" without-current-organization />
        <form-content-group title="Информация о запросе">
            <text-row :value="importSubstitutionRequest.purchase_category_name" label="Предмет запроса" />
            <text-row-datetime :value="importSubstitutionRequest.deadline" label="Срок сбора предложений" />
        </form-content-group>
        <import-substitution-request-show-ktru-item-block :ktruItem="importSubstitutionRequest.ktru_item" :price="importSubstitutionRequest.price" />
        <form-content-group title="Техническое описание">
            <text-row-documents label="Документ" :value="documents" />
        </form-content-group>
        <form-content-group title="Предложения импортозамещения">
            <template v-if="importSubstitutionRequest.replies.length > 0">
                <import-substitution-offer-card v-for="(offer, index) in importSubstitutionRequest.replies" :key="'offer_' + index" :offer="offer" :index="index" />
            </template>
            <template v-else>
                <p class="text-center fs-16 grey-color font-weight-bold">Предложения отсутствуют</p>
            </template>
        </form-content-group>

        <form-content-group title="" class="mb-5" v-if="importSubstitutionRequest.status_id === 1">
            <b-button v-if="$store.getters.isLoggedIn && $store.getters.isProvider" variant="custom-green" class="text-uppercase" @click="createReply">Подать предложение</b-button>
        </form-content-group>
    </div>
</template>

<script>
import FormHeader from '@/components/common/form-rows/form-header';
import ImportSubstitutionRequestShowKtruItemBlock from '@/components/import-substitution/import-substitution-request-show-ktru-item-block';
import FormContentGroup from '@/components/common/form-rows/form-content-group';
import ImportSubstitutionOfferCard from '@/components/import-substitution/import-substitution-offer-card';

export default {
    name: 'ImportSubstitutionRequestShow',
    components: {
        ImportSubstitutionOfferCard,
        FormContentGroup,
        ImportSubstitutionRequestShowKtruItemBlock,
        FormHeader
    },

    data() {
        return {
            loading: true,
            importSubstitutionRequest: {
                id: null,
                reg_number: '',
                purchase_category_name: '',
                ktru_item: {
                    id: null,
                    name: '',
                    description: '',
                    specs: []
                },
                organization: {
                    id: null,
                    name: ''
                },
                created_at: null,
                deadline: null,
                status_id: null,
                stored_document: null,
                price: 0,
                replies: []
            }
        };
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            this.importSubstitutionRequest = await this.$api.importSubstitutionRequests.get(this.$route.params.id);
        },
        onCancel() {
            this.$router.push({ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }).catch(() => {});
        },
        createReply() {
            this.$router.push({ name: 'ImportSubstitutionReplyCreate', params: { id: this.$route.params.id } }).catch(() => {});
        }
    },
    computed: {
        title() {
            return this.importSubstitutionRequest.reg_number ? 'Запрос импортозамещения №' + this.importSubstitutionRequest.reg_number : '';
        },
        documents() {
            return [{ ...this.importSubstitutionRequest.stored_document }];
        }
    }
};
</script>
