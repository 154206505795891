<template>
    <div>
        <slot name="title">
            <text-header>Лимиты по КБК</text-header>
        </slot>
        <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" details-td-class="py-1" small responsive>
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #row-details="data">
                <limit-info :limit="data.item" />
            </template>
            <template #cell(kbk)="data">
                <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' подробную информацию'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                {{ data.item.kbk }}
            </template>
            <template #cell(subkosgu_code)="data">
                {{ data.item.subkosgu_code ?? '&mdash;' }}
            </template>
            <template #cell(amount_available)="data">
                {{ $formatPrice(data.item.amount_available) }}
            </template>
            <template #cell(amount_total)="data">
                {{ $formatPrice(data.item.amount_total) }}
            </template>
            <template #head(amount_assigned)="data">
                {{ data.label }} <b-icon-question-circle class="atmo-quick-helper" title="Часть Стоимости спецификации, распределенная на данный Доступный лимит." />
            </template>
            <template #cell(amount_assigned)="data">
                {{ $formatPrice(data.item.amount_assigned) }}
            </template>
            <template v-if="totalCost" #bottom-row>
                <b-td colspan="7" class="text-right py-1">
                    <span class="fw-600">Распределено итого, руб.: {{ $formatPrice(totalCost) }}</span>
                </b-td>
            </template>
        </b-table>
    </div>
</template>

<script>

import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";
import LimitInfo from "@/components/externalPurchases/partials/limit-info.vue";

export default {
    name: 'limits-table',
    components: {LimitInfo},
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            fields: [
                { key: 'index', label: '№', tdClass: 'align-text-top py-1', thClass: 'py-1' },
                { key: 'kbk', label: 'КБК', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1' },
                { key: 'subkosgu_code', label: 'СубКОСГУ', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'year', label: 'Год финансирования', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_available', label: 'Доступный лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_total', label: 'Общий лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_assigned', label: 'Распределено, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
            ]
        };
    },
    computed: {
        totalCost() {
            return roundToTwoDecimals(this.items.reduce((sum, item) => {
                return sum += item.amount_assigned;
            }, 0));
        }
    }
};
</script>
