var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between" },
            [
              _c("text-header-new", [_vm._v("Адресная книга")]),
              _c(
                "div",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "custom-green", size: "sm" },
                      on: { click: _vm.onAddClick },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "my-auto mr-2",
                        attrs: { icon: "plus-circle", "aria-hidden": "true" },
                      }),
                      _vm._v(" Добавить адрес "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.addresses.length
            ? _c("b-col", { staticClass: "text-center mt-5" }, [
                _c("img", {
                  attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
                }),
                _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                  _vm._v("В данный момент адресная книга пуста"),
                ]),
              ])
            : _c(
                "b-col",
                { staticClass: "mb-3" },
                [
                  _c("b-table", {
                    staticClass: "table-atmo",
                    attrs: {
                      busy: _vm.isLoading,
                      fields: _vm.fields,
                      items: _vm.addresses,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(index)",
                        fn: function (row) {
                          return [_vm._v(_vm._s(row.index + 1))]
                        },
                      },
                      {
                        key: "cell(full_address)",
                        fn: function (row) {
                          return [
                            _vm._v(
                              " " + _vm._s(row.item.full_address.address) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c("action-button-small", {
                              attrs: { icon: "trash" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAddress(row.item.id)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Добавить адрес",
            size: "lg",
            "hide-footer": "",
            centered: "",
          },
          model: {
            value: _vm.form.showModal,
            callback: function ($$v) {
              _vm.$set(_vm.form, "showModal", $$v)
            },
            expression: "form.showModal",
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isLoading,
                rounded: "",
                opacity: "0.6",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("form-row-edit-text", {
                        attrs: {
                          v: _vm.$v.form.short_address,
                          "max-length": 50,
                          label: "Короткое название",
                        },
                        model: {
                          value: _vm.form.short_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "short_address", $$v)
                          },
                          expression: "form.short_address",
                        },
                      }),
                      _c("form-row-edit-address", {
                        attrs: {
                          value: _vm.form.full_address,
                          v: _vm.$v.form.full_address,
                          label: "Полный адрес из ФИАС",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "full_address", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: _vm.$v.form.$invalid,
                            variant: "custom-green",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("Добавить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }