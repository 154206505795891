var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "grey-color mb-3 main_text font-weight-bold" }, [
        _vm._v("Предложения 223-ФЗ"),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-2 cabinet_filter" },
        [
          _c("filter-new-text", {
            attrs: {
              label: "Заказчик (ИНН/Название)",
              md: "6",
              placeholder: "Введите название или ИНН организации заказчика",
            },
            model: {
              value: _vm.filter.customer,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "customer", $$v)
              },
              expression: "filter.customer",
            },
          }),
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.filterStatuses,
              label: "Статус предложения",
              "label-class": "grey-color",
              "label-field": "title",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус предложения",
            },
            model: {
              value: _vm.filter.status,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status", $$v)
              },
              expression: "filter.status",
            },
          }),
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.filterPurchaseStatuses,
              label: "Статус закупки",
              "label-class": "grey-color",
              "label-field": "title",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус закупки",
            },
            model: {
              value: _vm.filter.purchase_status,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "purchase_status", $$v)
              },
              expression: "filter.purchase_status",
            },
          }),
          _c(
            "b-col",
            { staticClass: "mb-1 mt-auto", attrs: { lg: "3", md: "3" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase w-100",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getProposals(true)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-1 mt-auto", attrs: { lg: "3", md: "3" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase w-100",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearFilter.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.proposals && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "mb-3",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _vm._l(_vm.proposals, function (proposal) {
        return _c(
          "div",
          { key: proposal.id },
          [
            _c("cabinet-fl223-proposal-card", {
              attrs: { proposal: proposal },
              on: { refresh: _vm.refresh },
            }),
          ],
          1
        )
      }),
      !_vm.proposals.length && !_vm.loading ? _c("empty-request") : _vm._e(),
      _vm.proposals && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "mb-3",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getProposals()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }