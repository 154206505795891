<template>
    <div>
        <b-modal :visible="visible" title="Выберите сертификат для привязки к учетной записи" no-close-on-backdrop hide-header-close no-close-on-esc centered size="lg">
            <div class="text-center">
                <b-button class="mb-3" size="sm" variant="outline-secondary" :disabled="is_certificates_loading" @click="refreshCertificates"> Обновить список сертификатов </b-button>
                <multiselect
                    v-model="selectedCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    :disabled="is_certificates_loading"
                    label="subjectName"
                    placeholder="Выберите сертификат"
                    track-by="thumbprint">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                </multiselect>
            </div>
            <template #modal-footer>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="close">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mx-1 text-uppercase" variant="custom-green" :disabled="!selectedCertificate" @click="linkCertificate">Привязать</b-button>
                </b-overlay>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { getUserCertificates } from 'crypto-pro';
import { $api } from '@/services/backend/api';

export default {
    name: 'user-change-certificate',
    data() {
        return {
            certificates: [],
            is_certificates_loading: false,
            selectedCertificate: null,
            isDataSending: false,
            visible: false
        };
    },
    async mounted() {
        await this.refreshCertificates();
        this.visible = true;
    },
    methods: {
        async refreshCertificates() {
            this.is_certificates_loading = true;
            this.certificates = await getUserCertificates();
            this.selectedCertificate = null;
            this.is_certificates_loading = false;
        },
        async linkCertificate() {
            this.isDataSending = true;
            if (await $api.personal.profile.changeCertificate(this.selectedCertificate)) {
                await this.$store.dispatch('logout');
            }
            this.isDataSending = false;
        },
        close() {
            this.$router.push({ name: 'UserProfile' });
        }
    }
};
</script>
