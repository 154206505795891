var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c("div", { staticClass: "purchase_part" }, [
        _c(
          "div",
          {
            staticClass: "purchase_part-header",
            class: {
              discontinued:
                _vm.purchase.status_id === 6 || _vm.purchase.status_id === 5,
            },
          },
          [
            _c("b-col", { staticClass: "px-1", attrs: { md: "8", lg: "8" } }, [
              _c(
                "p",
                {
                  staticClass: "purchase_part-header_number",
                  staticStyle: {
                    "white-space": "nowrap",
                    overflow: "hidden",
                    "text-overflow": "ellipsis",
                  },
                },
                [
                  _c("b-badge", { staticClass: "fs-14 mr-2" }, [
                    _vm._v("44-ФЗ"),
                  ]),
                  _c("b-badge", { staticClass: "fs-14 mr-2" }, [
                    _vm._v("Вне ИС"),
                  ]),
                  _vm.purchase.status_id === 11
                    ? _c("span", [_vm._v(_vm._s(_vm.purchase.reg_number))])
                    : _c(
                        "router-link",
                        {
                          staticClass: "white-system_link-style fw-600",
                          attrs: {
                            to: {
                              name: "ExternalPurchaseShow",
                              params: { id: _vm.purchase.id },
                            },
                            exact: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.purchase.reg_number))]
                      ),
                  _vm.purchase.purchase_object
                    ? _c(
                        "span",
                        {
                          staticStyle: { "text-overflow": "ellipsis ellipsis" },
                        },
                        [
                          _vm._v(
                            ' На закупку "' +
                              _vm._s(_vm.purchase.purchase_object) +
                              '"'
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("b-col", { staticClass: "px-1", attrs: { lg: "4", md: "4" } }, [
              _c(
                "p",
                {
                  staticClass: "purchase_part-header_label fw-600 float-right",
                },
                [
                  _vm._v(" " + _vm._s(_vm.purchase.status) + " "),
                  _vm.purchase.status_id === 6 || _vm.purchase.status_id === 5
                    ? _c("img", {
                        staticClass: "ml-2",
                        attrs: { src: "/images/white_times.svg", alt: "" },
                      })
                    : _vm._e(),
                  _vm.purchase.status_id === 4
                    ? _c("img", {
                        staticClass: "ml-2",
                        attrs: { src: "/images/file_check.svg", alt: "" },
                      })
                    : _vm._e(),
                  _vm.purchase.status_id === 3
                    ? _c("img", {
                        staticClass: "ml-2",
                        attrs: { src: "/images/white_contract.svg", alt: "" },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "purchase_part-body row" },
          [
            _c(
              "b-col",
              { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
              [
                _c("div", [
                  _vm.purchase.planned_contract_date
                    ? _c("div", { staticClass: "my-2" }, [
                        _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                          _vm._v("Договор заключен:"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-12 m-0 grey-color fw-600 time_to_end",
                          },
                          [
                            _c("span", { staticClass: "fw-600" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getDateFormat(
                                    _vm.purchase.planned_contract_date
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.purchase.planned_end_date
                    ? _c("div", { staticClass: "my-2" }, [
                        _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                          _vm._v("Дата исполнения договора:"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-12 m-0 grey-color fw-600 mb-4 time_to_end",
                          },
                          [
                            _c("span", { staticClass: "fw-600" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getDateFormat(
                                    _vm.purchase.planned_end_date
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.isPurchaseCreator()
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c("purchase-comment-edit-block", {
                            attrs: { purchase: _vm.purchase },
                            on: { refresh: _vm.refresh },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.status_id !== 11 && _vm.isPurchaseCreator()
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass:
                                "btn btn-card-action btn-card-action my-3",
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseEdit",
                                  params: { id: _vm.purchase.id },
                                },
                              },
                            },
                            [_vm._v("Редактировать")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _c(
              "b-col",
              { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
              [
                _c("div", [
                  _c("div", { staticClass: "my-2" }, [
                    _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                      _vm._v("Предмет закупки:"),
                    ]),
                    _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                      _vm._v(_vm._s(_vm.purchase.purchase_name)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "my-2 d-flex flex-column" },
                    [
                      _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                        _vm._v("Заказчик:"),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "system_link-style fw-600 text-uppercase fs-12",
                          attrs: {
                            to: "/organizations/" + _vm.purchase.org_id,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.purchase.organization) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "my-2" }, [
                    _c("p", { staticClass: "fs-12 grey-color my-2" }, [
                      _vm._v(" Количество позиций: "),
                      _c("span", { staticClass: "fw-600" }, [
                        _vm._v(_vm._s(_vm.purchase.items_count)),
                      ]),
                    ]),
                    _c("p", { staticClass: "fs-12 grey-color my-2" }, [
                      _vm._v(" Сумма закупки: "),
                      _c("span", { staticClass: "fw-600" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) +
                            " руб."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "b-col",
              { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
              [
                _c(
                  "div",
                  [
                    _vm.purchase.status_id === 11
                      ? [
                          _c(
                            "b-btn",
                            {
                              staticClass:
                                "btn btn-card-action btn-card-action-green my-1",
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseCreate",
                                  params: {
                                    mode: "edit_draft",
                                    id: _vm.purchase.id,
                                  },
                                },
                              },
                            },
                            [_vm._v("Редактировать")]
                          ),
                          _c(
                            "b-btn",
                            {
                              staticClass:
                                "btn btn-card-action btn-card-action-light my-1",
                              on: {
                                click: function ($event) {
                                  _vm.showDeleteModal = true
                                },
                              },
                            },
                            [_vm._v("Удалить")]
                          ),
                        ]
                      : [
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/search.svg",
                                    alt: "check_label",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "ExternalPurchaseShow",
                                      params: { id: _vm.purchase.id },
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Информация о закупке")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/small_box.svg",
                                    alt: "check_label",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "ExternalPurchaseShow",
                                      params: { id: _vm.purchase.id },
                                      hash: "#supplier-info",
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Поставщик")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/contract.svg",
                                    alt: "check_label",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "ExternalPurchaseShow",
                                      params: { id: _vm.purchase.id },
                                      hash: "#contract",
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Договор")]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          ref: "delete-modal",
          attrs: {
            title: "Удалить закупку",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            centered: "",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите удалить закупку " +
                _vm._s(_vm.purchase.reg_number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showDeleteModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _vm._v("  "),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-danger" },
                  on: {
                    click: function ($event) {
                      return _vm.onDelete(_vm.purchase.id)
                    },
                  },
                },
                [_vm._v("Удалить")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }