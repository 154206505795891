var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("title", function () {
        return [_c("text-header", [_vm._v("Лимиты по КБК")])]
      }),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            [
              _c("span", [
                _vm._v(
                  "Добавьте или измените лимиты. Необходимо полностью распределить стоимость спецификации по соответствующим лимитам."
                ),
              ]),
              _c("b-icon-question-circle", {
                staticClass: "atmo-quick-helper ml-1",
                attrs: {
                  title:
                    'Можно добавить новый лимит года финансирования по КБК (кнопка "Добавить лимит") или сменить лимит для уже добавленного в список КБК на лимит другого года (кнопка с кодом КБК).',
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("info"),
      _c("b-table", {
        staticClass: "table-atmo",
        class: { "is-table-invalid": _vm.$v.$invalid },
        attrs: {
          id: "my-table",
          fields: _vm.fields,
          items: _vm.items,
          "details-td-class": "py-1",
          "show-empty": "",
          small: "",
          responsive: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(index)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
            },
          },
          {
            key: "row-details",
            fn: function (data) {
              return [_c("limit-info", { attrs: { limit: data.item } })]
            },
          },
          {
            key: "cell(kbk)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-inline-block align-text-center" },
                  [
                    _c("action-button-small", {
                      attrs: {
                        title:
                          (data.detailsShowing ? "Скрыть" : "Показать") +
                          " подробную информацию",
                        icon: data.detailsShowing ? "eye-slash" : "eye",
                      },
                      on: { click: data.toggleDetails },
                    }),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          title:
                            "Нажмите, чтобы выбрать лимит другого года финансирования внутри этого КБК.",
                          variant: "outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editItem(data.item, data.index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(data.item.kbk))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(subkosgu_code)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(data.item.subkosgu_code ?? "—") + " "),
              ]
            },
          },
          {
            key: "cell(amount_available)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$formatPrice(data.item.amount_available)) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(amount_total)",
            fn: function (data) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$formatPrice(data.item.amount_total)) + " "
                ),
              ]
            },
          },
          {
            key: "head(amount_assigned)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(data.label) + " "),
                _c("b-icon-question-circle", {
                  staticClass: "atmo-quick-helper",
                  attrs: {
                    title:
                      "Часть Стоимости спецификации, распределенная на данный Доступный лимит.",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(amount_assigned)",
            fn: function (data) {
              return [
                _c("currency-input", {
                  staticClass:
                    "form-control fs-14 text-right currency-input mb-0",
                  class: _vm.$v.items.$each[data.index].$invalid
                    ? "is-invalid"
                    : "is-valid",
                  attrs: { currency: "RUB", locale: "ru" },
                  on: {
                    focus: function ($event) {
                      $event.target?.select()
                    },
                  },
                  model: {
                    value: data.item.amount_assigned,
                    callback: function ($$v) {
                      _vm.$set(data.item, "amount_assigned", $$v)
                    },
                    expression: "data.item.amount_assigned",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c("action-button-small", {
                  attrs: { icon: "trash", title: "Удалить" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem(data.index)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "w-100 text-center" }, [
                  _vm._v("Лимиты не добавлены"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "bottom-row",
            fn: function () {
              return [
                _c(
                  "b-td",
                  {
                    staticClass: "align-text-top py-1 pl-0",
                    attrs: { colspan: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase ml-1",
                        attrs: { variant: "custom-green" },
                        on: {
                          click: function ($event) {
                            _vm.showSelectModal = true
                          },
                        },
                      },
                      [_vm._v("Добавить лимит")]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-td",
                  { staticClass: "text-right py-1", attrs: { colspan: "4" } },
                  [
                    _vm.items && _vm.items.length > 0
                      ? _c("ul", [
                          _c("li", { staticClass: "fw-600" }, [
                            _vm._v("Распределено итого, руб.:"),
                          ]),
                          _c("li", { staticClass: "fw-600" }, [
                            _vm._v("Стоимость спецификации итого, руб.:"),
                          ]),
                          _vm.limitsDeliverablesVariance !== 0
                            ? _c("li", { staticClass: "fw-600" }, [
                                _vm._v(
                                  "Отклонение от стоимости спецификации, руб.:"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("b-td", { staticClass: "text-right py-1" }, [
                  _vm.items && _vm.items.length > 0
                    ? _c("ul", [
                        _c("li", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.$formatPrice(_vm.totalAmount))),
                        ]),
                        _c("li", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.$formatPrice(_vm.totalCost))),
                        ]),
                        _vm.limitsDeliverablesVariance !== 0
                          ? _c("li", { staticClass: "fw-600 text-danger" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(
                                    _vm.limitsDeliverablesVariance
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-form-invalid-feedback",
        { staticClass: "mt-3", attrs: { state: !_vm.$v.items.$invalid } },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c("limit-select-modal", {
        attrs: {
          show: _vm.showSelectModal,
          "order-type": _vm.orderType,
          year: _vm.limitYear,
          "is-edit": _vm.isEdit,
          "limit-id": _vm.limitId,
          "purchase-type": _vm.purchaseType,
          "purchase-id": _vm.purchaseId,
        },
        on: { hide: _vm.hideSelectModal, submit: _vm.submitLimit },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }