var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "create" },
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-3", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-36 fw-600 white-color my-2 if-tablet",
                          },
                          [
                            _vm._v(
                              "Внесистемная закупка № " +
                                _vm._s(_vm.purchase.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color mb-1" }, [
                          _vm._v(
                            "«" +
                              _vm._s(_vm.$store.state.organization.short_name) +
                              "»"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color" }, [
                          _vm._v(
                            " Пользователь: " +
                              _vm._s(_vm.$store.state.user.full_name) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticStyle: { "background-color": "#f5f5f7" },
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                { staticClass: "my-3" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "8" } },
                        [_c("text-header", [_vm._v(" Информация о закупке")])],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("youtube-link-button", {
                            staticClass: "text-right",
                            attrs: {
                              href: _vm.$links.external_purchase_edit_manual,
                              text: "Инструкция по внесистемным закупкам",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("form-row-edit-textarea", {
                    staticClass: "mb-0",
                    attrs: {
                      rows: 5,
                      label:
                        "Примечание к закупке (отображается только в ЛК Заказчика)",
                    },
                    model: {
                      value: _vm.purchase.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "comment", $$v)
                      },
                      expression: "purchase.comment",
                    },
                  }),
                  _c("text-row", {
                    attrs: { compact: "", label: "Тип закупки" },
                    model: {
                      value: _vm.purchase.order_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "order_type", $$v)
                      },
                      expression: "purchase.order_type",
                    },
                  }),
                  !_vm.isBasedOnFailedPurchase &&
                  !_vm.isBeforeUncategorizedLimit &&
                  !_vm.customerCanCreateHiddenPurchase
                    ? _c("text-row", {
                        attrs: { compact: "", label: "Категория закупки" },
                        model: {
                          value: _vm.purchase.fl44_external_purchase_category,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "fl44_external_purchase_category",
                              $$v
                            )
                          },
                          expression:
                            "purchase.fl44_external_purchase_category",
                        },
                      })
                    : _vm._e(),
                  _vm.isBasedOnFailedPurchase
                    ? _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "Номер несостоявшейся закупки",
                        },
                        model: {
                          value: _vm.purchase.failed_fl44_purchase.reg_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.failed_fl44_purchase,
                              "reg_number",
                              $$v
                            )
                          },
                          expression:
                            "purchase.failed_fl44_purchase.reg_number",
                        },
                      })
                    : _vm._e(),
                  !_vm.purchase.deliverable_group
                    ? _c("text-row", {
                        attrs: { compact: "", label: "Наименование закупки" },
                        model: {
                          value: _vm.purchase.purchase_object,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_object", $$v)
                          },
                          expression: "purchase.purchase_object",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.order_type_limit_used
                    ? _c("text-row", {
                        attrs: {
                          value: _vm.iskbklimitoptional ? "Да" : "Нет",
                          compact: "",
                          label: "Закупка при отсутствии ЛБО (лимитов по КБК)",
                        },
                      })
                    : _vm._e(),
                  !_vm.iskbklimitoptional
                    ? _c("text-row", {
                        attrs: {
                          value: _vm.purchase.is_advance_report ? "Да" : "Нет",
                          compact: "",
                          label: "Авансовый отчет",
                        },
                      })
                    : _vm._e(),
                  !_vm.iskbklimitoptional
                    ? _c("text-row", {
                        attrs: { compact: "", label: "Год ПГ (Плана-графика)" },
                        model: {
                          value: _vm.purchase.purchase_limit_year,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_limit_year", $$v)
                          },
                          expression: "purchase.purchase_limit_year",
                        },
                      })
                    : _vm._e(),
                  _vm.isHiddenPurchaseCategory
                    ? _c("text-row", {
                        attrs: { compact: "", label: "Предмет закупки" },
                        model: {
                          value: _vm.purchase.purchase_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_name", $$v)
                          },
                          expression: "purchase.purchase_name",
                        },
                      })
                    : _c("text-row", {
                        attrs: { compact: "", label: "Предмет закупки" },
                        model: {
                          value: _vm.purchase.deliverable_group,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "deliverable_group", $$v)
                          },
                          expression: "purchase.deliverable_group",
                        },
                      }),
                  _vm.editablePurchaseObject
                    ? _c("form-row-edit-text", {
                        staticClass: "mb-0",
                        attrs: {
                          v: _vm.$v.purchase.purchase_object,
                          hint: "Укажите непосредственно товары, работы, услуги, поставка, выполнение, оказание которых будут являться предметом контракта.",
                          label: "Объект закупки",
                          placeholder: "Введите объект закупки",
                        },
                        model: {
                          value: _vm.purchase.purchase_object,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_object", $$v)
                          },
                          expression: "purchase.purchase_object",
                        },
                      })
                    : _c("text-row", {
                        attrs: { compact: "", label: "Объект закупки" },
                        model: {
                          value: _vm.purchase.purchase_object,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_object", $$v)
                          },
                          expression: "purchase.purchase_object",
                        },
                      }),
                  _c("text-row-date", {
                    attrs: {
                      value: _vm.purchase.contract_conclusion_date,
                      compact: "",
                      label: "Дата заключения договора",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      "max-date": _vm.$now(),
                      "min-date": _vm.purchase.contract_conclusion_date,
                      v: _vm.$v.purchase.contract_execution_date,
                      label: "Дата исполнения договора",
                    },
                    model: {
                      value: _vm.purchase.contract_execution_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "contract_execution_date", $$v)
                      },
                      expression: "purchase.contract_execution_date",
                    },
                  }),
                  _c("form-row-specification-table", {
                    staticClass: "atmo-hash-jump-point",
                    attrs: {
                      id: "specification",
                      "no-add-spec": true,
                      "no-delete-spec": true,
                      "spec-error-message": _vm.specErrorMessage,
                      "spec-errors": _vm.specErrors,
                      "spec-sum": _vm.specSum,
                      v: _vm.$v.purchase.deliverables,
                      "with-count-edit": !_vm.isBasedOnFailedPurchase,
                      "with-price": "",
                    },
                    model: {
                      value: _vm.purchase.deliverables,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "deliverables", $$v)
                      },
                      expression: "purchase.deliverables",
                    },
                  }),
                  !_vm.iskbklimitoptional
                    ? _c("KBKLimitsEditableList.view", {
                        staticClass: "atmo-page-content my-2 py-2",
                        attrs: {
                          initiallist: _vm.purchase.limits,
                          limitfilterpresets: {
                            year: _vm.purchase.purchase_limit_year,
                            purchase_type_id: _vm.purchase.order_type_id,
                            purchase_id: _vm.purchase.id,
                          },
                          totalamount: _vm.spec_total_amount,
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Информация о поставщике")]),
                  _vm.purchase.is_private_provider
                    ? [
                        _c("text-row", {
                          attrs: {
                            compact: "",
                            label: "Наименование",
                            value: "Физическое лицо",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            value: _vm.purchase.private_provider_data.inn,
                            compact: "",
                            label: "ИНН",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            value: _vm.purchase.private_provider_data.name,
                            compact: "",
                            label: "ФИО",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            value: _vm.purchase.private_provider_data.address,
                            compact: "",
                            label: "Адрес регистрации",
                          },
                        }),
                      ]
                    : [
                        _c("text-row", {
                          attrs: { compact: "", label: "ИНН" },
                          model: {
                            value: _vm.purchase.supplier.inn,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "inn", $$v)
                            },
                            expression: "purchase.supplier.inn",
                          },
                        }),
                        _vm.purchase.supplier.kpp
                          ? _c("text-row", {
                              attrs: { compact: "", label: "КПП" },
                              model: {
                                value: _vm.purchase.supplier.kpp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.purchase.supplier, "kpp", $$v)
                                },
                                expression: "purchase.supplier.kpp",
                              },
                            })
                          : _vm._e(),
                        _c("text-row", {
                          attrs: { compact: "", label: "ОГРН / ОГРНИП" },
                          model: {
                            value: _vm.purchase.supplier.ogrn,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "ogrn", $$v)
                            },
                            expression: "purchase.supplier.ogrn",
                          },
                        }),
                        _c("text-row", {
                          attrs: { compact: "", label: "Наименование" },
                          model: {
                            value: _vm.purchase.supplier.full_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "full_name", $$v)
                            },
                            expression: "purchase.supplier.full_name",
                          },
                        }),
                        _c("text-row", {
                          attrs: { compact: "", label: "Фактический адрес" },
                          model: {
                            value: _vm.purchase.supplier.fact_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.supplier,
                                "fact_address",
                                $$v
                              )
                            },
                            expression: "purchase.supplier.fact_address",
                          },
                        }),
                      ],
                  _c("text-header", [_vm._v("Информация о договоре")]),
                  _c("text-row", {
                    attrs: { compact: "", label: "Статус закупки" },
                    model: {
                      value: _vm.purchase.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "status", $$v)
                      },
                      expression: "purchase.status",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    attrs: {
                      "label-cols-lg": 3,
                      v: _vm.$v.purchase.contract_attachment,
                      title: "Договор",
                    },
                    model: {
                      value: _vm.purchase.contract_attachment,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "contract_attachment", $$v)
                      },
                      expression: "purchase.contract_attachment",
                    },
                  }),
                  _c("form-row-edit-text", {
                    attrs: {
                      description: _vm.Config.get(
                        "theme.variables.view.customer_contract_number_description"
                      ),
                      v: _vm.$v.purchase.customer_contract_number,
                      label: "Номер договора в системе учета заказчика",
                    },
                    model: {
                      value: _vm.purchase.customer_contract_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "customer_contract_number", $$v)
                      },
                      expression: "purchase.customer_contract_number",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.iskbklimitoptional &&
                            !_vm.purchase.is_advance_report,
                          expression:
                            "!iskbklimitoptional && !purchase.is_advance_report",
                        },
                      ],
                    },
                    [
                      _c("text-header", [
                        _vm._v("Банковские реквизиты поставщика"),
                      ]),
                      _c("p", [
                        _c("span", [
                          _vm._v(
                            "Укажите банковские реквизиты поставщика для передачи в РИС."
                          ),
                        ]),
                      ]),
                      _c(
                        "b-form-row",
                        [
                          _c("frc-select", {
                            attrs: {
                              options: _vm.dictionaries.bankTypes,
                              v: _vm.$v.purchase.supplier_accounting.bank_type,
                              label: "Вид обслуживающей организации",
                              "label-field": "title",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.bank_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "bank_type",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.supplier_accounting.bank_type",
                            },
                          }),
                          _c(
                            "b-col",
                            { attrs: { lg: 6, sm: 12 } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    state:
                                      _vm.$v.purchase.supplier_accounting.bik
                                        .$invalid,
                                    label: "БИК банка:",
                                    "label-class": "fs-14 grey-color fw-700",
                                    "label-for": "supplier-accounting-bik",
                                  },
                                },
                                [
                                  _c("VueSuggestions", {
                                    staticClass: "fs-14 form-control",
                                    class: _vm.$v.purchase.supplier_accounting
                                      .bik.$invalid
                                      ? "is-invalid"
                                      : "is-valid",
                                    attrs: {
                                      id: "supplier-accounting-bik",
                                      model:
                                        _vm.purchase.supplier_accounting.bik,
                                      options: _vm.suggestionOptionsBank,
                                      placeholder: "Начните вводить",
                                    },
                                  }),
                                  _vm.$v.purchase.supplier_accounting.bik
                                    .$invalid
                                    ? [
                                        _c("b-form-invalid-feedback", [
                                          _vm._v(
                                            "Поле обязательно для заполнения"
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.bank_name,
                              disabled: "disabled",
                              label: "Название банка",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.bank_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "bank_name",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.supplier_accounting.bank_name",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.bank_city,
                              disabled: "",
                              label: "Город банка",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.bank_city,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "bank_city",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.supplier_accounting.bank_city",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.ks,
                              label: "Кор. счет",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.ks,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "ks",
                                  $$v
                                )
                              },
                              expression: "purchase.supplier_accounting.ks",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.rs,
                              label: "Расчетный счет",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.rs,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "rs",
                                  $$v
                                )
                              },
                              expression: "purchase.supplier_accounting.rs",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.purchase.supplier_accounting.bank_type !==
                                  "bank",
                                expression:
                                  "purchase.supplier_accounting.bank_type !== 'bank'",
                              },
                            ],
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.ls,
                              label: "Лицевой счет",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.ls,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "ls",
                                  $$v
                                )
                              },
                              expression: "purchase.supplier_accounting.ls",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.purchase.supplier_accounting.bank_type !==
                                  "bank",
                                expression:
                                  "purchase.supplier_accounting.bank_type !== 'bank'",
                              },
                            ],
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.fo_inn,
                              label: "ИНН финансового органа",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.fo_inn,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "fo_inn",
                                  $$v
                                )
                              },
                              expression: "purchase.supplier_accounting.fo_inn",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.purchase.supplier_accounting.bank_type !==
                                  "bank",
                                expression:
                                  "purchase.supplier_accounting.bank_type !== 'bank'",
                              },
                            ],
                            attrs: {
                              v: _vm.$v.purchase.supplier_accounting.fo_name,
                              label: "Полное наименование финансового органа",
                            },
                            model: {
                              value: _vm.purchase.supplier_accounting.fo_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.supplier_accounting,
                                  "fo_name",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.supplier_accounting.fo_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("text-header", [_vm._v("Документы об исполнении")]),
                  _c(
                    "p",
                    [
                      _c("strong", { staticClass: "text-danger" }, [
                        _vm._v("Важно!"),
                      ]),
                      _vm._v(
                        " Редактирование статуса закупки и перечня документов об исполнении производите через "
                      ),
                      _c(
                        "router-link",
                        {
                          staticStyle: {
                            "text-decoration": "underline",
                            color: "var(--green-color)",
                          },
                          attrs: {
                            to: {
                              name: "ExternalPurchaseShow",
                              params: { purchase: this.$route.params.id },
                              hash: "#contract-fulfillment",
                            },
                          },
                        },
                        [_vm._v("форму просмотра")]
                      ),
                      _vm._v(" . "),
                    ],
                    1
                  ),
                  _c("form-row-documents", {
                    attrs: { title: "" },
                    model: {
                      value: _vm.purchase.execution_documents,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "execution_documents", $$v)
                      },
                      expression: "purchase.execution_documents",
                    },
                  }),
                  _vm.specErrors
                    ? _c("p", {
                        staticClass: "text-danger",
                        domProps: { textContent: _vm._s(_vm.specErrorMessage) },
                      })
                    : _vm._e(),
                  _vm.limitError
                    ? _c("p", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.limitErrorMessage),
                        },
                      })
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "my-3 mt-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2 text-uppercase",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("Отмена")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    disabled: !_vm.isValid,
                                    variant: "custom-green",
                                  },
                                  on: { click: _vm.sendData },
                                },
                                [_vm._v("Сохранить")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }