var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-2", attrs: { fluid: "" } },
            [
              _c("b-container", [
                _c("p", { staticClass: "fs-40 fw-600 white-color my-2" }, [
                  _vm._v(
                    "Закупка 223ФЗ - № " + _vm._s(_vm.purchase.reg_number)
                  ),
                ]),
                _c("p", { staticClass: "white-color fs-14" }, [
                  _c("b", [_vm._v("Статус")]),
                  _vm._v(": " + _vm._s(_vm.purchase.status.title)),
                ]),
              ]),
            ],
            1
          ),
          _vm.purchase.status.id === "cancelled" &&
          _vm.purchase.cancellation_info
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _vm.purchase.cancellation_info.reason
                        ? _c("text-row", {
                            attrs: {
                              compact: true,
                              label: "Причина отмены закупки",
                            },
                            model: {
                              value: _vm.purchase.cancellation_info.reason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "reason",
                                  $$v
                                )
                              },
                              expression: "purchase.cancellation_info.reason",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.cancellation_info.attachment
                        ? _c("text-row-single-document", {
                            attrs: {
                              compact: true,
                              label: "Документ с обоснованием отмены закупки",
                            },
                            model: {
                              value: _vm.purchase.cancellation_info.attachment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "attachment",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.cancellation_info.attachment",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Наименование",
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.purchase.customer.id },
                      },
                    },
                    model: {
                      value: _vm.purchase.customer.full_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "full_name", $$v)
                      },
                      expression: "purchase.customer.full_name",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ИНН",
                    },
                    model: {
                      value: _vm.purchase.customer.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "inn", $$v)
                      },
                      expression: "purchase.customer.inn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "КПП",
                    },
                    model: {
                      value: _vm.purchase.customer.kpp,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "kpp", $$v)
                      },
                      expression: "purchase.customer.kpp",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "ОГРН",
                    },
                    model: {
                      value: _vm.purchase.customer.ogrn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "ogrn", $$v)
                      },
                      expression: "purchase.customer.ogrn",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Юридический адрес",
                    },
                    model: {
                      value: _vm.purchase.customer.legal_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "legal_address", $$v)
                      },
                      expression: "purchase.customer.legal_address",
                    },
                  }),
                  _vm.purchase.purchase_terms.contact_info
                    ? _c("text-row", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.purchase_terms.contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.purchase_terms,
                              "contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.purchase_terms.contact_info",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Объект закупки",
                      value: _vm.purchase.purchase_terms.purchase_object,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Предмет закупки",
                      value: _vm.purchase.purchase_terms.deliverable_group.name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Тип закупочной сессии",
                      value: _vm.purchase.purchase_terms.duration_type.name,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: `Продолжительность ${
                        _vm.purchase.purchase_terms.duration_type.id !==
                        "quotation_request"
                          ? "закупочной сессии"
                          : "приема предложений"
                      }${_vm.purchase.purchase_terms.duration_type.suffix}`,
                      value: _vm.purchase.purchase_terms.duration,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Дата и время размещения извещения",
                      value:
                        _vm.purchase.purchase_terms.purchase_start_datetime,
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Дата и время окончания приема предложений",
                      value: _vm.purchase.purchase_terms.purchase_end_datetime,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Вид оплаты",
                      value: _vm.purchase.purchase_terms.payment_form,
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Условия оплаты",
                      value: _vm.purchase.purchase_terms.payment_term,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата заключения договора",
                      value:
                        _vm.purchase.purchase_terms
                          .planned_contract_conclusion_datetime,
                    },
                  }),
                  _c("text-row-date", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Планируемая дата исполнения договора",
                      value:
                        _vm.purchase.purchase_terms
                          .planned_contract_execution_datetime,
                    },
                  }),
                  _vm.purchase.purchase_terms.duration_type.id !==
                  "quotation_request"
                    ? _c("text-row-price", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Сумма закупки",
                          value: _vm.purchase.purchase_terms.start_price,
                        },
                      })
                    : _vm._e(),
                  _c("text-row-single-document", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Проект договора",
                      value:
                        _vm.purchase.purchase_terms.contract_draft_attachment,
                    },
                  }),
                  _vm.purchase.additional_documents.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          compact: true,
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительные документы",
                        },
                        model: {
                          value: _vm.purchase.additional_documents,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "additional_documents", $$v)
                          },
                          expression: "purchase.additional_documents",
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Требования к поставщикам")]),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Отсутствие в реестре недобросовестных поставщиков",
                      value: _vm.purchase.supplier_requirements.only_not_rnp
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label:
                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                      value: _vm.purchase.supplier_requirements.only_smp
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      compact: true,
                      "label-cols-lg": 6,
                      "label-cols-sm": 6,
                      label: "Дополнительные требования к участникам закупки",
                      value: _vm.purchase.supplier_requirements
                        .has_additional_requirements
                        ? "ДА"
                        : "НЕТ",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.purchase.supplier_requirements
                              .has_additional_requirements,
                          expression:
                            "purchase.supplier_requirements.has_additional_requirements",
                        },
                      ],
                    },
                    [
                      _c("section", { staticStyle: { width: "100%" } }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "atmo-content-table mb-0" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "atmo-ctc-width-45pct" },
                                    [_vm._v("Описание")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "atmo-ctc-width-45pct" },
                                    [
                                      _vm._v(
                                        "Причина или цель установки требования"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.purchase.supplier_requirements.additional,
                                  function (requirement, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "td",
                                        { staticClass: "atmo-ctc-width-45pct" },
                                        [
                                          _vm._v(
                                            _vm._s(requirement.description)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "atmo-ctc-width-45pct" },
                                        [_vm._v(_vm._s(requirement.reason))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.purchase.delivery_terms.addresses.length > 0 ||
                  _vm.purchase.delivery_terms.schedule
                    ? [
                        _c("text-header", [_vm._v("Условия поставки")]),
                        _vm.purchase.delivery_terms.addresses.length > 0
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-sm": 4,
                                  "label-cols-lg": 4,
                                  label: "Адреса для доставки/самовывоза:",
                                  "label-class":
                                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.purchase.delivery_terms.addresses,
                                    function (address, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "fs-14 grey-color my-auto col-form-label",
                                        },
                                        [_vm._v(_vm._s(address))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("text-row", {
                          attrs: {
                            compact: true,
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label:
                              "График поставки товаров (выполнения работ, оказания услуг)",
                            value: _vm.purchase.delivery_terms.schedule,
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("form-row-specification-table-view", {
                    attrs: {
                      "with-price":
                        _vm.purchase.purchase_terms.duration_type.id !==
                        "quotation_request",
                    },
                    model: {
                      value: _vm.purchase.deliverables,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "deliverables", $$v)
                      },
                      expression: "purchase.deliverables",
                    },
                  }),
                  _c("private-fl223-proposals-block", {
                    attrs: { purchase: _vm.purchase },
                  }),
                  _c("public-fl223-protocol", {
                    attrs: { protocol: _vm.purchase.protocol },
                  }),
                  _c("private-fl223-winner-selection-block", {
                    attrs: { purchase: _vm.purchase },
                    on: { confirm: _vm.refresh, refresh: _vm.refresh },
                  }),
                  _vm.purchase.status.id === "contract:active" &&
                  _vm.purchase.contract
                    ? [
                        _c("text-header", [_vm._v("Информация о поставщике")]),
                        _c("text-row-link", {
                          attrs: {
                            compact: "",
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label: "Наименование",
                            "router-link": {
                              name: "OrganizationShow",
                              params: { id: _vm.purchase.supplier.id },
                            },
                          },
                          model: {
                            value: _vm.purchase.supplier.full_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "full_name", $$v)
                            },
                            expression: "purchase.supplier.full_name",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            compact: "",
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label: "ИНН",
                          },
                          model: {
                            value: _vm.purchase.supplier.inn,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "inn", $$v)
                            },
                            expression: "purchase.supplier.inn",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            compact: "",
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label: "КПП",
                          },
                          model: {
                            value: _vm.purchase.supplier.kpp,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "kpp", $$v)
                            },
                            expression: "purchase.supplier.kpp",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            compact: "",
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label: "ОГРН",
                          },
                          model: {
                            value: _vm.purchase.supplier.ogrn,
                            callback: function ($$v) {
                              _vm.$set(_vm.purchase.supplier, "ogrn", $$v)
                            },
                            expression: "purchase.supplier.ogrn",
                          },
                        }),
                        _c("text-row", {
                          attrs: {
                            compact: "",
                            "label-cols-lg": 4,
                            "label-cols-sm": 4,
                            label: "Юридический адрес",
                          },
                          model: {
                            value: _vm.purchase.supplier.legal_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.supplier,
                                "legal_address",
                                $$v
                              )
                            },
                            expression: "purchase.supplier.legal_address",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm.$store.getters.isLoggedIn &&
                  _vm.purchase.is_customer === true &&
                  _vm.purchase.actions.customer.contract !== "disallow"
                    ? _c("private-fl223-contract-customer-block", {
                        attrs: { purchase: _vm.purchase },
                        on: { refresh: _vm.refresh },
                      })
                    : _vm.$store.getters.isLoggedIn &&
                      _vm.purchase.is_customer === false &&
                      _vm.purchase.actions.supplier.contract !== "disallow"
                    ? _c("private-fl223-contract-supplier-block", {
                        attrs: { purchase: _vm.purchase },
                        on: { refresh: _vm.refresh },
                      })
                    : _vm.purchase.status.id === "contract:active" &&
                      _vm.purchase.contract
                    ? _c("public-fl223-contract-block", {
                        attrs: { purchase: _vm.purchase },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticClass: "my-4",
              attrs: { fluid: "", id: "action_buttons_row" },
            },
            [
              _c(
                "b-container",
                { staticClass: "mb-5" },
                [
                  _vm.$store.getters.isLoggedIn
                    ? _c("fl223-new-proposal-button", {
                        attrs: { noticeitem: _vm.purchase },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }