<template>
    <b-form @submit.prevent="onSubmit">
        <div v-if="!loading">
            <p class="fs-32 grey-color main_text" style="line-height: 1; margin-bottom: 30px">Настройка уведомлений</p>
            <b-row>
                <b-col>
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 80%; min-width: 250px">Событие</th>
                                <th width="100px;">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in notificationSettings" v-bind:key="item.id">
                                <td>{{ item.name }}</td>
                                <td>
                                    <b-form-checkbox v-model="item.email" :disabled="!item.allowed_email"></b-form-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <router-link :to="{ name: 'NotificationsList' }" tag="button" class="btn btn-custom-outline-secondary mr-2 text-uppercase">Отмена </router-link>
                    <b-button type="submit" class="text-uppercase" variant="custom-green">Сохранить</b-button>
                </b-col>
            </b-row>
        </div>
    </b-form>
</template>

<script>
import NotificationSettings from '@/services/api/NotificationSettings';

export default {
    name: 'NotificationSettings',
    data() {
        return {
            notificationSettings: [],
            loading: true
        };
    },
    mounted() {
        this.fillData().then(() => {
            this.loading = false;
        });
    },
    methods: {
        fillData: async function () {
            let notificationSettings = await NotificationSettings.get();
            this.notificationSettings = notificationSettings.data;
        },
        onSubmit() {
            this.loading = true;
            NotificationSettings.put(this.notificationSettings).then(() => {
                this.fillData().then(() => {
                    this.loading = false;
                });
            });
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
