<template>
    <div>
        <slot name="title">
            <text-header>Лимиты по КБК</text-header>
        </slot>
        <b-row class="mb-2">
            <b-col>
                <span>Добавьте или измените лимиты. Необходимо полностью распределить стоимость спецификации по соответствующим лимитам.</span>
                <b-icon-question-circle class="atmo-quick-helper ml-1" title='Можно добавить новый лимит года финансирования по КБК (кнопка "Добавить лимит") или сменить лимит для уже добавленного в список КБК на лимит другого года (кнопка с кодом КБК).' />
            </b-col>
        </b-row>
        <slot name="info"></slot>
        <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" :class="{ 'is-table-invalid': $v.$invalid }" details-td-class="py-1" show-empty small responsive>
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #row-details="data">
                <limit-info :limit="data.item" />
            </template>
            <template #cell(kbk)="data">
                <div class="d-inline-block align-text-center">
                <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' подробную информацию'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                <b-button title="Нажмите, чтобы выбрать лимит другого года финансирования внутри этого КБК." variant="outline" @click="editItem(data.item, data.index)">{{ data.item.kbk }}</b-button>
                </div>
            </template>
            <template #cell(subkosgu_code)="data">
                {{ data.item.subkosgu_code ?? '&mdash;' }}
            </template>
            <template #cell(amount_available)="data">
                {{ $formatPrice(data.item.amount_available) }}
            </template>
            <template #cell(amount_total)="data">
                {{ $formatPrice(data.item.amount_total) }}
            </template>
            <template #head(amount_assigned)="data">
                {{ data.label }} <b-icon-question-circle class="atmo-quick-helper" title="Часть Стоимости спецификации, распределенная на данный Доступный лимит." />
            </template>
            <template #cell(amount_assigned)="data">
                <currency-input v-model="data.item.amount_assigned" :class="$v.items.$each[data.index].$invalid ? 'is-invalid' : 'is-valid'" class="form-control fs-14 text-right currency-input mb-0" currency="RUB" locale="ru" @focus="$event.target?.select()" />
            </template>
            <template #cell(actions)="data">
                <action-button-small icon="trash" @click="deleteItem(data.index)" title="Удалить"/>
            </template>
            <template #empty>
                <div class="w-100 text-center">Лимиты не добавлены</div>
            </template>
            <template #bottom-row>
                <b-td colspan="2" class="align-text-top py-1 pl-0">
                    <b-button variant="custom-green" class="text-uppercase ml-1" @click="showSelectModal = true">Добавить лимит</b-button>
                </b-td>
                <b-td colspan="4" class="text-right py-1">
                    <ul v-if="items && items.length > 0">
                        <li class="fw-600">Распределено итого, руб.:</li>
                        <li class="fw-600">Стоимость спецификации итого, руб.:</li>
                        <li v-if="limitsDeliverablesVariance !== 0" class="fw-600">Отклонение от стоимости спецификации, руб.:</li>
                    </ul>
                </b-td>
                <b-td class="text-right py-1">
                    <ul v-if="items && items.length > 0">
                        <li class="fw-600">{{ $formatPrice(totalAmount) }}</li>
                        <li class="fw-600">{{ $formatPrice(totalCost) }}</li>
                        <li v-if="limitsDeliverablesVariance !== 0" class="fw-600 text-danger">{{ $formatPrice(limitsDeliverablesVariance) }}</li>
                    </ul>
                </b-td>
            </template>
        </b-table>
        <b-form-invalid-feedback :state="!$v.items.$invalid" class="mt-3">{{ errorMessage }}</b-form-invalid-feedback>
        <limit-select-modal :show="showSelectModal" :order-type="orderType" :year="limitYear" :is-edit="isEdit" :limit-id="limitId" :purchase-type="purchaseType" :purchase-id="purchaseId" @hide="hideSelectModal" @submit="submitLimit" />
    </div>
</template>

<script>

import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";
import LimitInfo from "@/components/externalPurchases/partials/limit-info.vue";
import { CurrencyInput } from "vue-currency-input";
import {minLength, minValue, required, requiredIf} from "vuelidate/lib/validators";
import LimitSelectModal from "@/components/externalPurchases/partials/limit-select-modal.vue";

export default {
    name: 'limits-editable-table',
    components: {LimitSelectModal, LimitInfo, CurrencyInput },
    props: {
        value: {
            type: Array,
            required: true
        },
        totalCost: {
            type: Number,
            required: true
        },
        limitYear: {
            type: Number,
            required: true
        },
        purchaseType: {
            type: String,
            default: 'fl44'
        },
        purchaseId: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Number | null
        },
        orderType: {
            type: Object,
            required: true
        },
        isSupplementaryAgreement: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showSelectModal: false,
            isEdit: false,
            limitId: 0,
            limitIndex: 0,
            fields: [
                { key: 'index', label: '№', tdClass: 'align-text-top py-1', thClass: 'py-1' },
                { key: 'kbk', label: 'КБК', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: 'min-width: 260px' },
                { key: 'subkosgu_code', label: 'СубКОСГУ', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'year', label: 'Год финансирования', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_available', label: 'Доступный лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_total', label: 'Общий лимит, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right' },
                { key: 'amount_assigned', label: 'Распределено, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } },
                { key: 'actions', label: '', tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '1px' } },
            ]
        };
    },
    validations() {
        return {
            items: {
                required: requiredIf(() => !this.isSupplementaryAgreement || this.totalAmount !== 0),
                minLength: minLength(1),
                limitsDeliverablesVariance() {
                    return this.limitsDeliverablesVariance === 0;
                },
                $each: {
                    amount_assigned: {
                        required,
                        minValue: minValue(0.01)
                    },
                    maxAmountAssigned(item) {
                        return item.amount_assigned <= item.amount_available;
                    }
                }
            }
        }
    },
    mounted() {
        this.$emit("validate", this.$v.$invalid);
    },
    methods: {
        deleteItem(index) {
            this.items.splice(index, 1);
        },
        editItem(item, index) {
            this.limitId = item.id;
            this.isEdit = true;
            this.limitIndex = index;
            this.showSelectModal = true;
        },
        hideSelectModal() {
            this.isEdit = false;
            this.showSelectModal = false;
        },
        submitLimit(item) {
            if (this.isEdit) {
                if (!this.items.some((element) => element.id === item.id && element.year === item.year)) {
                    Object.assign(this.items[this.limitIndex], item);
                }
            } else if (!this.items.some((element) => element.id === item.id && element.year === item.year)) {
                this.items.push(Object.assign(item, { amount_assigned: null }));
            }
            this.showSelectModal = false;
        }
    },
    watch: {
        items: {
            handler(value) {
                this.$emit("input", value);
            },
            deep: true
        },
        $v: {
            handler(value) {
                this.$emit("validate", value.$invalid);
            },
            deep: true
        }
    },
    computed: {
        items: {
            get() {
                return this.value;
            }
        },
        limitsDeliverablesVariance() {
            return roundToTwoDecimals(this.totalCost - this.totalAmount);
        },
        totalAmount() {
            return roundToTwoDecimals(this.items.reduce((sum, item) => {
                return sum += item.amount_assigned;
            }, 0));
        },
        errorMessage() {
            const items = Object.values(this.$v.items.$each.$iter);
            if (items.some((e) => e.amount_assigned.$invalid))
                return this.isSupplementaryAgreement
                    ? 'Поле "Распределено" не заполнено для одного или нескольких лимитов. При расторжении договора удалите лимит/лимиты из таблицы.'
                    : 'Поле "Распределено" не заполнено для одного или нескольких лимитов.';
            if (items.some((e) => !e.maxAmountAssigned)) return 'Поле "Распределено" превышает доступный лимит для одного или нескольких лимитов.';
            if (!this.$v.items.required) return 'Добавьте лимиты.';
            if (!this.$v.items.limitsDeliverablesVariance) return 'Итоговая сумма распределенных средств из лимитов не совпадает со стоимостью спецификации.';
            return '';
        }
    }
};
</script>

<style scoped>
.btn-outline {
    color: var(--grey-color);
    background-color: var(--white-color);
    border-color: var(--grey-color);
    vertical-align: -0.15em;
    margin-left: 7px;
    padding: 1px 5px 1px 5px !important;
    font-size: 14px;
    height: 24px;
    white-space: nowrap;
    float: inline-end;
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.btn-outline:not(:disabled):not(.disabled):active,
.btn-outline:active {
    color: var(--grey-color);
    border-color: var(--grey-color);
    filter: brightness(85%);
}
.btn-outline:focus {
    color: var(--grey-color);
    border-color: var(--grey-color);
}
.btn-outline:hover {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(85%);
}
.is-table-invalid {
    border: 1px solid red;
    border-radius: 4px;
}
.form-control.is-invalid, .form-control.is-valid {
    background-image: none;
    padding-right: 0.3rem !important;
}
</style>
