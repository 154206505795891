import axios from 'axios';

export default {
    // отображение на главной (с группировкой по типам)
    getArticles() {
        return axios.post('/landing_articles').then((response) => {
            return response.data;
        });
    },
    getFrontArticleCard(id) {
        return axios.get('/articles/' + id).then((response) => {
            return response.data;
        });
    }
};
