import axios from 'axios';

export default {
    getOrderTypes() {
        return axios.get('/directories/order-types');
    },
    // DEPRECATED: see $api.dictionary.search
    findOkeis(query) {
        return axios.get('/directories/okeis_find', { params: { query: query } });
    },
    // DEPRECATED: see $api.dictionary.search
    findOkpds(query) {
        return axios.get('/directories/okpds_find', { params: { query: query } });
    },
    getKtruItem(id) {
        return axios.get('/directories/ktru_items/' + id);
    },
    // DEPRECATED: see $api.dictionary.getList
    getPurchaseCategories() {
        return axios.get('/directories/purchase_categories');
    }
};
