<template>
    <b-col md="6" class="my-2">
        <div class="purchase_part">
            <div class="organization_part-header">
                <b-row class="w-100 m-0">
                    <b-col lg="8" md="8" class="px-1">
                        <p class="organization_part-header_number">
                            <b-badge variant="inverse-green" v-if="organization.is_smp" class="fs-12 mr-2" v-b-tooltip.hover="{ variant: 'custom-green' }" title="Является субъектом малого предпринимательства">СМП</b-badge>
                            <b-badge variant="inverse-green" v-if="organization.is_isp" class="fs-12" v-b-tooltip.hover="{ variant: 'custom-green' }" title="Является прямым производителем товаров, исполнителем услуг или подрядчиком работ">ИСП</b-badge>
                        </p>
                    </b-col>
                    <b-col lg="4" md="4" class="px-1">
                        <p class="organization_part-header_label float-right text-right my-auto" v-if="organization.is_registered">
                            <img class="mr-1" src="/images/check.svg" alt="check_label" />
                            Зарегистрирован
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div class="organization_part-body row">
                <div class="px-3 mt-2 w-100">
                    <p class="green-color fw-600 fs-14 text-uppercase">
                        <router-link :to="{ name: 'OrganizationShow', params: { id: organization.id } }" class="system_link-style" target="_blank">{{ organization.name }}</router-link>
                    </p>
                </div>
                <div class="px-3 mb-1 w-100">
                    <p class="fs-14 fw-400 grey-color">
                        Адрес: <span class="fw-600 grey-color"> {{ organization.address }} </span>
                    </p>
                </div>

                <b-col class="organization_part-body_col">
                    <div class="my-2">
                        <div class="w-100 my-2" v-if="organization.contact_name">
                            <p class="m-0 fs-14 grey-color">Контактное лицо:</p>
                            <p class="m-0 fs-14 grey-color fw-600">{{ organization.contact_name }}</p>
                        </div>
                        <div class="w-100 my-2" v-if="organization.rating">
                            <p class="fs-14 fw-400 m-0 grey-color">
                                Рейтинг:
                                <span class="fw-600 grey-color"> {{ $formatPrice(organization.rating) }} </span>
                            </p>
                            <div class="rating_scale">
                                <div class="rating_scale-part_wrapper" :style="`border-color: ${ratingColor}`" v-for="(el, index) in organizationRatingWhole" :key="'index_' + index">
                                    <div class="rating_scale-part" :style="`background-color: ${ratingColor}`"></div>
                                </div>
                                <div class="rating_scale-part_wrapper" v-for="(el, index) in Math.ceil(organizationRatingRest)" :key="'indexx_' + index">
                                    <div class="rating_scale-part" :style="`background: linear-gradient(to right, ${ratingColor} 0%, ${ratingColor} ${transitionPercent}%, #fff ${transitionPercent}%, #fff 100%)`"></div>
                                </div>
                                <div class="rating_scale-part_wrapper rest_part" v-for="(el, index) in rest" :key="'indexxx_' + index">
                                    <div class="rating_scale-part"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="organization_part-body_col">
                    <div class="my-2">
                        <div class="my-2">
                            <p class="fs-14 fw-400 m-0 text-uppercase grey-color">
                                ОГРН / ОГРНИП: <span class="fw-600 grey-color"> {{ organization.ogrn }} </span>
                            </p>
                        </div>
                        <div class="my-2" v-if="organization.inn">
                            <p class="fs-14 fw-400 m-0 text-uppercase grey-color">
                                ИНН: <span class="fw-600 grey-color"> {{ organization.inn }} </span>
                            </p>
                        </div>
                        <div v-if="organization.kpp">
                            <p class="fs-14 fw-400 m-0 text-uppercase grey-color">
                                КПП: <span class="fw-600 grey-color"> {{ organization.kpp }} </span>
                            </p>
                        </div>
                    </div>
                </b-col>
            </div>
        </div>
    </b-col>
</template>

<script>
export default {
    name: 'organization-list-card',
    props: ['organization'],
    methods: {},
    computed: {
        ratingColor() {
            if (this.organization.rating <= 2) {
                return '#E75D4F';
            } else if (this.organization.rating > 2 && this.organization.rating < 4) {
                return '#F6B33A';
            } else {
                return '#26B89A';
            }
        },
        organizationRatingWhole() {
            return Math.floor(this.organization.rating);
        },
        organizationRatingRest() {
            return this.organization.rating - this.organizationRatingWhole;
        },
        rest() {
            return 5 - Math.ceil(this.organization.rating);
        },
        transitionPercent() {
            return this.organizationRatingRest * 100;
        }
    }
};
</script>

<style scoped>
.lable {
    font-size: 12px;
    font-weight: 600;
    background: #fff;
    border-radius: 4px;
    color: var(--green-color);
    padding: 1px 11px;
    margin-left: 11px;
    line-height: 1;
    display: flex;
    align-items: center;
}
.rating_scale {
    border: 1px solid rgba(149, 155, 164, 0.25);
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
}
.rating_scale-part {
    width: 9px;
    height: 14px;
    border-radius: 3px;
}
.rating_scale-part_wrapper {
    border-radius: 4px;
    border: 1px solid rgba(149, 155, 164, 0.25);
    margin: 1px;
}
.max-content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.visible-768 {
    display: none;
}
.purchase_part {
    box-shadow: 0px 2px 4px #0000000a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #fff;
}
.organization_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--orange-color);
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 40px;
}
.organization_part-body {
    padding: 5px 15px;
}
.organization_part-header_number {
    font-size: 12px;
    color: #fff;
    margin: 0;
}
.organization_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.organization_part-body_col + .organization_part-body_col::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
.organization_part-body span {
    text-transform: none;
}
@media screen and (max-width: 767px) {
    .organization_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .organization_part-body_col::before {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .visible-768 {
        display: block;
    }
}
@media screen and (max-width: 568px) {
    .organization_part-header {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .organization_part-header_number {
        margin: 15px 0;
    }
}
</style>
