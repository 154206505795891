<template>
    <div v-if="visible">
        <b-btn class="btn-card-action btn-card-action-green my-1" @click="copyPurchase">Копировать</b-btn>
    </div>
</template>

<script>
export default {
    name: 'CopyPurchaseButton',
    props: ['purchase'],
    methods: {
        copyPurchase() {
            this.$emit('click');
        }
    },
    computed: {
        visible() {
            return this.purchase.conditions.customer_can_copy_purchase && this.isCustomer() && this.isPurchaseCreator();
        }
    }
};
</script>
