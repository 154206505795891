<template>
    <div class="create">
        <form-header :title="title" title-class="fs-36" without-current-organization />
        <form-content-group title="Информация о запросе">
            <text-row :value="importSubstitutionRequest.purchase_category_name" label="Предмет запроса" />
            <text-row-datetime :value="importSubstitutionRequest.deadline" label="Срок сбора предложений" />
        </form-content-group>
        <import-substitution-request-show-ktru-item-block :ktruItem="importSubstitutionRequest.ktru_item" :price="importSubstitutionRequest.price" />
        <form-content-group title="Техническое описание">
            <text-row-documents :value="documents" label="Документ" />
        </form-content-group>

        <import-substitution-request-create-ktru-item-block is-import-substitution-reply v-model="form.ktru_item" :v="$v.form.ktru_item" title="Предложенное ТРУ для импортозамещения" />
        <b-container>
            <form-action-buttons-group>
                <b-button class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="onCancel">Отменить</b-button>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-green" @click="sendData">Разместить предложение</b-button>
                </b-overlay>
            </form-action-buttons-group>
        </b-container>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minValue, required } from 'vuelidate/lib/validators';
import FormHeader from '@/components/common/form-rows/form-header';
import FormContentGroup from '@/components/common/form-rows/form-content-group';
import FormActionButtonsGroup from '@/components/common/form-rows/form-action-buttons-group';
import ImportSubstitutionRequestCreateKtruItemBlock from '@/components/import-substitution/import-substitution-request-create-ktru-item-block';
import ImportSubstitutionRequestShowKtruItemBlock from '@/components/import-substitution/import-substitution-request-show-ktru-item-block';

export default {
    name: 'ImportSubstitutionRequestCreate',
    mixins: [validationMixin],
    components: {
        ImportSubstitutionRequestShowKtruItemBlock,
        ImportSubstitutionRequestCreateKtruItemBlock,
        FormActionButtonsGroup,
        FormContentGroup,
        FormHeader
    },
    data() {
        return {
            loading: true,
            importSubstitutionRequest: {
                id: null,
                reg_number: '',
                purchase_category_name: '',
                ktru_item: {
                    id: null,
                    name: '',
                    description: '',
                    specs: []
                },
                organization: {
                    id: null,
                    name: ''
                },
                created_at: null,
                deadline: null,
                status_id: null,
                stored_document: null,
                price: 0,
                replies: []
            },
            isDataSending: false,
            form: {
                ktru_item: null
            }
        };
    },
    validations: {
        form: {
            ktru_item: {
                required,
                price: {
                    required,
                    minValue: minValue(0.01)
                }
            }
        }
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            this.importSubstitutionRequest = await this.$api.importSubstitutionRequests.get(this.$route.params.id);
        },
        onCancel() {
            this.$router.push({ name: 'ImportSubstitutionRequestShow', params: { id: this.$route.params.id } }).catch(() => {});
        },
        async sendData() {
            this.isDataSending = true;
            if (
                await this.$api.importSubstitutionRequests.storeReply(this.importSubstitutionRequest.id, {
                    ktru_item_id: this.form.ktru_item.id,
                    price: this.form.ktru_item.price
                })
            ) {
                this.onCancel();
            }
            this.isDataSending = false;
        }
    },
    computed: {
        title() {
            return this.importSubstitutionRequest.reg_number ? 'Создание предложения к запросу импортозамещения №' + this.importSubstitutionRequest.reg_number : '';
        },
        documents() {
            return [{ ...this.importSubstitutionRequest.stored_document }];
        }
    }
};
</script>
