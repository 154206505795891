var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "fs-32 grey-color my-4" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-row-reverse",
            },
            [
              _c(
                "file-uploader",
                {
                  attrs: { "is-main": true, "is-disabled": !!_vm.document },
                  on: { set_file: _vm.addFile },
                },
                [_vm._v("Загрузить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-2", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "grey-color" }, [
            _c("div", { class: _vm.v.$invalid ? "is-invalid" : "" }, [
              _vm.document
                ? _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Наименование")]),
                        _c("th", { attrs: { width: "1" } }),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "fs-14",
                                staticStyle: {
                                  "padding-top": "0",
                                  "padding-bottom": "0",
                                  color: "var(--green-color) !important",
                                },
                                attrs: { variant: "link", size: "xs" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$commonDocumentDownload(
                                      _vm.document
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.document.name))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { background: "transparent" },
                                attrs: { variant: "light", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(0, true)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/trash_icon.svg",
                                    alt: "trash_icon",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Наименование")]),
                        _c("th", { attrs: { width: "1" } }),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2", align: "center" } }, [
                          _vm._v("Не добавлено документов"),
                        ]),
                      ]),
                    ]),
                  ]),
            ]),
            _vm.v.$invalid
              ? _c("p", { staticClass: "invalid-feedback" }, [
                  _vm._v("Прикрепите документ"),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }