var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header-new", [_vm._v("Настройка уведомлений")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h6", { staticClass: "fw-600 pl-1" }, [
                _vm._v("Получать уведомления:"),
              ]),
              _c("form-row-edit-checkbox", {
                attrs: { compact: "", label: "на сайте" },
                model: {
                  value: _vm.form.internal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "internal", $$v)
                  },
                  expression: "form.internal",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: { compact: "", label: "на E-mail" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _c(
                "form-row-edit-checkbox",
                {
                  attrs: {
                    compact: "",
                    label: "в Telegram",
                    disabled:
                      !_vm.$store.state.user.is_chatme_notifiable &&
                      _vm.features.telegram_bot,
                  },
                  model: {
                    value: _vm.form.telegram,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "telegram", $$v)
                    },
                    expression: "form.telegram",
                  },
                },
                [
                  !_vm.$store.state.user.is_chatme_notifiable &&
                  _vm.features.telegram_bot
                    ? _c(
                        "b-btn",
                        {
                          staticClass: "text-uppercase my-1 mx-2 fixed-height",
                          attrs: {
                            href: _vm.$links.telegram_events_bot,
                            target: "_blank",
                            variant: "custom-green",
                          },
                        },
                        [_vm._v("Подключить уведомления о событиях в ИС ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isSavingSettings,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-green" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }