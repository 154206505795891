import axios from 'axios';

export default {
    get() {
        return axios.get('/notification_settings');
    },
    put(payload) {
        return axios.put('/notification_settings', payload);
    }
};
