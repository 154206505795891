var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Снятие извещения с публикации",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        visible: _vm.show,
      },
      on: { close: _vm.cancelModal, cancel: _vm.cancelModal },
    },
    [
      _c(
        "b-row",
        { staticClass: "py-2", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-18 text-center" }, [
            _vm._v("Вы действительно хотите снять извещение"),
            _c("br"),
            _vm._v("№ " + _vm._s(_vm.purchase.reg_number) + " с публикации? "),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c("b-col", [
            _c("ul", [
              _c("li", { staticClass: "fs-14 grey-color" }, [
                _vm._v("Снятие извещения с публикации возможно только 1 раз."),
              ]),
              _c("li", { staticClass: "fs-14 grey-color" }, [
                _vm._v(
                  "Снятие извещения с публикации приведет к отмене всех поданных на данное извещение предложений от поставщиков."
                ),
              ]),
              _c("li", { staticClass: "fs-14 grey-color" }, [
                _vm._v(
                  'Снятие извещения с публикации позволит Вам внести изменения в извещение, за исключением поля "Наименование закупочной сессии".'
                ),
              ]),
              _c("li", { staticClass: "fs-14 grey-color" }, [
                _vm._v(
                  " В момент снятия извещения с публикации, будет зафиксировано оставшееся рабочее время до окончания подачи предложений. В момент возобновления публикации, время окончания подачи предложений будет рассчитано на основании зафиксированного в момент снятия извещения с публикации, оставшегося рабочего времени. "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { type: "reset", variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelModal },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { type: "submit", variant: "custom-green" },
                  on: { click: _vm.okModal },
                },
                [_vm._v("Отправить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }