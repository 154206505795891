var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _vm.purchase.status_id !== _vm.PURCHASE_STATUS.status_draft &&
      _vm.purchase.status_id !== _vm.PURCHASE_STATUS.status_draft_save_signed
        ? _c(
            "div",
            { staticClass: "purchase_part" },
            [
              _c("subheader-customer-cabinet", {
                attrs: { purchase: _vm.purchase },
              }),
              _c(
                "div",
                { staticClass: "purchase_part-subheader" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("НЦК: "),
                        _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                          _vm._v(
                            _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) +
                              " руб."
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v(" Предложений: "),
                        _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                          _vm._v(
                            _vm._s(
                              _vm.purchase.is_hidden
                                ? "Скрыто"
                                : _vm.purchase.offersCount
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v(" Лучшее: "),
                        _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                          _vm._v(
                            _vm._s(
                              _vm.purchase.is_hidden
                                ? "Скрыто"
                                : _vm.purchase.bestOffer
                                ? _vm.$formatPrice(_vm.purchase.bestOffer) +
                                  " руб."
                                : "-"
                            ) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v(" Снижение: "),
                        _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                          _vm._v(
                            _vm._s(
                              _vm.purchase.is_hidden
                                ? "Скрыто"
                                : _vm.purchase.discount + "%"
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _vm.purchase.status_id === 1 && !_vm.purchase.is_hold
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("До окончания подачи предложений:"),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-18 m-0 grey-color fw-600 time_to_end",
                                },
                                [
                                  !!_vm.purchase.purchase_end
                                    ? _c("timer-new", {
                                        attrs: {
                                          data: _vm.purchase.purchase_end,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        !!_vm.purchase.planned_end_date
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Планируемая дата исполнения:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateFormat(
                                        _vm.purchase.planned_end_date
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.isPurchaseCreator()
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c("purchase-comment-edit-block", {
                                  attrs: { purchase: _vm.purchase },
                                  on: { refresh: _vm.refresh },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(_vm._s(_vm.purchase.purchase_name)),
                          ]),
                        ]),
                        _vm.$store.getters.isLoggedIn &&
                        _vm.$store.getters.isProvider
                          ? _c(
                              "div",
                              { staticClass: "my-2 d-flex flex-column" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 grey-color m-0" },
                                  [_vm._v("Заказчик:")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "system_link-style fw-600 text-uppercase fs-12",
                                    attrs: {
                                      to:
                                        "/organizations/" + _vm.purchase.org_id,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.purchase.organization))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Количество позиций: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(_vm._s(_vm.purchase.items_count)),
                            ]),
                          ]),
                        ]),
                        _vm.purchase.delivery_addresses &&
                        _vm.purchase.delivery_addresses.length
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 m-0 grey-color" },
                                  [
                                    _vm._v(
                                      "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.purchase.delivery_addresses,
                                  function (address, index) {
                                    return _c(
                                      "p",
                                      {
                                        key: index,
                                        staticClass:
                                          "fs-12 m-0 grey-color fw-600",
                                      },
                                      [_vm._v("— " + _vm._s(address))]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", { staticClass: "h-100" }, [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    alt: "check_label",
                                    src: "/images/search.svg",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    exact: "",
                                    target: "_blank",
                                    to: {
                                      name: "purchases.grouped.item.view",
                                      params: {
                                        group: "fl44",
                                        id: _vm.purchase.id,
                                      },
                                    },
                                  },
                                },
                                [_vm._v("Информация о закупке ")]
                              ),
                            ],
                            1
                          ),
                          _vm.purchase.offersCount > 0
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "check_label",
                                          src: "/images/dynamic.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        exact: "",
                                        target: "_blank",
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                          hash: "#atmo-proposals",
                                        },
                                      },
                                    },
                                    [_vm._v(" Динамика предложений ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.purchase && _vm.purchase.organizationFinal
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "check_label",
                                          src: "/images/small_helmet.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        exact: "",
                                        target: "_blank",
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                          hash: "#atmo-supplier-info",
                                        },
                                      },
                                    },
                                    [_vm._v(" Итоговый протокол ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.purchase &&
                            _vm.purchase.status_id ===
                              _vm.PURCHASE_STATUS.status_protocol_formed) ||
                          _vm.purchase.contract
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "check_label",
                                          src: "/images/grey_contract.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        exact: "",
                                        target: "_blank",
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                          hash: "#atmo-contract",
                                        },
                                      },
                                    },
                                    [_vm._v(" Договор ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$store.getters.isLoggedIn &&
                          !_vm.$store.getters.isProvider &&
                          _vm.purchase.org_id ===
                            _vm.$store.state.user.organization_id &&
                          [3, 4, 5, 6].includes(_vm.purchase.status_id)
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "check_label",
                                          src: "/images/grey_file_small.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        exact: "",
                                        target: "_blank",
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                          hash: "#atmo-contract-fulfillment",
                                        },
                                      },
                                    },
                                    [_vm._v("Исполнение договора ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2" },
                          [
                            _vm.isPurchaseCreator()
                              ? [
                                  _vm.purchase.rating_allowed &&
                                  _vm.$store.getters.isLoggedIn &&
                                  !_vm.$store.getters.isProvider
                                    ? _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "btn btn-card-action btn-card-action my-1",
                                          on: {
                                            click: function ($event) {
                                              _vm.ratingModal_visible = true
                                            },
                                          },
                                        },
                                        [_vm._v("Оценить поставщика ")]
                                      )
                                    : _vm._e(),
                                  _vm.purchase.conditions
                                    .customer_can_prolong_purchase
                                    ? _c(
                                        "b-btn",
                                        {
                                          staticClass: "btn-card-action my-1",
                                          on: { click: _vm.prolongPurchase },
                                        },
                                        [
                                          _vm._v(
                                            "Продлить закупочную сессию до " +
                                              _vm._s(
                                                _vm.getDateTimeFormat(
                                                  _vm.purchase
                                                    .max_prolong_datetime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("cancel-purchase-button", {
                                    attrs: { purchase: _vm.purchase },
                                    on: { cancel: _vm.refresh },
                                  }),
                                  _vm.$store.getters.isLoggedIn &&
                                  _vm.isPurchaseCreator() &&
                                  _vm.purchase.allow_external_purchase
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-card-action btn-card-action-light my-1",
                                          attrs: {
                                            to: {
                                              name: "ExternalPurchaseCreate",
                                              params: {
                                                mode: "create",
                                                id: _vm.purchase.id,
                                              },
                                            },
                                            tag: "button",
                                          },
                                        },
                                        [_vm._v(" Закупить вне ИС ")]
                                      )
                                    : _vm._e(),
                                  _vm.purchase.conditions
                                    .customer_can_change_purchase_length
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-card-action btn-card-action-light my-1",
                                          staticStyle: {
                                            "font-size": "12px !important",
                                          },
                                          attrs: {
                                            to: {
                                              name: "PurchasesShow",
                                              params: { id: _vm.purchase.id },
                                            },
                                            tag: "button",
                                          },
                                        },
                                        [_vm._v("Сделать не срочной ")]
                                      )
                                    : _vm._e(),
                                  _c("cancel-purchase-publishing-button", {
                                    attrs: { purchase: _vm.purchase },
                                    on: { click: _vm.cancelPurchasePublishing },
                                  }),
                                  _c("add-explanation-request-answer-button", {
                                    attrs: { purchase: _vm.purchase },
                                  }),
                                  _c("cancel-hold-time-button", {
                                    attrs: { purchase: _vm.purchase },
                                    on: { click: _vm.cancelHoldTime },
                                  }),
                                  _c("copy-purchase-button", {
                                    attrs: { purchase: _vm.purchase },
                                    on: { click: _vm.copyPurchase },
                                  }),
                                ]
                              : _vm._e(),
                            _c("purchase-supporting-buttons", {
                              attrs: { purchase: _vm.purchase },
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "purchase_part" },
            [
              _c("subheader-customer-cabinet", {
                attrs: { purchase: _vm.purchase },
              }),
              _c(
                "div",
                { staticClass: "purchase_part-subheader" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("НЦК: "),
                        _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                          _vm._v(
                            _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) +
                              " руб."
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        !!_vm.purchase.planned_end_date
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Планируемая дата исполнения:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateFormat(
                                        _vm.purchase.planned_end_date
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.isPurchaseCreator()
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c("purchase-comment-edit-block", {
                                  attrs: { purchase: _vm.purchase },
                                  on: { refresh: _vm.refresh },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(_vm._s(_vm.purchase.purchase_name)),
                          ]),
                        ]),
                        _vm.$store.getters.isLoggedIn &&
                        _vm.$store.getters.isProvider
                          ? _c(
                              "div",
                              { staticClass: "my-2 d-flex flex-column" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 grey-color m-0" },
                                  [_vm._v("Заказчик:")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "system_link-style fw-600 text-uppercase fs-12",
                                    attrs: {
                                      to:
                                        "/organizations/" + _vm.purchase.org_id,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.purchase.organization))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.purchase.delivery_addresses &&
                        _vm.purchase.delivery_addresses.length
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 m-0 grey-color" },
                                  [
                                    _vm._v(
                                      "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.purchase.delivery_addresses,
                                  function (address, index) {
                                    return _c(
                                      "p",
                                      {
                                        key: index,
                                        staticClass:
                                          "fs-12 m-0 grey-color fw-600",
                                      },
                                      [_vm._v("— " + _vm._s(address))]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", { staticClass: "h-100" }, [
                        _c(
                          "div",
                          { staticClass: "my-2" },
                          [
                            (_vm.purchase.status_id ===
                              _vm.PURCHASE_STATUS.status_draft ||
                              _vm.purchase.status_id ===
                                _vm.PURCHASE_STATUS.status_draft_save_signed) &&
                            _vm.purchase.change_length === false
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-card-action btn-card-action-green my-1",
                                    attrs: {
                                      tag: "button",
                                      to: {
                                        name: "purchases.grouped.item.edit",
                                        params: {
                                          group: "fl44",
                                          id: _vm.purchase.id,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("Редактировать ")]
                                )
                              : _vm._e(),
                            _vm.purchase.status_id ===
                            _vm.PURCHASE_STATUS.status_draft_save_signed
                              ? _c(
                                  "b-btn",
                                  {
                                    staticClass:
                                      "btn-card-action btn-card-action-green my-1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.publish(
                                          _vm.purchase.id,
                                          _vm.purchase.reg_number
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Опубликовать ")]
                                )
                              : _vm._e(),
                            _vm.purchase.conditions.customer_can_delete_purchase
                              ? _c(
                                  "b-btn",
                                  {
                                    staticClass:
                                      "btn-card-action btn-card-action-light my-1",
                                    staticStyle: {
                                      "font-size": "12px !important",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(
                                          _vm.purchase.id,
                                          _vm.purchase.reg_number
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Удалить ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.ratingModal_visible
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "ratingModal",
                centered: "",
                "hide-footer": "",
                title: "Оцените поставщика",
              },
              model: {
                value: _vm.ratingModal_visible,
                callback: function ($$v) {
                  _vm.ratingModal_visible = $$v
                },
                expression: "ratingModal_visible",
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "feedback_modal" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form",
                        {
                          staticClass: "px-4 pb-4",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmitRating.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "py-2",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("b-col", { staticClass: "fs-14 grey-color" }, [
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.purchase.organizationFinal.full_name
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "py-2",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "b-col",
                                { staticClass: "fs-14 grey-color" },
                                [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "stop-number-mousewheel",
                                        rawName: "v-stop-number-mousewheel",
                                      },
                                    ],
                                    attrs: {
                                      max: "5",
                                      min: "1",
                                      required: "",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.ratingValue,
                                      callback: function ($$v) {
                                        _vm.ratingValue = $$v
                                      },
                                      expression: "ratingValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        disabled: _vm.ratingSending,
                                        type: "reset",
                                        variant: "custom-outline-secondary",
                                      },
                                      on: { click: _vm.cancelRatingModal },
                                    },
                                    [_vm._v("Отмена")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        disabled: _vm.ratingSending,
                                        type: "submit",
                                        variant: "custom-green",
                                      },
                                    },
                                    [_vm._v("Отправить")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }