var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        description: _vm.description,
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [
              _vm._v(" " + _vm._s(_vm.label) + ": "),
              !!_vm.hint
                ? _c("div", { staticClass: "atmo-help" }, [
                    _vm._v("  "),
                    _c("i", { staticClass: "atmo-icon-question-mark" }),
                    _c("span", { staticClass: "atmo-help__text" }, [
                      _vm._v(_vm._s(_vm.hint)),
                    ]),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-form-input", {
        directives: [
          {
            name: "stop-number-mousewheel",
            rawName: "v-stop-number-mousewheel",
          },
        ],
        staticClass: "fs-14",
        attrs: {
          id: _vm.id,
          state: _vm.state,
          disabled: _vm.disabled,
          type: "number",
          step: "1",
          min: _vm.minValue,
          max: _vm.maxValue,
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      !_vm.state
        ? [_c("b-form-invalid-feedback", [_vm._v(_vm._s(_vm.errorMessage))])]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }