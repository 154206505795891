var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        "hide-footer": !(
          _vm.limits.length &&
          _vm.meta.total > 0 &&
          _vm.meta.last_page > 1
        ),
        "body-class": "pt-0",
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-close-on-esc": "",
        size: "xl",
        title: "Выберите лимит КБК",
      },
      on: { show: _vm.onShow, hide: _vm.onHide },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [_c("button-close-modal")]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                { staticClass: "w-100 m-0" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show:
                              _vm.isLoading &&
                              _vm.limits.length &&
                              _vm.meta.total > 0 &&
                              _vm.meta.last_page > 1,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "my-0 py-0",
                            attrs: {
                              "per-page": _vm.meta.per_page,
                              "total-rows": _vm.meta.total,
                              align: "center",
                            },
                            on: { input: _vm.getLimits },
                            model: {
                              value: _vm.meta.current_page,
                              callback: function ($$v) {
                                _vm.$set(_vm.meta, "current_page", $$v)
                              },
                              expression: "meta.current_page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        { staticClass: "sticky-top filter-bar" },
        [
          _c("b-col", { attrs: { md: 12 } }, [
            _c("span", [
              _vm._v("Год плана-графика лимитов: " + _vm._s(_vm.year) + "; "),
            ]),
            _c("span", [
              _vm._v(
                "Тип закупки: " +
                  _vm._s(_vm.orderType.name ?? _vm.orderType.title)
              ),
            ]),
          ]),
          !_vm.isEdit
            ? [
                _c("filter-text", {
                  attrs: {
                    disabled: _vm.isLoading,
                    label: "КБК",
                    placeholder: "Введите КБК",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.getLimits(false)
                    },
                  },
                  model: {
                    value: _vm.filter.kbk,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "kbk", $$v)
                    },
                    expression: "filter.kbk",
                  },
                }),
                _c("filter-text", {
                  attrs: {
                    disabled: _vm.isLoading,
                    label: "Код типа средств",
                    placeholder: "Введите код типа средств",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.getLimits(false)
                    },
                  },
                  model: {
                    value: _vm.filter.funds_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "funds_type", $$v)
                    },
                    expression: "filter.funds_type",
                  },
                }),
                _c("filter-text", {
                  attrs: {
                    disabled: _vm.isLoading,
                    label: "Код CубКОСГУ",
                    placeholder: "Введите код CубКОСГУ",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.getLimits(false)
                    },
                  },
                  model: {
                    value: _vm.filter.subkosgu_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "subkosgu_code", $$v)
                    },
                    expression: "filter.subkosgu_code",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-row",
        [
          !_vm.limits.length
            ? _c(
                "b-col",
                { staticClass: "text-center mt-5" },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.isLoading,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c("empty-request", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "info",
                              fn: function () {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "fs-14 text-danger my-4" },
                                    [
                                      _vm._v(
                                        "Лимиты по КБК по вашему запросу не найдены."
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1594670231
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-col",
                [
                  _c("b-table", {
                    staticClass: "table-atmo",
                    attrs: {
                      id: "limits-table",
                      busy: _vm.isLoading,
                      fields: _vm.fields,
                      items: _vm.limits,
                      small: "",
                      responsive: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "row-details",
                        fn: function (data) {
                          return [
                            _c(
                              "b-row",
                              { staticClass: "w-100" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("limit-info", {
                                      attrs: { limit: data.item },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("b-table", {
                                      staticClass: "table-atmo",
                                      attrs: {
                                        fields: _vm.detailFields,
                                        items: _vm.filterOutZeroAmount(
                                          data.item.limits
                                        ),
                                        small: "",
                                        responsive: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(amount_available)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$formatPrice(
                                                        data.item
                                                          .amount_available
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(amount_total)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$formatPrice(
                                                        data.item.amount_total
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(actions)",
                                            fn: function (detailData) {
                                              return [
                                                _c("action-button-small", {
                                                  attrs: {
                                                    disabled:
                                                      detailData.item
                                                        .amount_available === 0,
                                                    icon: "save2",
                                                    title: "Выбрать лимит",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.submitLimit(
                                                        data.item,
                                                        detailData.item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(kbk)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              attrs: {
                                title: data.detailsShowing
                                  ? "Скрыть список"
                                  : "Показать список лимитов по годам финансирования",
                                icon: data.detailsShowing ? "eye-slash" : "eye",
                              },
                              on: { click: data.toggleDetails },
                            }),
                            _vm._v(" " + _vm._s(data.item.kbk) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(subkosgu_code)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.subkosgu_code ?? "—") + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(target_code)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.target_code ?? "—") + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(targeted_funds_code)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(data.item.targeted_funds_code ?? "—") +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(rks_code)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.rks_code ?? "—") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }