var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", [
        _c(
          "h3",
          { staticClass: "grey-color mb-3 main_text font-weight-bold fs-28" },
          [_vm._v("Редактирование пользователя " + _vm._s(_vm.form.name))]
        ),
        _c(
          "form",
          { attrs: { autocomplete: "off" } },
          [
            _c("text-row", { attrs: { value: _vm.form.name, label: "ФИО" } }),
            _c("text-row-phone", {
              attrs: { value: _vm.form.phone, label: "Телефон" },
            }),
            _c("form-row-single-select", {
              attrs: {
                options: _vm.postOptions,
                v: _vm.$v.form.post,
                autocomplete: "organization-title",
                label: "Должность",
                placeholder: "Выберите должность",
              },
              model: {
                value: _vm.form.post,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "post", $$v)
                },
                expression: "form.post",
              },
            }),
            _c("text-row", {
              attrs: { value: _vm.form.inn, "force-show": "", label: "ИНН" },
            }),
            _c("text-row", {
              attrs: {
                value: _vm.form.snils,
                "force-show": "",
                label: "СНИЛС",
              },
            }),
            _c("text-row", {
              attrs: {
                value: _vm.form.username,
                description:
                  "Запомните или запишите логин - он будет нужен в случае необходимости сброса пароля",
                label: "Логин",
              },
            }),
            _c("form-row-edit-text", {
              attrs: {
                v: _vm.form.password ? _vm.$v.form.password : null,
                autocomplete: "new-password",
                label: "Новый пароль",
                type: "password",
              },
              on: {
                value: function ($event) {
                  _vm.form.password = $event
                },
              },
              model: {
                value: _vm.form.password,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password",
              },
            }),
            _c("form-row-edit-text", {
              attrs: {
                v: _vm.form.password ? _vm.$v.form.password_confirmation : null,
                autocomplete: "new-password",
                label: "Подтверждение пароля",
                type: "password",
              },
              on: {
                value: function ($event) {
                  _vm.form.password_confirmation = $event
                },
              },
              model: {
                value: _vm.form.password_confirmation,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "password_confirmation", $$v)
                },
                expression: "form.password_confirmation",
              },
            }),
            _c("text-row", {
              attrs: { value: _vm.form.email, label: "Email" },
            }),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-right", attrs: { md: "12" } },
                  [
                    _c(
                      "b-overlay",
                      {
                        staticClass: "d-inline-block",
                        attrs: {
                          show: _vm.isDataSending,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-small": "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: { variant: "custom-outline-secondary" },
                            on: { click: _vm.onCancel },
                          },
                          [_vm._v(" Отмена")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-overlay",
                      {
                        staticClass: "d-inline-block",
                        attrs: {
                          show: _vm.isDataSending,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-small": "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: {
                              disabled: _vm.$v.form.$invalid,
                              variant: "custom-green",
                            },
                            on: { click: _vm.onSubmit },
                          },
                          [_vm._v(" Сохранить")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }