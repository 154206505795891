var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isShowDetails
        ? [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { md: "6" } }, [
                  _c(
                    "h3",
                    { staticClass: "grey-color main_text font-weight-bold" },
                    [_vm._v("Лимиты бюджетных обязательств")]
                  ),
                ]),
                _c(
                  "b-col",
                  { staticClass: "text-right", attrs: { md: "6" } },
                  [
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          show: _vm.isLoading,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover.top",
                                value:
                                  "Обновление доступно не чаще чем раз в 30 минут",
                                expression:
                                  "'Обновление доступно не чаще чем раз в 30 минут'",
                                modifiers: { hover: true, top: true },
                              },
                            ],
                            staticClass: "d-inline-block",
                            attrs: { tabindex: "0" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: !_vm.allowRefresh,
                                  variant: "custom-green",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.refreshLimits()
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "my-auto mr-2",
                                  attrs: {
                                    icon: "arrow-clockwise",
                                    "aria-hidden": "true",
                                  },
                                }),
                                _vm._v(" Обновить "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-overlay",
              {
                attrs: {
                  show: _vm.isLoading,
                  opacity: "0.6",
                  rounded: "",
                  "spinner-variant": "primary",
                },
              },
              [
                _c(
                  "b-row",
                  { staticClass: "cabinet_filter" },
                  [
                    _c(
                      "b-col",
                      { attrs: { "md-12": "" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c("FilterNewMultipleSelect", {
                              attrs: {
                                options: _vm.forFilters.pppPlanYears,
                                label: "Год ПГ",
                                "label-class": "grey-color",
                                "label-field": "name",
                                lg: "6",
                                md: "6",
                                placeholder: "Выберите год ПГ",
                              },
                              model: {
                                value: _vm.filter.ppp_plan_year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "ppp_plan_year", $$v)
                                },
                                expression: "filter.ppp_plan_year",
                              },
                            }),
                            _c("FilterNewText", {
                              attrs: {
                                label: "КБК",
                                md: "6",
                                placeholder: "Введите КБК",
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.getLimits(false)
                                },
                              },
                              model: {
                                value: _vm.filter.kbk,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "kbk", $$v)
                                },
                                expression: "filter.kbk",
                              },
                            }),
                            _c("FilterNewMultipleSelect", {
                              attrs: {
                                options:
                                  _vm.$globalDictionaries.orderTypesWithLimits,
                                label: "Тип закупки",
                                "label-class": "grey-color",
                                "label-field": "title",
                                lg: "6",
                                md: "6",
                                placeholder: "Выберите тип закупки",
                              },
                              model: {
                                value: _vm.filter.order_type_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "order_type_id", $$v)
                                },
                                expression: "filter.order_type_id",
                              },
                            }),
                            _c("FilterNewText", {
                              attrs: {
                                label: "Код типа средств",
                                md: "6",
                                placeholder: "Введите код типа средств",
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.getLimits(false)
                                },
                              },
                              model: {
                                value: _vm.filter.funds_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "funds_type", $$v)
                                },
                                expression: "filter.funds_type",
                              },
                            }),
                            _c("FilterNewText", {
                              attrs: {
                                label: "Код мероприятия",
                                md: "6",
                                placeholder: "Введите код мероприятия",
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.getLimits(false)
                                },
                              },
                              model: {
                                value: _vm.filter.action_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "action_code", $$v)
                                },
                                expression: "filter.action_code",
                              },
                            }),
                            _c("FilterNewText", {
                              attrs: {
                                label: "Код СубКОСГУ",
                                md: "6",
                                placeholder: "Введите код СубКОСГУ",
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.getLimits(false)
                                },
                              },
                              model: {
                                value: _vm.filter.subkosgu_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "subkosgu_code", $$v)
                                },
                                expression: "filter.subkosgu_code",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "my-2 text-right",
                                attrs: { lg: "12", md: "12" },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { variant: "custom-green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getLimits(false)
                                      },
                                    },
                                  },
                                  [_vm._v("Найти")]
                                ),
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      variant: "custom-outline-secondary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.cleanFilters.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Сброс")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.limits && _vm.meta.total > 0 && _vm.meta.last_page > 1
                  ? _c("b-pagination", {
                      staticClass: "my-1",
                      attrs: {
                        "per-page": _vm.meta.per_page,
                        "total-rows": _vm.meta.total,
                        align: "center",
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    })
                  : _vm._e(),
                _c("b-table", {
                  staticClass: "table-atmo",
                  attrs: {
                    busy: _vm.isLoading,
                    fields: _vm.fields,
                    items: _vm.limits,
                    "empty-text": "Нет данных",
                    "show-empty": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(total_amount)",
                        fn: function (row) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatPrice(row.item.total_amount)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(subkosgu_code)",
                        fn: function (row) {
                          return [
                            _vm._v(
                              " " + _vm._s(row.item.subkosgu_code ?? "—") + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(funds_type)",
                        fn: function (row) {
                          return [
                            _vm._v(
                              " " + _vm._s(row.item.funds_type ?? "—") + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(action_code)",
                        fn: function (row) {
                          return [
                            _vm._v(
                              " " + _vm._s(row.item.action_code ?? "—") + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c("action-button-small", {
                              attrs: { icon: "eye" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetails(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    466319841
                  ),
                }),
                _vm.limits && _vm.meta.total > 0 && _vm.meta.last_page > 1
                  ? _c("b-pagination", {
                      staticClass: "my-1",
                      attrs: {
                        "per-page": _vm.meta.per_page,
                        "total-rows": _vm.meta.total,
                        align: "center",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getLimits()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : [
            _c("text-header-new", [_vm._v("Информация о лимите")]),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.ppp_plan_year,
                "force-show": "",
                label: "Год ПГ",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.ppp_year_ident,
                "force-show": "",
                label: "Год размещения извещения об осуществлении закупки",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.order_type_name,
                "force-show": "",
                label: "Тип закупки",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.ppp_ident_no,
                "force-show": "",
                label: "ИКЗ",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.kbk,
                "force-show": "",
                label: "КБК",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.funds_type,
                "force-show": "",
                label: "Код типа средств",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.action_code,
                "force-show": "",
                label: "Код мероприятия",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.currentLimit.limit.subkosgu_code,
                "force-show": "",
                label: "СубКОСГУ",
              },
            }),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.$formatPrice(_vm.currentLimit.limit.total_amount),
                label: "Общая сумма, руб.",
              },
            }),
            _c(
              "b-form-group",
              {
                staticClass: "mb-0",
                attrs: {
                  "label-cols-lg": 6,
                  "label-cols-sm": 6,
                  label: "Сумма по годам (доступно / всего), руб.:",
                  "label-class":
                    "fs-14 grey-color fw-700 d-flex align-items-baseline",
                },
              },
              _vm._l(
                _vm.filterOutZeroAmount(
                  _vm.currentLimit.limit.available_by_years
                ),
                function (record) {
                  return _c(
                    "p",
                    {
                      key: _vm.currentLimit.limit.id + "_" + record.year,
                      staticClass: "fs-14 grey-color my-auto col-form-label",
                    },
                    [
                      _c("b", [_vm._v(_vm._s(record.year))]),
                      _vm._v(
                        ": " +
                          _vm._s(_vm.$formatPrice(record.amount_available)) +
                          " / " +
                          _vm._s(_vm.$formatPrice(record.amount_total)) +
                          " "
                      ),
                    ]
                  )
                }
              ),
              0
            ),
            _c("text-row", {
              attrs: {
                compact: true,
                "label-cols-lg": 6,
                "label-cols-sm": 6,
                value: _vm.$formatDateTime(_vm.currentLimit.limit.updated_at),
                label: "\tДата/время обновления",
              },
            }),
            _c("text-header-new", [_vm._v("Использование лимита")]),
            _vm.currentLimit.limit.used_purchases &&
            _vm.currentLimit.meta.total > 0 &&
            _vm.currentLimit.meta.last_page > 1
              ? _c("b-pagination", {
                  staticClass: "my-1",
                  attrs: {
                    "per-page": _vm.currentLimit.meta.per_page,
                    "total-rows": _vm.currentLimit.meta.total,
                    align: "center",
                  },
                  model: {
                    value: _vm.currentLimit.meta.current_page,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentLimit.meta, "current_page", $$v)
                    },
                    expression: "currentLimit.meta.current_page",
                  },
                })
              : _vm._e(),
            _c("b-table", {
              staticClass: "table-atmo",
              attrs: {
                "current-page": _vm.currentLimit.meta.current_page,
                fields: _vm.currentLimit.fields,
                items: _vm.currentLimit.limit.used_purchases,
                "per-page": _vm.currentLimit.meta.per_page,
                "empty-text": "Нет данных",
                "show-empty": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(purchase_reg_number)",
                  fn: function (row) {
                    return [
                      row.item.purchasable_type === "purchase"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "atmo-button-link",
                              attrs: {
                                to: {
                                  name: "purchases.grouped.item.view",
                                  params: {
                                    group: "fl44",
                                    id: row.item.purchasable_id,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(row.item.purchase_reg_number))]
                          )
                        : _c(
                            "router-link",
                            {
                              staticClass: "atmo-button-link",
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseShow",
                                  params: { id: row.item.purchasable_id },
                                },
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(row.item.purchase_reg_number))]
                          ),
                    ]
                  },
                },
                {
                  key: "cell(amount)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$formatPrice(row.item.amount)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(created_at)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$formatDateTime(row.item.created_at)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.currentLimit.limit.used_purchases &&
            _vm.currentLimit.meta.total > 0 &&
            _vm.currentLimit.meta.last_page > 1
              ? _c("b-pagination", {
                  staticClass: "my-1",
                  attrs: {
                    "per-page": _vm.currentLimit.meta.per_page,
                    "total-rows": _vm.currentLimit.meta.total,
                    align: "center",
                  },
                  model: {
                    value: _vm.currentLimit.meta.current_page,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentLimit.meta, "current_page", $$v)
                    },
                    expression: "currentLimit.meta.current_page",
                  },
                })
              : _vm._e(),
            _c(
              "b-button",
              {
                staticClass: "mt-2",
                attrs: { variant: "custom-green" },
                on: {
                  click: function ($event) {
                    _vm.isShowDetails = false
                  },
                },
              },
              [_vm._v("Назад")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }