'use strict';

export interface IRawFinancials {
    price: number;
    available_balance: number;
    available: boolean;
}

export default class OrganizationReliabilityReportFinancialsVO {
    public report_price: number | null;
    public deposit_balance: number | null;
    public has_enough_balance: boolean;

    constructor(data?: IRawFinancials) {
        this.report_price = data?.price ?? null;
        this.deposit_balance = data?.available_balance ?? null;
        this.has_enough_balance = data?.available ?? false;

        Object.seal(this);
    }

    public get is_empty(): boolean {
        return this.report_price === null && this.deposit_balance === null && this.has_enough_balance === false;
    }
}
