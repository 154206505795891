var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-2" }, [
    _c("div", { staticClass: "purchase_part" }, [
      _c(
        "div",
        { staticClass: "purchase_part-header", class: _vm.proposalStatusColor },
        [
          _c(
            "b-row",
            { staticClass: "w-100 m-0" },
            [
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "8", md: "8" } },
                [
                  _c(
                    "p",
                    { staticClass: "purchase_part-header_number" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "white-system_link-style",
                          attrs: {
                            target: "_blank",
                            to: {
                              name: "Fl223ProposalShow",
                              params: { id: _vm.proposal.id },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "Предложение на закупку 223ФЗ № " +
                              _vm._s(_vm.proposal.purchase.reg_number) +
                              " (" +
                              _vm._s(_vm.proposal.purchase.status.title) +
                              ")"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "4", md: "4" } },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "purchase_part-header_label fw-600 float-right text-right my-auto",
                    },
                    [_vm._v(" " + _vm._s(_vm.proposal.status.title) + " ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "purchase_part-body row" },
        [
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "6" } },
            [
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _vm.proposal.purchase.status.id === "accepting" &&
                  !!_vm.proposal.purchase.purchase_end_datetime
                    ? _c(
                        "cabinet-notice-card-row-single",
                        { attrs: { label: "До окончания подачи предложений" } },
                        [
                          _c("timer-new", {
                            attrs: {
                              data: _vm.proposal.purchase.purchase_end_datetime,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "cabinet-notice-card-row-single",
                        {
                          attrs: {
                            label: "Дата и время окончания приема предложений",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getDateTimeFormat(
                                  _vm.proposal.purchase.purchase_end_datetime
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                  _c(
                    "cabinet-notice-card-row-single",
                    { attrs: { label: "Заказчик" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "system_link-style fw-600 text-uppercase fs-12",
                          attrs: {
                            to: {
                              name: "OrganizationShow",
                              params: { id: _vm.proposal.customer.id },
                            },
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.proposal.customer.name) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm.proposal.purchase.purchase_object
                    ? _c(
                        "cabinet-notice-card-row-single",
                        { attrs: { label: "Объект закупки" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.proposal.purchase.purchase_object) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "cabinet-notice-card-row-single",
                    { attrs: { label: "Предмет закупки" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.proposal.purchase.deliverable_group_title
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "cabinet-notice-card-row-single",
                    { attrs: { label: "Сумма закупки" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatPrice(
                              _vm.proposal.purchase.starting_price
                            )
                          ) +
                          " руб. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "6" } },
            [
              _c(
                "div",
                { staticClass: "h-100 my-2" },
                [
                  _vm.proposal.status.id === "rejected" &&
                  _vm.proposal.rejection_reason
                    ? _c(
                        "cabinet-notice-card-row-single",
                        { attrs: { label: "Причина отклонения" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.proposal.rejection_reason) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "cabinet-notice-card-row-single",
                    { attrs: { label: "Дата и время подачи предложения" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getDateTimeFormat(_vm.proposal.created_at)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "cabinet-notice-card-row-single",
                    { attrs: { label: "Стоимость предложения" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatPrice(_vm.proposal.price_with_vat)
                          ) +
                          " руб. "
                      ),
                    ]
                  ),
                  _vm.$store.getters.isLoggedIn &&
                  _vm.proposal.actions.supplier.edit
                    ? [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-card-action btn-card-action-green my-1",
                            attrs: {
                              to: {
                                name: "Fl223PurchaseNewProposal",
                                params: { id: _vm.proposal.purchase.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(" Изменить предложение ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.$store.getters.isLoggedIn &&
                  _vm.proposal.actions.supplier.create
                    ? [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-card-action btn-card-action-green my-1",
                            attrs: {
                              to: {
                                name: "Fl223PurchaseNewProposal",
                                params: { id: _vm.proposal.purchase.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(" Подать предложение ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }