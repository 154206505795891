<template>
    <div>
        <b-row>
            <b-col md="8">
                <h3 class="grey-color main_text font-weight-bold">Запросы на добавление позиций КТРУ</h3>
            </b-col>
            <b-col class="text-right" md="4">
                <b-btn :to="{ name: 'KtruRequestsCreate' }" size="sm" variant="custom-green">
                    <b-icon aria-hidden="true" class="my-auto mr-2" icon="plus-circle" />
                    Сформировать запрос
                </b-btn>
            </b-col>
        </b-row>
        <b-row class="my-3">
            <b-col md="12">
                <b-btn :href="$links.purchase_categories" class="mr-2" size="sm" variant="custom-danger">Категории предметов закупок</b-btn>
                <b-btn :href="$links.manual_ktru_requests_add" class="mr-2" size="sm" variant="custom-danger">Инструкция по добавлению позиции КТРУ</b-btn>
                <b-btn :href="$links.ktru_requests_video_manual" class="mr-2" size="sm" variant="custom-danger">
                    <b-icon aria-hidden="true" class="my-auto mr-2" icon="youtube" />
                    Видеоинструкция
                </b-btn>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!items.length" class="text-center mt-5">
                <img src="/images/empty_box.svg" alt="empty-data" />
                <p class="fs-14 grey-color my-4">В данный момент запросы на добавление позиций КТРУ отсутствуют</p>
            </b-col>
            <b-col v-else class="mb-3">
                <b-pagination v-if="items && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="my-table" class="my-2" />
                <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" small hover responsive>
                    <template #row-details="data">
                        <p v-if="data.item.description"><b>Описание:</b> {{ data.item.description }}</p>
                        <p v-if="data.item.specs && data.item.specs.length > 0"><b>Характеристики:</b></p>
                        <ul v-if="data.item.specs && data.item.specs.length > 0">
                            <li v-for="(spec, key) in data.item.specs" :key="'spec' + key">
                                <span style="font-weight: 900">{{ spec.name }}</span
                                >: {{ spec.value }}
                            </li>
                        </ul>
                    </template>
                    <template #cell(name)="data"> <action-button-small v-b-popover.hover.top="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" /> {{ data.item.name }} </template>
                    <template #cell(okei)="data">
                        {{ data.item.okei_local_symbol }}
                    </template>
                    <template #cell(country)="data">
                        {{ data.item.country_of_origin_name }}
                    </template>
                    <template #cell(status)="data">
                        <div>
                            <b>{{ data.item.status.title }}</b>
                        </div>
                        <template v-if="data.item.status.id === 'draft' || data.item.status.id === 'rejected'">
                            <div class="mt-1" v-if="data.item.status.id === 'rejected'"><b>Комментарий: </b>{{ data.item.comment }}</div>
                            <b-btn :to="{ name: 'KtruRequestsEdit', params: { id: data.item.id } }" class="btn-card-action btn-card-action-light mt-1">Редактировать</b-btn>
                            <b-btn class="btn-card-action btn-card-action-danger mt-1" @click="deleteRequest(data.item.id)">Удалить</b-btn>
                        </template>
                        <template v-else-if="data.item.status.id === 'accepted'">
                            <div class="mt-1">
                                Рег.номер созданной позиции КТРУ - <b>{{ data.item.ktru_item_reg_number }}</b>
                            </div>
                            <b-btn v-if="data.item.allow_add_to_favorite" @click="$api.cabinet.ktru.ktruFavorites.addKtruItem({ ktru_item_id: data.item.ktru_item_id }).then(() => (data.item.allow_add_to_favorite = false))" class="btn-card-action btn-card-action-green mt-1"
                                >Добавить в избранное</b-btn
                            >
                        </template>
                    </template>
                </b-table>
                <b-pagination v-if="items && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="my-table" class="my-2" @input="fillData" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'KtruRequestsList',
    data() {
        return {
            fields: [
                { key: 'name', label: 'Наименование товара (работ, услуг)', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap' },
                { key: 'okei', label: 'Ед.изм.', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } },
                { key: 'country', label: 'Страна происхождения', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } },
                { key: 'status', label: 'Статус', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '20%' } }
            ],
            items: [],
            filter: {},
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    async mounted() {
        await this.fillData();
        this.$scrollToTop();
    },
    methods: {
        async fillData() {
            try {
                this.loading = true;
                const response = await this.$api.cabinet.ktru.ktruRequests.getList(this.meta.current_page);
                this.items = response.data;
                this.meta = response.meta;
            } catch {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        async deleteRequest(requestId) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите удалить запрос на добавление позиции КТРУ?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.cabinet.ktru.ktruRequests.delete(requestId);
                        await this.fillData();
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.error(err);
                });
        }
    }
};
</script>
