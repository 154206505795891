var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center head_register-list" },
    [
      _c("span", { staticClass: "fs-52 fw-600" }, [_vm._v(_vm._s(_vm.number))]),
      _c("span", { staticClass: "pl-2 fs-18" }, [_vm._t("default")], 2),
      _vm.hasErrors
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: "На вкладке присутствуют незаполненные поля",
                  expression: "'На вкладке присутствуют незаполненные поля'",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "badge badge-danger ml-auto",
            },
            [_vm._v("!")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }