<template>
    <div>
        <b-container fluid class="main_layer py-5">
            <b-container>
                <p class="fs-52 fw-600 white-color mt-2 mb-4">Сброс пароля</p>
            </b-container>
        </b-container>
        <b-container v-if="!isLoading" class="pl-0 py-4">
            <b-container v-if="errorMessage">
                <p class="fs-32 fw-600 grey-color mt-2 mb-4">Не удалось сбросить пароль.</p>
                <p class="fs-32 fw-400 text-danger mt-2 mb-4">{{ errorMessage }}</p>
            </b-container>
            <b-container v-else>
                <p class="fs-32 fw-600 grey-color mt-2 mb-4">Произведён сброс пароля Вашей учётной записи.</p>
                <p class="fs-32 fw-400 grey-color mt-2 mb-4">На Ваш Email отправлен новый пароль.</p>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';

export default {
    name: 'reset-password',
    data() {
        return {
            errorMessage: null,
            isLoading: true
        };
    },
    async mounted() {
        const token = this.$route.params.token;
        await $api.authentication
            .resetPassword(token)
            .catch((error) => {
                this.errorMessage = error.response.data.message;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
