<template>
    <div>
        <input type="file" id="fileload" ref="fileload" v-on:change="onChangeFileUpload" style="display: none" accept=".xml" />
        <b-button @click="fileClick" variant="custom-danger">
            <slot></slot>
        </b-button>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';

export default {
    name: 'poa-upload-button',
    props: {
        isMain: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            file: null,
            returnFile: null
        };
    },
    methods: {
        fileClick() {
            this.$refs.fileload.value = '';
            this.file = null;
            this.returnFile = null;
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            this.$refs.fileload.dispatchEvent(evt);
        },
        async submitForm() {
            let formData = new FormData();
            formData.append('file', this.file);
            if (await $api.personal.profile.changePOA(formData)) {
                this.$emit('upload', true);
            }
        },
        onChangeFileUpload() {
            if (this.$refs.fileload.files.length > 0) {
                this.file = this.$refs.fileload.files[0];
                this.submitForm();
            }
        }
    }
};
</script>
