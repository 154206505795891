var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "h3",
          { staticClass: "grey-color mb-4 main_text font-weight-bold" },
          [_vm._v("Участие организации в закупках по 223-ФЗ")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
          },
          [
            _c("form-row-edit-text", {
              attrs: {
                label: "Положения о закупках",
                v: _vm.$v.form.purchasing_rules_url,
                placeholder:
                  "Укажите ссылку на Положение о закупках, опубликованное в ЕИС",
                description:
                  "Ссылка на Положение о закупках, опубликованное в ЕИС",
              },
              model: {
                value: _vm.$v.form.purchasing_rules_url.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.$v.form.purchasing_rules_url, "$model", $$v)
                },
                expression: "$v.form.purchasing_rules_url.$model",
              },
            }),
            _c("form-row-edit-price", {
              attrs: {
                label: "Лимит закупки",
                v: _vm.$v.form.limit,
                limit: 999999999999999999,
              },
              model: {
                value: _vm.$v.form.limit.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.$v.form.limit, "$model", $$v)
                },
                expression: "$v.form.limit.$model",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-right mx-1" },
          [
            _c(
              "b-button",
              {
                staticClass: "text-uppercase",
                attrs: {
                  variant: "custom-green",
                  disabled: _vm.$v.$invalid || !_vm.$v.$anyDirty,
                },
                on: { click: _vm.updateData },
              },
              [_vm._v("Сохранить информацию о закупках по 223-ФЗ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }