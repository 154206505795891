var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: "Электронные подписи",
            visible: _vm.visible,
            size: "lg",
            centered: "",
            "hide-footer": "",
          },
          on: { hidden: _vm.hidden },
        },
        [
          _c(
            "div",
            { staticClass: "px-3" },
            [
              _c("p", { staticClass: "fs-14 grey-color" }, [
                _c("img", {
                  staticClass: "mr-3",
                  attrs: { src: "/images/certificate.svg", alt: "certificate" },
                }),
                _vm._v(" " + _vm._s(_vm.offer.full_name) + " "),
              ]),
              _c(
                "b-row",
                { staticClass: "border-bottom py-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Организация: ")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "fs-14 grey-color fw-600",
                      attrs: { md: "9" },
                    },
                    [_vm._v(" " + _vm._s(_vm.offer.full_name) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Дата и время: ")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "fs-14 grey-color fw-600",
                      attrs: { md: "9" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getDateTimeFormat(_vm.offer.created_at)) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "bordered p-0" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "text-uppercase fw-600 w-100 d-flex justify-content-between",
                          attrs: { variant: "custom-unstyle-grey" },
                          on: { click: _vm.showCollapse },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.collapse ? "Скрыть" : "Показать") +
                              " электронную подпись "
                          ),
                          _c("i", {
                            staticClass: "fas",
                            class: {
                              "fa-chevron-down": !_vm.collapse,
                              "fa-chevron-up": _vm.collapse,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "b-collapse",
                        { staticClass: "mt-2", attrs: { id: "certificate" } },
                        [
                          _vm.offer && _vm.offer.x509certificate
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-10 grey-color p-3 greyBg m-0",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.offer.x509certificate[0]) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }