<template>
    <b-card-title>
        <b-row>
            <b-col md="6" class="my-1">
                Поставщик:<br />
                <router-link :to="{ name: 'OrganizationShow', params: { id: proposal.supplier.id } }" target="_blank" class="font-weight-bold">
                    {{ proposal.supplier.name }} <template v-if="proposal.supplier.country_code">({{ proposal.supplier.country_code }})</template>
                </router-link>
            </b-col>
            <b-col md="6" class="my-1 text-right">
                Стоимость предложения с НДС: <strong>{{ $formatPrice(proposal.price_with_vat) }} руб.</strong><br />
                Стоимость предложения без НДС: <strong>{{ $formatPriceOrMdash(proposal.price_wo_vat) }} руб.</strong>
            </b-col>
        </b-row>
    </b-card-title>
</template>

<script>
export default {
    name: 'proposal-card-title',
    props: {
        index: {},
        proposal: {}
    }
};
</script>

<style scoped>
a {
    color: var(--green-color);
}
</style>
