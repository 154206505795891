var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-2 toast_parent", style: "order:" + _vm.toast.id },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "toast_head", class: "toast-" + _vm.toast.variant },
          [
            _vm._v(" " + _vm._s(_vm.toast.title) + " "),
            _c("i", { staticClass: "close_btn", on: { click: _vm.remove } }, [
              _c("span"),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "toast_body" },
          [
            _vm.toast.boldText
              ? _c("p", { staticClass: "fw-600 boldText m-0" }, [
                  _vm._v(" " + _vm._s(_vm.toast.boldText) + " "),
                ])
              : _vm._e(),
            _vm.toast.text
              ? _c("span", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(_vm.toast.text) + " "),
                ])
              : _vm._e(),
            _vm.toast.linkText
              ? _c("span", {
                  staticClass: "text mt-2",
                  staticStyle: { display: "block" },
                  domProps: { innerHTML: _vm._s(_vm.toast.linkText) },
                })
              : _vm._e(),
            _vm.toast.link
              ? _c(
                  "router-link",
                  {
                    staticClass: "fs-12 green-color",
                    attrs: { to: _vm.toast.link },
                  },
                  [_vm._v("подробнее...")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }