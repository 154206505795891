<template>
    <b-form-group :description="description" :label="label + ':'" :label-for="id" class="w-100" :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <multiselect
            v-model="text"
            :options="options"
            :multiple="true"
            :allow-empty="allowEmpty"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="false"
            :show-labels="false"
            :placeholder="placeholder"
            :label="labelField"
            :track-by="trackBy"
            :disabled="disabled"
            :class="{ 'is-valid': state === true, 'is-invalid': state === false }">
            <template slot="clear" v-if="allowEmpty">
                <div class="multiselect__clear" v-if="text.length > 0" @mousedown.prevent.stop="clearAll()"></div>
            </template>
            <span slot="noOptions">Нет данных.</span>
            <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
        </multiselect>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-select-multiple',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        labelField: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        allowEmpty: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        clearAll() {
            this.text = [];
        }
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            }
        },
        id() {
            return 'form_row_select-multiple_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        }
    }
};
</script>

<style>
.multiselect__clear {
    position: absolute;
    right: 40px;
    height: 40px;
    width: 40px;
    display: block;
    cursor: pointer;
    z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
}

.multiselect__clear:before {
    transform: rotate(45deg);
}

.multiselect__clear:after {
    transform: rotate(-45deg);
}
</style>
